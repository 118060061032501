// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Product } from '../component/pages/dashboard/product.store';

@Injectable({
    providedIn: 'root',
})

export class SharedProductService {
    private productsSource = new BehaviorSubject<Product[]>([]);
    currentProducts = this.productsSource.asObservable();

    constructor() { }

    addProduct(product: Product) {
        const currentProducts = this.productsSource.value;
        this.productsSource.next([...currentProducts, product]);
    }

    clearProducts() {
        this.productsSource.next([]);
    }

    updateProductUrl(product: Product, newUrl: string) {
        const currentProducts = this.productsSource.value.map(p =>
            p === product ? { ...p, image_url_1: newUrl } : p
        );
        this.productsSource.next(currentProducts);
    }
}
