import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbTokenService } from '@nebular/auth';
import { CategoryService } from 'src/app/deebr/service/category.service';
import { TokenService } from 'src/app/deebr/service/token.service';
import { ReportStore } from '../../pages/report/report.store';
import { EmailStore } from '../../pages/generator/email.store';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  constructor(
    private tokenService: TokenService,
    private nbTokenService: NbTokenService,
    private router: Router,
    private categoryService: CategoryService,
    private reportStore: ReportStore,
    private emailstore: EmailStore
  ) {}

  ngOnInit(): void {
    this.nbTokenService.clear().subscribe(() => {
      this.tokenService.clearToken();
      this.categoryService.clear();
      localStorage.setItem('logoutEvent', Date.now().toString()); // Set logout event
      this.router.navigate(['/auth/login']);
      this.reportStore.resetState();
      this.emailstore.resetState();
    });
  }
}
