import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService, NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { EntrepriseService } from 'src/app/deebr/service/entreprise.service';
import { Router } from '@angular/router';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  enterpriseLogo: string | undefined = undefined;
  enterpriseName: string | undefined = undefined;
  @ViewChild('dialogDeconnexion') dialogDeconnexion!: TemplateRef<any>;
  dialogRef: NbDialogRef<any> | undefined = undefined;
  private onClickSubscription?: Subscription;

  userMenu: NbMenuItem[] = [
    { title: 'Déconnexion' }
  ];

  base64image =
    'data:image/svg+xml;base64,PHN2ZyBpZD0iU3ZnanNTdmcxMDAxIiB3aWR0aD0iMjg4IiBoZWlnaHQ9IjI4OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWxuczpzdmdqcz0iaHR0cDovL3N2Z2pzLmNvbS9zdmdqcyI+PGRlZnMgaWQ9IlN2Z2pzRGVmczEwMDIiPjwvZGVmcz48ZyBpZD0iU3ZnanNHMTAwOCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI4OCIgaGVpZ2h0PSIyODgiPjxnIGRhdGEtbmFtZT0iTGF5ZXIgMiIgZmlsbD0iIzhmOWJiMyIgY2xhc3M9ImNvbG9yMDAwIHN2Z1NoYXBlIj48ZyBkYXRhLW5hbWU9InBlcnNvbiIgZmlsbD0iIzhmOWJiMyIgY2xhc3M9ImNvbG9yMDAwIHN2Z1NoYXBlIj48cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIG9wYWNpdHk9IjAiIGZpbGw9IiM4ZjliYjMiIGNsYXNzPSJjb2xvcjAwMCBzdmdTaGFwZSI+PC9yZWN0PjxwYXRoIGQ9Ik0xMiAxMWE0IDQgMCAxMC00LTQgNCA0IDAgMDA0IDR6bTAtNmEyIDIgMCAxMS0yIDIgMiAyIDAgMDEyLTJ6TTEyIDEzYTcgNyAwIDAwLTcgNyAxIDEgMCAwMDIgMCA1IDUgMCAwMTEwIDAgMSAxIDAgMDAyIDAgNyA3IDAgMDAtNy03eiIgZmlsbD0iIzhmOWJiMyIgY2xhc3M9ImNvbG9yMDAwIHN2Z1NoYXBlIj48L3BhdGg+PC9nPjwvZz48L3N2Zz48L2c+PC9zdmc+';

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  constructor(
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private entrepriseService: EntrepriseService,
    private dialogService: NbDialogService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.entrepriseService.getEntreprise().subscribe(response => {
      if (response.count > 0) {
        this.enterpriseLogo = response.results[0].enterprise_logo; // Supposons que nous prenons le premier résultat
        this.enterpriseName = response.results[0].name; // Supposons que nous prenons le premier résultat
      }
    });
  
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));
    
      this.onClickSubscription = this.menuService.onItemClick()
      .pipe(
        filter(({ item }) => item.title === 'Déconnexion')
      )
      .subscribe(() => {
        this.beforeDeconnexion();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.onClickSubscription) {
      this.onClickSubscription.unsubscribe();
    }
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  closeDialog(dialogRef: NbDialogRef<any> | undefined = undefined) {
    dialogRef!.close('no');
  }

  confirmLogout(dialogRef: NbDialogRef<any> | undefined = undefined) {
    dialogRef!.close('yes');
  }

  beforeDeconnexion() {
    this.dialogRef = this.dialogService.open(this.dialogDeconnexion);
    this.dialogRef.onClose.subscribe(result => {
      if (result === 'yes') {
        this.router.navigate(['/auth/logout']);
      }
    });
  }
}
