import { 
  Component, OnInit, OnDestroy, OnChanges, AfterViewInit,
  Input, Output, SimpleChanges, EventEmitter, ViewChild, ElementRef, TemplateRef 
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Category } from '../../model/category.model';
import { Product } from '../pages/dashboard/product.store';
import { GeneratorService } from 'src/app/deebr/service/generator.service';
import { SharedDashboardProductService } from 'src/app/deebr/service/shared-dashboard-product.service';
import { SharedProductService } from 'src/app/deebr/service/shared-product.service';
import { ScrappedImagesService } from 'src/app/deebr/service/scrapped-images.service';
import { NbDialogService } from '@nebular/theme';
import { ChangeDetectionStrategy } from '@angular/core';
import { createSwapy } from 'swapy';
import { ChangeDetectorRef } from '@angular/core';

/**  
 * Pour gérer la possibilité d'avoir soit une image unique soit un tableau d'images  
 */
export interface ProductDisposition {
  col: number;
  row: number;
  colspan: number;
  rowspan: number;
  ordre_generation: number;
  id: number | null;
  image_url_1: string;
  image_produit_scenario: any | undefined;
  width: number;
  height: number;
  url: string;
  categoryId?:number;
}

export interface DashboardProduct {
  id: number | null;
  image_url_1: string;
  image_produit_scenario: any | undefined;
  ordre_generation: number;
  width: number;
  height: number;
  url: string;
  // Ces propriétés permettront de suivre la position et la taille dans la grille Swapy
  x?: number;
  y?: number;
  cols?: number;
  rows?: number;
}

@Component({
  selector: 'app-drag-drop-products',
  templateUrl: './drag-drop-products.component.html',
  styleUrls: ['./drag-drop-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush  // ✅ Ajouté

})
export class DragDropProductsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() show: boolean = false;
  @Input() templateId: number | null = null;
  @Input() productsDisposition: ProductDisposition[] = [];
  @Input() selectedProducts: { 
    id: number | null; 
    image_url_1: string; 
    image_produit_scenario: string | undefined; 
    ordre_generation: number; 
    width: number; 
    height: number; 
    url: string 
  }[] = [];
  @Output() orderChanged = new EventEmitter<{ updatedProducts: ProductDisposition[] }>();
  @Output() imageSelected = new EventEmitter<{ imageUrl: string, taskId: string }>();
  @Input() resetIndex: { categoryId: Category['id'], productId: number | null } | null = null;

  @ViewChild('dialogUploadImage') dialogUploadImage!: TemplateRef<any>;
  // Référence au conteneur Swapy
  @ViewChild('swapyContainer') swapyContainer!: ElementRef;

  selectedFile: File | null = null;
  errorMessage: string | null = null;
  panelOuvert: boolean = false;
  sidePanelHeight: string = '89%';
  sidePanelMarginTop: string = '90px';
  clickedProduct: DashboardProduct | null = null;
  private _showCreateGif: boolean = false;
  currentImageIndexes: { [key: number]: number } = {};
  images: { [key: number]: (string | string[])[] } = {};
  private scrappedImagesSubscription!: Subscription;
  sharedProducts: Product[] = [];
  loadingImages: { [key: number]: boolean } = {};

  // Tableau servant de "dashboard" pour les produits
  dashboard: DashboardProduct[] = [];

  // Instance de Swapy
  swapyInstance: any;

  constructor(
    private generatorService: GeneratorService,
    private sharedDashboardProductService: SharedDashboardProductService,
    private scrappedImagesService: ScrappedImagesService,
    private sharedProductService: SharedProductService,
    private dialogService: NbDialogService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    this.selectedProducts.forEach((product) => {
      this.currentImageIndexes[product.id!] = 0;
      this.images[product.id!] = [];
      this.loadingImages[product.id!] = true;
    });


    this.scrappedImagesSubscription = this.scrappedImagesService.currentProducts.subscribe((productMap) => {
      productMap.forEach((urls, productId) => {
        if (!this.images[productId!]) {
          this.images[productId!] = [];
        }

        let hasValidImages = false;
        urls.forEach((url: string) => {
          if (!this.images[productId!].includes(url) && url !== "no image found") {
            this.images[productId!].push(url);
            hasValidImages = true;

          }
        });

        this.loadingImages[productId!] = false;
        
      });
      this.cdr.detectChanges();
    });

    this.sharedProductService.currentProducts.subscribe(products => {
      this.sharedProducts = products;

      this.sharedProducts.forEach(product => {
        if (!this.images[product.id]) {
          this.loadingImages[product.id!] = true;
          this.images[product.id] = [];
        }
        if (!this.images[product.id].includes(product.image_url_1)) {
          this.images[product.id].unshift(product.image_url_1);
        }
      });
    });
  }

  private initSwapy(): void {


    if (this.swapyContainer?.nativeElement) {
      //console.log("🚀 Initialisation de Swapy...");
      
      this.swapyInstance = createSwapy(this.swapyContainer.nativeElement, {
        animation: 'none',
        swapMode: 'drop',
        autoScrollOnDrag:true,
      });
      
  
      this.swapyInstance.onSwap((event: any) => {
        //console.log("↔️ Swap détecté:", event);
        this.handleSwapyItemChange(event);
        // ...
      });
  
      //console.log("slotItemMap()", this.swapyInstance.slotItemMap());
    }
  }
  

  private updateAfterSwap(): void {
    //console.log("🔄 Mise à jour après swap");
  
    // Mise à jour du dashboard
    this.updateDashboard();

    // Vérifier si Swapy est initialisé avant de le ré-initialiser
    if (this.swapyInstance) {
      //console.log("🔄 Mise à jour de Swapy...");
      //console.log(this.swapyContainer.nativeElement)
      setTimeout(() => {
        this.swapyInstance.update();
        //console.log("✅ Swapy mis à jour");
        //console.log("itemmap à jour :", this.swapyInstance.slotItemMap());
      }, 200);
    } else {
      //console.log("🚀 Initialisation de Swapy dans update...");
      setTimeout(() => this.initSwapy(), 0);
    }
  }
  

  handleSwapyItemChange(event: any): void {
    //console.log("🔄 Swapy event:", event);
  
    const fromSlot = Number(event.fromSlot);
    const toSlot = Number(event.toSlot);
  
    const fromIndex = this.dashboard.findIndex(d => d.ordre_generation === fromSlot);
    const toIndex = this.dashboard.findIndex(d => d.ordre_generation === toSlot);
  
    if (fromIndex !== -1 && toIndex !== -1) {

      [this.dashboard[fromIndex].ordre_generation, this.dashboard[toIndex].ordre_generation] =
        [this.dashboard[toIndex].ordre_generation, this.dashboard[fromIndex].ordre_generation];
  
      [this.dashboard[fromIndex].x, this.dashboard[toIndex].x] =
        [this.dashboard[toIndex].x, this.dashboard[fromIndex].x];
  
      [this.dashboard[fromIndex].y, this.dashboard[toIndex].y] =
        [this.dashboard[toIndex].y, this.dashboard[fromIndex].y];
  
      [this.dashboard[fromIndex].cols, this.dashboard[toIndex].cols] =
        [this.dashboard[toIndex].cols, this.dashboard[fromIndex].cols];
  
      [this.dashboard[fromIndex].rows, this.dashboard[toIndex].rows] =
        [this.dashboard[toIndex].rows, this.dashboard[fromIndex].rows];
  
      //console.log(" Dashboard mis à jour après swap:", this.dashboard);
  

      this.productsDisposition = this.dashboard.map(product => ({
        id: product.id,
        image_url_1: product.image_url_1,
        image_produit_scenario: product.image_produit_scenario,
        ordre_generation: product.ordre_generation,
        col: product.x ?? 0,  
        row: product.y ?? 0,  
        colspan: product.cols ?? 1,  
        rowspan: product.rows ?? 1,  
        width: product.width,
        height: product.height,
        url: product.url,
      }));
  
      //console.log(" productsDisposition mis à jour:", this.productsDisposition);
  
      // Émettre l'événement avec les nouvelles positions
      this.orderChanged.emit({
        updatedProducts: this.productsDisposition
      });
  
  
    } else {
      console.warn("⚠️ Impossible de trouver les éléments à échanger dans le dashboard");
    }
  }
  

  ngAfterViewInit(): void {

  }
    
ngOnChanges(changes: SimpleChanges): void {
  //console.log("coucou change", this.show)
  if (changes['templateId'] || changes['selectedProducts']) {
    //console.log("this.productsDisposition", this.productsDisposition);
    //console.log("this.selectedProducts", this.selectedProducts);
    // Mise à jour du layout
    const maxCols = Math.max(...this.productsDisposition.map(p => p.col + p.colspan)) + 1;
    const maxRows = Math.max(...this.productsDisposition.map(p => p.row + p.rowspan)) + 1;
    const fixedColWidth = Math.min(200, (570 - maxCols * 10) / (maxCols - 1));
    const calculatedHeight = fixedColWidth * (maxRows - 1) + 10 * maxRows;
    this.sidePanelHeight = `${Math.min(calculatedHeight, window.innerHeight * 0.89)}px`;
    this.sidePanelMarginTop = `${Math.max((window.innerHeight - calculatedHeight) / 2 + 45, 90)}px`;


    this.updateAfterSwap();
  }
  if(this.show === false && this.swapyInstance){
    this.swapyInstance.destroy()
    this.swapyInstance = null;
    this.dashboard = []
  }

  if (this.resetIndex && this.resetIndex.productId !== null) {
    this.currentImageIndexes[this.resetIndex.productId] = 0;
    this.resetIndex = null;
  }
  setTimeout(() => {
    if (!this.swapyInstance) {
      //console.log("🚀 Réinitialisation de Swapy après mise à jour...");
      this.initSwapy();
    }
  }, 300);
}


  ngOnDestroy(): void {
    if (this.scrappedImagesSubscription) {
      this.scrappedImagesSubscription.unsubscribe();
    }
  }

  togglePanel() {
    this.panelOuvert = !this.panelOuvert;
  }

  getImageList(product: DashboardProduct): (string | string[])[] {
    const productImages: (string | string[])[] = [...(this.images[product.id!] || [])];
    if (
      product.image_produit_scenario &&
      product.image_produit_scenario !== '' &&
      product.image_produit_scenario !== -1 &&
      !productImages.includes(product.image_produit_scenario)
    ) {
      productImages.unshift(product.image_produit_scenario);
    }
    //attention limite à 10 images
    return productImages.slice(0, 10);
  }

  getCurrentImage(product: DashboardProduct): string | string[] {
    const productImages = this.getImageList(product);
    const currentIndex = this.currentImageIndexes[product.id!];
    return this.extractCurrentImage(productImages, currentIndex);
  }
  
  private extractCurrentImage(images: (string | string[])[], index: number): string | string[] {
    const imageElement = images[index] || images[0];
    return Array.isArray(imageElement) ? imageElement : imageElement || '';
  }
  
  trackByItem(index: number, item: DashboardProduct): string {
    return `${item.id}-${item.x}-${item.y}-${item.cols}-${item.rows}`;
  }

  private updateProductDisposition(product: DashboardProduct, updatedImage: string | string[]): void {
    const productIndex = this.productsDisposition.findIndex(p => p.id === product.id);
    if (productIndex !== -1) {
      // Si besoin, convertir en string (exemple : prendre le premier élément du tableau)
      const imageToAssign = Array.isArray(updatedImage) ? updatedImage[0] : updatedImage;
      this.productsDisposition[productIndex].image_url_1 = imageToAssign;
    }
    this.orderChanged.emit({
      updatedProducts: this.productsDisposition
    });
    
  }

  previousImage(product: DashboardProduct): void {
    const productImages = this.getImageList(product);
    const totalImages = productImages.length;
    const index = this.currentImageIndexes[product.id!] || 0;
    this.currentImageIndexes[product.id!] = index > 0 ? index - 1 : totalImages - 1;
    const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[product.id!]);
    this.updateProductDisposition(product, updatedImage);
  }
  
  nextImage(product: DashboardProduct): void {
    const productImages = this.getImageList(product);
    const index = this.currentImageIndexes[product.id!] || 0;
    this.currentImageIndexes[product.id!] = index < productImages.length - 1 ? index + 1 : 0;
    const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[product.id!]);
    this.updateProductDisposition(product, updatedImage);
  }

  selectImage(product: DashboardProduct, index: number): void {
    this.currentImageIndexes[product.id!] = index;
    const productImages = this.getImageList(product);
    const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[product.id!]);
    this.updateProductDisposition(product, updatedImage);
  }

  adjustImageSizeOnLoad(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    const containerElement = imgElement.parentElement as HTMLElement;
    if (!imgElement || !containerElement) {
      return;
    }
    this.adjustImageSize(imgElement, containerElement);
  }
  
  adjustImageSize(imgElement: HTMLImageElement, containerElement: HTMLElement): void {
    const imgWidth = imgElement.naturalWidth;
    const imgHeight = imgElement.naturalHeight;
    const containerWidth = containerElement.clientWidth;
    const containerHeight = containerElement.clientHeight;
    const imgRatio = imgWidth / imgHeight;
    const containerRatio = containerWidth / containerHeight;
    if (imgRatio > containerRatio) {
      imgElement.style.width = '100%';
      imgElement.style.height = 'auto';
    } else {
      imgElement.style.width = 'auto';
      imgElement.style.height = '100%';
    }
  }
  
  updateDashboard(): void {
    this.dashboard = this.selectedProducts.map((product, index) => {
      const slot = this.productsDisposition.find(sd => sd.ordre_generation === index + 1);
      return {
        id: product.id,
        image_url_1: product.image_url_1,
        image_produit_scenario: product.image_produit_scenario,
        ordre_generation: product.ordre_generation,
        width: product.width,
        height: product.height,
        url: product.url,
        x: slot?.col ?? 0,  // ✅ Transformation col -> x
        y: slot?.row ?? 0,  // ✅ Transformation row -> y
        cols: slot?.colspan ?? 1,  // ✅ Transformation colspan -> cols
        rows: slot?.rowspan ?? 1  // ✅ Transformation rowspan -> rows
      };
    });

    //console.log("🛠 Mise à jour du dashboard avec les valeurs correctes :", this.dashboard);
  }
  

  removeProduct(product: DashboardProduct): void {
    const index = this.dashboard.findIndex(p => p.id === product.id);
    if (index !== -1) {
      if (this.dashboard[index].id !== null) {
        this.currentImageIndexes[this.dashboard[index].id!] = 0;
      }
      this.dashboard[index].id = null;
      this.dashboard[index].image_url_1 = "";
      this.dashboard[index].image_produit_scenario = undefined;
      this.dashboard[index].url = "";
      const updatedProducts = this.dashboard.map(p => ({
        id: p.id,
        image_url_1: p.image_url_1,
        image_produit_scenario: p.image_produit_scenario,
        ordre_generation: p.ordre_generation,
        col: p.x ?? 0,
        row: p.y ?? 0,
        colspan: p.cols ?? 0,
        rowspan: p.rows ?? 0,
        width: p.width,
        height: p.height,
        url: p.url,
      }));
      this.orderChanged.emit({
        updatedProducts: updatedProducts
      });
      this.productsDisposition = updatedProducts;
    }
    this.swapyInstance.update()
  }

  onButtonClick(event: MouseEvent, product: DashboardProduct): void {
    event.stopPropagation();
    document.getElementById('file-upload-drag-and-drop')?.click();
    this.clickedProduct = product;
  }

  setClickedProduct(product: DashboardProduct): void {
    this.clickedProduct = product;
  }
  
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      if (this.clickedProduct) {
        this.uploadSelectedFile(this.clickedProduct);
      }
    }
  }

  uploadSelectedFile(product: DashboardProduct) {
    if (this.selectedFile) {
      const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
      const allowedExtensions = ['jpeg', 'jpg', 'png', 'webp'];
      const fileSizeInMB = this.selectedFile.size / 1000000;
  
      if (!allowedExtensions.includes(fileExtension!)) {
        this.errorMessage = "Ce format de fichier n’est pas pris en charge. Vous pouvez importer un fichier .jpeg, .png ou .webp.";
        this.dialogService.open(this.dialogUploadImage);
        return;
      }
  
      if (fileSizeInMB > 5) {
        this.errorMessage = "La taille du fichier ne doit pas dépasser 5 Mo.";
        this.dialogService.open(this.dialogUploadImage);
        return;
      }
  
      this.generatorService.uploadFile(this.selectedFile).subscribe((uploadedImageUrl: string) => {
        const productImages = this.getImageList(product);
        productImages.unshift(uploadedImageUrl);
        this.images[product.id!] = productImages;
        this.currentImageIndexes[product.id!] = 0;
        const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[product.id!]);
        this.updateProductDisposition(product, updatedImage);
      });
    } else {
      console.error("Aucun fichier sélectionné");
    }
  }
  
  handleCloseRequest() {
    this.showCreateGif = false;
  }

  get showCreateGif(): boolean {
    return this._showCreateGif;
  }

  set showCreateGif(value: boolean) {
    this._showCreateGif = value;
  }

  getFirstImageFromCarrousel(image: string | string[]): string {
    return Array.isArray(image) ? (image[0] || '') : (image || '');
  }

  createGif(event: MouseEvent, product: DashboardProduct) {
    this.showCreateGif = true;
    event.stopPropagation();
    this.clickedProduct = product;
    this.sharedDashboardProductService.addProduct(this.clickedProduct);
    this.cdr.detectChanges();
  }

  handleGifCreated(gifUrl: string) {
    if (this.clickedProduct) {
      const productImages = this.getImageList(this.clickedProduct);
      productImages.unshift(gifUrl);
      this.images[this.clickedProduct.id!] = productImages;
      this.currentImageIndexes[this.clickedProduct.id!] = 0;
      const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[this.clickedProduct.id!]);
      this.updateProductDisposition(this.clickedProduct, updatedImage);
    }
  }

  isGifImage(url: string | string[]): boolean {
    if (Array.isArray(url)) {
      return url[0].toLowerCase().endsWith('.gif');
    } else if (typeof url === 'string') {
      return url.toLowerCase().endsWith('.gif');
    }
    return false;
  }

  isCarrousel(image: string | string[]): boolean {
    return Array.isArray(image);
  }

  handleCarrouselCreated(carrouselImages: string[]) {
    if (this.clickedProduct) {
      const productImages = this.getImageList(this.clickedProduct);
      productImages.unshift(carrouselImages);
      this.images[this.clickedProduct.id!] = productImages;
      this.currentImageIndexes[this.clickedProduct.id!] = 0;
      const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[this.clickedProduct.id!]);
      this.updateProductDisposition(this.clickedProduct, updatedImage);
    }
  }

  preloadImages(imageList: (string | string[])[]): void {
    const flattenedImages: string[] = imageList.flatMap((image) =>
      Array.isArray(image) ? image : [image]
    );
    flattenedImages.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
    });
  }
  getSlotOrdre(item: DashboardProduct): number {
    const SlotOrdre = item.ordre_generation;
    return SlotOrdre;
  }
  
  // Méthode pour générer un nom unique pour le slot et l'item en fonction de l'item du dashboard
  getSlotName(item: DashboardProduct): string {
    if (item.id) {
      return `${item.id}`;
    } 
    // 🔥 Ajout d'un identifiant unique pour éviter les doublons de "empty-X"
    return `empty-${item.ordre_generation}-${Math.random().toString(36).substr(2, 5)}`;
  }
  
  correctPosition(items: any[]): any[] {
    let occupiedCells = new Set<string>();
  
    return items.map(item => {
      let x = item.x ?? 0;
      let y = item.y ?? 0;
      const cols = item.cols ?? 1;
      const rows = item.rows ?? 1;
  
      // Vérifie si la cellule est déjà occupée
      while (this.isOverlapping(x, y, cols, rows, occupiedCells)) {
        y += 1; // Décale vers le bas
      }
  
      // Marque les cellules comme occupées
      for (let dx = 0; dx < cols; dx++) {
        for (let dy = 0; dy < rows; dy++) {
          occupiedCells.add(`${x + dx},${y + dy}`);
        }
      }
  
      return { ...item, x, y }; // Retourne l'élément avec la nouvelle position
    });
  }
  
  // Vérifie si une cellule est déjà occupée
  isOverlapping(x: number, y: number, cols: number, rows: number, occupiedCells: Set<string>): boolean {
    for (let dx = 0; dx < cols; dx++) {
      for (let dy = 0; dy < rows; dy++) {
        if (occupiedCells.has(`${x + dx},${y + dy}`)) {
          return true;
        }
      }
    }
    return false;
  }
  

}
