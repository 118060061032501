import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Template } from '../pages/generator/email.store';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss']
})
export class TemplateModalComponent {
  @Input() templates: Template[] = [];
  @Output() templateSelected = new EventEmitter();
  
  isOpen = false;
  searchQuery: string = '';
  
  constructor(private cdRef: ChangeDetectorRef) {}
  
  get filteredTemplates(): Template[] {
    if (!this.searchQuery || this.searchQuery.trim() === '') {
      return this.templates;
    }
    
    const query = this.searchQuery.toLowerCase().trim();
    return this.templates.filter(template => 
      // Adjust these properties based on your Template interface
      template.name?.toLowerCase().includes(query)
    );
  }
  
  selectTemplate(template: Template) {
    this.templateSelected.emit(template);
    this.isOpen = false;
  }
  
  open() {
    this.isOpen = true;
    this.searchQuery = ''; // Clear search when opening
    this.cdRef.detectChanges();
  }
  
  close() {
    this.isOpen = false;
  }
}
