import { Component, Input, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { GeneratorService, GifStatus } from 'src/app/deebr/service/generator.service';
import { interval, of, concat, EMPTY } from 'rxjs';
import { exhaustMap, tap, takeWhile, timeout, catchError, delay } from 'rxjs/operators';


@Component({
  selector: 'app-image-to-gif-dialog',
  templateUrl: './image-to-gif-dialog.component.html',
  styleUrls: ['./image-to-gif-dialog.component.scss']
})
export class ImageToGifDialogComponent implements OnInit {
  @ViewChild('image') image!: ElementRef<HTMLImageElement>;
  frameStyles: any = {};
  @Input() imageUrl: string = '';
  progress: number = 0;
  gifUrl?: string;
  progressStarted: boolean = false;
  errorMessage?: string;
  dialogError: NbDialogRef<any> | undefined = undefined;
  @ViewChild('dialogErrorAnimate') dialogErrorAnimate!: TemplateRef<any>;

  constructor(
    private generatorService: GeneratorService,
    protected dialogRef: NbDialogRef<ImageToGifDialogComponent>,
    private dialogService: NbDialogService,
  ) {}

  ngOnInit(): void {
    console.log('Image URL in dialog:', this.imageUrl);
    this.calculateFrameSize();

  }


  calculateFrameSize() {
    if (this.image && this.image.nativeElement) {
      const img = this.image.nativeElement;
      const imgRatio = img.naturalWidth / img.naturalHeight;
      const frameRatio = 5 / 3;
      console.log("coucou", imgRatio);
  
      if (imgRatio < frameRatio) {
        console.log("coucou 2");
        const cadreHeight = img.naturalWidth / 1.67;
        console.log(cadreHeight)
        console.log(img.naturalHeight)
        const pct = cadreHeight / img.naturalHeight * 100;
        this.frameStyles = {
          width: '100%',
          height: `calc(${pct}%)`,
        };
      } else {
        const cadreWidth = img.naturalHeight * 1.67;
        const pct = cadreWidth / img.naturalWidth * 100;
        this.frameStyles = {
          height: '100%',
          width: `calc(${pct}%)`,
        };
      }
    }
  }
  

  onValidate(): void {
    this.progressStarted = true;
    this.generatorService.imageToGif(this.imageUrl).subscribe(response => {
      const taskId = response.task_id;
      if (taskId === 'error') {
        this.errorMessage = "Une erreur s'est produite lors de la génération du GIF.";
        // this.dialogRef.close();
      } else {
        interval(2000).pipe(
          exhaustMap(() => 
            this.generatorService.imageToGifProgress(taskId).pipe(
              timeout(20000),  // Augmente le délai d'attente à 10 secondes pour chaque requête
              catchError(error => {
                console.error('Error occurred:', error);
                // Retourner une valeur par défaut respectant l'interface GifStatus
                return of({ status: 'FAILED', progress: 0 } as GifStatus);
              })
            )
          ),
          tap((status: GifStatus) => {
            this.progress = status.progress * 100;
            if (status.status === 'SUCCEEDED') {
              this.gifUrl = status.gif_url || '';
              this.dialogRef.close(this.gifUrl);
            }
            else if (status.status === 'FAILED') {
              this.dialogRef.close(this.gifUrl);
              this.dialogError = this.dialogService.open(this.dialogErrorAnimate)
            }
          }),
          takeWhile((status: GifStatus) => status.status !== 'SUCCEEDED' && status.status !== 'FAILED', true)
        ).subscribe();
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  closeDialogError() {
    this.dialogError?.close();
  }

}
