<div class="modal-container" *ngIf="show">
  <div class="modal-content" *ngIf="!isReloading">
    <button class="close-button" (click)="onClose()">
      <nb-icon icon="close-outline"></nb-icon>
    </button>
    <h3 style="text-align: center; width: 75%; margin: auto;">{{emailStore.getCurrentImageIndex(taskId) | async}}/{{emailStore.getImagesArrayLength(taskId) | async}} : Choisissez votre image préférée ou recréez-en une :</h3>
    

 
  <!-- Sinon, affichez l'image -->
    <div >
        <img [src]="imageUrl" alt="Votre image" />
    </div>


    <div class="button-container">
      <button nbButton appearance="filled" status="basic" [nbSpinner]="isLoading" *ngIf="selectedButton === 0 || selectedButton === 1" (click)="selectImage(1)">Image 1</button>
      <button nbButton appearance="filled" status="basic" [nbSpinner]="isLoading" *ngIf="selectedButton === 0  || selectedButton === 2" (click)="selectImage(2)">Image 2</button>
      <button nbButton appearance="filled" status="basic" [nbSpinner]="isLoading" *ngIf="selectedButton === 0  || selectedButton === 3" (click)="selectImage(3)">Image 3</button>
      <button nbButton appearance="filled" status="basic" [nbSpinner]="isLoading" *ngIf="selectedButton === 0  || selectedButton === 4" (click)="selectImage(4)">Image 4</button>
      
      <!-- Hide the button when image is being generated (isLoading = true) -->
      <button nbButton appearance="filled" status="basic" *ngIf="!isLoading && (selectedButton === 0  || selectedButton === 5)" (click)="reloadImage(5)">Recréer Image</button>
    </div>
      <!-- Show the progress bar when image is being generated -->
  </div>
  <div *ngIf="LoadingBar" class="loading-bar">
    <p>Génération de l'image...</p>
    <nb-progress-bar [value]="progress" size = "giant" status="info" [displayValue]="true"></nb-progress-bar>
  </div>
</div>

  