<td>
  <nb-checkbox
    status="danger"
    [checked]="segment.checked"
    (checkedChange)="checkSegment($event)"
    [disabled]="disabledCheck"
  ></nb-checkbox>
</td>
<td class="categories" (click)="click()">
  <span class="truncate" [title]="label">{{ label }}</span>
</td>
<td class="number">
  <div>{{ segment.mailCount | number: '.0-0' }}</div>
  <div class="gauge">
    <div [style.width.%]="segment.percentage"></div>
  </div>
</td>
<td>
  <!--lift-->
  x&nbsp;{{ segment.lift | number: '1.1-1':'fr-FR' }}
</td>
<td class="number">
  <!--potential-->
  <span *ngIf="segment.potential">{{ segment.potential | number: '1.0-2' }}&nbsp;%</span>
</td>
<td>
  <!--action-->
  <nb-icon icon="trash-2-outline" style="cursor: pointer" (click)="delete()"></nb-icon>
</td>
