import { ModuleWithProviders, NgModule, Optional, SkipSelf, ɵɵqueryRefresh } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  getDeepFromObject,
  NbAuthJWTToken,
  NbAuthModule,
  NbAuthOAuth2JWTToken,
  NbPasswordAuthStrategy,
  NbPasswordAuthStrategyOptions,
} from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { LayoutService, PlayerService, StateService } from './utils';
import { UserData } from './data/users';

import { SmartTableData } from './data/smart-table';

import { SecurityCamerasData } from './data/security-cameras';
import { UserService } from './mock/users.service';
import { SmartTableService } from './mock/smart-table.service';
import { SecurityCamerasService } from './mock/security-cameras.service';
import { MockDataModule } from './mock/mock-data.module';
import { HttpClientModule, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'twitter',
  },
];

const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
  { provide: SmartTableData, useClass: SmartTableService },
  { provide: SecurityCamerasData, useClass: SecurityCamerasService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: `${environment.backendUrl}/`,

        login: {
          alwaysFail: false,
          endpoint: 'api/token/',
          method: 'post',
          requireValidToken: true,

          redirect: {
            success: '/pages/dashboard',
            failure: null,
          },

          defaultErrors: ['Mauvaise combinaison identifiant/mot de passe.'],
          defaultMessages: ['You have been successfully logged in.'],
        },
        refreshToken: {
          endpoint: 'api/token/refresh/',
        },
        requestPass: {
          endpoint: 'api/password_custom_reset/',
        },
        resetPass: {
          endpoint: 'api/password_reset/confirm/',
        },
        logout: false,
        token: {
          class: NbAuthOAuth2JWTToken,
          // key: "access",
          getter: (module: string, res: HttpResponse<any>, options: NbPasswordAuthStrategyOptions) => {
            let { refresh: refresh, access: token } = res.body;
            return { access_token: token, refresh_token: refresh };
          },
        },
      }),
    ],
    forms: {},
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  LayoutService,
  PlayerService,
  StateService,
];

@NgModule({
  imports: [CommonModule, HttpClientModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [NB_CORE_PROVIDERS],
    };
  }
}
