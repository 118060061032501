<nb-card>
  <nb-card-header>
    {{ isLoading ? 'Redimensionnement en cours...' : 'Choisissez votre image préférée' }}
  </nb-card-header>
  <nb-card-body class="spin-img">
        <!-- Utilisez ng-container pour regrouper le spinner et l'image principale -->
    <ng-container *ngIf="isLoading; else imageTemplate">
      <div class="loading-spinner">
        <div class="spinner"></div>
      </div>
    </ng-container>
    <ng-template #imageTemplate>
      <!-- Ajoutez la classe .hidden basée sur isLoading pour cacher l'image pendant le chargement -->
      <img [src]="selectedImageUrl" [class.hidden]="isLoading" class="img-fluid main-image" alt="Image sélectionnée">
    </ng-template>

      </nb-card-body>
      <nb-card-footer class="image-selector">
        <div class="thumbnail-container">
          <!-- Affiche les placeholders ou les images réelles selon l'état de isLoading -->
          <div class="thumbnail" *ngFor="let i of isLoading ? [].constructor(placeholderCount) : imageUrls; let index = index">
            <!-- Si isLoading est vrai, affiche le placeholder, sinon affiche l'image -->
            <ng-container *ngIf="isLoading; else imageThumbnail">
              <div class="image-placeholder"></div>
            </ng-container>
            <ng-template #imageThumbnail>
              <img [src]="imageUrls[index]" (click)="selectImage(imageUrls[index], $event)" [class.active]="imageUrls[index] === selectedImageUrl" alt="Image miniature">
            </ng-template>
          </div>
        </div>
    <div class="modal-actions">
      <button nbButton status="danger" (click)="cancel()">Annuler</button>
      <button nbButton status="success" (click)="save()">Conserver</button>
    </div>
  </nb-card-footer>
</nb-card>
