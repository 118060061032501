import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService, NbResetPasswordComponent, NB_AUTH_OPTIONS } from '@nebular/auth';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../auth.scss'],
})
export class ResetPasswordComponent extends NbResetPasswordComponent {
  token: string | undefined = undefined;
  form!: FormGroup;
  password: string | undefined = undefined;
  rePass: string | undefined = undefined;

  constructor(
    protected override service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) override options: {},
    cd: ChangeDetectorRef,
    router: Router,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected formBuilder: FormBuilder,
  ) {
    super(service, options, cd, router);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let tken = params.get('token');
      if (tken) {
        this.token = tken;
      }
    });
    this.form = this.formBuilder.group({
      password: new FormControl(this.password, { validators: Validators.required, updateOn: 'change' }),
      rePass: new FormControl(undefined, {
        validators: [Validators.required, this.validateForm()],
        updateOn: 'change',
      }),
    });
    this.form.get('password')?.valueChanges.subscribe((value) => {
      this.password = value;
    });
    this.form.get('rePass')?.valueChanges.subscribe((value) => {
      this.rePass = value;
    });
  }

  validateForm(): ValidatorFn {
    return (group): ValidationErrors | null => {
      if (this.password === group.value) return null;
      return { error: 'Les mots de passe ne sont pas identiques' };
    };
  }

  get passwordHasErrors(): boolean {
    let pErrors = this.form.get('password')?.errors;
    return this.form.get('password')?.errors && pErrors && (pErrors['minlength'] || pErrors['maxlength']);
  }

  get rePassHasErrors(): boolean {
    let pErrors = this.form.get('password')?.errors;

    return this.form.get('password')?.errors && pErrors && pErrors['required'];
  }

  override resetPass() {
    if (this.token && this.password) {
      this.userService.resetPassword(this.password, this.token).subscribe({
        next: () => {
          this.messages = ['Mot de passe changé !'];
          setTimeout(() => {
            return this.router.navigate(['/auth/login']);
          }, this.redirectDelay);
        },
        error: (error) => {
          if (error.error) {
            this.errors = [error.error.password ? error.error.password : ''];
          }
        },
      });
    }
  }
}
