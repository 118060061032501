import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-recommandation',
  templateUrl: './product-recommandation.component.html',
  styleUrls: ['./product-recommandation.component.scss']
})
export class ProductRecommandationComponent {
  productIds: string = '';
  campaignName: string = '';  // New property for campaign name
  errorMessage: string = '';
  isCalling: boolean = false;

  constructor(private http: HttpClient) { }

  generateSegment(): void {
    const url = `${environment.backendUrl}/api/product_recommandation`;
    const ids = this.productIds
      .split(/[\s,]+/)
      .map(id => id.trim())
      .filter(id => id !== '');

    // Include campaign_name in the request parameters
    const params = { 
      property_ids: ids.join(','), 
      campaign_name: this.campaignName  // Add campaign_name to the request
    };
  
    this.isCalling = true;
  
    this.http.get(url, { params }).subscribe({
      next: (response: any) => {
        this.isCalling = false;
        if (response.download_url) {
          this.downloadCSV(response.download_url);
        }
      },
      error: () => {
        this.isCalling = false;
        this.errorMessage = 'Failed to generate segment. Please try again.';
      }
    });
  }

  downloadCSV(downloadUrl: string): void {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `segment_${this.campaignName}.csv`;  // Adjusted download filename
    link.click();
  }

  isInputEmpty(): boolean {
    return !this.productIds.trim() || !this.campaignName.trim();  // Ensure both fields are filled
  }
}
