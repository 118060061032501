import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ToastService } from 'src/app/deebr/service/toast.service';
import { GeneratorService } from 'src/app/deebr/service/generator.service';
import { Subscription, map, Observable } from 'rxjs';
import { Event } from 'src/app/deebr/service/event.service';
import { EmailStore } from 'src/app/deebr/component/pages/generator/email.store';
import { NbDialogService } from '@nebular/theme';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Enterprise, UserStore } from '../../store/user.store';
import { th } from 'date-fns/locale';

@Component({
  selector: 'app-image-choice',
  templateUrl: './image-choice.component.html',
  styleUrls: ['./image-choice.component.scss']
})
export class ImageChoiceComponent {
  @Input() show: boolean = false;
  @Input() imageUrls: string[] = [];
  @Input() message: string = '';
  @Input() taskId: string = '';  // Assumant que vous passez le taskId en tant qu'input

  @Output() imageSelected = new EventEmitter<{ imageUrl: any, taskId: string, selectedRatio?: number, wantGif?: boolean }>();
  @Output() closeRequest = new EventEmitter<void>();

  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  @ViewChild('dialogGifTemplate') dialogGifTemplate!: TemplateRef<any>;

  subscription: Subscription;
  progress: number = 0;
  status: string = 'idle';
  intervalId: any;
  selectedButton: number = 0;
  LoadingBar = false;
  isReloading: boolean = false;
  selectedImageUrl: string = '';
  selectedFile: File | null = null;
  errorMessage: string | null = null;
  errorSearchMessage: string | null = null;
  errorMessageGif: string | null = null;
  loadingImages: string[] = [];
  wantGif: boolean = false;
  selectedImages: any = [];
  selectedRatioString = 'ratio-1-1';
  isButtonEnabled: boolean = false;
  isButtonAnimated: boolean = false;
  isImgLoading: boolean = false;
  searchTerm: string = '';
  isSearching: boolean = false;
  nbVisuelsPrincipaux: number = 1;
  currentVisuelIndex: number = 0;

  public showAdobeStock$: Observable<boolean> = this.userStore
    .selectEnterprise()
    .pipe(map((enterprise: Enterprise | null) => !!enterprise?.adobe_stock));

  constructor(
    private generatorService: GeneratorService,
    private ToastService: ToastService,
    private Event: Event,
    public emailStore: EmailStore,
    private dialogService: NbDialogService,
    private userStore: UserStore
  ) 
  {
    // Lorsque les images sont reçues:
    this.subscription = this.Event.imageChoiceEvent$.subscribe((eventData) => {
      this.show = true;
      this.imageUrls = eventData.imageUrls;
      this.message = eventData.message;
      this.taskId = eventData.taskId;
      this.nbVisuelsPrincipaux = eventData.nbVisuelsPrincipaux ?? 1;
      // Initialisez toutes les images comme étant en cours de chargement
      this.loadingImages = [...this.imageUrls];
      this.currentVisuelIndex = 0; // Reset the current visuel index
    });
    // Vous devez également vous abonner à l'événement closeModal
    this.Event.closeModalEvent$.subscribe(() => {
      this.show = false;
    });
  }
  get selectedRatio(): number {
    const ratioString = this.selectedRatioString.replace('ratio-', '');
    const [width, height] = ratioString.split('-').map(Number);
    return width / height;
  }
  onRatioChange(event: any): void {
    console.log('Ratio changed:', event);
  }

  logSelectedRatio(): void {
    console.log('Selected Ratio:', this.selectedRatioString);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      if (this.wantGif && this.nbVisuelsPrincipaux > 1) {
        moveItemInArray(this.selectedImages[this.currentVisuelIndex], event.previousIndex, event.currentIndex);
      } else {
        moveItemInArray(this.selectedImages, event.previousIndex, event.currentIndex);
      }
    }
  }

  removeImage(index: number): void {
    if (this.wantGif && this.nbVisuelsPrincipaux > 1) {
      this.selectedImages[this.currentVisuelIndex].splice(index, 1);
    } else {
      this.selectedImages.splice(index, 1);
    }
    this.updateButtonState();
  }

  onModalContentClicked(event: MouseEvent): void {
    event.stopPropagation();
  }

  onImageClick(event: MouseEvent, selectedImageUrl: string) {
    if (this.isSearching) {
      return;
    }
  
    if (this.wantGif) {
      // Handling GIF selection
      if (this.nbVisuelsPrincipaux > 1) {
        const selectedImagesForCurrentVisuel = this.selectedImages[this.currentVisuelIndex];
        const index = selectedImagesForCurrentVisuel.indexOf(selectedImageUrl);
  
        if (index > -1) {
          // Deselect image
          selectedImagesForCurrentVisuel.splice(index, 1);
        } else {
          // Select image
          if (selectedImagesForCurrentVisuel.length >= 3) {
            this.errorMessageGif = "Vous ne pouvez pas sélectionner plus de 3 images pour créer un GIF.";
            this.dialogService.open(this.dialogGifTemplate);
            return;
          }
          selectedImagesForCurrentVisuel.push(selectedImageUrl);
        }
      } else {
        // Single visuel, multiple images for GIF
        const index = this.selectedImages.indexOf(selectedImageUrl);
  
        if (index > -1) {
          // Deselect image
          this.selectedImages.splice(index, 1);
        } else {
          if (this.selectedImages.length >= 3) {
            this.errorMessageGif = "Vous ne pouvez pas sélectionner plus de 3 images pour créer un GIF.";
            this.dialogService.open(this.dialogGifTemplate);
            return;
          }
          this.selectedImages.push(selectedImageUrl);
        }
      }
    } else {
      // Handling static image selection
      if (this.nbVisuelsPrincipaux > 1) {
        const index = this.selectedImages.indexOf(selectedImageUrl);
  
        if (index > -1) {
          // Deselect image
          this.selectedImages.splice(index, 1);
        } else {
          if (this.selectedImages.length >= this.nbVisuelsPrincipaux) {
            this.errorMessageGif = `Vous ne pouvez pas sélectionner plus de ${this.nbVisuelsPrincipaux} images.`;
            this.dialogService.open(this.dialogGifTemplate);
            return;
          }
          this.selectedImages.push(selectedImageUrl);
        }
      } else {
        const index = this.selectedImages.indexOf(selectedImageUrl);
  
        if (index > -1) {
          // Deselect image
          this.selectedImages.splice(index, 1);
        } else {
          // Ensure only one image is selected
          this.selectedImages = [selectedImageUrl];
        }
      }
    }
  
    this.updateButtonState();
  }

  updateButtonState() {
    if (this.wantGif) {
      if (this.nbVisuelsPrincipaux > 1) {
        // For GIFs with multiple visuels, each visuel needs at least 1 images
        this.isButtonEnabled = this.selectedImages.every((visuelImages: string[]) => visuelImages.length >= 1);
      } else {
        // For a single visuel GIF, need at least 2 images
        this.isButtonEnabled = this.selectedImages.length >= 2;
      }
    } else {
      if (this.nbVisuelsPrincipaux > 1) {
        // For static images with multiple visuels, need one image per visuel
        this.isButtonEnabled = this.selectedImages.length >= this.nbVisuelsPrincipaux;
      } else {
        // For a single static image
        this.isButtonEnabled = this.selectedImages.length >= 1;
      }
    }
    this.isButtonAnimated = this.isButtonEnabled;
  }

  isImageSelected(imageUrl: string): boolean {
    if (this.wantGif && this.nbVisuelsPrincipaux > 1) {
      return this.selectedImages[this.currentVisuelIndex].includes(imageUrl);
    } else {
      return this.selectedImages.includes(imageUrl);
    }
  }

  getSelectionIndex(imageUrl: string): number {
    if (this.wantGif && this.nbVisuelsPrincipaux > 1) {
      return this.selectedImages[this.currentVisuelIndex].indexOf(imageUrl) + 1;
    } else {
      return this.selectedImages.indexOf(imageUrl) + 1;
    }
  }

  isLoading(url: string): boolean {
    return this.loadingImages.includes(url);
  }

  onImageLoaded(url: string) {
    // Une fois l'image chargée, retirez-la de la liste des images en cours de chargement
    const index = this.loadingImages.indexOf(url);
    if (index > -1) {
      this.loadingImages.splice(index, 1);
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onButtonClick(event: MouseEvent): void {
    event.stopPropagation(); // Empêche le clic de bulle jusqu'à l'étiquette
    document.getElementById('file-upload')?.click(); // Déclenche manuellement le clic sur l'input
  }

  uploadSelectedFile() {
    this.errorMessage = null;
    if (this.selectedFile) {
        const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
        const allowedExtensions = ['jpeg', 'jpg', 'png', 'webp'];
        const fileSizeInMB = this.selectedFile.size / 1000000; // Convertir la taille en Mo

        if (!allowedExtensions.includes(fileExtension!)) {
            this.errorMessage = "Ce format de fichier n’est pas pris en charge. Vous pouvez importer un fichier .jpeg, .png ou .webp.";
            console.log("erreur")
            return;
        }

        if (fileSizeInMB > 5) {
            this.errorMessage = "La taille du fichier ne doit pas dépasser 5 Mo.";
            return;
        }

        // Ajouter le placeholder pour le spinner
        this.imageUrls.unshift("loading");

        this.generatorService.uploadFile(this.selectedFile).subscribe((uploadedImageUrl: string) => {
            // Remplacer le placeholder par l'URL de l'image chargée
            const loadingIndex = this.imageUrls.indexOf("loading");
            if (loadingIndex !== -1) {
                this.imageUrls[loadingIndex] = uploadedImageUrl;
            }
        });
    } else {
        console.error("Aucun fichier sélectionné");
    }
}

  onImageLoad(event: any) {
    const image = event.target as HTMLImageElement;
    image.classList.add('image-loaded');
  }

  onClose() {
    this.wantGif = false;
    this.selectedImages = [];
    this.searchTerm = ''; // Réinitialise le champ de recherche
    this.errorSearchMessage = null; // Réinitialise le message d'erreur de recherche
    this.imageUrls = []; // Réinitialise la liste des images
    this.isImgLoading = false; // Assure que l'état de chargement est réinitialisé
    this.isSearching = false; // Réinitialise l'état de recherche
    this.ToastService.removeById(this.taskId);
    this.emailStore.patchState((state) => ({
      ...state,
      progress: {
        ...state.progress,
        [this.taskId]: {
          ...state.progress[this.taskId],
          progress: -1,
          imagesChoice: []
        },
      },
    }));
    this.closeRequest.emit();
  }
  

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  dragEnterCounter = 0;
  onDrop(event: DragEvent) {
    event.preventDefault();
    this.dragEnterCounter = 0;
    this.isDragging = false;
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      this.uploadSelectedFile();
    }
  }
  isDragging = false;

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    this.dragEnterCounter++;
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.dragEnterCounter--;
    if (this.dragEnterCounter === 0) {
      this.isDragging = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.intervalId)
  }


  ngOnInit(): void {
  }

  toggleGifPreference(isChecked: boolean) {
    this.wantGif = isChecked;
  
    if (this.wantGif) {
      // When GIF is desired
      if (this.nbVisuelsPrincipaux > 1) {
        // Ensure selectedImages is an array of arrays
        if (!Array.isArray(this.selectedImages[0])) {
          // Convert from array to array of arrays
          const previousImages = this.selectedImages;
          this.selectedImages = Array.from({ length: this.nbVisuelsPrincipaux }, () => []);
          previousImages.forEach((img: any, index: any) => {
            if (index < this.nbVisuelsPrincipaux) {
              this.selectedImages[index].push(img);
            }
          });
        }
      }
      // For a single visuel, selectedImages remains an array (allows multiple images for GIF)
    } else {
      // When GIF is not desired
      if (this.nbVisuelsPrincipaux > 1) {
        // Flatten selectedImages to a simple array
        this.selectedImages = this.selectedImages.map((visuelImages: any) => visuelImages[0] || []).filter(Boolean);
      } else {
        // Ensure only one image is selected
        if (Array.isArray(this.selectedImages[0])) {
          this.selectedImages = [this.selectedImages[0][0]].filter(Boolean);
        } else {
          this.selectedImages = this.selectedImages.slice(0, 1);
        }
      }
    }
  
    this.updateButtonState();
  }

  startGeneration() {
    console.log("start")
    console.log(this.selectedRatioString)
    console.log(this.selectedRatio)
    let ratio_output = undefined
    if (this.wantGif === true) {
        ratio_output = this.selectedRatio
    }
    this.imageSelected.emit({ imageUrl: this.selectedImages, taskId: this.taskId, selectedRatio: ratio_output, wantGif: this.wantGif });
    this.selectedImages = [];
    this.show = false;
    this.wantGif = false;
    this.searchTerm = ''; // Réinitialise le champ de recherche
    this.errorSearchMessage = null; // Réinitialise le message d'erreur de recherche
    this.imageUrls = []; // Réinitialise la liste des images
    this.isImgLoading = false; // Assure que l'état de chargement est réinitialisé
    this.isSearching = false; // Réinitialise l'état de recherche
    this.closeRequest.emit();
  }

  onSearch() {
    if (this.isSearching) {
      return;
    }

    if (!this.searchTerm) {
      this.errorSearchMessage = 'Veuillez entrer un terme de recherche.';
      return;
    }
    
    this.isSearching = true;
    this.isImgLoading = true;
    this.errorSearchMessage = null;
  
    this.generatorService.searchAdobeStock(this.searchTerm).subscribe({
      next: (response) => {
        if (response.urls.length === 0) {
          this.errorSearchMessage = 'Aucune image trouvée pour ce terme de recherche.';
        } else {
          this.imageUrls = response.urls;
        }
        this.isImgLoading = false;
        this.isSearching = false;
      },
      error: (error) => {
        console.error(error);
        this.errorSearchMessage = 'Une erreur est survenue lors de la recherche des images.';
        this.isImgLoading = false;
        this.isSearching = false; // Reset the flag on error
      }
    });
  }

}
