import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Event } from 'src/app/deebr/service/event.service';
import { GeneratorService } from 'src/app/deebr/service/generator.service';
import { NbDialogService } from '@nebular/theme';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SharedDashboardProductService } from 'src/app/deebr/service/shared-dashboard-product.service';
import { ScrappedImagesService } from 'src/app/deebr/service/scrapped-images.service';
import { DashboardProduct } from '../drag-drop-products/drag-drop-products.component';
import { ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-create-gif-product',
  templateUrl: './create-gif-product.component.html',
  styleUrls: ['./create-gif-product.component.scss']
})
export class CreateGifProductComponent {
  @Input() show: boolean = false;
  @Input() imageUrls: string[] = [];
  @Output() closeRequest = new EventEmitter<void>();
  @Output() gifCreated = new EventEmitter<string>();
  @Output() carrouselCreated = new EventEmitter<string[]>();
  @ViewChild('dialogGifTemplate') dialogGifTemplate!: TemplateRef<any>;
  @ViewChild('dialogGifTemplate2') dialogGifTemplate2!: TemplateRef<any>;
  @ViewChild('dialogErrorGif') dialogErrorGif!: TemplateRef<any>;

  selectedImages: string[] = [];
  dragEnterCounter = 0;
  isDragging = false;
  selectedFile: File | null = null;
  errorMessage: string | null = null;
  errorMessageGif: string | null = null;
  isButtonEnabled: boolean = false;
  isButtonAnimated: boolean = false;
  sharedProducts: DashboardProduct[] = [];
  ratioBloc: number = 1;
  widthBloc: number = 0;
  heightBloc: number = 0;
  gifUrl: string = '';
  scrappedMessage: string = '';
  selectedMessage: string = '';
  ampWarningMessage: string = '';
  loading: boolean = false;
  // New variables to track mode and selection limits
  mode: 'GIF' | 'Carrousel' = 'GIF'; // Default mode is GIF
  maxSelection: number = 3; // Limit for GIF

  constructor(
    private cdr: ChangeDetectorRef,
    private generatorService: GeneratorService,
    private dialogService: NbDialogService,
    private sharedDashboardProductService: SharedDashboardProductService,
    private scrappedImagesService: ScrappedImagesService
  ) { }

  ngOnInit(): void {

    this.sharedDashboardProductService.currentProducts.subscribe(products => {
      this.loading = true;
      this.updateScrappedMessage();

      this.sharedProducts = products;

      this.scrappedImagesService.currentProducts.subscribe(productMap => {
        this.imageUrls = [];

        this.sharedProducts.forEach(product => {
          const scrappedUrls: string[] = productMap.get(product.id) || [];
          if (scrappedUrls) {
            scrappedUrls.forEach(url => {
              if (!this.imageUrls.includes(url) && url !== "no image found") {
                this.imageUrls.push(url);
                this.cdr.detectChanges();
              } else {
                this.loading = false;
                this.updateScrappedMessage();
              }
            });
          }
          this.cleanImageUrls(productMap);
        });
      });

      if (this.sharedProducts.length > 0) {
        const widthBloc = this.sharedProducts[0].width;
        const heightBloc = this.sharedProducts[0].height;
        this.widthBloc = widthBloc;

        if (widthBloc && heightBloc) {
          this.ratioBloc = widthBloc / heightBloc;
        }

        if (widthBloc !== 250) {
          this.widthBloc = 250;
          this.heightBloc = this.widthBloc / this.ratioBloc;
        }
      }

      document.documentElement.style.setProperty('--ratio-bloc', this.ratioBloc.toString());
      document.documentElement.style.setProperty('--block-width', this.widthBloc.toString() + 'px');
      document.documentElement.style.setProperty('--block-height', this.heightBloc.toString() + 'px');
    });
  }

  // Toggle between GIF and Carrousel mode
  toggleMode(mode: 'GIF' | 'Carrousel') {
    if (mode === 'GIF' && this.selectedImages.length > 3) {
      this.errorMessageGif = `Vous ne pouvez pas sélectionner plus de 3 images pour créer un GIF. Veuillez supprimer des images pour passer en mode GIF.`;
      this.dialogService.open(this.dialogGifTemplate);
      return;
    }
    if (this.gifUrl && this.gifUrl !== 'loading') {
      this.errorMessageGif = `Veuillez supprimer le ${this.mode} actuel avant de changer de mode.`;
      this.dialogService.open(this.dialogGifTemplate2);
      return;
    }
    if (mode === 'Carrousel') {
      this.ampWarningMessage = `Cette fonctionnalité générera une deuxième version AMP HTML compatible uniquement avec Gmail et Yahoo mail. Vérifiez également que votre routeur est compatible avec les emails AMP HTML.`;
    } else {
      this.ampWarningMessage = '';
    }
    this.mode = mode;
    this.maxSelection = mode === 'GIF' ? 3 : 5;
    this.updateButtonState();
    this.updateScrappedMessage();
    this.updateSelectedMessage();
  }

  cleanImageUrls(productMap: Map<number | null, string[]>) {
    this.imageUrls = this.imageUrls.filter(url => {
        return this.sharedProducts.some(product => {
            const scrappedUrls = productMap.get(product.id) || [];
            this.loading = false;
            this.updateScrappedMessage();
            return scrappedUrls.includes(url);
        });
    });
    this.sharedProducts.forEach(product => {
      if (product.image_url_1 && !this.imageUrls.includes(product.image_url_1)) {
          this.imageUrls.push(product.image_url_1);
      }
      if (product.image_produit_scenario !== -1 && !this.imageUrls.includes(product.image_produit_scenario)) {
          this.imageUrls.push(product.image_produit_scenario);
      }
  });
  }

  updateScrappedMessage() {
    if (this.loading === true) {
      this.scrappedMessage = "Veuillez patienter, notre IA est en train de rechercher des images qui correspondent au produit sélectionné...";
    } else if (this.imageUrls.length > 2) {
      this.scrappedMessage = `Notre IA a détecté que les images ci-dessous correspondent au produit sélectionné. Vous pouvez les utiliser telles quelles ou ajouter des images supplémentaires si nécessaire pour personnaliser votre ${this.mode}.`;
    } else {
      this.scrappedMessage = `Aucune image trouvée pour ce produit. Veuillez ajouter des images pour créer un ${this.mode}.`;
    }
  }

  updateSelectedMessage() {
    if (this.selectedImages.length === 1) {
      this.selectedMessage = `Veuillez sélectionner au moins 2 images pour créer un ${this.mode}.`;
    } else {
      this.selectedMessage = "Vous avez sélectionné " + this.selectedImages.length + " images pour créer un " + this.mode + ". Vous pouvez réorganiser les images en les faisant glisser et déposer.";
    }
  }

  onImageClick(event: MouseEvent, selectedImageUrl: string) {
    const index = this.selectedImages.indexOf(selectedImageUrl);

    if (index > -1) {
        // Deselect image
        this.selectedImages.splice(index, 1);
        this.errorMessageGif = null;
        this.updateSelectedMessage()
    } else {
        // Select image
        if (this.selectedImages.length >= this.maxSelection) {
            this.errorMessageGif = `Vous ne pouvez pas sélectionner plus de ${this.maxSelection} images pour créer un ${this.mode}.`;
            this.dialogService.open(this.dialogGifTemplate);
            return;
        }

        this.selectedImages.push(selectedImageUrl);
        this.errorMessageGif = null;
        this.updateSelectedMessage()
    }
    this.updateButtonState();
    // console.log("imageUrls list : ", this.imageUrls);
    // console.log("selectedImages list : ", this.selectedImages);
  }

  updateButtonState() {
    this.isButtonEnabled = (this.selectedImages.length > 1);
    this.isButtonAnimated = this.isButtonEnabled;
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onButtonClick(event: MouseEvent): void {
    event.stopPropagation();
    document.getElementById('file-upload-create-gif')?.click();
  }

  uploadSelectedFile() {
    this.errorMessage = null;
    if (this.selectedFile) {
        const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
        const allowedExtensions = ['jpeg', 'jpg', 'png', 'webp'];
        const fileSizeInMB = this.selectedFile.size / 1000000; // Convertir la taille en Mo

        if (!allowedExtensions.includes(fileExtension!)) {
            this.errorMessage = "Ce format de fichier n’est pas pris en charge. Vous pouvez importer un fichier .jpeg, .png ou .webp.";
            return;
        }

        if (fileSizeInMB > 5) {
            this.errorMessage = "La taille du fichier ne doit pas dépasser 5 Mo.";
            return;
        }

        // Ajouter le placeholder pour le spinner
        this.imageUrls.unshift("loading");

        this.generatorService.uploadFile(this.selectedFile).subscribe((uploadedImageUrl: string) => {
            // Remplacer le placeholder par l'URL de l'image chargée
            const loadingIndex = this.imageUrls.indexOf("loading");
            if (loadingIndex !== -1) {
                this.imageUrls[loadingIndex] = uploadedImageUrl;
            }
        });
    } else {
        console.error("Aucun fichier sélectionné");
    }
  }

  onClose() {
    this.imageUrls = [];
    this.sharedDashboardProductService.clearProducts();
    this.selectedImages = [];
    this.gifUrl = '';
    this.loading = true;
    this.updateScrappedMessage();
    this.closeRequest.emit();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    this.dragEnterCounter++;
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.dragEnterCounter--;
    if (this.dragEnterCounter === 0) {
      this.isDragging = false;
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.dragEnterCounter = 0;
    this.isDragging = false;
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      this.uploadSelectedFile();
    }
  }

  getSelectionIndex(imageUrl: string): number {
    return this.selectedImages.indexOf(imageUrl) + 1;
  }

  removeImage(index: number): void {
    this.selectedImages.splice(index, 1);
    this.updateButtonState();
    this.updateSelectedMessage()
  }

  removeGif() {
    this.gifUrl = '';
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.selectedImages, event.previousIndex, event.currentIndex);
      // console.log('Updated selectedImages:', this.selectedImages);
    }
  }

  isGifImage(url: string): boolean {
    return url.toLowerCase().endsWith('.gif');
  }

  startCreateGif() {
    // console.log("start")

    if (this.isGifImage(this.gifUrl)) {
      this.errorMessageGif = `Veuillez supprimer le ${this.mode} actuel avant de créer un nouveau ${this.mode}.`;
      this.dialogService.open(this.dialogGifTemplate2);
    } else {
      this.gifUrl = 'loading';
      this.generatorService.createGifProduct(this.selectedImages, this.ratioBloc)
      .subscribe(
        (response) => {
          if (response.url === 'error') {
            this.gifUrl = '';
            this.dialogService.open(this.dialogErrorGif);
            return;
          } else {
          // console.log("GIF product created:", response);
            this.gifUrl = response.url;
          //console.log("GIF product URL:", this.gifUrl);
          this.cdr.detectChanges();
          }
        },
        (error) => {
          // Handle any errors that occurred during the API call
            console.error(`Error creating ${this.mode} product:`, error);
          this.gifUrl = '';
          this.dialogService.open(this.dialogErrorGif);
          this.cdr.detectChanges();
        }
      );
    }
  }

  validateGif() {
    this.gifCreated.emit(this.gifUrl);
    this.imageUrls = [];
    this.sharedDashboardProductService.clearProducts();
    this.selectedImages = [];
    this.gifUrl = '';
    this.loading = true;
    this.updateScrappedMessage();
    this.closeRequest.emit();
  }

  validateCarrousel() {
    this.carrouselCreated.emit(this.selectedImages);
    this.imageUrls = [];
    this.sharedDashboardProductService.clearProducts();
    this.selectedImages = [];
    this.gifUrl = '';
    this.loading = true;
    this.updateScrappedMessage();
    this.closeRequest.emit();
  }

}
