import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';



@Component({
  selector: 'app-resize-image-modal',
  templateUrl: './resize-image-modal.component.html',
  styleUrls: ['./resize-image-modal.component.scss']
})
export class ResizeImageModalComponent implements OnInit {
  @Input() imageUrl!: string;
  @Input() imgWidth!: number;
  @Input() imgHeight!: number;
  @Input() jElement!: any;

  public displayedWidth: number = 0;
  public displayedHeight: number = 0;
  public maxContainerWidth: number = 0;
  public maxContainerHeight: number = 0;

  public containerWidth: number = 0;
  public containerHeight: number = 0;

  public selectedRatio: { width: number, height: number } | null = null
  public selectedRatioWidth: number | null = null;
  public selectedRatioHeight: number | null = null;

  public frameWidth: number = 0;
  public frameHeight: number = 0;
  public scaleFactor: number = 1;

  public widthAlertMessage: string | null = null;
  public heightAlertMessage: string | null = null;



  public aspectRatios = [
    { width: 3, height: 2 },
    //{ width: 2, height: 3 },
    //{ width: 2, height: 1 },
    { width: 5, height: 3 },
    { width: 4, height: 3 },
    { width: 5, height: 4 },
    { width: 6, height: 4 },
    { width: 7, height: 5 },
    { width: 10, height: 8 },
    { width: 16, height: 9 }
  ];

  constructor(protected dialogRef: NbDialogRef<ResizeImageModalComponent>) {

  }

  ngOnInit() {

    this.calculateMaxContainerSize();
    this.calculateDisplaySize();
    
    this.adjustFrameSize();
    this.frameWidth = this.imgWidth * this.scaleFactor;
    this.frameHeight = this.imgHeight * this.scaleFactor;
    this.selectedRatio = this.aspectRatios.find(ratio => ratio.width === 3 && ratio.height === 2) || null;
    if (this.selectedRatio) {
      this.setAspectRatio(this.selectedRatio.width, this.selectedRatio.height);
    }

  }


  calculateDisplaySize(): void {
    const ratioContainer= this.maxContainerWidth / this.maxContainerHeight
    this.displayedWidth = this.imgWidth;
    this.displayedHeight = this.imgHeight;
    const ratioImg = this.displayedWidth  / this.displayedHeight;

    this.aspectRatios.forEach(ratio => {
      const ratioCadre = ratio.width / ratio.height
      if (ratioCadre < ratioContainer) {
        const heightCadre = this.maxContainerHeight;
        const widthCadre = heightCadre * ratioCadre;

        if(ratioImg < ratioCadre && this.displayedHeight > heightCadre){
          this.displayedHeight = heightCadre 
          this.displayedWidth = heightCadre * ratioImg
        }
        else if(ratioImg > ratioCadre && this.displayedWidth > widthCadre){
          this.displayedWidth = widthCadre
          this.displayedHeight = widthCadre / ratioImg
        }
      }
      if (ratioCadre > ratioContainer) {
        const widthCadre = this.maxContainerWidth;
        const heightCadre = widthCadre / ratioCadre
        if(ratioImg < ratioCadre && this.displayedHeight > heightCadre){
          this.displayedHeight = heightCadre 
          this.displayedWidth = heightCadre * ratioImg
        }
        else if(ratioImg > ratioCadre && this.displayedWidth > widthCadre){
          this.displayedWidth = widthCadre
          this.displayedHeight = widthCadre / ratioImg
        }
      }
    });
    this.scaleFactor = this.displayedWidth / this.imgWidth

  }

  calculateMaxContainerSize(): void {  

    this.maxContainerWidth = window.innerWidth * 0.75;
    this.maxContainerHeight = window.innerHeight* 0.75;
    this.containerWidth = this.maxContainerWidth
    this.containerHeight =  this.maxContainerHeight
  }

  setAspectRatio(width: number, height: number): void {
    this.selectedRatio = { width, height };
    this.selectedRatioWidth = width;
    this.selectedRatioHeight = height;
    this.adjustFrameSize();
  }

  adjustFrameSize(): void {
    if (this.selectedRatioWidth && this.selectedRatioHeight) {
      const imageRatio = this.displayedWidth / this.displayedHeight;
      const frameRatio = this.selectedRatioWidth / this.selectedRatioHeight;

      if (frameRatio > imageRatio) {
        this.frameWidth = (this.displayedHeight * this.selectedRatioWidth) / this.selectedRatioHeight;
        this.frameHeight = this.displayedHeight;
        this.onFrameWidthChange(this.frameWidth / this.scaleFactor)
      } else {
        this.frameHeight = (this.displayedWidth * this.selectedRatioHeight) / this.selectedRatioWidth;
        this.frameWidth = this.displayedWidth;
        this.onFrameHeightChange(this.frameHeight / this.scaleFactor)
      }
    } 

  }

  onFrameWidthChange(newWidth: number): void {

    if (newWidth < this.imgWidth) {
      this.widthAlertMessage = 'La valeur est trop faible, veuillez utiliser l\'outil de recadrage pour cropper cette image';
    } else {
      this.widthAlertMessage = null;
    }
    if(this.widthAlertMessage === null){
        this.frameWidth = newWidth * this.scaleFactor;
        if (this.frameWidth < this.displayedWidth){
          this.frameWidth = this.displayedWidth
        } 

        while (this.frameWidth> this.maxContainerWidth){
          console.log("zoom out height")
          console.log("this.imgWidth", this.imgWidth)
          console.log("this.frameWidth)", this.frameWidth)
          this.zoomOut()
        }

        while (this.frameWidth < this.maxContainerWidth && this.frameHeight < this.maxContainerHeight && this.imgWidth > this.frameWidth){
          this.zoomIn()
        }   
      }
  }
  onFrameHeightChange(newHeight: number): void {
    if (newHeight < this.imgHeight) {
      this.heightAlertMessage = 'La valeur est trop faible, veuillez utiliser l\'outil de recadrage pour cropper cette image';
    } else {
      this.heightAlertMessage = null;
    }

      if(this.heightAlertMessage === null){

        this.frameHeight = newHeight * this.scaleFactor;

        if (this.frameHeight < this.displayedHeight){
          this.frameHeight = this.displayedHeight
        } 
        while (this.frameHeight > this.maxContainerHeight){
          this.zoomOut()
        }
        while (this.frameWidth < this.maxContainerWidth && this.frameHeight < this.maxContainerHeight && this.imgHeight > this.frameHeight){

          this.zoomIn()
      } 
      }
    }

  getRoundedWidth(): number {
    return Math.round((this.frameWidth / this.scaleFactor)); // Pour arrondir à une décimale
  }
  getRoundedHeight(): number {
    return Math.round((this.frameHeight / this.scaleFactor)); // Pour arrondir à une décimale
  }
  

  onFrameSizeChange(): void {
    const frameRatio = this.frameWidth / this.frameHeight;

    // Optionnel : Mettez à jour les ratios sélectionnés en fonction des nouvelles dimensions
    this.selectedRatioWidth = this.frameWidth;
    this.selectedRatioHeight = this.frameHeight;

    this.adjustFrameSize();
  }

  
  zoomIn(): void {
    const zoomFactor = 1.1;
    this.applyZoom(zoomFactor);
  }

  zoomOut(): void {
    const zoomFactor = 0.9;
    this.applyZoom(zoomFactor);
  }

  applyZoom(zoomFactor: number): void {

      this.displayedWidth *= zoomFactor;
      this.displayedHeight *= zoomFactor;
      this.frameWidth *= zoomFactor;
      this.frameHeight *= zoomFactor;
      this.scaleFactor = this.displayedWidth / this.imgWidth;
  }
  

  isButtonDisabled(): boolean {
    return !!this.widthAlertMessage || !!this.heightAlertMessage;
  }
  
  validateAndClose(): void {
    this.dialogRef.close({
      newWidth: Math.round(this.frameWidth / this.scaleFactor),
      newHeight: Math.round(this.frameHeight / this.scaleFactor),
      imageUrl: this.imageUrl,
      jElement: this.jElement
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
