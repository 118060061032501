import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, map, mergeMap, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../model/category.model';
import { DicoEntry } from '../model/dico-entry.model';
import { CategoryService } from './category.service';

@Injectable({
  providedIn: 'root',
})
export class DicoService {
  constructor(private http: HttpClient, private categoryService: CategoryService) { }

  findCategoryById(categories: Category[], id: number): Category | undefined {
    return categories.find(category => category.id === id);
  }

  getDico(numDays: number, meta_cat: number[]): Observable<DicoEntry[]> {
    return combineLatest([this.categoryService.getCategories(meta_cat)]).pipe(
      mergeMap(([categories]) => {


        const params: { [key: string]: any } = {
          day: numDays,
          meta_category: meta_cat.join(','),
        };

        // Requesting dico infos
        return this.http
          .get<{
            [key: string]: {
              [key: string]: {
                index: number;
                real_n_1: number;
                real: number;
                forecast: number;
                curve_n_1: number[];
                curve_forecast: number[];
              };
            };
          }>(`${environment.backendUrl}/api/dico/`, { params })
          .pipe(
            map((response) => {

              const units: DicoEntry[] = [];

              for (const [metaCatKey, metaCatValue] of Object.entries(response)) {
                // Extract meta_category number
                const metaCategory = parseInt(metaCatKey.replace('meta_category_', ''), 10);

                // Filter categories by metaCategory
                const categoriesOfMeta = categories.filter(category => category.meta_category === metaCategory);

                for (const [id, entry] of Object.entries(metaCatValue)) {
                  // Ensure both IDs are of the same type
                  const category = this.findCategoryById(categoriesOfMeta, parseInt(id));

                  if (category) {
                    const dicoEntry = new DicoEntry(
                      metaCategory,
                      new Category(parseInt(id), category.label, metaCategory),
                      entry.real_n_1,
                      entry.real,
                      entry.forecast,
                      entry.curve_forecast,
                      entry.curve_n_1
                    );
                    units.push(dicoEntry);
                  } else {
                    console.warn(`Category not found for id ${id}`);
                  }
                }
              }
              return units;
            })
          );
      })
    );
  }

  getRatio(entry: DicoEntry | null, lastYear = false): number {
    if (entry) {
      if (lastYear) {
        return ((entry.forecast - entry.realNMinusOne) / (entry.realNMinusOne)) * 100;
      }
      return ((entry.forecast - entry.real) / (entry.real)) * 100;
    }
    return 0;
  }
}
