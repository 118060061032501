// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DashboardProduct } from "../component/drag-drop-products/drag-drop-products.component"

@Injectable({
    providedIn: 'root',
})

export class SharedDashboardProductService {
    private productsSource = new BehaviorSubject<DashboardProduct[]>([]);
    currentProducts = this.productsSource.asObservable();

    constructor() { }

    addProduct(product: DashboardProduct) {
        const currentProducts = this.productsSource.value;
        this.productsSource.next([...currentProducts, product]);
    }

    clearProducts() {
        this.productsSource.next([]);
    }

    updateProductUrl(product: DashboardProduct, newUrl: string) {
        const currentProducts = this.productsSource.value.map(p =>
            p === product ? { ...p, image_url_1: newUrl } : p
        );
        this.productsSource.next(currentProducts);
    }
}
