import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, startWith, take, tap, filter, distinctUntilChanged } from 'rxjs';
import { TaskState } from 'src/app/deebr/model/task.state';
import { DownloadService } from 'src/app/deebr/service/download.service';
import { Report, ReportStore } from './report.store';
import { Chart } from 'chart.js';

interface Sorting {
  key: string | null;
  direction: string | null;
}

export interface FormValue {
  search: string;
  period: 'last30days' | 'currentMonth' | 'lastMonth' | 'range';
  rangeStart: string | null;
  rangeEnd: string | null;
}

@Component({
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent {
  @ViewChild('datepicker',{static:false})
  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | null = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
  public toDate: NgbDate | null = this.calendar.getToday();
  barChart: any;
  barChartGenerating: boolean = false;
  showDatepicker: boolean = false;
  


  private readonly formDefaultValue: FormValue = {
    search: '',
    period: 'last30days',
    rangeStart: this.formatter.format(this.fromDate),
    rangeEnd: this.formatter.format(this.toDate),
  };

  public form: FormGroup = this.formBuilder.group({
    search: new FormControl(this.formDefaultValue.search),
    period: new FormControl(this.formDefaultValue.period),
    rangeStart: new FormControl(this.formDefaultValue.rangeStart),
    rangeEnd: new FormControl(this.formDefaultValue.rangeEnd),
  });

  public page = 1;
  public pageSize = 50;
  public pageChange$: BehaviorSubject<number> = new BehaviorSubject(this.page);

  public sorting$: BehaviorSubject<Sorting> = new BehaviorSubject({
    key: 'date',
    direction: 'desc',
  } as Sorting);

  public reportsFilteredByDate$: Observable<Report[]> = combineLatest([
    this.reportStore.selectReports(),
    this.form.valueChanges.pipe(startWith(this.formDefaultValue)),
  ]).pipe(
    map(([reports, value]) => reports.filter((report) => isFilteredByDate(report, value))),
    shareReplay(1),
  );

  public figures$ = this.reportsFilteredByDate$.pipe(
    map((reports) => {
      const sumRevenue = reports.reduce((sum, report) => sum + report.revenue, 0);
      const sumTransactions = reports.reduce((sum, report) => sum + report.transactions, 0);
      const sumOpening = reports.reduce((sum, report) => sum + report.opening, 0);
      const sumClicks = reports.reduce((sum, report) => sum + report.clicks, 0);
      const sumSent = reports.reduce((sum, report) => sum + report.sent, 0);

      return {
        revenue: sumRevenue,
        transactionsRate: sumTransactions / sumClicks,
        averageCart: sumRevenue / sumTransactions,
        orders: sumTransactions,
        sent: sumSent,
        opening: sumOpening,
        openingRate: sumOpening / sumSent,
        clicks: sumClicks,
        clickRate: sumClicks / sumSent,
        rpm: sumRevenue / (sumSent / 1_000),
      };
    }),
  );

  private reportsFilteredByText$: Observable<Report[]> = combineLatest([
    this.reportsFilteredByDate$,
    this.form.valueChanges.pipe(startWith(this.formDefaultValue)),
    this.sorting$,
  ]).pipe(
    map(([reports, value, sorting]) =>
      reports
        .filter(
          (report) => value.search === '' || report.campaign_name.toLowerCase().includes(value.search.toLowerCase()),
        )
        .sort((a: any, b: any) =>
          sorting.key
            ? sorting.direction === 'asc'
              ? a[sorting.key] < b[sorting.key]
                ? -1
                : 1
              : sorting.direction === 'desc'
              ? a[sorting.key] < b[sorting.key]
                ? 1
                : -1
              : 1
            : 1,
        ),
    ),
    tap(() => {
      this.page = 1;
      this.pageChange$.next(1);
    }),
    shareReplay(1),
  );

  public length$ = this.reportsFilteredByText$.pipe(map((reports) => reports.length));

  public sums$ = this.reportsFilteredByText$.pipe(
    map((reports) => {
      const sumRevenue = reports.reduce((sum, report) => sum + report.revenue, 0);
      const sumTransactions = reports.reduce((sum, report) => sum + report.transactions, 0);
      const sumOpening = reports.reduce((sum, report) => sum + report.opening, 0);
      const sumClicks = reports.reduce((sum, report) => sum + report.clicks, 0);
      const sumSent = reports.reduce((sum, report) => sum + report.sent, 0);

      return {
        revenue: sumRevenue,
        transactionsRate: sumTransactions / sumClicks,
        orders: sumTransactions,
        sent: sumSent,
        opening: sumOpening,
        openingRate: sumOpening / sumSent,
        clicks: sumClicks,
        clickRate: sumClicks / sumSent,
        rpm: sumRevenue / (sumSent / 1_000),
      };
    }),
  );

  public reportsFilteredByPage$: Observable<Report[]> = combineLatest([
    this.reportsFilteredByText$,
    this.pageChange$,
  ]).pipe(
    map(([reports, page]) => reports.slice((page - 1) * this.pageSize, (page - 1) * this.pageSize + this.pageSize)),
  );

  public loading$: Observable<boolean> = this.reportStore.state$.pipe(
    map((state) => state.reports.loading === TaskState.InProgress),
  );

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private readonly reportStore: ReportStore,
    private readonly downloadService: DownloadService,
  ) {}

  ngOnInit() {
    // Souscription pour dessiner le graphique
    this.reportStore.selectPieData().subscribe(data => {
        this.drawBarChart(data);
    });

    // Souscription pour surveiller l'état de chargement
    this.reportStore.state$.pipe(
        map(state => state.pieData.loading)
    ).subscribe(loadingStatus => {
        this.barChartGenerating = loadingStatus === TaskState.InProgress;
    });

    // Souscription aux changements de valeur du formulaire
    this.form.valueChanges.pipe(
        tap((value) => {
            this.loadPieData(value.period, value.rangeStart, value.rangeEnd);
        }),
    ).subscribe();

    // Chargez les données initiales basées sur les valeurs par défaut du formulaire
    this.loadInitialData();
    
}

loadInitialData() {
  // Vérifie si les données ont déjà été chargées
  this.reportStore.state$.pipe(
      take(1),
      filter(state => state.pieData.loading === TaskState.NotStarted)
  ).subscribe(() => {
      this.loadPieData(
          this.formDefaultValue.period, 
          this.formDefaultValue.rangeStart || undefined, 
          this.formDefaultValue.rangeEnd || undefined
      );
      this.reportStore.getReports();
  });
}



loadPieData(period: string, rangeStart?: string, rangeEnd?: string) {
  let startDate: string | undefined;
  let endDate: string | undefined;
  const now = DateTime.now();
  switch (period) {
      case 'currentMonth':
          const currentMonthStart = now.startOf('month');
          startDate = currentMonthStart.toISODate();
          endDate = now.toISODate();
          break;
      case 'lastMonth':
          const lastMonthStart = now.startOf('month').minus({ months: 1 });
          const lastMonthEnd = now.startOf('month').minus({ days: 1 }); // dernier jour du mois précédent
          startDate = lastMonthStart.toISODate();
          endDate = lastMonthEnd.toISODate();
          break;
        
      case 'last30days':
          const thirtyDaysAgo = DateTime.fromJSDate(new Date()).minus({ days: 30 });
          startDate = thirtyDaysAgo.toISODate();
          endDate = now.toISODate();
          break;
      case 'range':
          startDate = rangeStart;
          endDate = rangeEnd;
          break;
      // Si la période est "all" ou autre chose, nous ne définissons pas startDate et endDate.
  }
  this.reportStore.getPieData({ startDate, endDate });
}


  drawBarChart(data: Report[]) {
    // Extracting labels and values
    let labels = data.map(item => `${item.nb_mails}`);
    let percentage = data.map(item => item.percentage);
    let values = data.map(item => item.nb_emails_adress);

    // Create an array of objects for sorting purposes
    let combined = data.map((item, index) => ({
        label: labels[index],
        value: values[index],
        percentage: percentage[index]
    }));

    // Sort the combined array based on the numeric value of the label
    combined.sort((a, b) => parseInt(a.label) - parseInt(b.label));

    // Extract the sorted labels and values back into separate arrays
    labels = combined.map(item => item.label);
    values = combined.map(item => item.value);
    percentage = combined.map(item => item.percentage);

    if (this.barChart) {
        this.barChart.destroy();
    }

    this.barChart = new Chart('reportBarChart', {
      type: 'bar',
      data: {
          labels: labels,
          datasets: [
              {   
                  data: values,
                  barThickness: 25,
                  maxBarThickness: 20,
                  minBarLength: 2,
                  borderWidth: 1
              }
          ]
      },
      options: {
          // Ajouter un titre au graphique
          plugins: {
            legend: {
              display: false // Cela désactive la légende
          },

              tooltip: {
                  callbacks: {
                    label: function(context) {
                      let index = context.dataIndex;
                      let currentValue = values[index];
                      let formattedValue = currentValue?.toLocaleString('fr-FR'); // Format le nombre avec des séparateurs de milliers
                      let currentPercentage = percentage[index];
                      // Retourner la valeur formatée et le pourcentage
                      return `${formattedValue} @ (${currentPercentage}% de la base)`;
                  }
                  }
              }
          },
          scales: {
              x: {
                  // Titre pour l'axe des abscisses
                  title: {
                      display: true,
                      text: "Nombre d'emails reçus"
                  },
                  // Supprimer la grille de fond pour l'axe des abscisses
                  grid: {
                      display: false
                  }
              },
              y: {
                  // Titre pour l'axe des ordonnées
                  title: {
                      display: true,
                      text: "Nombre adresses emails"
                  },
                  // Supprimer la grille de fond pour l'axe des ordonnées
                  grid: {
                      display: false
                  },
                  ticks: {
                    callback: function(value, index, values) {
                      const numericValue = Number(value);
                      if (numericValue >= 1000) {
                          return numericValue / 1000 + ' K';
                      }
                      return value;
                  }
                    }
              }
          }
      }
  });
}


  public onDateSelection(date: NgbDate, datepicker:any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.form.get('rangeStart')?.setValue(this.formatter.format(this.fromDate));
    this.form.get('rangeEnd')?.setValue(this.formatter.format(this.toDate));
  }

  public isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public changeSorting(key: string): void {
    if (this.sorting$.value.key === key) {
      if (this.sorting$.value.direction === 'asc') {
        this.sorting$.next({ ...this.sorting$.value, direction: 'desc' });
      } else if (this.sorting$.value.direction === 'desc') {
        this.sorting$.next({ direction: null, key: null });
      } else {
        this.sorting$.next({ ...this.sorting$.value, direction: 'asc' });
      }
    } else {
      this.sorting$.next({ direction: 'asc', key });
    }
  }
  
  public export(): void {

    this.reportsFilteredByText$.pipe(take(1)).subscribe((reports) => {
      const heads = [
        `Campagnes`,
        `Date`,
        `Envois`,
        `Ouvertures`,
        `Taux d'ouverture`,
        `Clics`,
        `Taux de clics`,
        `CA`,
        `Conversions`,
        `Taux de conversions`,
        `RPM`,
        `objet`,
        `CA_offline`,
        `Conversions_offline`,
        `CA_online`,
        `Conversions_online`,
      ].join(';');

      const data = reports
        .map((report) =>
          [
            report.campaign_name,
            formatDate(report.date, 'dd/MM/y', this.locale),
            Math.round(report.sent),
            Math.round(report.opening),
            `${Math.round(report.openingRate * 100 * 10) / 10} %`.replace('.', ','),
            Math.round(report.clicks),
            `${Math.round(report.clicksRate * 100 * 100) / 100} %`.replace('.', ','),
            Math.round(report.revenue),
            Math.round(report.transactions),
            `${Math.round(report.transactionsRate * 100 * 10) / 10} %`.replace('.', ','),
            Math.round(report.rpm),
            report.subjects,
            isNaN(report.revenue_offline) ? '' : Math.round(report.revenue_offline),
            isNaN(report.transactions_offline) ? '' : Math.round(report.transactions_offline),
            isNaN(report.revenue_online) ? '' : Math.round(report.revenue_online),
            isNaN(report.transactions_online) ? '' : Math.round(report.transactions_online),
          ].join(';'),
        )
        .join('\n');
      const BOM = '\uFEFF';
      this.downloadService.saveAsCsv(`${heads}\n${data}`, `report-${format(new Date(), 'ddMMyyyy')}.csv`);
    });
  }
}


export function isFilteredByDate(report: Report, value: FormValue): boolean {
  const reportDate = new Date(report.date).getTime();

  const startDate = value.rangeStart && new Date(value.rangeStart).getTime();
  const endDate = value.rangeEnd && new Date(value.rangeEnd).getTime();

  // Calculez les dates pour la période "last30days"
  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);

  return (
    (value.period === 'last30days' && reportDate >= thirtyDaysAgo.getTime() && reportDate <= now.getTime()) ||
    (value.period === 'currentMonth' &&
      DateTime.fromFormat(report.date, 'yyyy-MM-dd').toFormat('yM') === DateTime.now().toFormat('yM')) ||
    (value.period === 'lastMonth' &&
      DateTime.fromFormat(report.date, 'yyyy-MM-dd').toFormat('yM') ===
        DateTime.now().minus({ month: 1 }).toFormat('yM')) ||
    (value.period === 'range' &&
      ((!value.rangeStart && !value.rangeEnd) ||
        (value.rangeStart && !value.rangeEnd && startDate! <= reportDate) ||
        (!value.rangeStart && value.rangeEnd && reportDate <= endDate!) ||
        (!!value.rangeStart && !!value.rangeEnd && startDate! <= reportDate && reportDate <= endDate!)))
  );
}

