import { Component, NgZone, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CampaignSegment } from 'src/app/deebr/model/campaign-segment.model';
import { Category } from 'src/app/deebr/model/category.model';
import { CategoryService } from 'src/app/deebr/service/category.service';
import { DicoEntry } from 'src/app/deebr/model/dico-entry.model';
import { Campagne, CampaignService } from 'src/app/deebr/service/campaign.service';
import { ChartData, ChartOptions } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { BaseChartDirective } from 'ng2-charts';
import { DicoService } from 'src/app/deebr/service/dico.service';
import { BehaviorSubject, combineLatest, finalize, map, Observable, of, switchMap } from 'rxjs';
import { format } from 'date-fns';
import { MailsService } from 'src/app/deebr/service/mails.service';
import { MetaCategory } from 'src/app/deebr/model/meta-category.model';
import { DfService } from 'src/app/deebr/service/df.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { EntrepriseService } from 'src/app/deebr/service/entreprise.service';
import { generateForecastDates } from 'src/app/deebr/model/chart';
import { StockOrDispo } from '../../table/table.component';
import { DownloadService } from 'src/app/deebr/service/download.service';
import { ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})

export class CampaignComponent implements OnInit {
  form!: FormGroup;
  segmentCreationForm!: FormGroup;
  segments: CampaignSegment[] = [];
  selectedSegment: CampaignSegment | null = null;
  categories: Category[] = [];
  currentCategory: Category | undefined = undefined;
  capping = 1;
  optimal: number | null = 30;
  volume: number | null = 30;
  numberOfDays = 7;
  emailsRatio: number | undefined = undefined;
  mailsCount: number = 0;
  dicoEntries: DicoEntry[] = [];
  stockOrDispo: StockOrDispo = StockOrDispo.Stock;
  displayForms = false;
  currentCategoryData: DicoEntry | undefined = undefined;
  forecastDates: Date[] = [];
  @ViewChild('dialog') dialog!: TemplateRef<any>;
  @ViewChild('dialogError') dialogError!: TemplateRef<any>;
  @ViewChild('dialogCurveError') dialogCurveError!: TemplateRef<any>;
  @ViewChild('dialogOk') dialogOk!: TemplateRef<any>;
  dialogRef: NbDialogRef<any> | undefined = undefined;
  hasCurveError: boolean = false;
  errorMessage: string = '';

  selectedCap: number = 1;
  segmentsGenerating = false;
  mergingCampaign = false;
  buildingCampaign = false;
  currentLiftCurveData: { x: number; y: number }[] = [];
  currentLiftCurveFn: Record<number, number> = {};
  potential = 0;
  lift = 0;
  @ViewChildren(BaseChartDirective) charts!: BaseChartDirective[];
  vennDiagrammGenerating = false;
  filteredCategories$!: Observable<Category[]>;
  metaCategory!: MetaCategory;
  metaCategoryChanges = false;
  metaCategories!: MetaCategory[];
  dataRefresh = new BehaviorSubject(false);
  vennData: { x: string[]; id: string[]; value: number }[] | undefined = undefined;
  @ViewChild('instance', { static: true }) instance!: NgbTypeahead;
  allSegmentChecked: boolean = false;
  total: number = 0;
  searchString = '';
  allCategories: Category[] = [];
  filteredDicoEntries: DicoEntry[] = [];

  evolData: ChartData<'line', { date: Date; purchase: number }[]> = {
    datasets: [],
  };

  evolCurveChartOptions: ChartOptions<'line'> = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'purchase',
    },
    scales: {
      yAxes: {},
      xAxes: {
        grid: { display: false },
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          callback: function (value: any, eee: any, aaa: any) {
            return new Date(aaa[eee].value).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' });
          },
        },
      },
    },

    interaction: {
      intersect: false,
      mode: 'index',
    },

    plugins: {
      legend: { display: true },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (ctx: any) => {
            let raw: { [key: string]: any } | any = ctx[0].raw;
            if (raw.date) {
              return new Date(raw.date).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' });
            }
            return '';
          },
          label: (ctx: any) => {
            return (
              (ctx.datasetIndex === 0 ? 'Année N : ' : 'Année N-1 : ') +
              Math.round(parseFloat(ctx.parsed.y)) +
              ' ventes'
            );
          },
        },
      },
    },
  };

  liftCurveChartOptions: ChartOptions<'line'> = {
    responsive: true,

    elements: {
      line: {
        tension: 0.5,
      },
    },
    parsing: {
      xAxisKey: 'x',
      yAxisKey: 'y',
    },
    scales: {
      xAxes: {
        min: 1,
        type: 'linear',
        ticks: {
          callback: (value: string | number) => (typeof value === 'number' ? value : parseFloat(value) * 100) + ' %',
        },
        title: { text: 'volume emails', display: true },
      },
      yAxes: {
        min: 0,
        type: 'linear',
        title: { text: 'Lift', display: true },
      },
    },
  };

  liftCurveData: ChartData<'line', { x: number | null; y: number | null }[]> = {
    datasets: [],
  };

  view: 'table' | 'treemap' = 'table';

  get canUpsertSegment(): boolean {
    const canInsert = this.segments.every((segment) =>
      segment.categories.every((category) => this.currentCategory?.id !== category.category.id),
    );
    const canUpdate = !!this.selectedSegment && this.selectedSegment.categories[0].percentage !== this.volume;
    return canInsert || canUpdate;
  }

  get canMergeCampaigns(): boolean {
    return this.segments.filter((segment) => segment.checked).length >= 2;
  }

  constructor(
    private categoryService: CategoryService,
    private dicoService: DicoService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private ngZone: NgZone,
    private mailsService: MailsService,
    private dfService: DfService,
    private dialogService: NbDialogService,
    private entrepriseService: EntrepriseService,
    private readonly downloadService: DownloadService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    this.metaCategories = this.categoryService.metaCategories;
    if (this.categoryService.currentMeta) {
      this.metaCategory = this.categoryService.currentMeta;
    }
    this.form = this.formBuilder.group({
      capping: [1]  // valeur par défaut
    });

    this.LoadDicoEntriesOnce();

    this.segmentCreationForm = this.formBuilder.group({
      metaCategory: new FormControl(this.categoryService.currentMeta?.id),
      volume: new FormControl(this.volume),
      capping: new FormControl(this.capping),
      search: new FormControl(),
    });

    this.segmentCreationForm.get('capping')?.valueChanges.subscribe((value: string) => {
      this.capping = parseInt(value);
      this.buildCampaign();
    });

    this.segmentCreationForm.get('metaCategory')?.valueChanges.subscribe((meta) => {
      this.metaCategory = this.categoryService.metaCategories.find(mc => mc.id === meta)!; //! voir si c'est utile
      this.categoryService.setCurrentMeta(meta);
      this.updateCategoriesForCurrentMetaCategory();
    });

    /**
     * when range slider moves, some data needs to be refreshed
     */
    this.segmentCreationForm.get('volume')?.valueChanges.subscribe((volume) => {
      this.volume = volume;

      // volume = % base
      // potential = % vente
      // lift = % vente / % base = potential / volume
      this.emailsRatio = (this.mailsCount * volume) / 100;
      this.lift = this.currentLiftCurveFn[this.volume!];
      this.potential = this.lift * volume;
      this.updateLiftPointInitialVolumePosition();
    });

    this.segmentCreationForm.get('search')?.valueChanges.subscribe((searchString) => {
      this.searchString = searchString;
    });

    this.mailsService.getCount().subscribe((count) => {
      this.mailsCount = count;
      this.emailsRatio = (this.mailsCount / this.volume!) * 100;
    });

    this.forecastDates = generateForecastDates();
  }
  get cappingControl(): FormControl {
    return this.form.get('capping') as FormControl;
  }

  setCategory(category: Category) {
    if (
      category.id !== this.currentCategory?.id ||
      category.meta_category !== this.currentCategory?.meta_category
    ) {
      this.selectedSegment = null;
      this.setCurrentCategory(category);
    }
  }

  upsertSegment() {
    if (this.currentCategory) {
      if (!this.selectedSegment) {
        // add
        const potential = this.currentLiftCurveFn[this.volume!] * this.volume!;
        const mailCount = (this.volume! * this.mailsCount) / 100;
        const lift = this.currentLiftCurveFn[this.volume!];
        this.selectedSegment = {
          meta_category: this.categoryService.currentMeta?.id || 0,
          categories: [
            {
              category: this.currentCategory,
              percentage: this.volume,
              potential,
              mailCount,
              lift,
            },
          ],
          percentage: this.volume,
          checked: false,
          potential,
          mailCount,
          lift,
          mailList: [],
        };
        this.segments = [...this.segments, this.selectedSegment];
        this.allSegmentChecked = false;
      } else {
        // update
        this.selectedSegment.percentage = this.volume;
        this.selectedSegment.potential = this.currentLiftCurveFn[this.volume!] * this.volume!;
        this.selectedSegment.mailCount = (this.volume! * this.mailsCount) / 100;
        this.selectedSegment.lift = this.currentLiftCurveFn[this.volume!];
        this.selectedSegment.categories[0].percentage = this.selectedSegment.percentage;
        this.selectedSegment.categories[0].potential = this.selectedSegment.potential;
        this.selectedSegment.categories[0].mailCount = this.selectedSegment.mailCount;
        this.selectedSegment.categories[0].lift = this.selectedSegment.lift;
      }

      this.updateLiftPointCurrentVolumePosition();
      this.buildCampaign();
    }
  }

  selectSegment(segment: CampaignSegment): void {
    if (this.selectedSegment !== segment && segment.categories.length === 1) {
      this.selectedSegment = segment;
      if (
        segment.categories[0].category.id === this.currentCategory?.id &&
        segment.categories[0].category.meta_category === this.currentCategory?.meta_category
      ) {
        this.segmentCreationForm.get('volume')?.setValue(segment.percentage);
      } else {
        this.setCurrentCategory(segment.categories[0].category, segment.percentage);
      }
    }
  }

  checkAllSegment(checked: boolean): void {
    this.allSegmentChecked = checked;
    this.segments.forEach((segment) => {
      segment.checked = checked;
    });
  }

  checkSegment(segment: CampaignSegment, checked: boolean): void {
    segment.checked = checked;
    this.toggleAllSegmentsChecked();
  }
  onValueChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCap = Number(selectElement.value);
  }
  deleteSegment(segment: CampaignSegment) {
    if (this.selectedSegment === segment) {
      this.selectedSegment = null;
    }
    this.segments = this.segments.filter((s) => s !== segment);
    this.toggleAllSegmentsChecked();
    this.buildCampaign();
  }

  askGenerateSegments() {
    this.dialogRef = this.dialogService.open(this.dialog);
  }

  generateSegments() {
    if (this.segments.length > 0) {
      this.segmentsGenerating = true;
      this.dialogRef?.close();

      this.entrepriseService.getEntreprise().pipe(
        switchMap((entreprise) => {
          const downloadType = entreprise.results[0].download_segment;
          let expectedResponseType: 'text' | 'blob' = 'text';
          let segmentObservable: Observable<any>; // Pour stocker le bon observable

          if (downloadType === 'zip') {
            expectedResponseType = 'blob';
            segmentObservable = this.campaignService.generateSegmentsBlob(this.capping, this.segments);
          } else {
            segmentObservable = this.campaignService.generateSegmentsText(this.capping, this.segments);
          }

          return segmentObservable.pipe(
            map((res) => ({
              data: res,
              name: entreprise.results[0].name,
              downloadType: downloadType,
            })),
          );
        }),
        finalize(() => {
          this.segmentsGenerating = false;
        }),
      )
        .subscribe({
          next: ({ data, name, downloadType }) => {
            if (downloadType === 'csv') {
              this.downloadService.saveAsCsv(data, `segments-${name}-${format(new Date(), 'ddMMyyyy')}.csv`);
            } else if (downloadType === 'zip') {
              const url = window.URL.createObjectURL(data);
              const anchor = document.createElement('a');
              anchor.href = url;
              anchor.download = `segments-${name}-${format(new Date(), 'ddMMyyyy')}.zip`;
              document.body.appendChild(anchor);
              anchor.click();
              document.body.removeChild(anchor);
              window.URL.revokeObjectURL(url);  // Nettoyage.
            }
            this.dialogService.open(this.dialogOk);
          },
          error: () => {
            this.dialogService.open(this.dialogError);
          },
        });
    }
  }


  onClickMergeCampaigns(): void {
    this.mergingCampaign = true;
    this.vennDiagrammGenerating = true;
    this.campaignService
      .mergeCampaigns(this.capping, this.segments)
      .pipe(
        finalize(() => {
          this.mergingCampaign = false;
          this.vennDiagrammGenerating = false;
        }),
      )
      .subscribe((res: Campagne) => {
        this.allSegmentChecked = false;
        this.selectedSegment = null;

        // Map over the segments from the response
        this.segments = res.segments.map((data) => {
          // Map categoryIds and metaCategoryIds together
          const categories = data.categoryIds.map((id, index) => {
            const metaCategoryId = data.metaCategoryIds[index];
            const category = this.categoryService.getCategoryById(id, metaCategoryId);
            return {
              category: category!,
              percentage: data.pourcentBase, // Adjust as needed
            };
          });

          // Since the segment may contain categories from multiple meta categories, set meta_category to null or an array of unique meta categories
          const uniqueMetaCategories = Array.from(new Set(data.metaCategoryIds));
          const segmentMetaCategory = uniqueMetaCategories.length === 1 ? uniqueMetaCategories[0] : 0;

          return {
            meta_category: segmentMetaCategory,
            categories: categories,
            percentage: data.pourcentBase,
            checked: false,
            potential: data.pourcentVente,
            mailCount: data.numb_mail,
            mailList: [],
            lift: data.pourcentVente / data.pourcentBase,
          };
        });

        this.total = res.numb_all_mail;
        this.vennData = res.venn;
      });
  }

  private updateCategoriesForCurrentMetaCategory() {
    // Filter categories
    this.categories = this.allCategories.filter(
      (category) => category.meta_category === this.categoryService.currentMeta?.id,
    );
    this.filteredCategories$ = of(this.categories);

    // Filter DicoEntries
    this.filteredDicoEntries = this.dicoEntries.filter(
      (dicoEntry) => dicoEntry.meta_category === this.categoryService.currentMeta?.id,
    );

  }

  private LoadDicoEntriesOnce() {
    this.metaCategoryChanges = true;
    // Fetch all data once
    const metaCategoryIds = this.metaCategories.map(meta => meta.id);
    combineLatest([
      this.dicoService.getDico(this.numberOfDays, metaCategoryIds),
      this.dfService.getDfTo(this.numberOfDays, metaCategoryIds),
      this.categoryService.getCategories(metaCategoryIds)
    ]).subscribe(([entries, dfTo, categories]) => {
      this.dicoEntries = entries;


      // Process dfTo data and merge with dicoEntries
      dfTo.forEach((entry) => {
        const dicoEntry = this.dicoEntries.find(de =>
          de.category.id === entry.category && de.category.meta_category === entry.meta_category
        );
        if (dicoEntry) {
          if (entry.stock !== undefined) {
            this.stockOrDispo = StockOrDispo.Stock;
            dicoEntry.stock = entry.stock;
          }
          if (entry.pourcent_dispo !== undefined) {
            this.stockOrDispo = StockOrDispo.Dispo;
            dicoEntry.dispo = entry.pourcent_dispo * 100;
          }
        }
      });

      this.allCategories = categories; // Store all categories
      this.updateCategoriesForCurrentMetaCategory();

      // Initialize search field
      this.filteredCategories$ = of(this.categories);

      // Handle route parameter for category
      let catId = this.route.snapshot.paramMap.get('category');
      if (catId && !isNaN(parseInt(catId))) {
        const category = this.allCategories.find(cat => cat.id === parseInt(catId!));
        if (category) {
          this.setCurrentCategory(category);
        }
      }
      this.metaCategoryChanges = false;
    }, () => {
      this.metaCategoryChanges = false; // Ensure spinner stops on error
    });
  }

  updateTrackStyle(): void {
    const customRangeInput = document.querySelector('.custom-range') as HTMLElement;
    const adjustedOptimal = this.optimal! * 2;
    customRangeInput.style.setProperty('--optimal-break', `${adjustedOptimal}%`);
  }

  private setCurrentCategory(category: Category, volume?: number | null) {
    this.ngZone.run(() => {
      this.currentCategory = category;

      if (this.currentCategory) {
        // Hiding forms & charts
        this.displayForms = false;
        this.currentCategoryData = undefined;

        // Getting all needed data from backend
        combineLatest([this.campaignService.getLiftCurve(this.currentCategory)]).subscribe(
          (res) => {
            const [lift] = res;
            
            if (lift?.error) {
              // Handle the error
              this.displayErrorMessage(lift!.error);
              // Set appropriate default values or hide the UI elements that depend on the curve
              this.currentLiftCurveData = [];
              this.currentLiftCurveFn = {};
              this.optimal = null;
              this.volume = null;
              this.segmentCreationForm.get('volume')?.setValue(null);
              this.currentCategoryData = undefined;
              this.displayForms = false;
            } else {
              // No error, proceed as before
              this.errorMessage = '';
              this.hasCurveError = false;

              this.currentLiftCurveData = lift!.curve ? lift!.curve : [];
              this.currentLiftCurveFn = this.currentLiftCurveData.reduce(
                (data, value) => ({ ...data, [value.x]: value.y }),
                {},
              );
              this.optimal = lift!.optimal || 30;
              this.updateTrackStyle();
              this.volume = volume || this.optimal;
              this.segmentCreationForm.get('volume')?.setValue(this.volume);
              this.currentCategoryData = this.dicoEntries.find(
                (dico: DicoEntry) =>
                  dico.category.id === this.currentCategory!.id &&
                  dico.category.meta_category === this.currentCategory!.meta_category,
              );

              if (this.currentCategoryData) {
                // Previous year tendency dataset
                const previous = this.currentCategoryData.curveNMinusOne.map((value, key) => ({
                  date: this.forecastDates[key],
                  purchase: value,
                }));

                // Current year forecast
                const current = this.currentCategoryData.evol.map((value, key) => ({
                  date: this.forecastDates[key],
                  purchase: value,
                }));

                this.evolData = {
                  datasets: [
                    { data: current, label: 'Année N' },
                    { data: previous, label: 'Année N-1' },
                  ],
                };

                this.updateLiftCurve();

                // Now we can show charts & forms
                this.displayForms = true;
              }
            }
          },
          (error) => {
            // Handle any unexpected errors
            this.displayErrorMessage('An unexpected error occurred.');
            console.error(error);
          },
        );
      }
    });
  }

  private displayErrorMessage(message: string) {
    
    this.hasCurveError = true;
    this.errorMessage = `Il n'y a pas assez de ventes pour la catégorie ${this.currentCategory?.label} pour générer un segment.`;
    this.displayForms = false;
    this.dialogService.open(this.dialogCurveError);
    this.currentCategory = undefined;
    // Additional logic to hide forms or disable inputs can be added here
  }

  private updateLiftPointInitialVolumePosition() {
    if (this.liftCurveData?.datasets[0]?.data[0]) {
      this.liftCurveData.datasets[0].data.pop();
      this.liftCurveData.datasets[0].data.push({ x: this.volume, y: this.currentLiftCurveFn[this.volume!] });
    }
    this.charts.forEach((elt) => elt.update('none'));
  }

  private updateLiftPointCurrentVolumePosition() {
    if (this.liftCurveData?.datasets[1]?.data[0]) {
      this.liftCurveData.datasets[1].data.pop();
      this.liftCurveData.datasets[1].data.push({ x: this.volume, y: this.currentLiftCurveFn[this.volume!] });
    }
    this.charts.forEach((elt) => elt.update('none'));
  }

  private updateLiftCurve() {
    this.liftCurveData.datasets = [
      // initial volume point
      {
        data: [
          {
            x: this.volume,
            y: this.currentLiftCurveFn[this.volume!],
          },
        ],
        pointRadius: 8,
        pointBorderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
      },

      // current volume point
      {
        data: [
          {
            x: this.selectedSegment?.categories[0].percentage || this.volume,
            y: this.currentLiftCurveFn[this.selectedSegment?.categories[0].percentage || this.volume!],
          },
        ],
        pointRadius: 8,
        pointBorderColor: 'rgba(255,99,132,0.5)',
        pointBackgroundColor: 'rgba(255,99,132,0.5)',
      },

      // optimal point
      {
        data: [{ x: this.optimal, y: this.currentLiftCurveFn[this.optimal!] }],
        pointRadius: 4,
        pointBorderColor: '#01bcf7',
        pointBackgroundColor: '#01bcf7',
      },

      // blue line
      {
        data: this.currentLiftCurveData,
        pointRadius: 0,
        borderColor: '#01bcf7',
        pointBorderColor: '#01bcf7',
        pointBackgroundColor: '#01bcf7',
      },
    ];

    this.charts.forEach((elt) => elt.update('none'));
  }

  private toggleAllSegmentsChecked(): void {
    this.allSegmentChecked = this.segments.every((segment) => segment.checked);
  }

  private buildCampaign() {
    if (this.segments.length > 0) {
      this.buildingCampaign = true;
      this.vennDiagrammGenerating = true;
      this.campaignService
        .mergeCampaigns(this.capping, this.segments)
        .pipe(
          finalize(() => {
            this.buildingCampaign = false;
            this.vennDiagrammGenerating = false;
          }),
        )
        .subscribe((res: Campagne) => {

          this.segments.forEach((segment) => {
            const data = (res.segments || []).find(
              (data) =>
                segment.categories.map((category) => category.category.id).join(',') === data.categoryIds.join(','),
            );
            if (data) {
              segment.percentage = data.pourcentBase;
              segment.potential = data.pourcentVente;
              segment.mailCount = data.numb_mail;
              segment.lift = data.pourcentVente / data.pourcentBase;
            }
          });
          this.total = res.numb_all_mail;
          this.vennData = res.venn;

        });
    }
  }
}
