import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ToastService } from 'src/app/deebr/service/toast.service';
import { GeneratorService } from 'src/app/deebr/service/generator.service';
import { Subscription, map, Observable } from 'rxjs';
import { Event } from 'src/app/deebr/service/event.service';
import { EmailStore } from 'src/app/deebr/component/pages/generator/email.store';
import { NbDialogService } from '@nebular/theme';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Enterprise, UserStore } from '../../store/user.store';

@Component({
  selector: 'app-image-choice',
  templateUrl: './image-choice.component.html',
  styleUrls: ['./image-choice.component.scss']
})
export class ImageChoiceComponent {
  @Input() show: boolean = false;
  @Input() imageUrls: string[] = [];
  @Input() message: string = '';
  @Input() taskId: string = '';  // Assumant que vous passez le taskId en tant qu'input

  @Output() imageSelected = new EventEmitter<{ imageUrl: string[], taskId: string, selectedRatio?: number  }>();
  @Output() closeRequest = new EventEmitter<void>();

  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  @ViewChild('dialogGifTemplate') dialogGifTemplate!: TemplateRef<any>;

  subscription: Subscription;
  progress: number = 0;
  status: string = 'idle';
  intervalId: any;
  selectedButton: number = 0;
  LoadingBar = false;
  isReloading: boolean = false;
  selectedImageUrl: string = '';
  selectedFile: File | null = null;
  errorMessage: string | null = null;
  errorSearchMessage: string | null = null;
  errorMessageGif: string | null = null;
  loadingImages: string[] = [];
  wantGif = false;
  selectedImages: string[] = [];
  selectedRatioString = 'ratio-1-1';
  isButtonEnabled: boolean = false;
  isButtonAnimated: boolean = false;
  isImgLoading: boolean = false;
  searchTerm: string = '';
  isSearching: boolean = false;

  public showAdobeStock$: Observable<boolean> = this.userStore
    .selectEnterprise()
    .pipe(map((enterprise: Enterprise | null) => !!enterprise?.adobe_stock));

  constructor(
    private generatorService: GeneratorService,
    private ToastService: ToastService,
    private Event: Event,
    public emailStore: EmailStore,
    private dialogService: NbDialogService,
    private userStore: UserStore
  ) 
  {
    // Lorsque les images sont reçues:
    this.subscription = this.Event.imageChoiceEvent$.subscribe((eventData) => {
      this.show = true;
      this.imageUrls = eventData.imageUrls;
      this.message = eventData.message;
      this.taskId = eventData.taskId;
      // Initialisez toutes les images comme étant en cours de chargement
      this.loadingImages = [...this.imageUrls];
    });
    // Vous devez également vous abonner à l'événement closeModal
    this.Event.closeModalEvent$.subscribe(() => {
      this.show = false;
    });
  }
  get selectedRatio(): number {
    const ratioString = this.selectedRatioString.replace('ratio-', '');
    const [width, height] = ratioString.split('-').map(Number);
    return width / height;
  }
  onRatioChange(event: any): void {
    console.log('Ratio changed:', event);
  }

  logSelectedRatio(): void {
    console.log('Selected Ratio:', this.selectedRatioString);
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.selectedImages, event.previousIndex, event.currentIndex);
      console.log('Updated selectedImages:', this.selectedImages);
    }
  }

  removeImage(index: number): void {
    this.selectedImages.splice(index, 1);
    this.updateButtonState();
  }
  onModalContentClicked(event: MouseEvent): void {
    event.stopPropagation();
  }

  onImageClick(event: MouseEvent, selectedImageUrl: string) {
    if (this.isSearching) {
      return;
    }
    const index = this.selectedImages.indexOf(selectedImageUrl);

    if (index > -1) {
        // Deselect image
        this.selectedImages.splice(index, 1);
        this.errorMessageGif = null;
    } else {
        // Select image
        if (this.selectedImages.length >= 3 && this.wantGif) {
            this.errorMessageGif = "Vous ne pouvez pas sélectionner plus de 3 images pour créer un GIF.";
            this.dialogService.open(this.dialogGifTemplate);
            return;
        }

        if (this.wantGif) {
            this.selectedImages.push(selectedImageUrl);
            this.errorMessageGif = null;
        } else {
            if (this.selectedImages.length >= 1) {
                // Remplacer l'image sélectionnée si une image est déjà sélectionnée et l'option GIF n'est pas sélectionnée
                this.selectedImages = [selectedImageUrl];
                this.errorMessageGif = null;
            } else {
                this.selectedImages.push(selectedImageUrl);
                this.errorMessageGif = null;
            }
        }
    }
    this.updateButtonState();
  }

  updateButtonState() {
    this.isButtonEnabled = (!this.wantGif && this.selectedImages.length > 0) || 
                           (this.wantGif && this.selectedImages.length > 1);
    this.isButtonAnimated = this.isButtonEnabled;
  }

  getSelectionIndex(imageUrl: string): number {
    return this.selectedImages.indexOf(imageUrl) + 1;
  }

  isLoading(url: string): boolean {
    return this.loadingImages.includes(url);
  }

  onImageLoaded(url: string) {
    // Une fois l'image chargée, retirez-la de la liste des images en cours de chargement
    const index = this.loadingImages.indexOf(url);
    if (index > -1) {
      this.loadingImages.splice(index, 1);
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onButtonClick(event: MouseEvent): void {
    event.stopPropagation(); // Empêche le clic de bulle jusqu'à l'étiquette
    document.getElementById('file-upload')?.click(); // Déclenche manuellement le clic sur l'input
  }

  uploadSelectedFile() {
    this.errorMessage = null;
    if (this.selectedFile) {
        const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
        const allowedExtensions = ['jpeg', 'jpg', 'png', 'webp'];
        const fileSizeInMB = this.selectedFile.size / 1000000; // Convertir la taille en Mo

        if (!allowedExtensions.includes(fileExtension!)) {
            this.errorMessage = "Ce format de fichier n’est pas pris en charge. Vous pouvez importer un fichier .jpeg, .png ou .webp.";
            console.log("erreur")
            return;
        }

        if (fileSizeInMB > 5) {
            this.errorMessage = "La taille du fichier ne doit pas dépasser 5 Mo.";
            return;
        }

        // Ajouter le placeholder pour le spinner
        this.imageUrls.unshift("loading");

        this.generatorService.uploadFile(this.selectedFile).subscribe((uploadedImageUrl: string) => {
            // Remplacer le placeholder par l'URL de l'image chargée
            const loadingIndex = this.imageUrls.indexOf("loading");
            if (loadingIndex !== -1) {
                this.imageUrls[loadingIndex] = uploadedImageUrl;
            }
        });
    } else {
        console.error("Aucun fichier sélectionné");
    }
}



  onImageLoad(event: any) {
    const image = event.target as HTMLImageElement;
    image.classList.add('image-loaded');
  }

  onClose() {
    this.wantGif = false;
    this.selectedImages = [];
    this.ToastService.removeById(this.taskId);
    this.emailStore.patchState((state) => ({
      ...state,
      progress: {
        ...state.progress,
        [this.taskId]: {
          ...state.progress[this.taskId],
          progress: -1,
          imagesChoice: []
        },
      },
    }));
    this.closeRequest.emit();

  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  dragEnterCounter = 0;
  onDrop(event: DragEvent) {
    event.preventDefault();
    this.dragEnterCounter = 0;
    this.isDragging = false;
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      this.uploadSelectedFile();
    }
  }
  isDragging = false;

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    this.dragEnterCounter++;
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.dragEnterCounter--;
    if (this.dragEnterCounter === 0) {
      this.isDragging = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.intervalId)
  }


  ngOnInit(): void {
  }

  toggleGifPreference(isChecked: boolean) {
    this.wantGif = isChecked;
    if (this.selectedImages.length > 1){
      this.selectedImages = this.selectedImages.slice(0,1)
    }
    this.updateButtonState();
    console.log(this.wantGif);
  }

  startGeneration() {
    console.log("start")
    console.log(this.selectedRatioString)
    console.log(this.selectedRatio)
    this.imageSelected.emit({ imageUrl: this.selectedImages, taskId: this.taskId, selectedRatio: this.selectedRatio });
    this.selectedImages = [];
    this.show = false;
    this.wantGif = false;
    this.closeRequest.emit();
  }

  onSearch() {
    if (this.isSearching) {
      return;
    }

    if (!this.searchTerm) {
      this.errorSearchMessage = 'Veuillez entrer un terme de recherche.';
      return;
    }
    
    this.isSearching = true;
    this.isImgLoading = true;
    this.errorSearchMessage = null;
  
    this.generatorService.searchAdobeStock(this.searchTerm).subscribe({
      next: (response) => {
        if (response.urls.length === 0) {
          this.errorSearchMessage = 'Aucune image trouvée pour ce terme de recherche.';
        } else {
          this.imageUrls = response.urls;
        }
        this.isImgLoading = false;
        this.isSearching = false;
      },
      error: (error) => {
        console.error(error);
        this.errorSearchMessage = 'Une erreur est survenue lors de la recherche des images.';
        this.isImgLoading = false;
        this.isSearching = false; // Reset the flag on error
      }
    });
  }

}
