import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ImageSelectionService } from '../../service/image-selection.service';

@Component({
  selector: 'app-firefly-choice',
  templateUrl: './firefly-choice.component.html',
  styleUrls: ['./firefly-choice.component.scss']
})
export class FireflyChoiceComponent implements OnInit {
  constructor(
    protected dialogRef: NbDialogRef<FireflyChoiceComponent>,
    private imageSelectionService: ImageSelectionService
  ) {}

  placeholderCount = 3;
  selectedImageUrl: string = '';
  isLoading: boolean = true;
  private _imageUrls: string[] = [];

  ngOnInit(): void {
    this.imageSelectionService.imageUrls$.subscribe(urls => {
      if (urls.length > 0) {
        this.isLoading = false;
        this._imageUrls = urls;
        this.selectImage(urls[0]);
      } else {
        this.isLoading = true;
      }
    });
  }

  @Input()
  set imageUrls(urls: string[]) {
    if (urls && urls.length > 0) {
      this._imageUrls = urls;
      this.selectImage(urls[0]);
      this.isLoading = false;
    }
  }

  get imageUrls(): string[] {
    return this._imageUrls;
  }

  selectImage(url: string, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.selectedImageUrl = url;
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.selectedImageUrl);
  }
}
