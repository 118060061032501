<form [formGroup]="form">
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-is-auto">
        <select class="form-control" formControlName="period">
          <option value="currentMonth">Ce mois-ci</option>
          <option value="lastMonth">Le mois dernier</option>
          <option value="last30days">Les 30 derniers jours</option>
          <option value="range">Sur la période</option>
        </select>
      </div>
      <ng-container *ngIf="form.value.period === 'range'">
        <div class="col-is-auto">
          <div class="dp-hidden position-absolute">
            <input
              name="datepicker1"
              class="form-control"
              id="dateRange"
              ngbDatepicker
              #datepicker="ngbDatepicker"
              [autoClose]='"outside"'
              (dateSelect)="onDateSelection($event, datepicker)"
              [displayMonths]="2"
              [dayTemplate]="t"
              outsideDays="hidden"
              [startDate]="fromDate!"
              tabindex="-1"
            />
            <ng-template #t let-date let-focused="focused">
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
          <div class="input-group">
            <input class="form-control" (click)="datepicker.toggle()" [value]="formatter.format(fromDate)" />
            <input class="form-control" (click)="datepicker.toggle()"[value]="formatter.format(toDate)" />
            <button
              class="btn btn-outline-secondary bi bi-calendar3"
              (click)="datepicker.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </ng-container>

    </div>

    <ng-container *ngrxLet="figures$ as figures">
      <div class="row mb-4 card-container">
        <!-- Conteneur pour les 4 vignettes -->
        <div class="col-lg-6">
            <div class="row">
                <!-- Première vignette -->
                <div class="col-6">
                  <nb-card class="card">
                    <div class="container-figure">
                      <div class="icon currency">
                        <i class="bi-cash"></i>
                      </div>
                      <div>
                        <div class="figure">{{ figures.revenue | currency: 'EUR':'symbol':'1.0-0' }}</div>
                        <div>Chiffre d'affaires</div>
                      </div>
                    </div>
                  </nb-card>
                </div>
                <!-- Deuxième vignette -->
                <div class="col-6">
                  <nb-card class="card">
                    <div class="container-figure">
                      <div class="icon rate">
                        <i class="bi-handbag"></i>
                      </div>
                      <div>
                        <div class="figure">{{ figures.transactionsRate * 100 | number: '1.2-2' }} %</div>
                        <div>Taux de conversions</div>
                      </div>
                    </div>
                  </nb-card>
                </div>
                <!-- Troisième vignette -->
                <div class="col-6">
                  <nb-card class="card">
                    <div class="container-figure">
                      <div class="icon currency">
                        <i class="bi-basket"></i>
                      </div>
                      <div>
                        <div class="figure">{{ figures.averageCart | currency: 'EUR':'symbol':'1.2-2' }}</div>
                        <div>Panier moyen</div>
                      </div>
                    </div>
                  </nb-card>
                </div>
                <!-- Quatrième vignette -->
                <div class="col-6">
                  <nb-card class="card">
                    <div class="container-figure">
                      <div class="icon value">
                        <i class="bi-cart"></i>
                      </div>
                      <div>
                        <div class="figure">{{ figures.orders | number: '1.0-0' }}</div>
                        <div>Conversions</div>
                      </div>
                    </div>
                  </nb-card>
                </div>
                <div class="col-6">
                  <nb-card class="card">
                    <div class="container-figure">
                      <div class="icon value">
                        <i class="bi-envelope"></i>
                      </div>
                      <div>
                        <div class="figure">{{ figures.sent | number: '1.0-0' }}</div>
                        <div>Emails envoyés</div>
                      </div>
                    </div>
                  </nb-card>
                </div>
                <div class="col-6">
                  <nb-card class="card">
                    <div class="container-figure">
                      <div class="icon rate">
                        <i class="bi-envelope-open"></i>
                      </div>
                      <div>
                        <div class="figure">{{ figures.openingRate * 100 | number: '1.1-1' }} %</div>
                        <div>Taux d'ouvertures</div>
                      </div>
                    </div>
                  </nb-card>
                </div>
                <div class="col-6">
                  <nb-card class="card">
                    <div class="container-figure">
                      <div class="icon rate">
                        <i class="bi-mouse2"></i>
                      </div>
                      <div>
                        <div class="figure">{{ figures.clickRate * 100 | number: '1.2-2' }} %</div>
                        <div>Taux de clics</div>
                      </div>
                    </div>
                  </nb-card>
                </div>
                <div class="col-6">
                  <nb-card class="card">
                    <div class="container-figure">
                      <div class="icon currency">
                        <i class="bi-piggy-bank"></i>
                      </div>
                      <div>
                        <div class="figure">{{ figures.rpm | currency: 'EUR':'symbol':'1.0-0' }}</div>
                        <div>RPM (Revenu pour mille mails)</div>
                      </div>
                    </div>
                  </nb-card>
                </div>
            </div>
        </div>
    
        <!-- Conteneur pour le graphique -->
        <div class="col-lg-6">
          <nb-card [nbSpinner]="barChartGenerating" nbSpinnerStatus="primary" style="max-width: 601px; margin-top:5px">
                <nb-card-header>Pression marketing sur les opt-ins</nb-card-header>
                <nb-card-body>
                    <canvas id="reportBarChart"></canvas>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
    
    </ng-container>

    <div class="row">
      <div class="col">
        <nb-card [nbSpinner]="(loading$ | async) === true" nbSpinnerStatus="primary">
          <nb-card-header *ngrxLet="length$ as length">
            <div class="row justify-content-between">
              <div class="col-is-auto">
                <input
                  placeholder="Rechercher"
                  class="form-control search-field"
                  name="search"
                  formControlName="search"
                />
              </div>
              <div class="col-is-auto">
                <button [disabled]="length === 0" (click)="export()" nbButton appearance="filled" status="danger">
                  Exporter le rapport en CSV
                </button>
              </div>
            </div>
          </nb-card-header>

          <nb-card-body>
            <div class="table-container">
              <table class="table">
                <thead>
                  <tr *ngrxLet="sums$ as sums">
                    <th
                      class="sortable-table-head column-campaign_name"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'campaign_name' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'campaign_name' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('campaign_name')"
                    >
                      <div class="th-content">
                        <div>Campagnes</div>
                        <div class="figure-heading">Totaux</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-date"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'date' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'date' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('date')"
                    >
                      <div class="th-content">
                        <div>Date</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-sent"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'sent' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'sent' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('sent')"
                    >
                      <div class="th-content">
                        <div>Envois</div>
                        <div class="figure">{{ sums.sent | number: '1.0-0' }}</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-opening"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'opening' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'opening' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('opening')"
                    >
                      <div class="th-content">
                        <div>Ouvertures</div>
                        <div class="figure">{{ sums.opening | number: '1.0-0' }}</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-openingRate"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'openingRate' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'openingRate' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('openingRate')"
                    >
                      <div class="th-content">
                        <div>Taux d'ouverture</div>
                        <div class="figure">{{ sums.openingRate * 100 | number: '1.1-1' }} %</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-clicks"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'clicks' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'clicks' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('clicks')"
                    >
                      <div class="th-content">
                        <div>Clics</div>
                        <div class="figure">{{ sums.clicks | number: '1.0-0' }}</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-clicksRate"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'clicksRate' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'clicksRate' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('clicksRate')"
                    >
                      <div class="th-content">
                        <div>Taux de clics</div>
                        <div class="figure">{{ sums.clickRate * 100 | number: '1.2-2' }} %</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-revenue"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'revenue' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'revenue' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('revenue')"
                    >
                      <div class="th-content">
                        <div>CA</div>
                        <div class="figure">{{ sums.revenue | currency: 'EUR':'symbol':'1.0-0' }}</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-transactions"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'transactions' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'transactions' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('transactions')"
                    >
                      <div class="th-content">
                        <div>Conversions</div>
                        <div class="figure">{{ sums.orders | number: '1.0-0' }}</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-transactionsRate"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'transactionsRate' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'transactionsRate' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('transactionsRate')"
                    >
                      <div class="th-content">
                        <div>Taux de conversions</div>
                        <div class="figure">{{ sums.transactionsRate * 100 | number: '1.1-1' }} %</div>
                      </div>
                    </th>
                    <th
                      class="sortable-table-head column-rpm"
                      [class]="{
                        'sorting-asc': sorting$.value.key === 'rpm' && sorting$.value.direction === 'asc',
                        'sorting-desc': sorting$.value.key === 'rpm' && sorting$.value.direction === 'desc'
                      }"
                      (click)="changeSorting('rpm')"
                    >
                      <div class="th-content">
                        <div>RPM</div>
                        <div class="figure">{{ sums.rpm | currency: 'EUR':'symbol':'1.0-0' }}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody *ngrxLet="reportsFilteredByPage$ as reports">
                  <tr *ngFor="let report of reports">
                    <td>{{ report.campaign_name }}</td>
                    <td class="date">{{ report.date | date: 'mediumDate' }}</td>
                    <td class="number">{{ report.sent | number: '1.0-0' }}</td>
                    <td class="number">{{ report.opening | number: '1.0-0' }}</td>
                    <td class="number">{{ report.openingRate * 100 | number: '1.1-1' }} %</td>
                    <td class="number">{{ report.clicks | number: '1.0-0' }}</td>
                    <td class="number">{{ report.clicksRate * 100 | number: '1.2-2' }} %</td>
                    <td class="number">{{ report.revenue | currency: 'EUR':'symbol':'1.0-0' }}</td>
                    <td class="number">{{ report.transactions | number: '1.0-0' }}</td>
                    <td class="number">{{ report.transactionsRate * 100 | number: '1.1-1' }} %</td>
                    <td class="number">{{ report.rpm | currency: 'EUR':'symbol':'1.0-0' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </nb-card-body>

          <nb-card-footer *ngrxLet="length$ as length">
            <div class="row justify-content-end">
              <div class="col-is-auto">
                <ngb-pagination
                  *ngIf="length > pageSize"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [collectionSize]="length"
                  (pageChange)="pageChange$.next($event)"
                ></ngb-pagination>
              </div>
            </div>
          </nb-card-footer>
        </nb-card>
      </div>
    </div>
  </div>
</form>
