import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../model/category.model';
import { DicoEntry } from '../model/dico-entry.model';

@Injectable({
  providedIn: 'root',
})
export class ForecastService {
  constructor(private http: HttpClient) {}

  getForecast(numDays: number, category?: Category): Observable<{ date: number; purchase: number }[]> {
    return this.http.get<{ forecast: { ds: number; yhat: number } }>(`${environment.backendUrl}/api/forecast/`, { params: { day: numDays } }).pipe(
      map((response: any) => {
        let units: { date: number; purchase: number }[] = [];
        let entries: { [key: string]: any }[] = response;

        if (entries.length) {
          entries.forEach((entry, key) => {
            units.push({
              date: entry['date'],
              purchase: entry['yhat'],
            });
          });
        }

        return units;
      }),
    );
  }
}
