<div class="modal-fullscreen" *ngIf="isOpen">
    <div class="modal-header">
        <button type="button" class="btn btn-outline-secondary" (click)="close()">Retour</button>
    </div>
    
    <div class="modal-content">
        <!-- Titre 'Template' -->
        <div style="text-align: left; padding: 15px 0px 20px 30px;">
            <strong>Template</strong>
        </div>

        <!-- Grille des templates -->
        <div class="template-grid">
            <div *ngFor="let template of templates" class="template-item" (click)="selectTemplate(template)">
                <img [src]="template.preview" alt="{{template.name}}">
                <p class="template-title"><strong>{{ template.name }}</strong></p>
            </div>
        </div>
    </div>
</div>


  
