import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService } from 'src/app/deebr/service/toast.service';
import { GeneratorService } from 'src/app/deebr/service/generator.service';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/deebr/service/event.service';
import { EmailStore } from 'src/app/deebr/component/pages/generator/email.store';
import { filter } from 'rxjs/operators';
import { Progress } from 'src/app/deebr/component/pages/generator/email.store';
import { delay } from 'rxjs/operators';
import { NbProgressBarModule } from '@nebular/theme';
import { v4 as uuidv4 } from 'uuid';
import { take } from 'rxjs/operators';







@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent {
  @Input() show: boolean = false;
  @Input() imageUrl: string = '';
  @Input() message: string = '';
  @Input() taskId: string = '';  // Assumant que vous passez le taskId en tant qu'input

  @Output() imageSelected = new EventEmitter<number>();

  subscription: Subscription;
  progress: number = 0;
  status: string = 'idle';
  intervalId: any;
  task_id_midjourney : string ='';
  selectedButton: number = 0;
  isLoading = false;
  LoadingBar = false;
  isReloading: boolean = false;

  constructor(
    private generatorService: GeneratorService,
    private ToastService: ToastService,
    private Event: Event,
    public emailStore: EmailStore
  ) {
    this.subscription = this.Event.imagemodalEvent$.subscribe((eventData) => {
      this.show = true;
      this.imageUrl = eventData.imageUrl;
      this.message = eventData.message;
      this.taskId = eventData.taskId;
    });

    // Vous devez également vous abonner à l'événement closeModal
    this.Event.closeChoiceEvent$.subscribe(() => {
      this.show = false;
    });
  }

  onClose() {
    this.ToastService.clear();
    this.show = false;
  }




  
  selectImage(index: number) {
    this.selectedButton = index;
    this.isLoading = true;
    this.generatorService.selectImage(index, this.imageUrl).subscribe((response) => {
      if (response) {
        this.imageUrl = response;
        this.ToastService.imageSelected(this.imageUrl, this.taskId);
        const imageName = this.emailStore.getCurrentImageName(this.taskId); // Récupérer le nom de l'image courante
        this.emailStore.setImage(this.taskId, imageName, this.imageUrl);
      }
      this.isLoading = false;
      this.show = false;
      this.emailStore.nextImage(this.taskId);
      this.selectedButton = 0;

    // Vérifier si toutes les images ont été sélectionnées
    this.emailStore.select(state => state.progress[this.taskId]).pipe(
      filter((currentProgress): currentProgress is Progress => !!currentProgress),
      filter((currentProgress: Progress) => !!currentProgress.imagesArray && !!currentProgress.currentImageIndex), take(1) // ignore if imagesArray or currentImageIndex is undefined
    ).subscribe((currentProgress: Progress) => {
      if (currentProgress.currentImageIndex !== undefined && currentProgress.imagesArray !== undefined && currentProgress.currentImageIndex < currentProgress.imagesArray.length) {
        // Il reste encore des images à sélectionner, afficher la prochaine
        const nextImage = currentProgress.imagesArray[currentProgress.currentImageIndex];
        this.Event.showModal(nextImage.url, nextImage.content, this.taskId);
      } else {
        // Toutes les images ont été sélectionnées, appeler l'API generateEmailFinal
        this.emailStore.select(state => state.selectedImages[this.taskId]).pipe(take(1)).subscribe(selectedImages => {
          let finalImages: { [key: string]: string } = {};
          if (Array.isArray(selectedImages)) {
            for (let image of selectedImages) {
              finalImages[image.name] = image.url;
            }
          }
          this.generatorService.generateEmailFinal(this.taskId, finalImages).subscribe((response) => {
          })
          delay(1000)
          this.emailStore.progress({taskId: this.taskId});
      });
    }
  });
    }, (error) => {

      this.isLoading = false;
    });

  }
    
  reloadImage(index: number) {
    this.isReloading = true;
    this.task_id_midjourney = uuidv4();
    this.selectedButton = index;
    this.isLoading = true;
    this.LoadingBar = true
    
    // Initialisez l'intervalle ici, avant de faire l'appel API
    this.intervalId = setInterval(() => {
      if (!this.LoadingBar) {
        clearInterval(this.intervalId);
      } else {
        this.generatorService.progress(this.task_id_midjourney).subscribe(progress => {
          this.progress = progress.progress;
        });
      }
    }, 1000);
  
    this.generatorService.regenerateImage(this.taskId, this.imageUrl, this.message, this.task_id_midjourney).subscribe((response) => {
      if (response) {
        this.imageUrl = response;
        this.Event.showModal(this.imageUrl, this.message, this.taskId);
      }
      this.isReloading = false;
      this.isLoading = false;
      this.LoadingBar = false;
      this.selectedButton = 0;
    }, (error) => {
      // handle the error case
      this.isLoading = false;
      this.LoadingBar = false;
      this.isReloading = false;
    });
    this.selectedButton = 0;
  }
  
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.intervalId)
  }



}


