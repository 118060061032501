import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { filter, map, mergeMap, Observable, switchMap, tap, Subject  } from 'rxjs';
import { MetaCategory } from 'src/app/deebr/model/meta-category.model';
import { TaskState } from 'src/app/deebr/model/task.state';
import { GeneratorService } from 'src/app/deebr/service/generator.service';
import { ToastService } from 'src/app/deebr/service/toast.service';
import { Product } from '../dashboard/product.store';
import {Event} from 'src/app/deebr/service/event.service';
import { ProductDisposition } from '../../drag-drop-products/drag-drop-products.component';
import { LoggingService } from 'src/app/deebr/service/logging.service';


export interface Template {
  id: number;
  name: string;
  nb_visuels_principaux: number;
  nb_produits: number;
  preview: string;
  products_disposition: {
    show: boolean;
    schema: ProductDisposition[];
  };
}

export interface Email {
  id: number;
  name: string;
  heure?: string;
  titre?: string;
  object?: string;
  html?: string;
  css?: string;
  preview_html_url?: string;
  preview_img_url?: string;
}


export interface Generate {
  status: string;
  task_id: string;
  images_choice?: string[]
}

export interface Progress {
  progress: number; // -1 | 0..100
  status: string;
  images?: {[key: string]: {url: string, content: string}};
  imagesChoice?: string[];
  imagesArray?: {name: string, url: string, content: string}[];
  currentImageIndex?: number;
  nbVisuelsPrincipaux?: number;
}

export interface EmailState {
  templates: {
    entities: Template[];
    loading: TaskState;
  };
  products: {
    saving: TaskState;
  };
  emails: {
    entities: Email[];
    loading: TaskState;
  };
  email: {
    entities: Record<Email['id'], Email>;
    loading: TaskState;
  };
  progress: Record<Generate['task_id'], Progress>;
  selectedImages: Record<Generate['task_id'], { name: string; url: string }[]>; // Ajoutez cette ligne
}


const initialState: EmailState = {
  templates: {
    entities: [],
    loading: TaskState.NotStarted,
  },
  products: {
    saving: TaskState.NotStarted,
  },
  emails: {
    entities: [],
    loading: TaskState.NotStarted,
  },
  email: {
    entities: {},
    loading: TaskState.NotStarted,
  },
  progress: {},
  selectedImages: {},
  
};


export interface SaveEmailPayload {
  id: Email['id'];
  html: string;
  css: string;
  name: string;
  preview_html_url: string | undefined;
  preview_img_url: string | undefined;
  isManualSave: boolean;
}

@Injectable()
export class EmailStore extends ComponentStore<EmailState> {
  private stopListening$ = new Subject<void>();
  private imagesArray: any[] = [];
  private imagesChoice: string[] = [];
  private currentImageIndex: number = 0;
  readonly email$ = this.select(state => state.email);
  translateEmailCompleted = new Subject<void>();
  constructor(
    private readonly generatorService: GeneratorService, 
    private toastService: ToastService,
    private Event: Event,
    private loggingService: LoggingService) {
    super(initialState);

  }

  resetState() {
    this.setState(initialState);
  }

  nextImage(taskId: string) {
    // Increment the current image index
    this.patchState(state => {
      const currentImageIndex = state.progress[taskId]?.currentImageIndex;
      return {
        ...state,
        progress: {
          ...state.progress,
          [taskId]: {
            ...state.progress[taskId],
            currentImageIndex: currentImageIndex != null ? currentImageIndex + 1 : 0
          }
        }
      };
    });
  }
  
  getCurrentImageName(taskId: string): string {
    const progress = this.get().progress[taskId];

    if (progress && progress.imagesArray && typeof progress.currentImageIndex === 'number') {
      const currentImage = progress.imagesArray[progress.currentImageIndex];
      return currentImage ? currentImage.name : '';
    }
    return '';
  }
  
  
  
  
  setImage(taskId: string, imageName: string, imageUrl: string) {
    this.patchState(state => {
      if (!state.selectedImages[taskId]) {
        state.selectedImages[taskId] = [];
      }
      const existingImageIndex = state.selectedImages[taskId].findIndex(img => img.name === imageName);
  
      if (existingImageIndex > -1) {
        state.selectedImages[taskId][existingImageIndex] = { name: imageName, url: imageUrl };
      } else {
        state.selectedImages[taskId].push({ name: imageName, url: imageUrl });
      }
  
      return state;
    });
  }

  
  getCurrentImageIndex(taskId: string) {
    return this.select(state => state.progress[taskId]?.currentImageIndex)
      .pipe(
        map(index => index !== undefined ? index + 1 : 0)
      );
}


  
  getImagesArrayLength(taskId: string) {
    return this.select(state => state.progress[taskId]?.imagesArray?.length);
  }
  

  


  readonly getTemplates = this.effect((action$: Observable<void>) => {
    return action$.pipe(
      tap(() => {
        this.patchState((state) => ({
          ...state,
          templates: {
            ...state.templates,
            loading: TaskState.InProgress,
          },
        }));
      }),
      switchMap(() =>
        this.generatorService.getTemplates().pipe(
          tapResponse(
            (templates) => {
              // réorganiser les templates par ordre alphabétique
              const sortedTemplates = templates.sort((a, b) => 
                a.name.localeCompare(b.name)
              );
              this.patchState((state) => ({
                ...state,
                templates: {
                  ...state.templates,
                  entities: sortedTemplates,
                  loading: TaskState.Success,
                },
              }));
            },
            () => {
              this.patchState((state) => ({
                ...state,
                templates: {
                  ...state.templates,
                  loading: TaskState.Failed,
                },
              }));
            },
          ),
        ),
      ),
    );
  });

  readonly generateEmail = this.effect(
    
    (
      action$: Observable<{
        metaCategoryId: MetaCategory['id'];
        ids: Product['id'][];
        images_products_urls: Product['image_url_1'][];
        theme: string;
        templateId: Template['id'];
        visuel_url?: string;
        task_id?: string;
        selected_ratio?: number;
        nbVisuelsPrincipaux?: number;
        wantGif?: boolean;
      }>,
    ) => {
      return action$.pipe(
        tap(() => {
          this.patchState((state) => ({
            ...state,
            products: {
              ...state.products,
              saving: TaskState.InProgress,
            },
          }));
        }),
        switchMap(({ metaCategoryId, ids, images_products_urls, theme, templateId, visuel_url, task_id, selected_ratio, nbVisuelsPrincipaux, wantGif }) =>

          this.generatorService.generateEmail(metaCategoryId, ids, images_products_urls, theme, templateId, visuel_url, task_id, selected_ratio, wantGif).pipe(
            tapResponse(
              (res: Generate) => {
                this.patchState((state) => ({
                  ...state,
                  products: {
                    ...state.products,
                    saving: TaskState.Success,
                  },
                  progress: {
                    ...state.progress,
                    [res.task_id]: {
                      progress: 0,
                      status: res.status,
                      nbVisuelsPrincipaux: nbVisuelsPrincipaux
                    },
                  },
                }));

                this.toastService.createProgress(res.task_id, 'Email Creator', res.status);
              },
              () => {
                this.patchState((state) => ({
                  ...state,
                  products: {
                    ...state.products,
                    saving: TaskState.Failed,
                  },
                }));
                this.toastService.error('Email Creator', 'Une erreur est survenue !');
              },
            ),
          ),
        ),
      );
    },
  );

  readonly progress = this.effect(
    (
      action$: Observable<{
        taskId: Generate['task_id'];
      }>,
    ) => {
      return action$.pipe( 
        mergeMap(({ taskId }) =>
          
          this.generatorService.progress(taskId).pipe(     
            tapResponse(
              (res: Progress & {images?: {[key: string]: {url: string, content: string}}, images_choice?: string[]}) => { 
                if (res.progress === -1) {
                  this.patchState((state) => ({
                    ...state,
                    progress: Object.keys(state.progress)
                      .filter((key) => key !== taskId)
                      .reduce((acc, key) => ({ ...acc, [key]: state.progress[key] }), {}),
                  }));
                  this.toastService.cancelProgress(taskId, res.status);
                } else if (res.progress === 100) {
                  this.imagesArray = [];
                  this.currentImageIndex = 0,
                  this.patchState((state) => ({
                    ...state,
                    progress: {
                      ...state.progress,
                      [taskId]: {
                        progress: res.progress,
                        status: res.status,
                      },
                    },
                  }));
                  this.toastService.updateProgress(taskId, 'Génération terminée', res.progress);
                } else if (res.images && Object.keys(res.images).length > 0 && this.currentImageIndex == 0 && this.imagesArray.length == 0) {
                    this.imagesArray = Object.entries(res.images).map(([name, image]) => ({name, ...image}));
                    this.currentImageIndex = 0;
                    this.Event.showModal(this.imagesArray[this.currentImageIndex].url, this.imagesArray[this.currentImageIndex].content, taskId);
                    this.patchState((state) => ({
                      ...state,
                      progress: {
                        ...state.progress,
                        [taskId]: {
                          progress: res.progress,
                          status: res.status,
                          images: res.images,
                          imagesArray: this.imagesArray,
                          currentImageIndex: 0
                        },
                      },
                    }));
                  } else if (res.images_choice && res.images_choice.length > 0) {
                    this.imagesChoice = res.images_choice;
                    const nbVisuelsPrincipaux = this.get().progress[taskId]?.nbVisuelsPrincipaux;
                    this.Event.showChoice(this.imagesChoice, "Votre message ici si nécessaire", taskId, nbVisuelsPrincipaux);
                    this.patchState((state) => ({
                        ...state,
                        progress: {
                            ...state.progress,
                            [taskId]: {
                                progress: res.progress,
                                status: res.status,
                                imagesChoice: this.imagesChoice,
                                nbVisuelsPrincipaux: nbVisuelsPrincipaux
                            },
                        },
                    }));
                }
                
                  else {
                  this.patchState((state) => ({
                    ...state,
                    progress: {
                      ...state.progress,
                      [taskId]: {
                        progress: res.progress,
                        status: res.status,
                      },
                    },
                  }));
                  this.toastService.updateProgress(
                    taskId,
                    res.status,
                    res.progress,
                  );
                }
              },
              () => {
                this.patchState((state) => ({
                  ...state,
                  progress: Object.keys(state.progress)
                    .filter((key) => key !== taskId)
                    .reduce((acc, key) => ({ ...acc, [key]: state.progress[key] }), {}),
                }));
                this.toastService.cancelProgress(taskId, 'Une erreur est survenue !');
              },
            ),
          ),
        ),
      );
    },
  );

  readonly getEmails = this.effect((action$: Observable<void>) => {
    return action$.pipe(
      tap(() => {
        this.patchState((state) => ({
          ...state,
          emails: {
            ...state.emails,
            loading: TaskState.InProgress,
          },
        }));
      }),
      switchMap(() =>
        this.generatorService.getEmails().pipe(
          tapResponse(
            (emails) => {
              this.patchState((state) => ({
                ...state,
                emails: {
                  ...state.emails,
                  entities: emails,
                  loading: TaskState.Success,
                },
              }));
            },
            () => {
              this.patchState((state) => ({
                ...state,
                emails: {
                  ...state.emails,
                  loading: TaskState.Failed,
                },
              }));
            },
          ),
        ),
      ),
    );
  });

  readonly getEmail = this.effect((action$: Observable<{ id: Email['id'] }>) => {
    return action$.pipe(
      tap(() => {
        this.patchState((state) => ({
          ...state,
          email: {
            ...state.email,
            loading: TaskState.InProgress,
          },
        }));
      }),
      switchMap(({ id }) =>
        this.generatorService.getEmail(id).pipe(
          tapResponse(
            (email) => {
              
              this.patchState((state) => ({
                ...state,
                email: {
                  ...state.email,
                  entities: {
                    ...state.email.entities,
                    [id]: {
                      ...email, // Assurez-vous que tous les champs (dont `name`) sont inclus
                    },
                  },
                  loading: TaskState.Success,
                },
              }));
            },
            () => {
              this.patchState((state) => ({
                ...state,
                email: {
                  ...state.email,
                  loading: TaskState.Failed,
                },
              }));
            },
          ),
        ),
      ),
    );
  });

  readonly saveEmail = this.effect((action$: Observable<SaveEmailPayload>) => {
    return action$.pipe(
      tap(({ id, html, css, name, preview_html_url, preview_img_url }) => {
        this.loggingService.log(`saveEmail effect triggered for email ID: ${id}`);
        this.patchState((state) => ({
          ...state,
          email: {
            ...state.email,
            entities: {
              ...state.email.entities,
              [id]: {
                ...(state.email.entities[id] || {}),
                id,
                html,
                css,
                name,
                preview_html_url,
                preview_img_url,
              },
            },
            loading: TaskState.InProgress,
          },
        }));
      }),
      
      switchMap(({ id, html, css, name, isManualSave }) =>
        this.generatorService.saveEmail(id, html, css, name).pipe(
          tapResponse(
            (res: string) => {
              this.loggingService.log(`Email with ID: ${id} successfully saved`);
              this.patchState((state) => ({
                ...state,
                email: {
                  ...state.email,
                  loading: TaskState.Success,
                },
              }));
              if (isManualSave) {
                this.toastService.success('Email Creator', res);
              }
            },
            (error) => {
              this.loggingService.log(`Error saving email with ID: ${id}: ${error}`, 'error');
              this.patchState((state) => ({
                ...state,
                email: {
                  ...state.email,
                  loading: TaskState.Failed,
                },
              }));
              if (isManualSave) {
                this.toastService.error('Email Creator', 'Une erreur est survenue !');
              }
            },
          ),
        ),
      ),
    );
  });


  // Dans l'Email Store
readonly copyEmail = this.effect((action$: Observable<{ id: Email['id']; name: Email['name'] }>) => {
  return action$.pipe(
    tap(() => {
      this.patchState((state) => ({
        ...state,
        emails: {
          ...state.emails,
          loading: TaskState.InProgress,
        },
      }));
    }),
    switchMap(({ id, name }) =>
      this.generatorService.getEmail(id).pipe(
        switchMap((Email) =>
          this.generatorService.copyEmail(id, Email.html, Email.css).pipe(
            tapResponse(
              (newEmail: Email) => {
                this.patchState((state) => {
                  return {
                    ...state,
                    emails: {
                      ...state.emails,
                      entities: [...state.emails.entities, newEmail],
                      loading: TaskState.Success,
                    },
                  };
                });
                this.getEmails();
                this.toastService.success('Email Creator', "Email copié");
              },
              () => {
                this.patchState((state) => ({
                  ...state,
                  emails: {
                    ...state.emails,
                    loading: TaskState.Failed,
                  },
                }));
                this.toastService.error('Email Creator', 'Une erreur est survenue !');
              }
            )
          )
        )
      )
    )
  );
});

readonly translateEmail = this.effect((action$: Observable<{ id: Email['id']; langage_code: string }>) => {
  return action$.pipe(
    tap(({ id }) => {
      this.toastService.createProgress(String(id), 'Email Creator', 'Traduction en cours');
      this.patchState((state) => ({
        ...state,
        emails: {
          ...state.emails,
          loading: TaskState.InProgress,
        },
      }));
    }),
    
    switchMap(({ id, langage_code }) =>
      this.generatorService.getEmail(id).pipe(
        tap(() => {
          this.toastService.updateProgress(String(id), 'Traduction en cours', 30);
        }),
        switchMap(() =>
          this.generatorService.translateEmail(id, langage_code).pipe(
            tapResponse(
              (newEmail: Email) => {
                this.patchState((state) => {
                  return {
                    ...state,
                    emails: {
                      ...state.emails,
                      entities: [...state.emails.entities, newEmail],
                      loading: TaskState.Success,
                    },
                  };
                });
                this.toastService.updateProgress(String(id), 'Traduction en cours', 90);
                this.getEmails();
                this.toastService.removeById(String(id));
                this.toastService.success('Email Creator', 'Email traduit');

                // Notifier que la traduction est terminée
                this.translateEmailCompleted.next();
              },
              () => {
                this.patchState((state) => ({
                  ...state,
                  emails: {
                    ...state.emails,
                    loading: TaskState.Failed,
                  },
                }));
                this.toastService.error('Email Creator', 'Une erreur est survenue !');
                this.toastService.removeById(String(id));
                // Notifier que la traduction est terminée (en cas d'erreur aussi)
                this.translateEmailCompleted.next();
              }
            )
          )
        )
      )
    )
  );
});

  
  

  readonly deleteEmail = this.effect((action$: Observable<{ id: Email['id'] }>) => {
    return action$.pipe(
      tap(() => {
        this.patchState((state) => ({
          ...state,
          emails: {
            ...state.emails,
            loading: TaskState.InProgress,
          },
        }));
      }),
      switchMap(({ id }) =>
        this.generatorService.deleteEmail(id).pipe(
          tapResponse(
            () => {
              this.patchState((state) => ({
                ...state,
                emails: {
                  ...state.emails,
                  entities: state.emails.entities.filter((entity) => entity.id !== id),
                  loading: TaskState.Success,
                },
              }));
              this.toastService.success('Email Creator', "Email supprimé");
            },
            () => {
              this.patchState((state) => ({
                ...state,
                emails: {
                  ...state.emails,
                  loading: TaskState.Failed,
                },
              }));
            },
          ),
        ),
      ),
    );
  });

  
  selectTemplates(): Observable<Template[]> {
    return this.state$.pipe(
      filter((state) => {
        if (state.templates.loading === TaskState.NotStarted) {
          this.getTemplates();
          return false;
        }
        return true;
      }),
      map((state) => state.templates.entities),
    );
  }

  selectProductSaving(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.products.saving === TaskState.InProgress));
  }

  selectEmails(): Observable<Email[]> {
    return this.state$.pipe(
      filter((state) => {
        if (state.emails.loading === TaskState.NotStarted) {
          this.getEmails();
          return false;
        }
        return true;
      }),
      map((state) => state.emails.entities),
    );
  }

  resetEmails(): void {
    this.patchState((state) => ({
      ...state,
      emails: {
        ...state.emails,
        loading: TaskState.NotStarted,
      },
    }));
  }

  selectEmail(id: Email['id']): Observable<Email> {
    return this.state$.pipe(
      filter((state) => {
        if (!state.email.entities[id] && state.email.loading !== TaskState.InProgress) {
          this.getEmail({ id });
          return false;
        }
        return true;
      }),
      map((state) => state.email.entities[id]),
    );
  }
  

  selectProgress(): Observable<EmailState['progress']> {
    return this.state$.pipe(map((state) => state.progress));
  }
}
