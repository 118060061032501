import { addDays } from "date-fns";

// external tooltip config
const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = document.getElementById('tooltip');
  //let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
  }
  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
  tooltipEl.style.borderRadius = '3px';
  tooltipEl.style.color = 'white';
  tooltipEl.style.opacity = '1';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.transform = 'translate(-50%, 0)';
  tooltipEl.style.transition = 'all .1s ease';

  const table = document.createElement('table');
  table.style.margin = '0px';

  tooltipEl.appendChild(table);

  return tooltipEl;
};

const findPos = (obj: any) => {
  let curleft = 0,
    curtop = 0;
  if (obj.offsetParent) {
    do {
      curleft += obj.offsetLeft;
      curtop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
  }
  return [curleft, curtop];
};

const externalTooltipHandler = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b: any) => b.lines);

    const tableHead = document.createElement('thead');

    titleLines.forEach((title: string) => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = '0';

      const th = document.createElement('th');
      th.style.borderWidth = '0';
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body: any, i: any) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'none';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';

      const td = document.createElement('td');
      td.style.borderWidth = '0';

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    if (tableRoot) {
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  }

  //const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  //let {left: positionX, top: positionY} = getOffset(chart.canvas);
  let [positionX, positionY] = findPos(chart.canvas);
  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX - 180 + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY - 155 + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export const smallSalesChartOptions: any = {
  //ChartConfiguration['options']= { @TODO change back this to orignal type
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.5,
    },
    point: {
      radius: 0,
    },
  },
  parsing: {
    xAxisKey: 'date',
    yAxisKey: 'purchase',
  },
  scales: {
    xAxes: {
      display: false,
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'MMM d ', // This is the default
        },
      },
    },
    yAxes: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    vline: false,
    legend: { display: true },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: externalTooltipHandler,
      callbacks: {
        title: (ctx: any) => {
          let raw: { [key: string]: any } | any = ctx[0].raw;
          if (raw.date) {
            return new Date(raw.date).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' });
          }
          return '';
        },
        label: (ctx: any) => {
          return Math.round(parseFloat(ctx.parsed.y)) + ' ventes';
        },
      },
    },
  },
};

export function generateForecastDates() {
  let now = new Date();

  return [...Array(60).keys()].map((n) => {
    return addDays(now, n);
  });
}
