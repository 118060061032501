import { NbJSThemeOptions, DEFAULT_THEME as baseTheme } from '@nebular/theme';

const baseThemeVariables = baseTheme.variables;

export const DEFAULT_THEME = {
  name: 'default',
  base: 'default',
  variables: {
    temperature: {
      arcFill: [
        baseThemeVariables ? baseThemeVariables['primary'] : undefined,
        baseThemeVariables ? baseThemeVariables['primary'] : undefined,
        baseThemeVariables ? baseThemeVariables['primary'] : undefined,
        baseThemeVariables ? baseThemeVariables['primary'] : undefined,
        baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      ],
      arcEmpty: baseThemeVariables ? baseThemeVariables['bg2'] : undefined,
      thumbBg: baseThemeVariables ? baseThemeVariables['bg2'] : undefined,
      thumbBorder: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
    },

    solar: {
      gradientLeft: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      gradientRight: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      shadowColor: 'rgba(0, 0, 0, 0)',
      secondSeriesFill: baseThemeVariables ? baseThemeVariables['bg2'] : undefined,
      radius: ['80%', '90%'],
    },

    traffic: {
      tooltipBg: baseThemeVariables ? baseThemeVariables['bg'] : undefined,
      tooltipBorderColor: baseThemeVariables ? baseThemeVariables['border2'] : undefined,
      tooltipExtraCss: 'border-radius: 10px; padding: 4px 16px;',
      tooltipTextColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      tooltipFontWeight: 'normal',

      yAxisSplitLine: baseThemeVariables ? baseThemeVariables['separator'] : undefined,

      lineBg: baseThemeVariables ? baseThemeVariables['border4'] : undefined,
      lineShadowBlur: '1',
      itemColor: baseThemeVariables ? baseThemeVariables['border4'] : undefined,
      itemBorderColor: baseThemeVariables ? baseThemeVariables['border4'] : undefined,
      itemEmphasisBorderColor: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      shadowLineDarkBg: 'rgba(0, 0, 0, 0)',
      shadowLineShadow: 'rgba(0, 0, 0, 0)',
      gradFrom: baseThemeVariables ? baseThemeVariables['bg2'] : undefined,
      gradTo: baseThemeVariables ? baseThemeVariables['bg2'] : undefined,
    },

    electricity: {
      tooltipBg: baseThemeVariables ? baseThemeVariables['bg'] : undefined,
      tooltipLineColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      tooltipLineWidth: '0',
      tooltipBorderColor: baseThemeVariables ? baseThemeVariables['border2'] : undefined,
      tooltipExtraCss: 'border-radius: 10px; padding: 8px 24px;',
      tooltipTextColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      tooltipFontWeight: 'normal',

      axisLineColor: baseThemeVariables ? baseThemeVariables['border3'] : undefined,
      xAxisTextColor: baseThemeVariables ? baseThemeVariables['fg'] : undefined,
      yAxisSplitLine: baseThemeVariables ? baseThemeVariables['separator'] : undefined,

      itemBorderColor: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      lineStyle: 'solid',
      lineWidth: '4',
      lineGradFrom: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      lineGradTo: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      lineShadow: 'rgba(0, 0, 0, 0)',

      areaGradFrom: baseThemeVariables ? baseThemeVariables['bg2'] : undefined,
      areaGradTo: baseThemeVariables ? baseThemeVariables['bg2'] : undefined,
      shadowLineDarkBg: 'rgba(0, 0, 0, 0)',
    },

    bubbleMap: {
      titleColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      areaColor: baseThemeVariables ? baseThemeVariables['bg4'] : undefined,
      areaHoverColor: baseThemeVariables ? baseThemeVariables['fgHighlight'] : undefined,
      areaBorderColor: baseThemeVariables ? baseThemeVariables['border5'] : undefined,
    },

    profitBarAnimationEchart: {
      textColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,

      firstAnimationBarColor: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      secondAnimationBarColor: baseThemeVariables ? baseThemeVariables['success'] : undefined,

      splitLineStyleOpacity: '1',
      splitLineStyleWidth: '1',
      splitLineStyleColor: baseThemeVariables ? baseThemeVariables['separator'] : undefined,

      tooltipTextColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      tooltipFontWeight: 'normal',
      tooltipFontSize: '16',
      tooltipBg: baseThemeVariables ? baseThemeVariables['bg'] : undefined,
      tooltipBorderColor: baseThemeVariables ? baseThemeVariables['border2'] : undefined,
      tooltipBorderWidth: '1',
      tooltipExtraCss: 'border-radius: 10px; padding: 4px 16px;',
    },

    trafficBarEchart: {
      gradientFrom: baseThemeVariables ? baseThemeVariables['warningLight'] : undefined,
      gradientTo: baseThemeVariables ? baseThemeVariables['warning'] : undefined,
      shadow: baseThemeVariables ? baseThemeVariables['warningLight'] : undefined,
      shadowBlur: '0',

      axisTextColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      axisFontSize: '12',

      tooltipBg: baseThemeVariables ? baseThemeVariables['bg'] : undefined,
      tooltipBorderColor: baseThemeVariables ? baseThemeVariables['border2'] : undefined,
      tooltipExtraCss: 'border-radius: 10px; padding: 4px 16px;',
      tooltipTextColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      tooltipFontWeight: 'normal',
    },

    countryOrders: {
      countryBorderColor: baseThemeVariables ? baseThemeVariables['border4'] : undefined,
      countryFillColor: baseThemeVariables ? baseThemeVariables['bg3'] : undefined,
      countryBorderWidth: '1',
      hoveredCountryBorderColor: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      hoveredCountryFillColor: baseThemeVariables ? baseThemeVariables['primaryLight'] : undefined,
      hoveredCountryBorderWidth: '1',

      chartAxisLineColor: baseThemeVariables ? baseThemeVariables['border4'] : undefined,
      chartAxisTextColor: baseThemeVariables ? baseThemeVariables['fg'] : undefined,
      chartAxisFontSize: '16',
      chartGradientTo: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      chartGradientFrom: baseThemeVariables ? baseThemeVariables['primaryLight'] : undefined,
      chartAxisSplitLine: baseThemeVariables ? baseThemeVariables['separator'] : undefined,
      chartShadowLineColor: baseThemeVariables ? baseThemeVariables['primaryLight'] : undefined,

      chartLineBottomShadowColor: baseThemeVariables ? baseThemeVariables['primary'] : undefined,

      chartInnerLineColor: baseThemeVariables ? baseThemeVariables['bg2'] : undefined,
    },

    echarts: {
      bg: baseThemeVariables ? baseThemeVariables['bg'] : undefined,
      textColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      axisLineColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      splitLineColor: baseThemeVariables ? baseThemeVariables['separator'] : undefined,
      itemHoverShadowColor: 'rgba(0, 0, 0, 0.5)',
      tooltipBackgroundColor: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      areaOpacity: '0.7',
    },

    chartjs: {
      axisLineColor: baseThemeVariables ? baseThemeVariables['separator'] : undefined,
      textColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
    },

    orders: {
      tooltipBg: baseThemeVariables ? baseThemeVariables['bg'] : undefined,
      tooltipLineColor: 'rgba(0, 0, 0, 0)',
      tooltipLineWidth: '0',
      tooltipBorderColor: baseThemeVariables ? baseThemeVariables['border2'] : undefined,
      tooltipExtraCss: 'border-radius: 10px; padding: 8px 24px;',
      tooltipTextColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      tooltipFontWeight: 'normal',
      tooltipFontSize: '20',

      axisLineColor: baseThemeVariables ? baseThemeVariables['border4'] : undefined,
      axisFontSize: '16',
      axisTextColor: baseThemeVariables ? baseThemeVariables['fg'] : undefined,
      yAxisSplitLine: baseThemeVariables ? baseThemeVariables['separator'] : undefined,

      itemBorderColor: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      lineStyle: 'solid',
      lineWidth: '4',

      // first line
      firstAreaGradFrom: baseThemeVariables ? baseThemeVariables['bg3'] : undefined,
      firstAreaGradTo: baseThemeVariables ? baseThemeVariables['bg3'] : undefined,
      firstShadowLineDarkBg: 'rgba(0, 0, 0, 0)',

      // second line
      secondLineGradFrom: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      secondLineGradTo: baseThemeVariables ? baseThemeVariables['primary'] : undefined,

      secondAreaGradFrom: 'rgba(51, 102, 255, 0.2)',
      secondAreaGradTo: 'rgba(51, 102, 255, 0)',
      secondShadowLineDarkBg: 'rgba(0, 0, 0, 0)',

      // third line
      thirdLineGradFrom: baseThemeVariables ? baseThemeVariables['success'] : undefined,
      thirdLineGradTo: baseThemeVariables ? baseThemeVariables['successLight'] : undefined,

      thirdAreaGradFrom: 'rgba(0, 214, 143, 0.2)',
      thirdAreaGradTo: 'rgba(0, 214, 143, 0)',
      thirdShadowLineDarkBg: 'rgba(0, 0, 0, 0)',
    },

    profit: {
      bg: baseThemeVariables ? baseThemeVariables['bg'] : undefined,
      textColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      axisLineColor: baseThemeVariables ? baseThemeVariables['border4'] : undefined,
      splitLineColor: baseThemeVariables ? baseThemeVariables['separator'] : undefined,
      areaOpacity: '1',

      axisFontSize: '16',
      axisTextColor: baseThemeVariables ? baseThemeVariables['fg'] : undefined,

      // first bar
      firstLineGradFrom: baseThemeVariables ? baseThemeVariables['bg3'] : undefined,
      firstLineGradTo: baseThemeVariables ? baseThemeVariables['bg3'] : undefined,
      firstLineShadow: 'rgba(0, 0, 0, 0)',

      // second bar
      secondLineGradFrom: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      secondLineGradTo: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      secondLineShadow: 'rgba(0, 0, 0, 0)',

      // third bar
      thirdLineGradFrom: baseThemeVariables ? baseThemeVariables['success'] : undefined,
      thirdLineGradTo: baseThemeVariables ? baseThemeVariables['successLight'] : undefined,
      thirdLineShadow: 'rgba(0, 0, 0, 0)',
    },

    orderProfitLegend: {
      firstItem: baseThemeVariables ? baseThemeVariables['success'] : undefined,
      secondItem: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      thirdItem: baseThemeVariables ? baseThemeVariables['bg3'] : undefined,
    },

    visitors: {
      tooltipBg: baseThemeVariables ? baseThemeVariables['bg'] : undefined,
      tooltipLineColor: 'rgba(0, 0, 0, 0)',
      tooltipLineWidth: '1',
      tooltipBorderColor: baseThemeVariables ? baseThemeVariables['border2'] : undefined,
      tooltipExtraCss: 'border-radius: 10px; padding: 8px 24px;',
      tooltipTextColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      tooltipFontWeight: 'normal',
      tooltipFontSize: '20',

      axisLineColor: baseThemeVariables ? baseThemeVariables['border4'] : undefined,
      axisFontSize: '16',
      axisTextColor: baseThemeVariables ? baseThemeVariables['fg'] : undefined,
      yAxisSplitLine: baseThemeVariables ? baseThemeVariables['separator'] : undefined,

      itemBorderColor: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      lineStyle: 'dotted',
      lineWidth: '6',
      lineGradFrom: '#ffffff',
      lineGradTo: '#ffffff',
      lineShadow: 'rgba(0, 0, 0, 0)',

      areaGradFrom: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      areaGradTo: baseThemeVariables ? baseThemeVariables['primaryLight'] : undefined,

      innerLineStyle: 'solid',
      innerLineWidth: '1',

      innerAreaGradFrom: baseThemeVariables ? baseThemeVariables['success'] : undefined,
      innerAreaGradTo: baseThemeVariables ? baseThemeVariables['success'] : undefined,
    },

    visitorsLegend: {
      firstIcon: baseThemeVariables ? baseThemeVariables['success'] : undefined,
      secondIcon: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
    },

    visitorsPie: {
      firstPieGradientLeft: baseThemeVariables ? baseThemeVariables['success'] : undefined,
      firstPieGradientRight: baseThemeVariables ? baseThemeVariables['success'] : undefined,
      firstPieShadowColor: 'rgba(0, 0, 0, 0)',
      firstPieRadius: ['70%', '90%'],

      secondPieGradientLeft: baseThemeVariables ? baseThemeVariables['warning'] : undefined,
      secondPieGradientRight: baseThemeVariables ? baseThemeVariables['warningLight'] : undefined,
      secondPieShadowColor: 'rgba(0, 0, 0, 0)',
      secondPieRadius: ['60%', '97%'],
      shadowOffsetX: '0',
      shadowOffsetY: '0',
    },

    visitorsPieLegend: {
      firstSection: baseThemeVariables ? baseThemeVariables['warning'] : undefined,
      secondSection: baseThemeVariables ? baseThemeVariables['success'] : undefined,
    },

    earningPie: {
      radius: ['65%', '100%'],
      center: ['50%', '50%'],

      fontSize: '22',

      firstPieGradientLeft: baseThemeVariables ? baseThemeVariables['success'] : undefined,
      firstPieGradientRight: baseThemeVariables ? baseThemeVariables['success'] : undefined,
      firstPieShadowColor: 'rgba(0, 0, 0, 0)',

      secondPieGradientLeft: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      secondPieGradientRight: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      secondPieShadowColor: 'rgba(0, 0, 0, 0)',

      thirdPieGradientLeft: baseThemeVariables ? baseThemeVariables['warning'] : undefined,
      thirdPieGradientRight: baseThemeVariables ? baseThemeVariables['warning'] : undefined,
      thirdPieShadowColor: 'rgba(0, 0, 0, 0)',
    },

    earningLine: {
      gradFrom: baseThemeVariables ? baseThemeVariables['primary'] : undefined,
      gradTo: baseThemeVariables ? baseThemeVariables['primary'] : undefined,

      tooltipTextColor: baseThemeVariables ? baseThemeVariables['fgText'] : undefined,
      tooltipFontWeight: 'normal',
      tooltipFontSize: '16',
      tooltipBg: baseThemeVariables ? baseThemeVariables['bg'] : undefined,
      tooltipBorderColor: baseThemeVariables ? baseThemeVariables['border2'] : undefined,
      tooltipBorderWidth: '1',
      tooltipExtraCss: 'border-radius: 10px; padding: 4px 16px;',
    },
  },
} as NbJSThemeOptions;
