import { Component, OnInit, OnDestroy, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartConfiguration } from 'chart.js';
import { combineLatest, map, Observable, Subject, takeUntil, take, tap, catchError, Subscription, BehaviorSubject, EMPTY } from 'rxjs';
import { CampaignSegment } from '../../model/campaign-segment.model';
import { Category } from '../../model/category.model';
import { smallSalesChartOptions } from '../../model/chart';
import { DicoEntry } from '../../model/dico-entry.model';
import { MetaCategory } from '../../model/meta-category.model';
import { TaskState } from '../../model/task.state';
import { DicoService } from '../../service/dico.service';
import { Product, ProductsState, ProductsStore } from '../pages/dashboard/product.store';
import { EmailStore, Template } from '../pages/generator/email.store';
import { Enterprise, UserStore } from '../../store/user.store';
import { Event } from '../../../deebr/service/event.service';
import { ProductsService } from 'src/app/deebr/service/product.service';
import { TemplateModalComponent } from '../template-modal/template-modal.component';
import { DownloadService } from 'src/app/deebr/service/download.service';
import { ProductDisposition, DashboardProduct } from '../drag-drop-products/drag-drop-products.component';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { GeneratorService } from 'src/app/deebr/service/generator.service';
import { ScrappedImagesService } from 'src/app/deebr/service/scrapped-images.service';
import { SharedProductService } from 'src/app/deebr/service/shared-product.service';

enum Direction {
  Asc = 'asc',
  Desc = 'desc',
}

export type TableDataLine = {
  category: Category;
  id: number;
  label?: string;
  real?: number;
  realNMinusOne?: number;
  evolution?: number;
  evolutionNMinusOne?: number;
  stock?: number;
  dispo?: number;
  forecast?: number;
  data?: ChartConfiguration['data'];
  showProducts: boolean;
  isSegment?: boolean;
};

type ColumnKey = keyof TableDataLine;

interface Filter {
  deebr: boolean;
  new: boolean;
  visuel: boolean;

}

interface EmailPayload {
  metaCategoryId: number;
  ids: number[];
  theme: string;
  templateId: number;
  visuel_url?: string[];  // Notez le '?' qui rend cette propriété optionnelle
  task_id?: string;     // De même ici
}

export enum StockOrDispo {
  Stock = 'stock',
  Dispo = 'dispo',
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [ProductsStore],
})

export class TableComponent implements OnInit, OnDestroy {
  public forms: Record<Category['id'], FormGroup> = {};
  public nbProducts: Record<Template['id'], Template['nb_produits']> = {};
  public nbVisuelsPrincipaux: Record<Template['id'], Template['nb_visuels_principaux']> = {};
  public matrice: Record<Template['id'], Template['products_disposition']> = {};
  public filter: Filter = { deebr: false, new: false, visuel: false };
  private salesChartsDataList: any = [];
  private _showChoice: boolean = false;
  public selectionDragAndDrop: { id: number | null; image_url_1: string; image_produit_scenario: string | undefined; ordre_generation: number; width: number; height: number; url: string }[] = [];
  public productsDisposition: Record<Category['id'], ProductDisposition[]> = {}; //TODO: peut-etre changer le product ProductDisposition[] en {id: number, image_url_1: string}[] etc
  lines$: BehaviorSubject<TableDataLine[]> = new BehaviorSubject<TableDataLine[]>([]);
  selectedImageUrl!: string;
  taskId!: string;
  imageUrls: string[] = [];
  choiceToastSubscription: Subscription;
  currentCategoryId?: number;
  tempCategoryId?: number;
  selectedTemplateId: number | null = null;
  selectedTemplateName: string | null = null;
  isSaving: boolean = false
  exclude_ids: string | null = null;
  activeFilter: 'deebr' | 'new' | 'visuel' | null = null;
  sortKey: 'ventes' | 'stock' | 'pageviews' | 'score' | null = 'ventes';
  searchTerm: string | null = null;
  sortDirection: 'ASC' | 'DESC' = 'DESC';
  startatOperator: string = '<=';
  endatOperator: string = '>=';
  startDate: string | null = null;
  endDate: string | null = null;
  resetKey = 0;
  lenselection: number = 0;
  panelState: string = 'out';
  unselectedProductsCount: number = 0;
  selectedProductsCount: number = 0;
  smallSalesChartOptions: any = smallSalesChartOptions;
  products$: Observable<ProductsState['products']> = this.productStore.selectProducts();
  categoryId$: Subject<Category['id']> = new Subject();
  isSaving$: Observable<boolean> = this.emailStore.selectProductSaving();
  destroy$: Subject<void> = new Subject();
  TaskState: typeof TaskState = TaskState;
  hoveringProduct: any = null;
  public maxItemCount: number = 0;
  showFromMatrice: boolean = false;
  replaceVenteWithDevis = false;
  images: { [key: number]: string[] } = {};
  private scrappedImagesSubscription!: Subscription;
  clickedProduct: Product | null = null;
  resetProductIndex: { categoryId: Category['id'], productId: number | null } | null = null;  // Ajoute cette propriété


  dialogRef: NbDialogRef<any> | undefined = undefined;
  @ViewChild('dialogSelectTemplate') dialogSelectTemplate!: TemplateRef<any>;
  @ViewChild('dialogNoPlace') dialogNoPlace!: TemplateRef<any>;
  @ViewChild('dialogErrorScrap') dialogErrorScrap!: TemplateRef<any>;

  @Input()
  metaCategory!: MetaCategory;

  @Input()
  currentCategory?: Category | undefined;

  @Input()
  dicoEntries: DicoEntry[] = [];

  @Input()
  segments?: CampaignSegment[] | undefined;

  @Input()
  stockOrDispo: StockOrDispo = StockOrDispo.Stock;

  @Input()
  numberOfDays!: number;

  @Input()
  forecastDates: Date[] = [];

  @Input()
  searchString: string = '';

  @Input()
  canShowProduct: boolean = false;

  @Input()
  templates: Template[] = [];

  @Output()
  onRowClicked: EventEmitter<Category> = new EventEmitter();

  @ViewChild('templateModal') templateModal!: TemplateModalComponent;
  openModal() {
    this.templateModal.open(); // Assurez-vous que `templateModal` est correctement référencé
  }

  onBlur(): void {
    const textArea = document.querySelector('.textarea-resize');
    if (textArea) {
      textArea.scrollTop = 0;
    }
  }

  initializeProductsDisposition(): void {
    this.productsDisposition[this.currentCategoryId!] = this.matrice[this.selectedTemplateId ?? 0].schema;
    this.showFromMatrice = this.matrice[this.selectedTemplateId ?? 0].show;
    this.productsDisposition[this.currentCategoryId!] = this.productsDisposition[this.currentCategoryId!]?.map((productDisposition) => ({ ...productDisposition, id: null, image_url_1: '', image_produit_scenario: '' }));
  }

  initializeSelectionDragAndDrop(): void {
    // fill the selectionDragAndDrop array with the productsDisposition array
    this.selectionDragAndDrop = this.productsDisposition[this.currentCategoryId!].map(product => ({ id: product.id, image_url_1: product.image_url_1, image_produit_scenario: product.image_produit_scenario, ordre_generation: product.ordre_generation, width: product.width, height: product.height, url: product.url }));
  }

  onTemplateSelect(template: Template) {
    this.selectedTemplateId = template.id;
    this.selectedTemplateName = template.name;
    this.initializeProductsDisposition();
    this.initializeSelectionDragAndDrop();
    this.maxItemCount = Object.keys(this.productsDisposition[this.currentCategoryId!]).length;
    // console.log("INITIALISATION PRODUCTS DISPOSITION: ", this.productsDisposition);
    // console.log("INITIALISATION SELECTION DRAG AND DROP: ", this.selectionDragAndDrop);
  }

  public showGenerator$: Observable<boolean> = this.userStore
    .selectEnterprise()
    .pipe(map((enterprise: Enterprise | null) => !!enterprise?.email_gen));

  public showPerfectstayElement$: Observable<boolean> = this.userStore
  .selectEnterprise()
  .pipe(
    map((enterprise: Enterprise | null) => {
      return enterprise?.name.toLowerCase().includes('perfectstay') ?? false;
    })
  );

  private updateReplaceVenteWithDevis(enterprise: Enterprise | null): void {
    if (enterprise?.name.toLowerCase().includes('perfectstay')) {
      this.replaceVenteWithDevis = enterprise.name === 'perfectstay_emirates' || enterprise.name === 'perfectstay_transavia';
    } else {

      this.replaceVenteWithDevis = false;
    }
    this.cdr.detectChanges(); // Trigger change detection
  }
  
  

  sorting: { key: string | undefined; direction: Direction | undefined } = {
    key: 'forecast',
    direction: Direction.Desc,
  };

  constructor(
    private formBuilder: FormBuilder,
    private dicoService: DicoService,
    private userStore: UserStore,
    private productStore: ProductsStore,
    private emailStore: EmailStore,
    private Event: Event,
    private cdr: ChangeDetectorRef,
    private readonly productsService: ProductsService,
    private readonly downloadService: DownloadService,
    private dialogService: NbDialogService,
    private generatorService: GeneratorService,
    private scrappedImagesService: ScrappedImagesService,
    private sharedProductService: SharedProductService,
  ) {
    this.lines$.subscribe(data => {});
    this.choiceToastSubscription = this.Event.imageChoiceEvent$.subscribe((toast: { imageUrls: string[], message: string, taskId: string }) => {
      if (toast) {
        this.imageUrls = toast.imageUrls;
        this.showChoice = true;
        this.taskId = toast.taskId;
      }
    });
  }

  get showChoice(): boolean {
    return this._showChoice;
  }

  set showChoice(value: boolean) {
    this._showChoice = value;
  }

  formatNumber(num?: number): string {
    if (num === undefined || num === null) {
      return 'N/A';  // Ou tout autre texte par défaut
    }
    return num.toLocaleString('fr-FR');
  }

  formatNumberToTwoDecimals(num?: number): any {
    if (num === undefined || num === null) {
      return 'N/A'; // Ou tout autre texte par défaut
    } else if (num === 0) {
      return 0;
    }
    return Number(num).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  toggleDragDropPanel() {
    this.panelState = this.panelState === 'out' ? 'in' : 'out';
    // Communiquer avec le composant app-drag-drop-products si nécessaire
  }

  exportProducts(categoryId: Category['id']) {
    if (this.currentCategoryId === undefined || this.currentCategoryId === null) {
      console.error("Aucune catégorie actuelle sélectionnée!");
      return;
    }

    this.productStore.state$.pipe(take(1)).subscribe(state => {
      const currentCategoryState = state.products[this.metaCategory.id]?.[categoryId] || {};

      this.productsService.getProduct(
        this.currentCategoryId!,
        this.metaCategory.id,
        0,
        currentCategoryState.filter,
        currentCategoryState.sort,
        currentCategoryState.searchTerm,
        currentCategoryState.exclude_ids,
        null,
        currentCategoryState.startat,
        currentCategoryState.startatComparator,
        currentCategoryState.endat,
        currentCategoryState.endatComparator,
        true, // Indiquez que vous voulez exporter les données
        'blob' // Le type de réponse attendu pour un export
      ).pipe(
        tap((response: Blob) => {
          if (response.size === 0) {
            console.error("Aucun produit à exporter.");
            return;
          }
          response.text().then(text => {
            this.downloadService.saveAsCsv(text, 'products.csv');
          });
        }),
        catchError((error) => {
          console.error('An error occurred', error);
          return EMPTY;
        })
      ).subscribe();
    });
  }

  loadAllProductsForCategory(metaCategoryId: number, categoryId: Category['id']): Observable<Product[]> {
    return new Observable<Product[]>(observer => {
      this.productsService.getProduct(categoryId, metaCategoryId, 0, null, { key: 'ventes', direction: 'DESC' }, null,)
        .pipe(
          take(1),
        )
        .subscribe({
          next: (products) => {
            observer.next(products); // Pousse les produits dans l'Observable
            observer.complete(); // Complète l'Observable
          },
          error: (err) => {
            observer.error(err); // En cas d'erreur, propage l'erreur
            observer.complete(); // Complète l'Observable
          }
        });
      // Pas besoin de retourner une fonction de désinscription ici,
      // car `take(1)` garantit que l'Observable est complété après le premier événement.
    });
  }

  handleCloseRequest() {
    this.showChoice = false;
  }

  ngOnInit(): void {
    this.userStore.selectEnterprise().subscribe({
      next: (enterprise) => {
        this.updateReplaceVenteWithDevis(enterprise);
      },
      error: (err) => {
        console.error('Error:', err);
      },
      complete: () => {

      }
    });
  
    this.showPerfectstayElement$.subscribe();
    this.setLineGraphsData();
    this.buildSortedList();
    this._initProductsLoader();
    this.lines$.subscribe(data => {});
    this.setLineGraphsData();
    this.buildSortedList();
    this._initProductsLoader();
    this.lines$.subscribe(data => { });

    // Subscribe to the scrapped images service
    this.scrappedImagesSubscription = this.scrappedImagesService.currentProducts.subscribe((productMap) => {
      productMap.forEach((urls, productId) => {
        if (!this.images[productId!]) {
          this.images[productId!] = [];
        }

        // Add only unique URLs to prevent duplication
        urls.forEach((url: string) => {
          if (!this.images[productId!].includes(url) && url !== "no image found") {
            this.images[productId!].push(url);
          }
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('searchString' in changes) {
      this.buildSortedList();
    }

    if ('dicoEntries' in changes) {
      this.setLineGraphsData();
      this.buildSortedList();
    }

    if ('segments' in changes) {
      this.buildSortedList();
    }

    if ('templates' in changes) {
      this.nbProducts = (this.templates || []).reduce(
        (res, template) => ({ ...res, [template.id]: template.nb_produits }),
        {},
      );

      this.matrice = (this.templates || []).reduce(
        (res, template) => ({ ...res, [template.id]: template.products_disposition }),
        {},
      );

      this.nbVisuelsPrincipaux = (this.templates || []).reduce(
        (res, template) => ({ ...res, [template.id]: template.nb_visuels_principaux }),
        {},
      );
      
    }
  }

  onImageSelected(event: { imageUrl: any, taskId: string, selectedRatio?: number, wantGif?: boolean }, categoryId: Category['id']) {
    // console.log('onImageSelected', event.selectedRatio)
    this.validateSelection(categoryId, event.imageUrl, event.taskId, event.selectedRatio, event.wantGif);
  }

  ngOnDestroy(): void {
    this.destroy$.next(void 0);
    this.destroy$.complete();
    this.choiceToastSubscription.unsubscribe();
  }

  changeSorting(key: string) {
    if (this.sorting.key === key) {
      if (this.sorting.direction === Direction.Asc) {
        this.sorting.direction = Direction.Desc;
      } else if (this.sorting.direction === Direction.Desc) {
        this.sorting = { direction: undefined, key: undefined };
      } else {
        this.sorting.direction = Direction.Asc;
      }
    } else {
      this.sorting.key = key;
      this.sorting.direction = Direction.Asc;
    }
    this.buildSortedList();
  }

  getTooltipText(entry: { id: number }): string {
    if (this.selectedTemplateId === null) {
      return 'Veuillez choisir un template pour générer votre email';
    } else {
      this.selectedProductsCount = this.productsDisposition[entry.id]?.filter(p => p.id !== null)?.length || 0;
      this.unselectedProductsCount = this.productsDisposition[entry.id]?.filter(p => p.id === null)?.length || 0;

      if (this.unselectedProductsCount > 0) {
        return 'Veuillez sélectionner ' + this.unselectedProductsCount + ' produit(s) pour pouvoir générer votre email';
      } else if (this.unselectedProductsCount < 0) {
        return 'Veuillez supprimer ' + (-this.unselectedProductsCount) + ' produit(s) ou changer de template pour pouvoir générer votre email';
      }
    }
    return '';
  }

  tooltipDisabled(entry: { id: number }): boolean {
    return this.selectedTemplateId !== null && this.productsDisposition[entry.id]?.filter(p => p.id !== null).length === this.nbProducts[this.selectedTemplateId] && !this.isSaving;
  }

  filterBy(categoryId: Category['id'], filterValue: 'deebr' | 'new' | 'visuel' | null): void {
    this.productStore.state$.pipe(
      take(1),
      tap(state => {
        const currentCategoryState = state.products[this.metaCategory.id]?.[categoryId] || {};
        // Mise à jour de l'état avec le nouveau filtre et réinitialisation de la page
        this.productStore.patchState({
          ...state,
          products: {
            ...state.products,
            [this.metaCategory.id]: {
              ...state.products[this.metaCategory.id],
              [categoryId]: {
                ...currentCategoryState,
                filter: filterValue,
                entities: [],
                page: 1, // Réinitialiser la pagination à la première page lors de l'application d'un nouveau filtre
              },
            },
          },
        });
        this.productStore.getProduct({
          metaCategoryId: this.metaCategory.id,
          categoryId: categoryId,
          page: 1, // S'assurer de demander la première page de résultats
          filter: filterValue,
          sort: currentCategoryState.sort, // Conserver le tri actuel
          searchTerm: currentCategoryState.searchTerm,
          exclude_ids: currentCategoryState.exclude_ids,
          startat: currentCategoryState.startat,
          startatComparator: currentCategoryState.startatComparator,
          endat: currentCategoryState.endat,
          endatComparator: currentCategoryState.endatComparator,
        });
      })
    ).subscribe();
  }

  public clearSelection(categoryId: Category['id']): void {
    this.productsDisposition[categoryId].forEach(product => {
      product.id = null;
      product.image_url_1 = '';
      product.image_produit_scenario = '';
    });
  }

  resetFilter(categoryId: Category['id']): void {
    this.activeFilter = null;
    this.sortKey = null;
    this.searchTerm = '';
    this.exclude_ids = null;
    this.sortDirection = 'DESC';
    this.startatOperator = '<=';
    this.endatOperator = '>=';
    this.startDate = '';
    this.endDate = '';
    this.cdr.detectChanges();
    this.productStore.state$.pipe(
      take(1),
      tap(state => {
        const currentCategoryState = state.products[this.metaCategory.id]?.[categoryId] || {};
        this.productStore.patchState({
          ...state,
          products: {
            ...state.products,
            [this.metaCategory.id]: {
              ...state.products[this.metaCategory.id],
              [categoryId]: {
                ...currentCategoryState,
                filter: this.activeFilter,
                sort: { key: 'ventes', direction: this.sortDirection },
                searchTerm: this.searchTerm,
                exclude_ids: this.exclude_ids,
                startat: this.startDate,
                startatComparator: this.startatOperator,
                endat: this.endDate,
                endatComparator: this.endatOperator,
                entities: [],
                page: 1,
              },
            },
          },
        });
        this.productStore.getProduct({
          metaCategoryId: this.metaCategory.id,
          categoryId: categoryId,
          filter: this.activeFilter,
          sort: { key: 'ventes', direction: this.sortDirection },
          searchTerm: this.searchTerm,
          exclude_ids: this.exclude_ids,
          startat: this.startDate,
          startatComparator: this.startatOperator,
          endat: this.endDate,
          endatComparator: this.endatOperator,
          page: 1,
        });
      })
    ).subscribe();
  }

  sortBy(categoryId: Category['id'], sortKey: 'ventes' | 'stock' | 'pageviews' | 'score'): void {
    this.sortDirection = 'DESC'; // Assumption: sorting direction is always 'DESC' for simplicity
    this.productStore.state$.pipe(
      take(1),
      tap(state => {
        const currentCategoryState = state.products[this.metaCategory.id]?.[categoryId] || {};

        const updatedCategoryState = {
          ...currentCategoryState,
          sort: {
            key: sortKey,
            direction: this.sortDirection,
          },
          entities: [],
          page: 1,
        };

        this.productStore.patchState({
          ...state,
          products: {
            ...state.products,
            [this.metaCategory.id]: {
              ...state.products[this.metaCategory.id],
              [categoryId]: updatedCategoryState,
            },
          },
        });

        // Appel à getProduct avec les nouveaux critères de tri et de pagination
        this.productStore.getProduct({
          metaCategoryId: this.metaCategory.id,
          categoryId: categoryId,
          page: 1,
          filter: updatedCategoryState.filter,
          sort: { key: sortKey, direction: this.sortDirection },
          searchTerm: updatedCategoryState.searchTerm,
          exclude_ids: updatedCategoryState.exclude_ids,
          startat: updatedCategoryState.startat,
          startatComparator: updatedCategoryState.startatComparator,
          endat: updatedCategoryState.endat,
          endatComparator: updatedCategoryState.endatComparator,
        });
      })
    ).subscribe();
  }

  public handleStartComparatorChange(startatOperator: string): void {
    this.startatOperator = startatOperator

    if (!this.currentCategoryId) {
      console.error("Aucune catégorie actuelle sélectionnée!");
      return;
    }

    if (this.startDate) {
      const formattedEndDate = this.endDate ? this.formatDate(new Date(this.endDate)) : this.endDate;
      this.filterByDate(this.currentCategoryId, this.formatDate(new Date(this.startDate)), this.startatOperator, formattedEndDate, this.endatOperator);
    }
  }

  public handleEndComparatorChange(endatOperator: string): void {
    this.endatOperator = endatOperator

    if (!this.currentCategoryId) {
      console.error("Aucune catégorie actuelle sélectionnée!");
      return;
    }

    if (this.endDate) {
      const formattedStartDate = this.startDate ? this.formatDate(new Date(this.startDate)) : this.startDate;
      this.filterByDate(this.currentCategoryId, formattedStartDate, this.startatOperator, this.formatDate(new Date(this.endDate)), this.endatOperator);
    }
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    // Ajoute un zéro devant le mois/jour s'il est inférieur à 10 pour conserver le format mm/dd
    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  }

  public handleStartDateChange(date: Date): void {
    if (!this.currentCategoryId) {
      console.error("Aucune catégorie actuelle sélectionnée!");
      return;
    }

    this.startDate = this.formatDate(date);
    const formattedEndDate = this.endDate ? this.formatDate(new Date(this.endDate)) : this.endDate;
    this.filterByDate(this.currentCategoryId, this.startDate, this.startatOperator, formattedEndDate, this.endatOperator);
  }

  public handleEndDateChange(date: Date): void {
    if (!this.currentCategoryId) {
      console.error("Aucune catégorie actuelle sélectionnée!");
      return;
    }

    this.endDate = this.formatDate(date);
    const formattedStartDate = this.startDate ? this.formatDate(new Date(this.startDate)) : this.startDate;
    this.filterByDate(this.currentCategoryId, formattedStartDate, this.startatOperator, this.endDate, this.endatOperator);
  }

  public handleStartDateInputChange(): void {
    if (!this.currentCategoryId) {
      console.error("Aucune catégorie actuelle sélectionnée !");
      return;
    }

    this.startDate = null;
    this.filterByDate(this.currentCategoryId, this.startDate, this.startatOperator, this.endDate, this.endatOperator);
  }

  public handleEndDateInputChange(): void {
    if (!this.currentCategoryId) {
      console.error("Aucune catégorie actuelle sélectionnée !");
      return;
    }

    this.endDate = null;
    this.filterByDate(this.currentCategoryId, this.startDate, this.startatOperator, this.endDate, this.endatOperator);
  }

  public filterByDate(
    categoryId: Category['id'],
    startDate: string | null,
    startatOperator: string | null,
    endDate: string | null,
    endatOperator: string | null
  ): void {
    this.productStore.state$.pipe(
      take(1),
      tap(state => {
        const currentCategoryState = state.products[this.metaCategory.id]?.[categoryId] || {};
        this.productStore.patchState({
          ...state,
          products: {
            ...state.products,
            [this.metaCategory.id]: {
              ...state.products[this.metaCategory.id],
              [categoryId]: {
                ...currentCategoryState,
                startat: startDate,
                startatComparator: startatOperator,
                endat: endDate,
                endatComparator: endatOperator,
                entities: [],
                page: 1, // Réinitialiser la pagination à la première page lors de l'application d'un nouveau filtre
              },
            },
          },
        });
        this.productStore.getProduct({
          metaCategoryId: this.metaCategory.id,
          categoryId: categoryId,
          page: 1,
          filter: currentCategoryState.filter,
          sort: currentCategoryState.sort,
          searchTerm: currentCategoryState.searchTerm,
          exclude_ids: currentCategoryState.exclude_ids,
          startat: startDate,
          startatComparator: startatOperator,
          endat: endDate,
          endatComparator: endatOperator,
        });
      })
    ).subscribe();
  }

  getSortText(sortKey: string): string {
    const sortTextMap: { [key: string]: string } = {
      'ventes': 'Ventes',
      'stock': 'Stock',
      'pageviews': 'Pages Vues',
      'score': 'Score',
    };
    return sortTextMap[sortKey] || '';
  }

  public loadMore(categoryId: Category['id']): void {
    this.productStore.state$.pipe(
      take(1),
      tap(state => {
        const currentCategoryState = state.products[this.metaCategory.id]?.[categoryId];
        if (!currentCategoryState) return; // S'assurer que l'état de la catégorie existe

        const currentPage = currentCategoryState?.page ?? 1;
        const nextPage = currentPage + 1;

        const actionParams = {
          metaCategoryId: this.metaCategory.id,
          categoryId: categoryId,
          page: nextPage, // Incrémenter le numéro de page pour le chargement
          searchTerm: currentCategoryState.searchTerm,
          exclude_ids : currentCategoryState.exclude_ids,
          filter: currentCategoryState.filter,
          sort: currentCategoryState.sort,
          startat: currentCategoryState.startat,
          startatComparator: currentCategoryState.startatComparator,
          endat: currentCategoryState.endat,
          endatComparator: currentCategoryState.endatComparator,
          // Ajouter d'autres paramètres si nécessaire
        };

        // Mise à jour de l'état pour indiquer la tentative de chargement de plus de produits
        this.productStore.patchState((state) => ({
          ...state,
          products: {
            ...state.products,
            [this.metaCategory.id]: {
              ...state.products[this.metaCategory.id],
              [categoryId]: {
                ...currentCategoryState,
                page: actionParams.page, // Mettre à jour le numéro de page
                loading: TaskState.InProgress,
              },
            },
          },
        }));
        // Déclencher l'effet getProduct avec les paramètres mis à jour
        this.productStore.getProduct(actionParams);
      })
    ).subscribe();
  }


  public onSearch(categoryId: Category['id'], event: any): void {
    this.searchTerm = event.target.value;
    this.productStore.state$.pipe(
      take(1),
      tap(state => {
        const currentCategoryState = state.products[this.metaCategory.id]?.[categoryId] || {};
        const updatedCategoryState = {
          ...currentCategoryState,
          searchTerm: this.searchTerm,
          entities: [],
          page: 1,
        };
        this.productStore.patchState({
          ...state,
          products: {
            ...state.products,
            [this.metaCategory.id]: {
              ...state.products[this.metaCategory.id],
              [categoryId]: updatedCategoryState,
            },
          },
        });
        this.productStore.getProduct({
          metaCategoryId: this.metaCategory.id,
          categoryId: categoryId,
          page: 1,
          filter: updatedCategoryState.filter,
          sort: currentCategoryState.sort,
          searchTerm: this.searchTerm,
          exclude_ids: updatedCategoryState.exclude_ids,
          startat: updatedCategoryState.startat,
          startatComparator: updatedCategoryState.startatComparator,
          endat: updatedCategoryState.endat,
          endatComparator: updatedCategoryState.endatComparator,
        });
      })
    ).subscribe();
  }

  public onIdsExclusion(categoryId: Category['id'], event: any): void {
    this.exclude_ids = event.target.value;
    this.productStore.state$.pipe(
      take(1),
      tap(state => {
        const currentCategoryState = state.products[this.metaCategory.id]?.[categoryId] || {};
        const updatedCategoryState = {
          ...currentCategoryState,
          exclude_ids: this.exclude_ids,
          entities: [],
          page: 1,
        };
        this.productStore.patchState({
          ...state,
          products: {
            ...state.products,
            [this.metaCategory.id]: {
              ...state.products[this.metaCategory.id],
              [categoryId]: updatedCategoryState,
            },
          },
        });
        this.productStore.getProduct({
          metaCategoryId: this.metaCategory.id,
          categoryId: categoryId,
          page: 1,
          filter: updatedCategoryState.filter,
          sort: currentCategoryState.sort,
          searchTerm: currentCategoryState.searchTerm,
          exclude_ids: this.exclude_ids,
          startat: updatedCategoryState.startat,
          startatComparator: updatedCategoryState.startatComparator,
          endat: updatedCategoryState.endat,
          endatComparator: updatedCategoryState.endatComparator,
        });
      })
    ).subscribe();
  }

  getSelectionNumber(categoryId: Category['id'], productId: number): number | undefined {
    const productIndex = this.productsDisposition[categoryId].findIndex(p => p.id === productId);
    return productIndex !== -1 ? productIndex + 1 : undefined;
  }

  changeCategory(categoryId: Category['id']): void {
    this.currentCategoryId = categoryId;

    // console.log("Catégorie changée : ", categoryId);
  }

  pushScrappedProducts(id: number | null, url_image: string, url_html: string): void {
    this.generatorService.scrapImageProductPage(url_image, url_html)
    .subscribe((response) => {
      // Supposons que la réponse contient les informations nécessaires pour créer un produit
      const scrappedProduct = {
        id: id,
        urls: response.urls,
      };
      // console.log("pushScrappedProducts scrappedProduct: ", scrappedProduct);
      //! peut etre recup les images scrappé ici
      // Utilisez le service partagé pour émettre le produit scrappé
      this.scrappedImagesService.updateProducts(scrappedProduct);
    }, (error) => {
      this.dialogService.open(this.dialogErrorScrap);
    });
  }

  toggleProductSelection(categoryId: Category['id'], product: Product): void {
    
    // Premièrement, souscrivez à showPerfectstayElement$ pour obtenir sa valeur actuelle.
    this.showPerfectstayElement$.pipe(take(1)).subscribe(isPerfectStay => {

      if (this.selectedTemplateId === null && !isPerfectStay) {
        this.dialogRef = this.dialogService.open(this.dialogSelectTemplate);
        return;
      }
  
      // Après avoir passé la vérification, continuez avec la logique de sélection du produit.
      const productIndexInSelected = this.productsDisposition[categoryId]?.findIndex(p => p.id === product.id);
      const productAlreadySelected = productIndexInSelected !== -1;
      // console.log("product selected", this.isProductSelected(product.id ))
      if (productAlreadySelected) {

        this.resetProductIndex = { categoryId, productId: product.id };


        this.productsDisposition[categoryId][productIndexInSelected] = { ...this.productsDisposition[categoryId][productIndexInSelected], id: null, image_url_1: '', image_produit_scenario: '' };
        this.productsDisposition[categoryId][productIndexInSelected] = { ...this.productsDisposition[categoryId][productIndexInSelected], id: null, image_url_1: '', image_produit_scenario: '' };
        this.selectionDragAndDrop = this.productsDisposition[categoryId]
          .map(p => ({
            id: p.id,
            image_url_1: p.image_url_1,
            image_produit_scenario: p.image_produit_scenario,
            ordre_generation: p.ordre_generation,
            width: p.width,
            height: p.height,
            url: p.url
          }));
      } else {
        const placeholderIndex = this.productsDisposition[this.currentCategoryId!]?.findIndex(p => p.id === null);
        // console.log(product.image_produit_scenario)
        const newImageUrl = product.image_produit_scenario && product.image_produit_scenario !== '' && product.image_produit_scenario !== -1
        ? String(product.image_produit_scenario)
        : product.image_url_1;
        if (placeholderIndex !== -1) {
          this.productsDisposition[categoryId][placeholderIndex] = { ...this.productsDisposition[categoryId][placeholderIndex], id: product.id, image_url_1: newImageUrl, image_produit_scenario: product.image_produit_scenario, url: product.url };
          this.selectionDragAndDrop = this.productsDisposition[categoryId]
            .map(p => ({
              id: p.id,
              image_url_1: p.image_url_1,
              image_produit_scenario: p.image_produit_scenario,
              ordre_generation: p.ordre_generation,
              width: p.width,
              height: p.height,
              url: p.url
            }));
            if (!this.images[product.id] || this.images[product.id].length === 0) {
              this.clickedProduct = product;
              this.sharedProductService.addProduct(product);
              this.pushScrappedProducts(product.id, product.image_url_1, product.url);
            }
        } else {
          this.dialogRef = this.dialogService.open(this.dialogNoPlace);
        }
      }
      
      // Continuez avec d'autres opérations comme le changement de catégorie.
      this.changeCategory(categoryId);
    });
  }

  closeDialog() {
    this.dialogRef?.close();
  }

  hasSelectedProducts(categoryId: Category['id']): boolean {
    return this.productsDisposition[categoryId] && this.productsDisposition[categoryId]?.filter(p => p.id === null) && this.productsDisposition[categoryId]?.filter(p => p.id !== null).length > 0;
  }

  hasfilter(categoryId: Category['id']): any {
    return this.activeFilter || this.startDate || this.endDate || this.searchTerm || this.exclude_ids;
  }

  public isProductSelected(productId: number): boolean {
    return Object.values(this.productsDisposition)?.some(products => products?.some(p => p.id === productId));
  }

  handleOrderChanges(event: { categoryId: Category['id']; updatedProducts: ProductDisposition[] }): void {
    this.productsDisposition[event.categoryId] = event.updatedProducts;
    this.selectionDragAndDrop = event.updatedProducts
      .sort((a, b) => a.ordre_generation - b.ordre_generation)
      .map(product => ({
        id: product.id,
        image_url_1: product.image_url_1,
        image_produit_scenario: product.image_produit_scenario,
        ordre_generation: product.ordre_generation,
        width: product.width,
        height: product.height,
        url: product.url,
      }));
    // console.log("handleorder PRODUCTS DISPOSITION: ", this.productsDisposition);
    // console.log("handleorder selectionDragAndDrop: ", this.selectionDragAndDrop);
    
  }

  public validateSelection(categoryId: Category['id'], selectedImageUrl?: any, taskId?: string, selectedRatio?: number, wantGif?: boolean): void {
    const theme: string = this.forms[categoryId].value.theme;
    const templateId: number = this.selectedTemplateId ?? 0;
    const nbVisuelsPrincipaux = this.nbVisuelsPrincipaux[this.selectedTemplateId ?? 0];
    const payload: any = {
      metaCategoryId: this.metaCategory.id,
      ids: this.productsDisposition[categoryId].map(product => product.id),
      images_products_urls: this.productsDisposition[categoryId].map(product => product.image_url_1),
      theme,
      templateId,
      nbVisuelsPrincipaux,
    };

    if (selectedImageUrl && taskId) {
      payload.visuel_url = selectedImageUrl;
      payload.task_id = taskId;
    }
    if (selectedRatio) {
      payload.selected_ratio = selectedRatio;
    }
    if (wantGif) {
      payload.wantGif = wantGif;
    }
    // console.log("on validateSelection", selectedRatio)
    this.emailStore.generateEmail(payload);
  }

  public getImageSrc(product: any): string {
    if (this.hoveringProduct === product && product.image_produit_scenario?.length > 1) {
      // console.log("product.image_produit_scenario ", product.image_produit_scenario);
      return product.image_produit_scenario;
    }
    return product.image_url_1;
  }

  private buildSortedList() {
    const lines = this.dicoEntries
      .map((entry, key) => {
        return {
          category: entry.category,
          id: entry.category.id,
          label: entry.category.label,
          real: entry.real,
          realNMinusOne: entry.realNMinusOne,
          evolution: this.dicoService.getRatio(entry),
          evolutionNMinusOne: this.dicoService.getRatio(entry, true),
          stock: entry.stock,
          dispo: entry.dispo,
          forecast: entry.forecast,
          data: this.salesChartsDataList[key],
          showProducts: false,
          isSegment: (this.segments || []).some((segment) =>
            segment.categories.some(
              (category) =>
                category.category.id === entry.category.id &&
                (Array.isArray(segment.meta_category)
                  ? segment.meta_category.includes(entry.category.meta_category)
                  : segment.meta_category === entry.category.meta_category)
            )
          ),
        } as TableDataLine;
      })
      .filter((a: TableDataLine) => {
        if (!this.searchString) {
          return true;
        }
        return a.label
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .includes(this.searchString.normalize('NFD').replace(/\p{Diacritic}/gu, ''));
      })
      .sort((a: TableDataLine, b: TableDataLine) => {
        if (!this.sorting.key) {
          return a.label && b.label ? (a.label > b.label ? 0 : 1) : 1;
        } else {
          let aVal = a[this.sorting.key as ColumnKey];
          let bVal = b[this.sorting.key as ColumnKey];
          if (typeof aVal === 'number' && typeof bVal === 'number') {
            if (this.sorting.direction === Direction.Asc) {
              return aVal - bVal;
            } else {
              return bVal - aVal;
            }
          }
          return 0;
        }
      });
    this.lines$.next(lines);
  }

  isActiveEntry(entry: TableDataLine): boolean {
    if (!this.currentCategory) {
      return false;
    }
    const categoryMatches = entry.id === this.currentCategory.id;
    const metaCategoryMatches = entry.category.meta_category === this.currentCategory.meta_category;
  
    return categoryMatches && metaCategoryMatches;
  }

  private setLineGraphsData() {
    this.salesChartsDataList = this.dicoEntries.map((entry) => {
      const ratio = this.dicoService.getRatio(entry, true);
      return {
        datasets: [
          {
            data: entry.evol.map((evo, key) => ({
              date: this.forecastDates[key].getTime(),
              purchase: evo,
            })),
            borderColor: ratio > 0 ? '#74cdcd' : '#F76284',
            pointBorderColor: ratio > 0 ? '#74cdcd' : '#F76284',
            pointHoverBorderColor: ratio > 0 ? '#74cdcd' : '#F76284',
          },
        ],
      };
    });
  }

  public toggleProducts(entry: TableDataLine): void {
    // console.log("tempCategoryId ", this.tempCategoryId); //! pour garder les filtres actifs lors de l'ouverture et fermeture des catégories
    const currentLines = this.lines$.getValue();

    //! pour garder les filtres actifs lors de l'ouverture et fermeture des catégories
    // if (entry.id === this.tempCategoryId) {
    //   this.activeFilter = this.activeFilter;
    //   this.sortKey = this.sortKey;
    //   this.searchTerm = this.searchTerm;
    //   this.sortDirection = this.sortDirection;
    //   this.startatOperator = this.startatOperator;
    //   this.endatOperator = this.endatOperator;
    //   this.startDate = this.startDate;
    //   this.endDate = this.endDate;
    // } else {
    //   this.activeFilter = null;
    //   this.sortKey = null;
    //   this.searchTerm = '';
    //   this.sortDirection = 'DESC';
    //   this.startatOperator = '<=';
    //   this.endatOperator = '>=';
    //   this.startDate = '';
    //   this.endDate = '';
    // }

    // Fermer la catégorie précédemment ouverte si elle est différente de celle actuellement cliquée
    if (this.currentCategoryId !== null && this.currentCategoryId !== entry.id) {
      const previousIndex = currentLines.findIndex(line => line.id === this.currentCategoryId);
      if (previousIndex !== -1) {
        currentLines[previousIndex].showProducts = false;
      }
    }

    // Ouvrir la catégorie actuellement cliquée ou la fermer si elle était déjà ouverte conserver canshowproduct pour bloquer ouverture dans campagne
    const currentIndex = currentLines.findIndex(line => line.id === entry.id);
    if (currentIndex !== -1  && this.canShowProduct === true) {
      currentLines[currentIndex].showProducts = !currentLines[currentIndex].showProducts;
      this.currentCategoryId = currentLines[currentIndex].showProducts ? entry.id : undefined;
    }

    this.lines$.next([...currentLines]);

    if (this.selectedTemplateId !== null) {
      this.productsDisposition[entry.id] = this.matrice[this.selectedTemplateId ?? 0].schema;
      this.showFromMatrice = this.matrice[this.selectedTemplateId ?? 0].show;
      this.productsDisposition[entry.id] = this.productsDisposition[entry.id]?.map((productDisposition) => ({ ...productDisposition, id: null, image_url_1: '' }));
    }

    if (currentLines[currentIndex].showProducts) {
      this._initForm(entry.id);
      this.categoryId$.next(entry.id);
      // this.activeFilter = null; //! pour garder les filtres actifs lors de l'ouverture et fermeture des catégories
      // this.sortKey = null; //! pour garder les filtres actifs lors de l'ouverture et fermeture des catégories
      setTimeout(() => {
        window.scrollTo({ top: 100, behavior: 'smooth' });
        const tableElement = document.getElementById(`table-id-${entry.id}`);
        if (tableElement) {
          const topPosition = tableElement.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
      }, 0);
    }

    // this.tempCategoryId = entry.id; //! pour garder les filtres actifs lors de l'ouverture et fermeture des catégories
    this.onRowClicked.emit(entry.category);
    this.cdr.detectChanges();
    // console.log("UPDATE TOGGLE PRODUCTS DISPOSITION: ", this.productsDisposition);
    // console.log("toggleProducts currentCategoryId ", this.currentCategoryId);
  }

  private _initProductsLoader(): void {
    combineLatest([this.products$, this.categoryId$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([products, categoryId]) => {
        if (!products[this.metaCategory.id]?.[categoryId]) {
          this.productStore.getProduct({
            metaCategoryId: this.metaCategory.id,
            categoryId,
            page: 1,
            filter: null,
            sort: { key: 'ventes', direction: 'DESC' },
            searchTerm: null,
            exclude_ids:null,
            startat: '',
            startatComparator: '',
            endat: '',
            endatComparator: '',
          });
        } else {
          const { page, filter, sort, loading, searchTerm, exclude_ids} = products[this.metaCategory.id][categoryId];
          if (loading === TaskState.NotStarted) {
            this.productStore.getProduct({
              metaCategoryId: this.metaCategory.id,
              categoryId,
              page,
              filter,
              sort,
              searchTerm: searchTerm,
              exclude_ids:exclude_ids,
              startat: '',
              startatComparator: '',
              endat: '',
              endatComparator: '',
            });
          }
        }
      });
  }

  private _initForm(categoryId: Category['id']): void {
    this.forms[categoryId] = this.formBuilder.group({
      theme: this.formBuilder.control(''),
      templateId: this.formBuilder.control(this.templates?.[0]?.id, Validators.required),
    });
  }
}
