import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { filter, Observable, take } from 'rxjs';
import { Enterprise, UserStore } from '../store/user.store';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<Enterprise> {
  constructor(private userStore: UserStore) {}

  resolve(): Observable<Enterprise> {
    this.userStore.loadEnterprise();
    return this.userStore.selectEnterprise().pipe(filter(Boolean), take(1));
  }
}
