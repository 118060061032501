import { Component, AfterViewInit, ElementRef, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';


@Component({
    selector: 'app-email-preview-popup',
    templateUrl: './email-preview-popup.component.html',
    styleUrls: ['./email-preview-popup.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class EmailPreviewPopupComponent implements AfterViewInit {
    @ViewChild('desktopContainer', { static: true }) desktopContainer!: ElementRef;
    @ViewChild('mobileContainer', { static: true }) mobileContainer!: ElementRef;
    private externalPreviewPopup!: HTMLElement;
    private iframeMobile!: HTMLIFrameElement;
    public hasAmpHtml: boolean = false; // Indicateur si AMPHTML est disponible
    public selectedView: "html" | "amphtml" = 'amphtml'; // Vue sélectionnée par défaut
    private styledHtml: string = ''; // Contenu HTML
    private styledAmpHtml: string = ''; // Contenu AMPHTML

    constructor(private renderer: Renderer2, private el: ElementRef) {}

    ngAfterViewInit() {
        this.externalPreviewPopup = this.el.nativeElement.querySelector('#externalPreviewPopup');
        this.iframeMobile = this.el.nativeElement.querySelector('#iframeMobile');
    
        // Utilisez une fonction fléchée pour conserver le contexte de `this`
        this.renderer.listen(this.externalPreviewPopup, 'click', () => this.close());
        this.renderer.listen(this.iframeMobile, 'load', () => this.onMobileIframeLoaded());
    }
    
    
    close() {
        console.log('Closing preview popup...');
        this.renderer.setStyle(this.externalPreviewPopup, 'visibility', 'hidden');
    
        // Réinitialiser le contenu des iframes pour éviter les conflits lors de la réouverture
        if (this.iframeMobile && this.iframeMobile.contentWindow) {
            console.log('Resetting iframeMobile content on close...');
            this.iframeMobile.contentWindow.document.open();
            this.iframeMobile.contentWindow.document.close();
        }
    
        const iframeDesktop = this.el.nativeElement.querySelector('#iframeDesktop');
        if (iframeDesktop && iframeDesktop.contentWindow) {
            console.log('Resetting iframeDesktop content on close...');
            iframeDesktop.contentWindow.document.open();
            iframeDesktop.contentWindow.document.close();
        }
    }

    
    
    

    onMobileIframeLoaded() {
        if (!this.iframeMobile || !this.iframeMobile.contentDocument) {
            console.warn('No contentDocument found for iframeMobile or iframeMobile is undefined');
            return;
        }
    
        const frameDocument = this.iframeMobile.contentDocument;
    
        this.renderer.setStyle(frameDocument.documentElement, 'overflow-x', 'visible');
        this.renderer.setStyle(frameDocument.body, 'overflow-y', 'scroll');
        this.renderer.setStyle(frameDocument.body, 'visibility', 'visible');
    
        const isAdaptive = !frameDocument.documentElement.querySelector('.gmail-fix');
        if (!isAdaptive) {
            const clientWidth = frameDocument.documentElement.clientWidth;
            const scrollWidth = frameDocument.documentElement.scrollWidth;
            const scale = (clientWidth / scrollWidth).toFixed(2);
    
            this.renderer.setStyle(frameDocument.documentElement, 'height', '100%');
            this.renderer.setStyle(frameDocument.documentElement, 'transform', `scale(${scale})`);
            this.renderer.setStyle(frameDocument.documentElement, 'transform-origin', 'top left');
            this.renderer.setStyle(frameDocument.body, 'overflow-x', 'hidden');
        }
    }
    

    openPreviewPopup(html: string, ampHtml?: string) {
        console.log('Opening preview popup...');
        this.styledHtml = html;
        this.styledAmpHtml = ampHtml || '';
        this.hasAmpHtml = !!ampHtml; // Vérifier si AMPHTML est disponible
        this.selectedView = this.hasAmpHtml ? 'amphtml' : 'html';
        
        if (this.hasAmpHtml){
            this.showAmpHtml()
        }
        else{
            this.showHtml()
        }
  
        this.renderer.setStyle(this.externalPreviewPopup, 'visibility', 'visible');
    }
    
    updateSingleSelectGroupValue(selectedValue: any) {
        console.log('Selected View:', selectedValue);
        if (Array.isArray(selectedValue)) {
            selectedValue = selectedValue[0];  // Utiliser la première valeur si c'est un tableau
        }
    
        if (selectedValue === 'html') {
            console.log("coucou")
          this.showHtml();
        } else if (selectedValue === 'amphtml') {
          this.showAmpHtml();
        }
      }

    showHtml() {
        this.updateIframes(this.styledHtml);
    }
    
    showAmpHtml() {
        if (this.hasAmpHtml) {
            console.log('Updating iframes with AMPHTML content...');
            this.updateIframes(this.styledAmpHtml);
        } else {
            console.warn('No AMPHTML content available');
        }
    }
    

    private updateIframes(content: string) {
        console.log('Updating iframes with content:', content.substring(0, 100)); // Aperçu du contenu
    
        const iframeDesktop = this.el.nativeElement.querySelector('#iframeDesktop') as HTMLIFrameElement;
        if (iframeDesktop) {
            console.log('Setting srcdoc for iframeDesktop...');
            iframeDesktop.srcdoc = content;
        } else {
            console.warn('iframeDesktop not found');
        }
    
        if (this.iframeMobile) {
            console.log('Setting srcdoc for iframeMobile...');
            this.iframeMobile.srcdoc = content;
        } else {
            console.warn('iframeMobile not found');
        }
    }
    
    
    
    
    
}
