<nb-card>
  <nb-card-header>
    Paramètres du redimensionnement de l'image
    <button nbButton (click)="closeModal()" class="close-button" tabindex="-1">
      <nb-icon icon="close-outline"></nb-icon>
    </button>
  </nb-card-header>

  <nb-card-body>
    <div class="row-container">
      <!-- Colonne de gauche -->
      <div class="left-column">
        <label style="margin-top: 10px;color:grey;"><strong>Formats prédéfinis :</strong></label>
      
        <div class="button-grid">
          <button nbButton status="basic" class="aspect-ratio-btn"
                  (click)="setAspectRatio(3, 2)" 
                  style="display: flex; flex-direction: column; align-items: center; border: none; background: none;">
            <div [ngStyle]="{'width.px': 25, 'height.px': (25 / 3) * 2, 'border': '1px solid grey', 'margin-bottom': '5px'}" tabindex="0"></div>
            <span style="color:grey">3:2</span>
          </button>
          <button nbButton status="basic" class="aspect-ratio-btn" *ngFor="let ratio of aspectRatios.slice(1)"
                  (click)="setAspectRatio(ratio.width, ratio.height)" 
                  style="display: flex; flex-direction: column; align-items: center; border: none; background: none;">
            <div [ngStyle]="{'width.px': 25, 'height.px': (25 / ratio.width) * ratio.height, 'border': '1px solid grey', 'margin-bottom': '5px'}"></div>
            <span style="color:grey">{{ratio.width}}:{{ratio.height}}</span>
          </button>
        </div>
      
        <label style="margin-top: 10px;color:grey;"><strong>Taille personnalisée :</strong></label>
        <span style="font-size:12px"><i>Taille de l'image originale : {{imgWidth}} x {{imgHeight}}</i></span>
        <span style="font-size:14px">Nouvelles dimensions :</span>
      
        <div class="input-group">
          <label for="frameWidth">Largeur : </label>
          <input type="number" nbInput fieldSize="small" status="basic" placeholder="Default" [ngModel]="getRoundedWidth()" (ngModelChange)="onFrameWidthChange($event)" class="form-control" tabindex="-1"/>
          <div *ngIf="widthAlertMessage" class="alert alert-warning">
            {{ widthAlertMessage }}
          </div>
        </div>
      
        <div class="input-group">
          <label for="frameHeight">Hauteur : </label>
          <input type="number" nbInput fieldSize="small" status="basic" placeholder="Default" [ngModel]="getRoundedHeight()" (ngModelChange)="onFrameHeightChange($event)" class="form-control" tabindex="-1" />
          <div *ngIf="heightAlertMessage" class="alert alert-warning">
            {{ heightAlertMessage }}
          </div>
        </div>
        <div class="small-frame-container">
          <div class="small-frame" [ngStyle]="{'width.px': 50, 'height.px': 20}">
          </div>
          <span style="margin-left: 10px;">Zone de remplissage</span>
        </div>
      </div>
      

      <!-- Colonne de droite -->
      
      <div class="right-column">
        <div class="container"
          [ngStyle]="{
            'width.px': maxContainerWidth, 
            'height.px': maxContainerHeight
          }">

          <img [src]="imageUrl" [ngStyle]="{'width.px': displayedWidth, 'height.px': displayedHeight}" alt="Image à redimensionner">
          <div 
            *ngIf="frameWidth && frameHeight" 
            class="frame"
            [ngStyle]="{
                'width.px': frameWidth, 
                'height.px': frameHeight 
            }">
          </div>
        </div>
        </div>
        <div class="zoom-controls">
          <button nbButton status="basic" (click)="zoomOut()">-</button>
          <button nbButton status="basic" (click)="zoomIn()">+</button>
        </div>
        

    </div>

  </nb-card-body>

  <nb-card-footer class="footer-buttons">
    <button nbButton (click)="closeModal()" status="basic" class="cancel-button" >Annuler</button>
    <button nbButton (click)="validateAndClose()" status="danger" [disabled]="isButtonDisabled()">Valider</button>
  </nb-card-footer>
</nb-card>
