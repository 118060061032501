import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CampaignSegment } from '../model/campaign-segment.model';
import { Category } from '../model/category.model';
import { CategoryService } from './category.service';
import 'anychart';
import { id } from '@swimlane/ngx-charts';

interface Segment {
  categoryIds: Category['id'][];
  metaCategoryIds: Category['meta_category'][];
  numb_mail: number;
  pourcentBase: number;
  pourcentVente: number;
  proba: number;
  list_id?: number[];
}

export interface PostCampagneResponse {
  segments: Segment[];
  numb_all_mail: number;
  venn: {
    x: string[];
    value: number;
    meta_category: string[];
  }[];
}

export interface Campagne {
  segments: Segment[];
  numb_all_mail: number;
  venn: {
    id: string[];
    meta_category: string[];
    x: string[];
    value: number;
    label: {
      fontColor: string;
    };
  }[];
}

interface SegmentAchats {
  segment_achats: string;
  count_size: number;
}

interface SegmentClients {
  segment_email: string;
  count_size: number;
}

interface AgePyramid {
  age_group: string;
  MME: number;
  MR: number;
}

interface GenreData {
  civilite: string;
  count: number;
  percentage: number;
}

// Now update the LiftCurveResponse interface
interface LiftCurveResponse {
  [key: string]: {
    ideal_pourcent_base: number | null;
    curve: number[] | null;
    error: string | null;
    infos_crm?: {
      segment_achats: SegmentAchats[];
      segment_clients: SegmentClients[];
      age_pyramid: AgePyramid[];
      realite_genre: GenreData[];
      segment_genre: GenreData[];
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(private http: HttpClient, private categoryService: CategoryService) {}

  getLiftCurve(category: Category): Observable<{
    optimal: number | null;
    curve: { x: number; y: number }[] | null;
    error?: string;

  } | undefined> {
    return this.http
      .get<LiftCurveResponse>(
        `${environment.backendUrl}/api/campagnecurve/`,
        {
          params: {
            category: category.id?.toString() ?? '0',
            meta_category: category.meta_category?.toString() ?? '0',
          },
        },
      )
      .pipe(
        map((response) => {
          const categoryKey = `${category.id}`;
          const values = response[categoryKey];
  
          if (values) {
            if (values.error) {
              // Return the error to the component
              return {
                optimal: null,
                curve: null,
                error: values.error,
                infos_crm: undefined,
              };
            } else {
              return {
                optimal: values.ideal_pourcent_base,
                curve: values.curve
                  ? values.curve.map((value, index) => ({ x: index + 1, y: value }))
                  : null,
                error: undefined,
                infos_crm: values.infos_crm,
              };
            }
          } else {
            // Handle the case where the category is not found in the response
            return {
              optimal: null,
              curve: null,
              error: 'Category not found in response',
              infos_crm: undefined,
            };
          }
        }),
      );
  }

  getInfosCRM(category: Category, pct_base: number): Observable<{
    infos_crm?: {
      segment_achats: SegmentAchats[];
      segment_clients: SegmentClients[];
      age_pyramid: AgePyramid[];
      realite_genre: GenreData[];
      segment_genre: GenreData[];
    };
  } | undefined> {
    return this.http
      .get<LiftCurveResponse>(
        `${environment.backendUrl}/api/campagnecurve/`,
        {
          params: {
            category: category.id?.toString() ?? '0',
            meta_category: category.meta_category?.toString() ?? '0',
            infos_crm: true,
            pct_base:pct_base,
          },
        },
      )
      .pipe(
        map((response) => {
          const categoryKey = `${category.id}`;
          const values = response[categoryKey];
  
          if (values) {
            if (values.error) {
              // Return the error to the component
              return {
                optimal: null,
                curve: null,
                error: values.error,
                infos_crm: undefined,
              };
            } else {
              return {
                optimal: values.ideal_pourcent_base,
                curve: values.curve
                  ? values.curve.map((value, index) => ({ x: index + 1, y: value }))
                  : null,
                error: undefined,
                infos_crm: values.infos_crm,
              };
            }
          } else {
            // Handle the case where the category is not found in the response
            return {
              optimal: null,
              curve: null,
              error: 'Category not found in response',
              infos_crm: undefined,
            };
          }
        }),
      );
  }

  mergeCampaigns(capping: number, segments: CampaignSegment[]): Observable<Campagne> {
    return this.http
      .post<PostCampagneResponse>(`${environment.backendUrl}/api/campagne/`, {
        send: false,
        capping,
        segments: Object.values(
          segments.reduce(
            (
              res: {
                lists: { [key: number]: any[] };
                indexOfMerge: number | null;
              },
              segment: CampaignSegment,
              index: number,
            ) => {
              // Build an array of category objects for each segment
              const categories = segment.categories.map((category) => ({
                meta_category: category.category.meta_category, // Use meta_category from the category
                category: category.category.id,
                pctBase: category.percentage,
              }));
  
              if (segment.checked) {
                // Merge with existing segment if 'checked' is true
                const pos = res.indexOfMerge ?? index;
                res.lists[pos] = [...(res.lists[pos] || []), ...categories];
                res.indexOfMerge = pos;
              } else {
                // Create a new segment
                const pos = index;
                res.lists[pos] = categories;
              }
  
              return res;
            },
            {
              lists: {},
              indexOfMerge: null,
            },
          ).lists,
        ),
      })
      .pipe(
        map((res: PostCampagneResponse) => ({
          ...res,
          venn: res.venn.map((item: { x: string[]; meta_category: string[]; value: number }) => {
            // Prepare to map categories with their corresponding meta categories
            const xLabels: string[] = [];
  
            item.x.forEach((xStr, index) => {
              // Split xStr into ids
              const ids = xStr.split(',').map((id) => id.trim());
              // Get the corresponding meta_category string and split it
              const metaCatStr = item.meta_category[index];
              const metaCats = metaCatStr.split(',').map((mc) => mc.trim());
  
              // Check if ids and metaCats have the same length
              if (ids.length !== metaCats.length) {
                console.error('Mismatch between ids and meta categories:', ids, metaCats);
              }
  
              // Map ids and metaCats to labels
              const labels = ids.map((id, idx) => {
                const metaCat = parseInt(metaCats[idx]);
                const categoryLabel = this.categoryService.getCategoryById(parseInt(id), metaCat)?.label || '';
                return categoryLabel;
              });
  
              // Join the labels and add to xLabels array
              xLabels.push(labels.join(', '));
            });
  
            return {
              id: item.x,
              x: xLabels,
              value: item.value,
              meta_category: item.meta_category,
              label: { fontColor: '#212529',
                      fontWeight: 'normal',
                      fontFamily: 'Open Sans, sans-serif',
                      fontSize: '15px'
              },
            };
          }),
        })),
      );
  }

  generateSegmentsText(capping: number, segments: CampaignSegment[]): Observable<string> {
    return this.http.post(
      `${environment.backendUrl}/api/campagne/`,
      {
        send: true,
        capping,
        segments: Object.values(
          segments.reduce(
            (
              res: {
                lists: { [key: number]: any[] };
                indexOfMerge: number | null;
              },
              segment: CampaignSegment,
              index: number,
            ) => {
              // Build an array of category objects for each segment
              const categories = segment.categories.map((category) => ({
                meta_category: category.category.meta_category,
                category: category.category.id,
                pctBase: category.percentage,
              }));
  
              if (segment.checked) {
                // Merge with existing segment if 'checked' is true
                const pos = res.indexOfMerge ?? index;
                res.lists[pos] = [...(res.lists[pos] || []), ...categories];
                res.indexOfMerge = pos;
              } else {
                // Create a new segment
                const pos = index;
                res.lists[pos] = categories;
              }
  
              return res;
            },
            {
              lists: {},
              indexOfMerge: null,
            },
          ).lists,
        ),
      },
      {
        observe: 'body',
        responseType: 'text',
      },
    );
  }
  
  generateSegmentsBlob(capping: number, segments: CampaignSegment[]): Observable<Blob> {
    return this.http.post(
      `${environment.backendUrl}/api/campagne/`,
      {
        send: true,
        capping,
        segments: Object.values(
          segments.reduce(
            (
              res: {
                lists: { [key: number]: any[] };
                indexOfMerge: number | null;
              },
              segment: CampaignSegment,
              index: number,
            ) => {
              // Build an array of category objects for each segment
              const categories = segment.categories.map((category) => ({
                meta_category: category.category.meta_category,
                category: category.category.id,
                pctBase: category.percentage,
              }));
  
              if (segment.checked) {
                // Merge with existing segment if 'checked' is true
                const pos = res.indexOfMerge ?? index;
                res.lists[pos] = [...(res.lists[pos] || []), ...categories];
                res.indexOfMerge = pos;
              } else {
                // Create a new segment
                const pos = index;
                res.lists[pos] = categories;
              }
  
              return res;
            },
            {
              lists: {},
              indexOfMerge: null,
            },
          ).lists,
        ),
      },
      {
        observe: 'body',
        responseType: 'blob',
      },
    );
  }
}
