import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { filter, map, Observable, switchMap, tap } from 'rxjs';
import { TaskState } from 'src/app/deebr/model/task.state';
import { ReportService } from 'src/app/deebr/service/report.service';

export interface Report {
  index: number;
  campaign_name: string;
  date: string;
  sent: number;
  opening: number;
  openingRate: number;
  clicks: number;
  clicksRate: number;
  revenue: number;
  transactions: number;
  transactionsRate: number;
  revenue_offline: number;
  transactions_offline: number;
  revenue_online: number;
  transactions_online: number;
  rpm: number;
  subjects: string;
  nb_mails?: number;
  nb_emails_adress?: number;
  percentage?: number | 0;
}

export interface ReportState {
  reports: {
    entities: Report[];
    loading: TaskState;
  };
  pieData: PieData;
}

export interface PieData {
  entities: Report[];
  loading: TaskState;
}

const initialState = {
  reports: {
    entities: [],
    loading: TaskState.NotStarted,
  },
  pieData: {
    entities: [],
    loading: TaskState.NotStarted,
  },
};

@Injectable()
export class ReportStore extends ComponentStore<ReportState> {
  constructor(private readonly reportService: ReportService) {
    super(initialState);
  }

  resetState() {
    this.setState(initialState);
  }
  
  readonly getReports = this.effect((action$: Observable<void>) => {
    return action$.pipe(
      tap(() => {
        this.patchState((state) => ({
          ...state,
          reports: {
            ...state.reports,
            loading: TaskState.InProgress,
          },
        }));
      }),
      switchMap(() =>
        this.reportService.getReports().pipe(
          map((reports) =>
            reports.map((report) => ({
              ...report,
              openingRate: report.sent > 0 ? report.opening / report.sent : 0,
              clicksRate: report.sent > 0 ? report.clicks / report.sent : 0,
              transactionsRate: report.clicks > 0 ? report.transactions / report.clicks : 0,
              rpm: report.sent > 0 ? report.revenue / (report.sent / 1_000) : 0
            })),
          ),
          tapResponse(
            (reports) => {
              this.patchState((state) => ({
                ...state,
                reports: {
                  ...state.reports,
                  entities: reports,
                  loading: TaskState.Success,
                },
              }));
            },
            () => {
              this.patchState((state) => ({
                ...state,
                reports: {
                  ...state.reports,
                  loading: TaskState.Failed,
                },
              }));
            },
          ),
        ),
      ),
    );
  });

  selectReports(): Observable<Report[]> {
    return this.state$.pipe(
      filter((state) => {
        if (state.reports.loading === TaskState.NotStarted) {
          this.getReports();
          return false;
        }
        return true;
      }),
      map((state) => state.reports.entities),
    );
  }
  readonly getPieData = this.effect((action$: Observable<{ startDate?: string, endDate?: string }>) => {
    return action$.pipe(
      tap(() => {
        this.patchState({
          pieData: {
            loading: TaskState.InProgress,
            entities: []  // Ou laissez le tel quel si vous ne voulez pas le réinitialiser
          },
        });
      }),
      switchMap((action) =>
        this.reportService.getPie(action.startDate, action.endDate).pipe(
          tapResponse(
            (data) => {
              this.patchState((currentState) => ({
                pieData: {
                  entities: data,
                  loading: TaskState.Success,
                },
              }));
            },
            () => {
              this.patchState((currentState) => ({
                pieData: {
                  ...currentState.pieData,
                  loading: TaskState.Failed,
                },
              }));
            },
          ),
        ),
      ),
    );
  });
  
  
  selectPieData(): Observable<Report[]> {
    return this.state$.pipe(
      map((state) => state.pieData.entities),
    );
}
}
