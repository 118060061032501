import { Component, NgZone, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CampaignSegment } from 'src/app/deebr/model/campaign-segment.model';
import { Category } from 'src/app/deebr/model/category.model';
import { CategoryService } from 'src/app/deebr/service/category.service';
import { DicoEntry } from 'src/app/deebr/model/dico-entry.model';
import { Campagne, CampaignService } from 'src/app/deebr/service/campaign.service';
import { ChartData, ChartOptions } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { BaseChartDirective } from 'ng2-charts';
import { DicoService } from 'src/app/deebr/service/dico.service';
import { BehaviorSubject, combineLatest, finalize, map, Observable, of, switchMap, catchError } from 'rxjs';
import { format } from 'date-fns';
import { MailsService } from 'src/app/deebr/service/mails.service';
import { MetaCategory } from 'src/app/deebr/model/meta-category.model';
import { DfService } from 'src/app/deebr/service/df.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { EntrepriseService } from 'src/app/deebr/service/entreprise.service';
import { generateForecastDates } from 'src/app/deebr/model/chart';
import { StockOrDispo } from '../../table/table.component';
import { DownloadService } from 'src/app/deebr/service/download.service';
import { ChangeDetectorRef } from '@angular/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as d3 from 'd3';

type AgePyramid = any;
type GenreRealiteData = any;
type GenreSegmentData = any;
type SegmentAchats = any;
type SegmentEmail = any;

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})

export class CampaignComponent implements OnInit {
  form!: FormGroup;
  segmentCreationForm!: FormGroup;
  segments: CampaignSegment[] = [];
  selectedSegment: CampaignSegment | null = null;
  categories: Category[] = [];
  currentCategory: Category | undefined = undefined;
  capping = 1;
  optimal: number | null = 30;
  volume: number | null = 30;
  numberOfDays = 7;
  emailsRatio: number | undefined = undefined;
  mailsCount: number = 0;
  dicoEntries: DicoEntry[] = [];
  stockOrDispo: StockOrDispo = StockOrDispo.Stock;
  displayForms = false;
  displayInfosCRM = false;
  spinnerInfosCRM = false;
  spinnerInfosCRMError = true;
  currentCategoryData: DicoEntry | undefined = undefined;
  forecastDates: Date[] = [];
  @ViewChild('dialog') dialog!: TemplateRef<any>;
  @ViewChild('dialogError') dialogError!: TemplateRef<any>;
  @ViewChild('dialogCurveError') dialogCurveError!: TemplateRef<any>;
  @ViewChild('dialogOk') dialogOk!: TemplateRef<any>;
  dialogRef: NbDialogRef<any> | undefined = undefined;
  hasCurveError: boolean = false;
  errorMessage: string = '';
  genreChartPlugins = [ChartDataLabels];
  private genreRealiteData: any[] = [];
  private genreSegmentData: any[] = [];
  selectedCap: number = 1;
  segmentsGenerating = false;
  mergingCampaign = false;
  buildingCampaign = false;
  currentLiftCurveData: { x: number; y: number }[] = [];
  currentLiftCurveFn: Record<number, number> = {};
  potential = 0;
  lift = 0;
  @ViewChildren(BaseChartDirective) charts!: BaseChartDirective[];
  vennDiagrammGenerating = false;
  filteredCategories$!: Observable<Category[]>;
  metaCategory!: MetaCategory;
  metaCategoryChanges = false;
  metaCategories!: MetaCategory[];
  dataRefresh = new BehaviorSubject(false);
  vennData: { x: string[]; id: string[]; value: number }[] | undefined = undefined;
  @ViewChild('instance', { static: true }) instance!: NgbTypeahead;
  allSegmentChecked: boolean = false;
  total: number = 0;
  searchString = '';
  allCategories: Category[] = [];
  filteredDicoEntries: DicoEntry[] = [];
  //départements
  mapSvg: any;
  mapContainer: any;
  mapWidth = 600;
  mapHeight = 500;
  mapColorScale: any;
  mapData: any[] = [];
  mapTooltip: any;
  departementGeoData: any;
  //régions
  regionsMapSvg: any;
  regionsMapContainer: any;
  regionsMapWidth = 600;
  regionsMapHeight = 500;
  regionsMapColorScale: any;
  regionsMapData: any[] = [];
  regionsMapTooltip: any;
  regionsGeoData: any;



  evolData: ChartData<'line', { date: Date; purchase: number }[]> = {
    datasets: [],
  };

  evolCurveChartOptions: ChartOptions<'line'> = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'purchase',
    },
    scales: {
      yAxes: {},
      xAxes: {
        grid: { display: false },
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          callback: function (value: any, eee: any, aaa: any) {
            return new Date(aaa[eee].value).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' });
          },
        },
      },
    },

    interaction: {
      intersect: false,
      mode: 'index',
    },

    plugins: {
      legend: { display: true },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (ctx: any) => {
            let raw: { [key: string]: any } | any = ctx[0].raw;
            if (raw.date) {
              return new Date(raw.date).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' });
            }
            return '';
          },
          label: (ctx: any) => {
            return (
              (ctx.datasetIndex === 0 ? 'Année N : ' : 'Année N-1 : ') +
              Math.round(parseFloat(ctx.parsed.y)) +
              ' ventes'
            );
          },
        },
      },
    },
  };

  liftCurveChartOptions: ChartOptions<'line'> = {
    responsive: true,

    elements: {
      line: {
        tension: 0.5,
      },
    },
    parsing: {
      xAxisKey: 'x',
      yAxisKey: 'y',
    },
    scales: {
      xAxes: {
        min: 1,
        type: 'linear',
        ticks: {
          callback: (value: string | number) => (typeof value === 'number' ? value : parseFloat(value) * 100) + ' %',
        },
        title: { text: 'volume emails', display: true },
      },
      yAxes: {
        min: 0,
        type: 'linear',
        title: { text: 'Lift', display: true },
      },
    },
  };

  liftCurveData: ChartData<'line', { x: number | null; y: number | null }[]> = {
    datasets: [],
  };

  view: 'table' | 'treemap' = 'table';

  get canUpsertSegment(): boolean {
    const canInsert = this.segments.every((segment) =>
      segment.categories.every((category) => this.currentCategory?.id !== category.category.id),
    );
    const canUpdate = !!this.selectedSegment && this.selectedSegment.categories[0].percentage !== this.volume;
    return canInsert || canUpdate;
  }

  get canMergeCampaigns(): boolean {
    return this.segments.filter((segment) => segment.checked).length >= 2;
  }

  constructor(
    private categoryService: CategoryService,
    private dicoService: DicoService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private ngZone: NgZone,
    private mailsService: MailsService,
    private dfService: DfService,
    private dialogService: NbDialogService,
    private entrepriseService: EntrepriseService,
    private readonly downloadService: DownloadService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    this.metaCategories = this.categoryService.metaCategories;
    if (this.categoryService.currentMeta) {
      this.metaCategory = this.categoryService.currentMeta;
    }
    this.form = this.formBuilder.group({
      capping: [1]  // valeur par défaut
    });

    this.LoadDicoEntriesOnce();

    this.segmentCreationForm = this.formBuilder.group({
      metaCategory: new FormControl(this.categoryService.currentMeta?.id),
      volume: new FormControl(this.volume),
      capping: new FormControl(this.capping),
      search: new FormControl(),
    });

    this.segmentCreationForm.get('capping')?.valueChanges.subscribe((value: string) => {
      this.capping = parseInt(value);
      this.buildCampaign();
    });

    this.segmentCreationForm.get('metaCategory')?.valueChanges.subscribe((meta) => {
      this.metaCategory = this.categoryService.metaCategories.find(mc => mc.id === meta)!; //! voir si c'est utile
      this.categoryService.setCurrentMeta(meta);
      this.updateCategoriesForCurrentMetaCategory();
    });

    /**
     * when range slider moves, some data needs to be refreshed
     */
    this.segmentCreationForm.get('volume')?.valueChanges.subscribe((volume) => {
      this.volume = volume;

      // volume = % base
      // potential = % vente
      // lift = % vente / % base = potential / volume
      this.emailsRatio = (this.mailsCount * volume) / 100;
      this.lift = this.currentLiftCurveFn[this.volume!];
      this.potential = this.lift * volume;
      this.updateLiftPointInitialVolumePosition();
    });

    this.segmentCreationForm.get('search')?.valueChanges.subscribe((searchString) => {
      this.searchString = searchString;
    });

    this.mailsService.getCount().subscribe((count) => {
      this.mailsCount = count;
      this.emailsRatio = (this.mailsCount / this.volume!) * 100;
    });

    this.forecastDates = generateForecastDates();
    if (this.currentCategoryData) {
      this.initializeRegionsMap();
    }
  }
  get cappingControl(): FormControl {
    return this.form.get('capping') as FormControl;
  }

   setCategory(category: Category) {
    if (
      category.id !== this.currentCategory?.id ||
      category.meta_category !== this.currentCategory?.meta_category
    ) {
      this.selectedSegment = null;
      this.setCurrentCategory(category);
    }
  }

  upsertSegment() {
    if (this.currentCategory) {
      if (!this.selectedSegment) {
        // add
        const potential = this.currentLiftCurveFn[this.volume!] * this.volume!;
        const mailCount = (this.volume! * this.mailsCount) / 100;
        const lift = this.currentLiftCurveFn[this.volume!];
        this.selectedSegment = {
          meta_category: this.categoryService.currentMeta?.id || 0,
          categories: [
            {
              category: this.currentCategory,
              percentage: this.volume,
              potential,
              mailCount,
              lift,
            },
          ],
          percentage: this.volume,
          checked: false,
          potential,
          mailCount,
          lift,
          mailList: [],
        };
        this.segments = [...this.segments, this.selectedSegment];
        this.allSegmentChecked = false;
      } else {
        // update
        this.selectedSegment.percentage = this.volume;
        this.selectedSegment.potential = this.currentLiftCurveFn[this.volume!] * this.volume!;
        this.selectedSegment.mailCount = (this.volume! * this.mailsCount) / 100;
        this.selectedSegment.lift = this.currentLiftCurveFn[this.volume!];
        this.selectedSegment.categories[0].percentage = this.selectedSegment.percentage;
        this.selectedSegment.categories[0].potential = this.selectedSegment.potential;
        this.selectedSegment.categories[0].mailCount = this.selectedSegment.mailCount;
        this.selectedSegment.categories[0].lift = this.selectedSegment.lift;
      }

      this.updateLiftPointCurrentVolumePosition();
      this.buildCampaign();
    }
  }

  selectSegment(segment: CampaignSegment): void {
    if (this.selectedSegment !== segment && segment.categories.length === 1) {
      this.selectedSegment = segment;
      if (
        segment.categories[0].category.id === this.currentCategory?.id &&
        segment.categories[0].category.meta_category === this.currentCategory?.meta_category
      ) {
        this.segmentCreationForm.get('volume')?.setValue(segment.percentage);
      } else {
        this.setCurrentCategory(segment.categories[0].category, segment.percentage);
      }
    }
  }

  checkAllSegment(checked: boolean): void {
    this.allSegmentChecked = checked;
    this.segments.forEach((segment) => {
      segment.checked = checked;
    });
  }

  checkSegment(segment: CampaignSegment, checked: boolean): void {
    segment.checked = checked;
    this.toggleAllSegmentsChecked();
  }
  onValueChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCap = Number(selectElement.value);
  }
  deleteSegment(segment: CampaignSegment) {
    if (this.selectedSegment === segment) {
      this.selectedSegment = null;
    }
    this.segments = this.segments.filter((s) => s !== segment);
    this.toggleAllSegmentsChecked();
    this.buildCampaign();
  }

  askGenerateSegments() {
    this.dialogRef = this.dialogService.open(this.dialog);
  }

  generateSegments() {
    if (this.segments.length > 0) {
      this.segmentsGenerating = true;
      this.dialogRef?.close();

      this.entrepriseService.getEntreprise().pipe(
        switchMap((entreprise) => {
          const downloadType = entreprise.results[0].download_segment;
          let expectedResponseType: 'text' | 'blob' = 'text';
          let segmentObservable: Observable<any>; // Pour stocker le bon observable

          if (downloadType === 'zip') {
            expectedResponseType = 'blob';
            segmentObservable = this.campaignService.generateSegmentsBlob(this.capping, this.segments);
          } else {
            segmentObservable = this.campaignService.generateSegmentsText(this.capping, this.segments);
          }

          return segmentObservable.pipe(
            map((res) => ({
              data: res,
              name: entreprise.results[0].name,
              downloadType: downloadType,
            })),
          );
        }),
        finalize(() => {
          this.segmentsGenerating = false;
        }),
      )
        .subscribe({
          next: ({ data, name, downloadType }) => {
            if (downloadType === 'csv') {
              this.downloadService.saveAsCsv(data, `segments-${name}-${format(new Date(), 'ddMMyyyy')}.csv`);
            } else if (downloadType === 'zip') {
              const url = window.URL.createObjectURL(data);
              const anchor = document.createElement('a');
              anchor.href = url;
              anchor.download = `segments-${name}-${format(new Date(), 'ddMMyyyy')}.zip`;
              document.body.appendChild(anchor);
              anchor.click();
              document.body.removeChild(anchor);
              window.URL.revokeObjectURL(url);  // Nettoyage.
            }
            this.dialogService.open(this.dialogOk);
          },
          error: () => {
            this.dialogService.open(this.dialogError);
          },
        });
    }
  }


  onClickMergeCampaigns(): void {
    this.mergingCampaign = true;
    this.vennDiagrammGenerating = true;
    this.campaignService
      .mergeCampaigns(this.capping, this.segments)
      .pipe(
        finalize(() => {
          this.mergingCampaign = false;
          this.vennDiagrammGenerating = false;
        }),
      )
      .subscribe((res: Campagne) => {
        this.allSegmentChecked = false;
        this.selectedSegment = null;

        // Map over the segments from the response
        this.segments = res.segments.map((data) => {
          // Map categoryIds and metaCategoryIds together
          const categories = data.categoryIds.map((id, index) => {
            const metaCategoryId = data.metaCategoryIds[index];
            const category = this.categoryService.getCategoryById(id, metaCategoryId);
            return {
              category: category!,
              percentage: data.pourcentBase, // Adjust as needed
            };
          });

          // Since the segment may contain categories from multiple meta categories, set meta_category to null or an array of unique meta categories
          const uniqueMetaCategories = Array.from(new Set(data.metaCategoryIds));
          const segmentMetaCategory = uniqueMetaCategories.length === 1 ? uniqueMetaCategories[0] : 0;

          return {
            meta_category: segmentMetaCategory,
            categories: categories,
            percentage: data.pourcentBase,
            checked: false,
            potential: data.pourcentVente,
            mailCount: data.numb_mail,
            mailList: [],
            lift: data.pourcentVente / data.pourcentBase,
          };
        });

        this.total = res.numb_all_mail;
        this.vennData = res.venn;
      });
  }

  private updateCategoriesForCurrentMetaCategory() {
    // Filter categories
    this.categories = this.allCategories.filter(
      (category) => category.meta_category === this.categoryService.currentMeta?.id,
    );
    this.filteredCategories$ = of(this.categories);

    // Filter DicoEntries
    this.filteredDicoEntries = this.dicoEntries.filter(
      (dicoEntry) => dicoEntry.meta_category === this.categoryService.currentMeta?.id,
    );

  }

  private LoadDicoEntriesOnce() {
    this.metaCategoryChanges = true;
    // Fetch all data once
    const metaCategoryIds = this.metaCategories.map(meta => meta.id);
    combineLatest([
      this.dicoService.getDico(this.numberOfDays, metaCategoryIds),
      this.dfService.getDfTo(this.numberOfDays, metaCategoryIds),
      this.categoryService.getCategories(metaCategoryIds)
    ]).subscribe(([entries, dfTo, categories]) => {
      this.dicoEntries = entries;


      // Process dfTo data and merge with dicoEntries
      dfTo.forEach((entry) => {
        const dicoEntry = this.dicoEntries.find(de =>
          de.category.id === entry.category && de.category.meta_category === entry.meta_category
        );
        if (dicoEntry) {
          if (entry.stock !== undefined) {
            this.stockOrDispo = StockOrDispo.Stock;
            dicoEntry.stock = entry.stock;
          }
          if (entry.pourcent_dispo !== undefined) {
            this.stockOrDispo = StockOrDispo.Dispo;
            dicoEntry.dispo = entry.pourcent_dispo * 100;
          }
        }
      });

      this.allCategories = categories; // Store all categories
      this.updateCategoriesForCurrentMetaCategory();

      // Initialize search field
      this.filteredCategories$ = of(this.categories);

      // Handle route parameter for category
      let catId = this.route.snapshot.paramMap.get('category');
      if (catId && !isNaN(parseInt(catId))) {
        const category = this.allCategories.find(cat => cat.id === parseInt(catId!));
        if (category) {
          this.setCurrentCategory(category);
        }
      }
      this.metaCategoryChanges = false;
    }, () => {
      this.metaCategoryChanges = false; // Ensure spinner stops on error
    });
  }

  updateTrackStyle(): void {
    const customRangeInput = document.querySelector('.custom-range') as HTMLElement;
    const adjustedOptimal = this.optimal! * 2;
    customRangeInput.style.setProperty('--optimal-break', `${adjustedOptimal}%`);
  }

  private setCurrentCategory(category: Category, volume?: number | null) {
    this.spinnerInfosCRM = true;
    this.displayInfosCRM = true;
    this.ngZone.run(() => {
      this.currentCategory = category;
  
      if (!this.currentCategory) return;
  
      this.displayForms = false;
      this.currentCategoryData = undefined;
  
      this.campaignService.getLiftCurve(this.currentCategory).pipe(
        catchError((err) => {
          // Gérer uniquement l'erreur de getLiftCurve ici
          this.handleLiftCurveError(err);
          this.spinnerInfosCRM = false; // Tu peux aussi arrêter le spinner ici si besoin
          return of(null); // Stop le pipe
        }),
        switchMap((lift) => {
          if (!lift) return of({ infos_crm: undefined });
  
          this.processLiftCurveData(lift);
          this.updateLiftCurve();
          this.displayForms = true;
  
          if (!this.currentCategory) return of({ infos_crm: undefined });
  
          return this.campaignService.getInfosCRM(this.currentCategory, lift.optimal ?? 5);
        })
      ).subscribe(
        (infosCRM) => {
          if (!infosCRM) return;
          
          console.log("this.displayInfosCRM", this.displayInfosCRM)
          this.processInfosCRMData(infosCRM);
          
          this.spinnerInfosCRM = false;
          console.log("this.displayInfosCRM ", this.displayInfosCRM);
        },
        () => {
          // Erreur uniquement dans getInfosCRM
          this.displayInfosCRM = false;
          this.spinnerInfosCRM = false;
        }
      );
    });
  }
  
  
  onVolumeChange(event: any) {
    this.spinnerInfosCRM = true;
    const newVolume = event.target.value;
    this.volume = Number(newVolume); // Met à jour la variable volume
    console.log("Nouvelle valeur du volume :", this.volume);
  
    if (!this.currentCategory) return;
  
    // Appel de getInfosCRM avec le nouveau pct_base (volume)
    this.campaignService.getInfosCRM(this.currentCategory, this.volume).subscribe(
      (infosCRM) => {
        if (!infosCRM) return;
        this.spinnerInfosCRMError = true;
  
        console.log("Mise à jour des données InfosCRM avec volume =", this.volume);
        
        // Mettre à jour les données
        this.processInfosCRMData(infosCRM);
  
        // Assurer la mise à jour de l'affichage
        this.cdr.detectChanges();
        this.spinnerInfosCRM = false;
      },
      (error) => {
        this.spinnerInfosCRMError = false;
        this.displayInfosCRM = false;
        this.displayErrorMessage('Erreur lors de la mise à jour des données.');
        console.error("Erreur lors du chargement des infos CRM :", error);
      }
    );
  }
  
  // Gère les erreurs de getLiftCurve
  private handleLiftCurveError(error?: string) {
    this.displayErrorMessage(error || 'Erreur lors de la récupération de la courbe.');
    this.currentLiftCurveData = [];
    this.currentLiftCurveFn = {};
    this.optimal = null;
    this.volume = null;
    this.segmentCreationForm.get('volume')?.setValue(null);
    this.currentCategoryData = undefined;
    this.displayForms = false;
  }
  
  // Traite les données de la lift curve
  private processLiftCurveData(lift: any) {
    this.errorMessage = '';
    this.hasCurveError = false;
    this.currentLiftCurveData = lift.curve ?? [];
    this.currentLiftCurveFn = this.currentLiftCurveData.reduce(
      (data, value) => ({ ...data, [value.x]: value.y }), {}
    );
    this.optimal = lift.optimal ?? 30;
    this.updateTrackStyle();
    this.volume = this.optimal;
    this.segmentCreationForm.get('volume')?.setValue(this.volume);
  }
  
  // Traite les données InfosCRM
  private processInfosCRMData(infosCRM: any) {
    if (!infosCRM.infos_crm) return;
  
    const parsedData = infosCRM.infos_crm;
    console.log('InfosCRM data:', parsedData);
  
    if (parsedData.segment_achats) {
      this.pieChartDataSegmentAchat = {
        labels: parsedData.segment_achats.map((item: SegmentAchats) => item.segment_achats),
        datasets: [{ data: parsedData.segment_achats.map((item: SegmentAchats) => item.count_size), hoverOffset: 4 }],
      };
    }

    if (parsedData.segment_email) {
      this.pieChartDataSegmentEmail = {
        labels: parsedData.segment_email.map((item: SegmentEmail) => item.segment_email),
        datasets: [{ data: parsedData.segment_email.map((item: SegmentEmail) => item.count_size), hoverOffset: 4 }],
      };
    }

    if (parsedData.age_pyramid?.length) {
      this.processAgePyramidData(parsedData.age_pyramid);
    }
  
    if (parsedData.realite_genre?.length) {
      this.processRealiteGenreData(parsedData.realite_genre);
    }

    if (parsedData.segment_genre?.length) {
      this.processSegmentGenreData(parsedData.segment_genre);
    }

    if (parsedData.map && parsedData.map.regions) {
      this.regionsMapData = parsedData.map.regions;
      this.cdr.detectChanges();
      setTimeout(() => {
        this.initializeRegionsMap();
      }, 3);
    
      
      console.log("this.regionsMapData.length", this.regionsMapData.length)
    }
  
    this.cdr.detectChanges(); // ✅ Forcer la mise à jour des graphiques
    this.charts.forEach((elt) => elt.update('none'));
  }
  
  
  // Traitement des pyramides des âges
  private processAgePyramidData(agePyramid: AgePyramid[]) {
    const sortedAgePyramid = [...agePyramid].sort((a, b) => {
      return parseInt(a.age_group.split('-')[0]) - parseInt(b.age_group.split('-')[0]);
    });
  
    const ageGroups = sortedAgePyramid.map(item => item.age_group);
    const femaleData = sortedAgePyramid.map(item => -item.MME);
    const maleData = sortedAgePyramid.map(item => item.MR);
    const maxValue = Math.max(...femaleData.map(Math.abs), ...maleData);
  
    this.agePyramidOptions = {
      ...this.agePyramidOptions,
      scales: {
        ...this.agePyramidOptions.scales,
        x: {
          ...this.agePyramidOptions.scales?.['x'],
          min: -maxValue,
          max: maxValue
        },
        y: {
          ...this.agePyramidOptions.scales?.['y'],
          reverse: true // This reverses the y-axis order
        }
      }
    };
    
    // Create the chart data
    this.agePyramidData = {
      labels: ageGroups,
      datasets: [
        {
          label: 'Female',
          data: femaleData,
          backgroundColor: 'rgba(255, 99, 132, 0.7)',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 1
        },
        {
          label: 'Male',
          data: maleData,
          backgroundColor: 'rgba(54, 162, 235, 0.7)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }
      ]
    };
    console.log("this.agePyramidData", this.agePyramidData)
    this.charts.forEach((elt) => elt.update('none'));
  }
  
  // Traitement des genres
  private processRealiteGenreData(realiteGenre: GenreRealiteData[]) {
    const sortedGenreData = [...realiteGenre].sort((a, b) => {
      return a.civilite === 'MME' ? -1 : 1;
    });
    
    this.genreRealiteData = sortedGenreData;
    this.genreRealiteChartData = {
      labels: sortedGenreData.map(item => item.civilite),
      datasets: [{
        data: sortedGenreData.map(item => item.percentage),
        backgroundColor: sortedGenreData.map(item => item.civilite === 'MME' ? 'rgba(255, 99, 132, 0.7)' : 'rgba(54, 162, 235, 0.7)'),
        borderColor: sortedGenreData.map(item => item.civilite === 'MME' ? 'rgb(255, 99, 132)' : 'rgb(54, 162, 235)'),
        hoverBackgroundColor: sortedGenreData.map(item => item.civilite === 'MME' ? 'rgba(255, 99, 132, 0.9)' : 'rgba(54, 162, 235, 0.9)'),
      }]
    };
  }

  private processSegmentGenreData(segmentGenre: GenreSegmentData[]) {
    const sortedGenreData = [...segmentGenre].sort((a, b) => {
      return a.civilite === 'MME' ? -1 : 1;
    });
    
    this.genreSegmentData = sortedGenreData;
    this.genreSegmentChartData = {
      labels: sortedGenreData.map(item => item.civilite),
      datasets: [{
        data: sortedGenreData.map(item => item.percentage),
        backgroundColor: sortedGenreData.map(item => item.civilite === 'MME' ? 'rgba(255, 99, 132, 0.7)' : 'rgba(54, 162, 235, 0.7)'),
        borderColor: sortedGenreData.map(item => item.civilite === 'MME' ? 'rgb(255, 99, 132)' : 'rgb(54, 162, 235)'),
        hoverBackgroundColor: sortedGenreData.map(item => item.civilite === 'MME' ? 'rgba(255, 99, 132, 0.9)' : 'rgba(54, 162, 235, 0.9)'),
      }]
    };
  }

  private displayErrorMessage(message: string) {
    
    this.hasCurveError = true;
    this.errorMessage = `Il n'y a pas assez de ventes pour la catégorie ${this.currentCategory?.label} pour générer un segment.`;
    this.displayForms = false;
    this.dialogService.open(this.dialogCurveError);
    this.currentCategory = undefined;
  }

  private updateLiftPointInitialVolumePosition() {
    if (this.liftCurveData?.datasets[0]?.data[0]) {
      this.liftCurveData.datasets[0].data.pop();
      this.liftCurveData.datasets[0].data.push({ x: this.volume, y: this.currentLiftCurveFn[this.volume!] });
    }
    this.charts.forEach((elt) => elt.update('none'));
  }

  private updateLiftPointCurrentVolumePosition() {
    if (this.liftCurveData?.datasets[1]?.data[0]) {
      this.liftCurveData.datasets[1].data.pop();
      this.liftCurveData.datasets[1].data.push({ x: this.volume, y: this.currentLiftCurveFn[this.volume!] });
    }
    this.charts.forEach((elt) => elt.update('none'));
  }

  private updateLiftCurve() {
    this.liftCurveData.datasets = [
      // initial volume point
      {
        data: [
          {
            x: this.volume,
            y: this.currentLiftCurveFn[this.volume!],
          },
        ],
        pointRadius: 8,
        pointBorderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
      },

      // current volume point
      {
        data: [
          {
            x: this.selectedSegment?.categories[0].percentage || this.volume,
            y: this.currentLiftCurveFn[this.selectedSegment?.categories[0].percentage || this.volume!],
          },
        ],
        pointRadius: 8,
        pointBorderColor: 'rgba(255,99,132,0.5)',
        pointBackgroundColor: 'rgba(255,99,132,0.5)',
      },

      // optimal point
      {
        data: [{ x: this.optimal, y: this.currentLiftCurveFn[this.optimal!] }],
        pointRadius: 4,
        pointBorderColor: '#01bcf7',
        pointBackgroundColor: '#01bcf7',
      },

      // blue line
      {
        data: this.currentLiftCurveData,
        pointRadius: 0,
        borderColor: '#01bcf7',
        pointBorderColor: '#01bcf7',
        pointBackgroundColor: '#01bcf7',
      },
    ];

    this.charts.forEach((elt) => elt.update('none'));
  }

  private toggleAllSegmentsChecked(): void {
    this.allSegmentChecked = this.segments.every((segment) => segment.checked);
  }

  private buildCampaign() {
    if (this.segments.length > 0) {
      this.buildingCampaign = true;
      this.vennDiagrammGenerating = true;
      this.campaignService
        .mergeCampaigns(this.capping, this.segments)
        .pipe(
          finalize(() => {
            this.buildingCampaign = false;
            this.vennDiagrammGenerating = false;
          }),
        )
        .subscribe((res: Campagne) => {

          this.segments.forEach((segment) => {
            const data = (res.segments || []).find(
              (data) =>
                segment.categories.map((category) => category.category.id).join(',') === data.categoryIds.join(','),
            );
            if (data) {
              segment.percentage = data.pourcentBase;
              segment.potential = data.pourcentVente;
              segment.mailCount = data.numb_mail;
              segment.lift = data.pourcentVente / data.pourcentBase;
            }
          });
          this.total = res.numb_all_mail;
          this.vennData = res.venn;

        });
    }
  }

  pieChartDataSegmentAchat: ChartData<'pie'> = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: [],
      hoverOffset: 4,
      spacing: 1
    }]
  };
  
  pieChartOptionsSegmentAchat: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw as number;
            const total = context.dataset.data.reduce((acc: number, data: number) => acc + data, 0);
            const percentage = Math.round((value / total) * 100);
            return `${label}: ${value} (${percentage}%)`;
          }
        }
      }
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: 'transparent'
      }
    },
    // Add these specific pie chart options
    cutout: 0,
  };

  pieChartDataSegmentEmail: ChartData<'pie'> = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: [],
      hoverOffset: 4,
      spacing: 1
    }]
  };
  
  pieChartOptionsSegmentEmail: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw as number;
            const total = context.dataset.data.reduce((acc: number, data: number) => acc + data, 0);
            const percentage = Math.round((value / total) * 100);
            return `${label}: ${value} (${percentage}%)`;
          }
        }
      }
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: 'transparent'
      }
    },
    // Add these specific pie chart options
    cutout: 0,
  };

  agePyramidData: ChartData<'bar'> = {
    labels: [],
    datasets: []
  };

  agePyramidOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
        ticks: {
          callback: function(value) {
            return Math.abs(Number(value));
          }
        },
        grid: {
          color: 'rgba(200, 200, 200, 0.3)'
        }
      },
      y: {
        stacked: true,
        grid: {
          color: 'rgba(200, 200, 200, 0.3)'
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            let value = Math.abs(context.parsed.x);
            return `${label}: ${value}`;
          }
        }
      }
    }
  };

  // Réalité genre
  // Add these properties to your component class
  genreRealiteChartData: ChartData<'bar'> = {
    labels: [],
    datasets: []
  };

  genreSegmentChartData: ChartData<'bar'> = {
    labels: [],
    datasets: []
  };

  genreRealiteChartOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        max: 100, // Set a fixed max for better visualization
        grid: {
          color: 'rgba(200, 200, 200, 0.3)'
        },
        ticks: {
          callback: function(value) {
            return value + '%';
          }
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Ventes des 30 derniers jours',
        position: 'top',
        align: 'center',
        font: {
          size: 16
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const civilite = context.label || '';
            // Use this.genreData instead of parsedData
            const originalData = this.genreRealiteData.find(item => item.civilite === civilite);
            if (originalData) {
              return `${civilite}: ${originalData.count} (${context.parsed.x.toFixed(2)}%)`;
            }
            return `${civilite}: ${context.parsed.x.toFixed(2)}%`;
          }
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return value.toFixed(2) + '%';
        },
        color: '#000',
        font: {
          weight: 'bold'
        },
        padding: {
          left: 10
        }
      }
      
    }
  };

  genreSegmentChartOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        max: 100, // Set a fixed max for better visualization
        grid: {
          color: 'rgba(200, 200, 200, 0.3)'
        },
        ticks: {
          callback: function(value) {
            return value + '%';
          }
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Segment',
        position: 'top',
        align: 'center',
        font: {
          size: 16
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const civilite = context.label || '';
            // Use this.genreData instead of parsedData
            const originalData = this.genreSegmentData.find(item => item.civilite === civilite);
            if (originalData) {
              return `${civilite}: ${originalData.count} (${context.parsed.x.toFixed(2)}%)`;
            }
            return `${civilite}: ${context.parsed.x.toFixed(2)}%`;
          }
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return value.toFixed(2) + '%';
        },
        color: '#000',
        font: {
          weight: 'bold'
        },
        padding: {
          left: 10
        }
      }
      
    }
  };


  
  

  //! Régions //

  initializeRegionsMap(): void {
    // Clear any existing map and tooltip
    d3.select('#regions-map-container').selectAll('*').remove();
    d3.select('body').selectAll('.regions-map-tooltip').remove();
    
    // Create SVG container with proper viewBox for responsive scaling
    this.regionsMapContainer = d3.select('#regions-map-container')
      .append('svg')
      .attr('width', this.regionsMapWidth)
      .attr('height', this.regionsMapHeight)
      .attr('viewBox', `0 0 ${this.regionsMapWidth} ${this.regionsMapHeight}`)
      .attr('preserveAspectRatio', 'xMidYMid meet');
      
    this.regionsMapSvg = this.regionsMapContainer.append('g');
    
    // Create tooltip div
    this.regionsMapTooltip = d3.select('body').append('div')
      .attr('class', 'regions-map-tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border', '1px solid #ddd')
      .style('border-radius', '3px')
      .style('padding', '5px')
      .style('pointer-events', 'none');
    
    // Load GeoJSON data and create map
    d3.json('assets/regions-version-simplifiee.geojson')
      .then((geoData: any) => {
        this.regionsGeoData = geoData;
        console.log("this.regionsGeoData", this.regionsGeoData);
        this.updateRegionsMapWithData();
      });
  }

  updateRegionsMapWithData(): void {
    if (!this.regionsGeoData || !this.regionsMapData.length) return;
    
    // Clear previous map elements
    this.regionsMapSvg.selectAll('*').remove();
    this.regionsMapContainer.selectAll('.regions-map-legend').remove();
    
    // Calcul des min et max pour gérer les valeurs négatives et positives
    const counts = this.regionsMapData.map(d => d.count);
    const minCount = Math.min(...counts);
    const maxCount = Math.max(...counts);

    // Échelle divergente : bleu (négatif) → gris (0) → rouge (positif)
    this.regionsMapColorScale = d3.scaleLinear<string>()
      .domain([minCount, 0, maxCount])
      .range(['#35a2eb', '#f9f9f9', '#ff6384']) // bleu → gris → rouge
      .interpolate(d3.interpolateRgb);

    
    const projection = d3.geoMercator()
      .center([2.2, 46.8]) 
      .scale(1800) 
      .translate([this.regionsMapWidth / 2, this.regionsMapHeight / 2]);
      
    const path = d3.geoPath().projection(projection);
    
    this.regionsMapSvg.selectAll('path')
      .data(this.regionsGeoData.features)
      .enter()
      .append('path')
      .attr('d', path)
      .attr('fill', (d: any) => {
        const regionCode = d.properties.code;
        const regionData = this.regionsMapData.find(item => item.code_region.toString() === regionCode);
        if (regionData) {
          return this.regionsMapColorScale(regionData.count);
        }
        return '#f9f9f9'; // Default color for regions without data
      })
      .attr('stroke', '#fff')
      .attr('stroke-width', 0.5)
      .on('mouseover', (event: any, d: any) => {
        const regionCode = d.properties.code;
        const regionData = this.regionsMapData.find(item => item.code_region.toString() === regionCode);
        const rawCount = regionData ? regionData.count : 0;
        const formattedCount = rawCount > 0 ? `+${rawCount} %` : `${rawCount} %`;

  
        this.regionsMapTooltip.transition()
          .duration(200)
          .style('opacity', .9);
        this.regionsMapTooltip.html(`
          <strong>Region:</strong> ${d.properties.nom}<br/>
          <strong>Différence vs base totale:</strong> ${formattedCount}
        `)
          .style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 28) + 'px');
      })
      .on('mouseout', () => {
        this.regionsMapTooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });
  
    // Nouvelle légende basée sur maxCount
    this.addRegionsMapLegend(minCount, maxCount);

  }
  
  
  addRegionsMapLegend(minValue: number, maxValue: number): void {
    const gradientId = 'regions-map-legend-gradient-' + new Date().getTime();
  
    const legend = this.regionsMapContainer.append('g')
      .attr('class', 'regions-map-legend')
      .attr('transform', `translate(${this.regionsMapWidth - 60}, ${(this.regionsMapHeight / 2) - 100})`);
  
    const defs = this.regionsMapContainer.append('defs');
    const gradient = defs.append('linearGradient')
      .attr('id', gradientId)
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '0%')
      .attr('y2', '0%');
  
    // Gradient stops : bleu (bas) → gris (milieu) → rouge (haut)
    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#35a2eb');  // Bleu (valeurs négatives)
  
    gradient.append('stop')
      .attr('offset', '50%')
      .attr('stop-color', '#f9f9f9');  // Gris (zéro)
  
    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#ff6384');  // Rouge (valeurs positives)
  
    const legendWidth = 20;
    const legendHeight = 200;
  
    legend.append('rect')
      .attr('width', legendWidth)
      .attr('height', legendHeight)
      .style('fill', `url(#${gradientId})`);
  
    // Add title
    legend.append('text')
      .attr('x', legendWidth / 2)
      .attr('y', -15)
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .text('Diff. %');
  
  }
  

}
