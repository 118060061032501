import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageSelectionService {
  private imageUrlsSource = new BehaviorSubject<string[]>([]);
  public imageUrls$ = this.imageUrlsSource.asObservable();

  updateImageUrls(urls: string[]) {
    this.imageUrlsSource.next(urls);
  }
  // Dans ImageSelectionService
resetImageUrls() {
    this.imageUrlsSource.next([]); // Réinitialise les URLs des images
  }
  
}
