<div id="externalPreviewPopup">
    <div class="modal-container">
        <div class="modal-header-container">
            <div>
                <button type="button" class="close modal-close-button" (click)="close()">
                    <span>×</span>
                </button>
                <nb-button-group *ngIf="hasAmpHtml" class="amp-button" size="small" (valueChange)="updateSingleSelectGroupValue($event)">
                    <button nbButtonToggle value="html"  (click)="$event.stopPropagation()" [pressed]="selectedView === 'html'">HTML</button>
                    <button nbButtonToggle value="amphtml"  (click)="$event.stopPropagation()" [pressed]="selectedView === 'amphtml'">⚡ AMPHTML</button>
                  </nb-button-group>
                
                <h4 class="modal-title">Prévisualisation de l'email</h4>
                
            </div>            
        </div>
        <!-- Contenu de la popup -->
        <div id="content" style="padding: 15px;" class="preview-container-fluid">
            <div class="preview-row">
                <div class="preview-col-sm-8">
                    <div class="esdev-desktop-device">
                        <div class="esdev-email-window-panel">
                            <div class="esdev-email-subject" style="min-height: 20px"></div>
                        </div>
                        <div class="esdev-desktop-device-screen" #desktopContainer>
                            <iframe id="iframeDesktop" frameborder="0" width="100%" height="642"></iframe>
                        </div>
                    </div>
                </div>
                <div class="preview-col-sm-4 esdev-no-padding-left">
                    <div class="esdev-mobile-device center-block">
                        <div class="esdev-mobile-device-screen" #mobileContainer>

                            <iframe id="iframeMobile" frameborder="0" width="100%" height="568" (load)="onMobileIframeLoaded()"></iframe>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
