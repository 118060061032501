import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { TokenService } from '../service/token.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  constructor(private tokenService: TokenService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.tokenService.verify().pipe(
      catchError(() => {
        this.tokenService.clearToken();
        return of(false);
      }),
      map((response) => {
        return !!response || this.router.createUrlTree(['/auth/login']);
      }),
    );
  }
}
