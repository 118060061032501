import { Injectable } from '@angular/core';

export interface Toast {
  id?: string;
  title: string;
  message: string;
  status: 'pending' | 'success' | 'error';
  autohide: boolean;
  progress?: number;
  taskid? : string;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: Toast[] = [];



  success(title: string, message: string) {
    this.toasts.push({ title, message, status: 'success', autohide: true });
  }

  error(title: string, message: string) {
    this.toasts.push({ title, message, status: 'error', autohide: false });
  }

  createProgress(id: string, title: string, message: string) {
    const existingToast = this.toasts.find(toast => toast.id === id);
    if (!existingToast) {
      this.toasts.push({ id, title, message, status: 'pending', autohide: false, progress: 0 });
    } else {
      console.log("toast avec le même id existe déjà");
    }
  }

  updateProgress(id: string, message: string, progress: number) {
    const toast = this.toasts.find((toast) => toast.id === id);
    if (toast) {
      toast.message = message;
      toast.progress = progress;
      toast.status = progress === 100 ? 'success' : 'pending';
    }
  }

  cancelProgress(id: string, message: string) {
    this.toasts = this.toasts.map((toast) => (toast.id === id ? { ...toast, message, status: 'error' } : toast));
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
  
  removeById(id: string) {
    this.toasts = this.toasts.filter((toast) => toast.id !== id);
  }
  
  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
  
  imageSelected(url: string, taskId: string) {
    const title = 'Merveilleux choix !';
    const escapedUrl = encodeURIComponent(url);
    const toastMessage = `Nous ajoutons cette image à votre email<br><img src="${url}" alt="Votre choix">`;
    // const duration = 5000;
  
    const toast: Toast = { title: title, message: toastMessage, status: 'success', autohide: true };
        this.toasts.push(toast);
  
    // setTimeout(() => this.remove(toast), duration);
  }
  
    
}
