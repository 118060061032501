import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MetaCategory } from '../model/meta-category.model';
import { CategoryService } from '../service/category.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriesResolver implements Resolve<MetaCategory> {
  constructor(private categoryService: CategoryService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MetaCategory> {
    return this.categoryService.getCurrentMeta();
  }
}
