import { HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../component/pages/dashboard/product.store';
import { Email, Generate, Progress,  Template } from '../component/pages/generator/email.store';
import { MetaCategory } from '../model/meta-category.model';

type Images = { [key: string]: string };
interface GifResponse {
  task_id: string;
}

export interface GifStatus {
  status: string;
  progress: number;
  gif_url: string;
}

@Injectable({ providedIn: 'root' })
export class GeneratorService {
  constructor(private httpClient: HttpClient) {}

  public getTemplates(): Observable<Template[]> {
    return this.httpClient.get<Template[]>(`${environment.backendUrl}/api/template`);
  }


  public generateEmail(
    metaCategoryId: MetaCategory['id'],
    ids: Product['id'][],
    images_products_urls: Product['image_url_1'][],
    theme: string,
    templateId: Template['id'],
    visuel_url?:string,
    task_id?:string,
    selected_ratio?: number,
    wantGif?: boolean,
  ): Observable<Generate> {
    const payload: any = {
        meta: metaCategoryId,
        ids,
        images_products_urls,
        theme,
        id_template: templateId
    };

    if (visuel_url) {
        payload.visuel_url = visuel_url;
    }

    if (task_id) {
        payload.task_id = task_id;
    }
    if (selected_ratio) {
      payload.selected_ratio = selected_ratio;
    }
    if (wantGif) {
      payload.wantGif = wantGif;
    }

    return this.httpClient.post<Generate>(`${environment.backendUrl}/api/generate/`, payload);
}
  
  public generateEmailFinal(
    task_id: Generate['task_id'],
    images: Images,
  ): Observable<Generate> {
    return this.httpClient.post<Generate>(`${environment.backendUrl}/api/generate_final/`, {
      task_id,
      images,
    });
  }

  public progress(id: Generate['task_id']): Observable<Progress> {
    return this.httpClient.get<Progress>(`${environment.backendUrl}/api/progress/${id}`);
  }

  public getEmails(): Observable<Email[]> {
    return this.httpClient.get<Email[]>(`${environment.backendUrl}/api/generate`);
  }

  public getEmail(id: Email['id']): Observable<Email> {
    return this.httpClient.get<Email>(`${environment.backendUrl}/api/generate`, {
      params: { id_generated: `${id}` },
    });
  }

  public saveEmail(id: Email['id'] | null, html: string, css: string, name:string, isExporting:boolean): Observable<string> {
    return this.httpClient.post<string>(`${environment.backendUrl}/api/generatesave/`, {
      id,
      html,
      css,
      name,
      isExporting
    });
  }
  public createEmptyEmail(): Observable<{ message: string; id: number }> {
    return this.httpClient.post<{ message: string; id: number }>(
      `${environment.backendUrl}/api/template/`,
      {}
    );
  }
  
  
  public getLangagecode(get_language_code: number | null): Observable<string> {
    return this.httpClient.post<string>(`${environment.backendUrl}/api/generatesave/`, {
      get_language_code
    });
  }
  public translateEmail(id: Email['id'], langage_code: string): Observable<Email> {
    return this.httpClient.post<Email>(`${environment.backendUrl}/api/generatesave/`, {
      id,
      langage_code,
    });
  }
  public copyEmail(id: Email['id'], html: string | undefined, css: string | undefined): Observable<Email> {
    return this.httpClient.post<Email>(`${environment.backendUrl}/api/generatesave/`, {
      id,
      html,
      css,
      copy: 1  // Indiquer que c'est une copie
    });
  }

  public resizeImageRatio(url: string, ratio: number): Observable<{ [key: string]: string }> {
    // Créer les paramètres de requête
    let params = new HttpParams()
      .set('url', url)
      .set('ratio', ratio.toString());
  
    // Envoyer la requête avec les paramètres
    // Assurez-vous que le type de retour correspond à la nouvelle structure de réponse
    return this.httpClient.post<{ [key: string]: string }>(`${environment.backendUrl}/api/image_resize_ratio/`, null, {
      params: params,
    });
  }
  public WriteCTAOnImage(image_url: string, text: string): Observable<{ [key: string]: string }> {
    // Créer le corps de la requête avec les données à envoyer
    const body = {
      text: text,
      image_url: image_url,
    };
  
    // Envoyer la requête POST avec le corps en JSON
    return this.httpClient.post<{ [key: string]: string }>(`${environment.backendUrl}/api/write_cta_on_image/`, body);
  }
  public majRemiseProduct(image_url: string, html_url: string, width: number): Observable<{ [key: string]: string }> {
    // Créer le corps de la requête avec les données à envoyer
    const body = {
      url_html: html_url,
      url_image: image_url,
      width: width.toString(),
    };
  
    // Envoyer la requête POST avec le corps en JSON
    return this.httpClient.post<{ [key: string]: string }>(`${environment.backendUrl}/api/maj_remise_produit/`, body);
  }

  public recadrageProduct(url_image: string, width_block: number, height_block: number): Observable<{ [key: string]: string }> {
    const body = {
      url_image: url_image,
      width_block: width_block.toString(),
      height_block: height_block.toString(),
    };

    return this.httpClient.post<{ [key: string]: string }>(`${environment.backendUrl}/api/recadrage_produit/`, body);
  }

  public imageToGif(url: string): Observable<GifResponse> {
    // Envoyer les paramètres dans le corps de la requête POST
    const body = { image_url: url };
    return this.httpClient.post<GifResponse>(`${environment.backendUrl}/api/create_gif_from_static_image/`, body);
  }

  public imageToGifProgress(task_id: string): Observable<GifStatus> {
    return this.httpClient.get<GifStatus>(`${environment.backendUrl}/api/create_gif_from_static_image/${task_id}/`);
  }
  

  public writeOnImage(url: string, text_up: string, text_down: string, single_text: string): Observable<{[key: string]: string}> {
    // Créer les paramètres de requête
    let params = new HttpParams()
      .set('url', url)
      .set('text_up', text_up)
      .set('text_down', text_down)
      .set('single_text', single_text);

    // Envoyer la requête avec les paramètres
    return this.httpClient.post<{[key: string]: string}>(`${environment.backendUrl}/api/write_on_image/`, null, {
      params: params,
    });
  }

  public deleteEmail(id: Email['id']): Observable<void> {
    return this.httpClient.get<void>(`${environment.backendUrl}/api/generate`, {
      params: {
        del_email: `${id}`,
      },
    });
  }

  public regenerateImage(taskId: string, imageUrl: string, content: string, task_id_midjourney : string): Observable<any> {
    return this.httpClient.post<any>(`${environment.backendUrl}/api/image_selection/`, {
      reload: true,
      task_id: taskId,
      url_discord: imageUrl,
      content: content,
      task_id_midjourney :task_id_midjourney
    });
  }
  
  public selectImage(imageNumber: number, imageUrl: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.backendUrl}/api/image_selection/`, {
      reload: false,
      img_number: imageNumber,
      url_discord: imageUrl,
    });
  }

  public uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    
    // Supposons que vous ayez un endpoint séparé pour l'upload d'images
    return this.httpClient.post<{ url: string }>(`${environment.backendUrl}/api/image_tmp/upload/`, formData).pipe(
        map(response => response.url)
    );
  }
  
  public exportSocial(imageUrl: string, htmlUrl: string): Observable<Blob> {
    return this.httpClient.post(`${environment.backendUrl}/api/export_social/?url_html_image=${htmlUrl}&url_preview_image=${imageUrl}`, null, {
      responseType: 'blob'
    });
  }

  public resizeImagesInHtml(html: string): Observable<{ html: string }> {
    return this.httpClient.post<{ html: string }>(`${environment.backendUrl}/api/resize_images_in_html/`, { html: html });
  }

  public createGifProduct(selected_images: string[], ratio_produit: number): Observable<any> {
    return this.httpClient.post<any>(`${environment.backendUrl}/api/create_gif_product/`, {
      selected_images: selected_images,
      ratio_produit: ratio_produit
    });
  }

  public scrapImageProductPage(url_image: string, url_html: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.backendUrl}/api/scrap_image_product_page/`, {
      url_image: url_image,
      url_html: url_html
    });
  }

  public addTrackingToUrls(html: string | undefined, tracking: string): Observable<{ html: string }> {
    return this.httpClient.post<{ html: string }>(`${environment.backendUrl}/api/add_tracking_to_urls/`, {
      html: html,
      tracking: tracking
    });
  }

  public pushEmail(email_id: number, html: string): Observable<{ status: string; message: string }> {
    return this.httpClient.post<{ status: string; message: string }>(`${environment.backendUrl}/api/push_email/`, {
      email_id: email_id,
      html_compiled: html
    });
  }

  public changeValueInEnterprise(id_entreprise: number, field: string, value: string): Observable<any> {
    return this.httpClient.patch<any>(`${environment.backendUrl}/api/enterprise/${id_entreprise}/`, {
      [field]: value
    });
  }

  /**
   * Search Adobe Stock images using a search term.
   * @param searchTerm The search term to use for the Adobe Stock API.
   * @returns An Observable containing a list of image URLs.
   */
  public searchAdobeStock(searchTerm: string): Observable<{urls: string[];}> {
    const payload = { search_term: searchTerm };

    return this.httpClient.post<{urls: string[];}>(
      `${environment.backendUrl}/api/adobe_stock_search/`,
      payload
    );
  }

  public mergeImages(staticImageUrl: string, overlayImageUrl: string, x: number, y: number, width: number, height: number): Observable<{merged_image_url: string}> {
    return this.httpClient.post<{merged_image_url: string}>(`${environment.backendUrl}/api/merge_image_overlay/`, {
      static_image_url: staticImageUrl,
      overlay_image_url: overlayImageUrl,
      x: x,
      y: y,
      width: width,
      height: height
    });
  }

  public checkMajProduitWebhook(): Observable<{ has_webhook: boolean }> {
    return this.httpClient.get<{ has_webhook: boolean }>(`${environment.backendUrl}/api/maj_produit/check_webhook/`);
  }

  public majProduit(payload: any = {}): Observable<{ 
    task_id: string; 
    status: string; 
    message: string;
    minutes_since_completion?: number;
  }> {
    return this.httpClient.post<any>(`${environment.backendUrl}/api/maj_produit/`, payload);
  }

}
