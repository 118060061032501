import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, Chart, ChartEvent } from 'chart.js';
import 'chartjs-chart-treemap';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { DicoService } from '../../service/dico.service';
import { DicoEntry } from '../../model/dico-entry.model';
import α from 'color-alpha';
import { BehaviorSubject } from 'rxjs';
import { DfService } from '../../service/df.service';
import { DecimalPipe, formatNumber } from '@angular/common';
import { Category } from '../../model/category.model';

@Component({
  selector: 'app-treemap',
  templateUrl: './treemap.component.html',
  styleUrls: ['./treemap.component.scss'],
})
export class TreemapComponent implements OnInit, OnDestroy {
  minColor: { color: string; value: number } | undefined = undefined;
  maxColor: { color: string; value: number } | undefined = undefined;
  baseColor = '#3369a7';

  @Output() onTileClicked: EventEmitter<DicoEntry> = new EventEmitter();
  @ViewChild(BaseChartDirective, { static: true }) treemap!: BaseChartDirective;
  @Input() update!: BehaviorSubject<any>;
  @Input() entries!: DicoEntry[];


  treeMapOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (ctx: any, el: any) => {
      let entry = this.getCategoryById(el[0].element.$context.raw._data.catId);
      if (entry) {
        this.onTileClicked.emit(entry);
      }
    },
    onHover: (event: ChartEvent, chartElement: any) => {
      if (event?.native?.target) {
        //event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      }
    },
    legend: { display: false },
    plugins: {
      title: { display: false },
      legend: { display: false },
      vline: false,
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (ctx: any) => {
            if (Array.isArray(ctx)) {
              return ctx[0].raw.label;
            }
            return ctx.raw._data.data.category.label;
          },
          label: (ctx: any) => {
            return [
              ctx.raw._data.data.category.label,
              'Année N : ' + ctx.raw._data.data.real,
              'croissance vs. N-1 : ' +
                formatNumber(
                  ((ctx.raw._data.data.forecast - ctx.raw._data.data.realNMinusOne) /
                    ctx.raw._data.data.realNMinusOne) *
                    100,
                  'fr-FR',
                  '.0-2',
                ) +
                ' %',
              ctx.raw._data.data.dispo
                ? 'Dispo : ' + formatNumber(ctx.raw._data.data.dispo, 'fr-FR', '.0-2') + ' %'
                : 'Stock : ' + formatNumber(ctx.raw._data.data.stock, 'fr-FR', '.0-2'),
            ];
          },
        },
      },
    },
  };

  treeMapData: ChartConfiguration['data'] = {
    datasets: [],
  };

  constructor(private dicoService: DicoService, private dfService: DfService, private decimalPipe: DecimalPipe) {}

  ngOnInit(): void {
    Chart.register(TreemapController, TreemapElement);

    this.update?.subscribe((value) => {
      if (value) {
        window.setTimeout(
          (t: any) => {
            t.buildTreemapOption(this.entries);
            t.treemap.update();
          },
          100,
          this,
        );
      }
    });
  }

  colorFromRaw(ctx: any) {
    if (ctx.type !== 'data') {
      return 'transparent';
    }

    const value = Math.log(1 + 5 * ctx.raw._data.alpha);

    let alpha = value; //(1 + Math.log(value/10)) ;

    return α('#3369a7', alpha);
  }

  buildTreemapOption(data: DicoEntry[]) {
    //computing min & max values for ratio
    let min = data.reduce((acc: number | null, dico: DicoEntry) => {
      let ratio = this.dicoService.getRatio(dico, true);
      if (acc === null) {
        return ratio;
      } else if (acc > ratio) {
        return ratio;
      }
      return acc;
    }, null);
    let max = data.reduce((acc: number | null, dico: DicoEntry) => {
      let ratio = this.dicoService.getRatio(dico, true);
      if (acc === null) {
        return ratio;
      } else if (acc < ratio) {
        return ratio;
      }
      return acc;
    }, null);

    let treeData = data.map((entry) => {
      // computing alpha between 0 & 1 using ratios with min & max : (ratio - min) / (max - min)
      let alpha = 0.5;
      if (min && max && min != max) {
        min = Math.max(min, 1);
        max = Math.min(max, 1000);

        let value = this.dicoService.getRatio(entry, true);
        let normValue = Math.min(this.dicoService.getRatio(entry, true), max);
        normValue = Math.max(normValue, min);
        alpha = (normValue - min) / (max - min);

        if (isFinite(value) && (this.maxColor === undefined || value > this.maxColor.value)) {
          this.maxColor = { value: value, color: α(this.baseColor, alpha) };
        }
        if (isFinite(value) && (this.minColor === undefined || value < this.minColor.value)) {
          this.minColor = { value: value, color: α(this.baseColor, alpha) };
        }
      }

      //dataset comes with all these computations
      return {
        label: entry.category.label,
        value: entry.forecast,
        data: entry,
        alpha: alpha,
        catId: entry.category.id,
      };
    });
    this.treeMapData.datasets = [
      {
        tree: treeData,
        data: [],
        key: 'value',
        //label: fas,
        spacing: 5,

        backgroundColor: this.colorFromRaw,
        pointBackgroundColor: this.colorFromRaw,
        // legend: { display: false },
        label: '',
        labels: {
          display: true,
          formatter: this.treemapLabelFormatter,
          color: 'black',
          padding: 2,
        },
        captions: {
          display: true,
          formatter: (ctx: any) => {
            return '';
          },
        },
      },
    ];
  }

  treemapLabelFormatter(ctx: any) {
    return ctx.raw._data.label;
  }



  getCategoryById(catId: number): undefined | DicoEntry {
    let result: undefined | DicoEntry;
    this.entries.forEach((entry) => {
      if (catId === entry.category.id) {
        result = entry;
      }
    });
    return result;
  }

  gradientStyle() {
    return { background: `linear-gradient(.25turn, ${this.minColor?.color}, ${this.maxColor?.color})` };
  }

  ngOnDestroy(): void {
    Chart.unregister(TreemapController, TreemapElement);
  }
}
