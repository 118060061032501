<div class="modal-fullscreen" *ngIf="show">
    <div class="modal-header">
        <button type="button" class="btn btn-outline-secondary" (click)="onClose()">Retour</button>
        <button type="button"
          class="btn btn-primary start-generation-button"
          (click)="startGeneration()"
          [disabled]="!isButtonEnabled">
          Commencer la génération
        </button>
    </div>

    <div class="modal-container">
        <div class="modal-content" (click)="$event.stopPropagation()">
            <h3 style="text-align: center; width: 75%; margin: auto;">Choissisez votre visuel principal :</h3><br/>
            <p>Quelque soit le format, notre IA redimensionnera l'image et se chargera d'ajouter de nouveaux élements si besoin. </p>
            <!-- Section pour afficher les images sélectionnées lorsque GIF est activé -->
            <p *ngIf="wantGif && selectedImages.length > 0">Choissisez l'ordre de vos images pour la création du GIF :</p>

            <div *ngIf="wantGif && selectedImages.length > 0"
                class="container"
                [ngClass]="selectedRatioString"
                cdkDropList 
                (cdkDropListDropped)="drop($event)"
                cdkDropListOrientation="horizontal">
                          
              <div *ngFor="let selectedImage of selectedImages; let i = index" cdkDrag class="item selected-image-wrapper">
                  <div class="delete-button" (click)="removeImage(i)">
                    <i class="bi-trash"></i>
                  </div>
                  <img [src]="selectedImage" alt="Image" class="selected-image" />
              </div>
            </div>

          <div *ngIf="wantGif && selectedImages.length > 0">
            <br>
            <label for="ratioSelect">Choisissez le ratio de votre GIF :</label>
            <select id="ratioSelect" [(ngModel)]="selectedRatioString" style="margin-left: 8px;">
              <option value="ratio-1-1">1:1 (Carré)</option>
              <option value="ratio-16-9">16:9 (Horizontal)</option>
              <option value="ratio-4-3">4:3 (Horizontal)</option>
              <option value="ratio-9-16">9:16 (Vertical)</option>
              <option value="ratio-4-5">4:5 (Vertical)</option>
            </select>
          </div>
          <div class="checkbox-container">
            <label>
              <nb-checkbox [(checked)]="wantGif" status="danger" (checkedChange)="toggleGifPreference($event)">
                {{ wantGif ? "Désélectionner pour ne pas avoir de GIF" : "Je veux un GIF !" }}
              </nb-checkbox>
            </label>
          </div>
          <!-- Search Bar for Adobe Stock Images -->
          <div *ngIf="showAdobeStock$ | async" class="search-bar">
            <input
              type="text"
              [(ngModel)]="searchTerm"
              placeholder="Rechercher des images sur Adobe Stock"
              class="form-control"
              (keyup.enter)="onSearch()"
              [disabled]="isSearching"
            />
            <p *ngIf="errorSearchMessage" class="error-message">{{ errorSearchMessage }}</p>
          </div>
            <div class="images-container" [class.grayed-out]="isSearching">
                <label for="file-upload" class="custom-file-upload" 
                    [class.dragover]="isDragging"
                    (dragover)="onDragOver($event)" 
                    (dragenter)="onDragEnter($event)"
                    (dragleave)="onDragLeave($event)"
                    (drop)="onDrop($event)">
                    <button nbButton appearance="filled" status="danger" (click)="onButtonClick($event)">
                        Charger une image
                    </button>
                    <p><br>Ou faire glisser un fichier image</p>
                    <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
                    <i class="fa fa-plus"></i>
                </label>
                <input id="file-upload" type="file" style="display:none;"
                    (change)="onFileSelected($event); uploadSelectedFile()">
                    <ng-container *ngFor="let imageUrl of imageUrls">
                      <div class="image-container" [class.selected]="selectedImages.includes(imageUrl)" *ngIf="imageUrl !== '0'" (click)="onImageClick($event, imageUrl)">
                        <ng-container *ngIf="imageUrl === 'loading'; else showImage">
                          <div class="loading-spinner">
                            <div class="spinner"></div>
                          </div>
                        </ng-container>
                        <ng-template #showImage>
                          <img [src]="imageUrl" class="displayed-image" (load)="onImageLoad($event)"/>
                          <div *ngIf="selectedImages.includes(imageUrl)" class="selection-number">
                            {{ getSelectionIndex(imageUrl) }}
                          </div>
                        </ng-template>
                      </div>
                    </ng-container>
                <!-- Upload button styled as an image with a "+" icon -->
            </div>
        </div>
    </div>
</div>

<!-- Template de dialogue pour afficher les messages d'erreur -->
<ng-template #dialogTemplate let-ref="dialogRef">
  <nb-card>
    <nb-card-body>{{ errorMessage }}</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="success" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogGifTemplate let-ref="dialogRef">
  <nb-card>
    <nb-card-body>{{ errorMessageGif }}</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="success" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>