<div class="container-fluid">
  <canvas
    baseChart
    [data]="treeMapData"
    [options]="treeMapOptions"
    [type]="'treemap'"
    #treemap
    id="treemap"
  ></canvas>
</div>
<div class="row justify-content-center mt-4">
  <div class="col-3">
    <div class="row">
      <div class="color-scale" [ngStyle]="gradientStyle()"></div>
    </div>
    <div class="row">
      <div class="col-auto mr-auto">{{ minColor?.value | number: '.0-0' }} %</div>
      <div class="col-auto">{{ maxColor?.value | number: '.0-0' }} %</div>
    </div>
  </div>
</div>
