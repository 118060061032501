import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../model/category.model';
import { DicoEntry } from '../model/dico-entry.model';

@Injectable({
  providedIn: 'root',
})
export class MailsService {
  constructor(private http: HttpClient) {}

  getCount(): Observable<number> {
    return this.http.get<{ numb_mails: number }>(`${environment.backendUrl}/api/mails/`).pipe(
      map((value) => {
        return value.numb_mails;
      }),
    );
  }
}
