import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbAuthOAuth2JWTToken, NbAuthToken, NbTokenService } from '@nebular/auth';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  currentToken!: NbAuthToken | undefined;
  isRefreshing = false;
  private tokenIsValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private tokenService: NbTokenService) {


    this.tokenService.tokenChange().subscribe((token) => {

      this.currentToken = token;
      this.tokenIsValid.next(this.currentToken?.isValid() || false);
    });

    // Initialiser le token stocké
    this.tokenService.get().subscribe((token) => {

      if (token instanceof NbAuthOAuth2JWTToken) {
        this.currentToken = token;
      } else {
        const storedTokenString = localStorage.getItem('auth_app_token');
        if (storedTokenString) {
          const storedToken = JSON.parse(storedTokenString);
          this.currentToken = new NbAuthOAuth2JWTToken(storedToken, 'email');
        }
      }
      this.tokenIsValid.next(this.currentToken?.isValid() || false);

      // Ajoutez un log pour vérifier la récupération du token depuis localStorage
      const storedToken = localStorage.getItem('auth_app_token');

    });
  }

  refresh(): Observable<boolean> {

    if (!this.currentToken?.getPayload()?.refresh_token) {

      this.tokenIsValid.next(false);
      return of(false);
    }

    let refresh = this.currentToken.getPayload().refresh_token;

    return this.http.post<{ access: string }>(`${environment.backendUrl}/api/token/refresh/`, { refresh }).pipe(
      map((response) => {

        if (response.access) {
          let newToken = new NbAuthOAuth2JWTToken(
            { access_token: response.access, refresh_token: refresh },
            'email'
          );
          this.tokenService.set(newToken).subscribe(() => {

          });
          this.tokenIsValid.next(true);
          return true;
        } else {
          this.tokenIsValid.next(false);
          return false;
        }
      }),
      catchError((error) => {
        console.error('Refresh Error:', error);
        this.tokenIsValid.next(false);
        return of(false);
      })
    );
  }

  verify(): Observable<boolean> {

    if (this.currentToken?.isValid()) {

      return of(true);
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.refresh().pipe(
        map((isRefreshed) => {
          this.isRefreshing = false;
          return isRefreshed;
        }),
        catchError((error) => {
          this.isRefreshing = false;
          return of(false);
        })
      );
    }

    return this.tokenIsValid.asObservable();
  }

  clearToken() {
    this.currentToken = undefined;
    this.isRefreshing = false;
    this.tokenService.clear().subscribe(() => {
    });
  }
}
