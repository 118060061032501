<div class="header-container">
  <div class="logo-container">
    <a class="logo" href="#" (click)="navigateHome()"><img src="/assets/logo_deebr_marge.png" /></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="true"
        [name]="user?.name"
        icon="person-outline"
        [picture]="enterpriseLogo || base64image"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<ng-template #dialogDeconnexion let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>Êtes-vous sûr de vouloir vous déconnecter de <strong>{{ enterpriseName }}</strong> ?</nb-card-body>
    <nb-card-body>Toutes les données non sauvegardées seront perdues.</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="closeDialog(dialogRef)" class="non-button">Non</button>
          <button nbButton status="success" (click)="confirmLogout(dialogRef)">Oui</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>