
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Toast {
  title: string;
  message: string;
  autohide: boolean;
  taskid? : string;
  status: 'pending' | 'success' | 'error';
}

@Injectable({ providedIn: 'root' })
export class Event {
    event: Toast[] = [];
    imagemodalEvent$ = new Subject<{imageUrl: string, message: string, taskId: string}>();
    closeModalEvent$ = new Subject<void>();
    imageChoiceEvent$ = new Subject<{imageUrls: string[], message: string, taskId: string}>();
    closeChoiceEvent$ = new Subject<void>();

    showModal(url: string, message: string, taskId: string) {
        this.event.push({ title: 'Choisissez votre image', message: `${message}\n${url}`, status: 'success', autohide: false });
        this.imagemodalEvent$.next({ imageUrl: url, message: `Choisissez votre image. ${message}`, taskId: taskId });
        
    }

    showChoice(urls: string[], message: string, taskId: string) {
      this.event.push({ title: 'Choisissez votre image', message: `${message}\n${urls}`, status: 'success', autohide: false });
      this.imageChoiceEvent$.next({ imageUrls: urls, message: `Choisissez votre image. ${message}`, taskId: taskId });
      
  }
    closeChoice() { // Ajoutez ceci
      this.closeModalEvent$.next();
    }
    closeModal() { // Ajoutez ceci
      this.closeModalEvent$.next();
    }

}