import { Component, ChangeDetectorRef } from '@angular/core';
import { NbRequestPasswordComponent, NbAuthService } from '@nebular/auth';
import { HttpErrorResponse } from '@angular/common/http';



@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['../auth.scss'],
})
export class RequestPasswordComponent extends NbRequestPasswordComponent {
  // Vos autres propriétés et constructeur ici
  
  override requestPass(): void {
    this.submitted = true;
    this.service.requestPassword(this.strategy, this.user).subscribe({
      next: (result) => {
        this.submitted = false;
        if (result.isSuccess()) {
          console.log("'email envoyé")
          this.showMessages.success = ["Nous vous avons envoyé un lien de réinitialisation sur votre adresse mail"];
          this.messages = this.showMessages.success;
        } else {
          // Si l'opération échoue, extrayez le message d'erreur de la réponse de l'API
          const apiErrorMessage = result.getResponse().error.error;
          console.log('Message d\'erreur de l\'API:', apiErrorMessage);
          this.errors = [apiErrorMessage];
          this.showMessages.error = [apiErrorMessage];
        }
      },
      error: (error) => {
        this.submitted = false;
        // Gestion des erreurs HTTP qui ne sont pas capturées par Nebular Auth
        console.error('Erreur lors de la communication avec le serveur:', error);
        const errorMessage = error.error.error || 'Une erreur est survenue lors de la communication avec le serveur.';
        this.errors = [errorMessage];
        this.showMessages.error = this.errors;
      }
    });
  }
  
  
}
