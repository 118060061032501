import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../component/pages/dashboard/product.store';

@Injectable({ providedIn: 'root' })
export class ProductsService {
  constructor(private httpClient: HttpClient) {}

  public getProduct(
    categoryId: number,
    metaCategory: number,
    page: number,
    filter: 'deebr' | 'new' | 'visuel' | null,
    sort: { key: 'stock' | 'ventes' | 'pageviews' | 'score' | null; direction: 'ASC' | 'DESC' },
    searchTerm?: string | null,
    exclude_ids?: string | null,
    list_images_url?: string | null,
    startat?: string | null,
    startatComparator?: string | null,
    endat?: string | null,
    endatComparator?: string | null,
    export_value: boolean = false,
    responseType: 'json' | 'blob' = 'json' 
  ): Observable<any> { 
    // console.log(`Dans getProduct - list_ids: ${list_ids}, list_images_url: ${list_images_url} startat: ${startat}, startatComparator: ${startatComparator}`);
    let params = new HttpParams()
      .set('category', categoryId.toString())
      .set('meta_category', metaCategory.toString())
      .set('page', page.toString());

    if (filter) {
      params = params.set('filter', filter);
    }
    if (sort && sort.key) {
      params = params.set('tri', sort.key).set('ordre', sort.direction);
    }
    if (searchTerm) {
      params = params.set('search', searchTerm);
    }
    if (export_value) {
      params = params.set('export', export_value.toString());
    }
    if (exclude_ids) {
      params = params.set('exclude_ids', exclude_ids);
    }
    if (list_images_url) {
      params = params.set('list_images_url', list_images_url);
    }
    if (startat && startatComparator) {
      params = params.set('startat', startat).set('startat_comparator', startatComparator);
    }
    if (endat && endatComparator) {
      params = params.set('endat', endat).set('endat_comparator', endatComparator);
    }
    return this.httpClient.get<Product[]>(`${environment.backendUrl}/api/products`, {
      params: params,
      responseType: responseType as 'json' // Cast le type de réponse en 'json' pour TypeScript
    });
  }
}


