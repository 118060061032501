import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, ElementRef , TemplateRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { TaskState } from 'src/app/deebr/model/task.state';
import { Email, EmailStore, EmailState } from './email.store';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { GeneratorService } from 'src/app/deebr/service/generator.service';

interface Sorting {
  key: keyof Email | null;
  direction: 'asc' | 'desc' | null;
}

function isValidKey(key: string, obj: Email): boolean {
  return key in obj;
}


@Component({
  templateUrl: './generator-list.component.html',
  styleUrls: ['./generator-list.component.scss']
})
export class GeneratorListComponent implements OnInit, OnDestroy {
  @ViewChild('menuRef') menuElementRef!: ElementRef;
  public sorting$: BehaviorSubject<Sorting> = new BehaviorSubject({
    key: 'heure',
    direction: 'desc',
  } as Sorting);
  @ViewChild('dialog') dialog!: TemplateRef<any>;
  dialogRef: NbDialogRef<any> | undefined = undefined;
  overlayRef: OverlayRef | null = null;
  currentEmail!: any;
  showMenu: boolean = false;
  searchTerm: string = '';
  displayMode: string = 'grid';
  languageList: Array<{ code: string; text: string }> = [];
  newEmailspinner : boolean = false;


  constructor(
    private overlay: Overlay,
    private readonly generatorService: GeneratorService, 
    private readonly emailStore: EmailStore,
    private dialogService: NbDialogService,
    private viewContainerRef: ViewContainerRef,
    ) {}
    

  public emails$: Observable<Email[]> = combineLatest([this.emailStore.selectEmails(), this.sorting$]).pipe(
    map(([emails, sorting]) =>
      emails.sort((a: any, b: any) =>
        sorting.key
          ? sorting.direction === 'asc'
            ? a[sorting.key] < b[sorting.key]
              ? -1
              : 1
            : sorting.direction === 'desc'
            ? a[sorting.key] < b[sorting.key]
              ? 1
              : -1
            : 1
          : 1,
      ),
    ),
  );

  public loading$: Observable<boolean> = this.emailStore.state$.pipe(
    map((state) => state.emails.loading === TaskState.InProgress),
  );
  
  onSearchTermChange(term: string): void {
    this.searchTerm$.next(term);
    console.log("searchTerm a changé :", term); // Ce log devrait maintenant s'afficher à chaque changement
  }
  
  private searchTerm$ = new BehaviorSubject<string>('');
  
  ngOnInit() {
    const savedDisplayMode = sessionStorage.getItem('displayMode');
  if (savedDisplayMode) {
    this.displayMode = savedDisplayMode;
  }
    document.addEventListener('click', this.closeMenu.bind(this));
    this.searchTerm$.asObservable().subscribe(term => {
      console.log("searchTerm a changé :", term);
    });    this.emails$ = combineLatest([
      this.emailStore.selectEmails(),
      this.sorting$,
      this.searchTerm$.asObservable()
    ]).pipe(
      map(([emails, sorting, searchTerm]) => {
        // Filtre les emails en fonction du terme de recherche
        let filteredEmails = searchTerm
          ? emails.filter(email => email.name?.toLowerCase().includes(searchTerm.toLowerCase()))
          : emails;
      
        // Applique le tri
        return filteredEmails.sort((a: Email, b: Email) => {
          if (sorting.key && sorting.key in a && sorting.key in b) {
            const sortValue1 = a[sorting.key as keyof Email];
            const sortValue2 = b[sorting.key as keyof Email];
      
            // Vérifiez si les valeurs sont définies avant de les comparer
            if (sortValue1 !== undefined && sortValue2 !== undefined) {
              if (sorting.direction === 'asc') {
                return sortValue1 < sortValue2 ? -1 : 1;
              } else if (sorting.direction === 'desc') {
                return sortValue1 > sortValue2 ? -1 : 1;
              }
            }
          }
          return 0;
        });
      })
      
    );
  }
  


  ngOnDestroy() {
    document.removeEventListener('click', this.closeMenu.bind(this));
    this.emailStore.resetEmails();
  }

  onSort(): void {
    // Obtenez la valeur actuelle de sorting
    const currentSorting = this.sorting$.getValue();
  
    // Mettez à jour la direction du tri
    this.sorting$.next({
      key: currentSorting.key, // ou une clé de tri spécifique, si nécessaire
      direction: currentSorting.direction === 'asc' ? 'desc' : 'asc' // inverse la direction
    });
  }

  createEmptyEmail() {
    this.newEmailspinner = true; // Affiche le spinner
    console.log(this.newEmailspinner);
  
    this.generatorService.createEmptyEmail().subscribe({
      next: (response) => {
        console.log(response.message); // Affiche : "Generated HTML created successfully"
        console.log(response.id);      // Affiche l'ID, par exemple : 1628
  
        // Rafraîchit les emails après la création
        this.emailStore.resetEmails();
      },
      error: (err) => {
        console.error("Erreur lors de la création de l'email :", err);
      },
      complete: () => {
        this.newEmailspinner = false; // Cache le spinner après la fin de la requête
        console.log(this.newEmailspinner);
      }
    });
  }
  
    

  toggleOptionsMenu(event: MouseEvent, email: any): void {
    event.stopPropagation();
  
    // Si le menu actuel est déjà ouvert pour cet email, fermez-le.
    if (this.showMenu && this.currentEmail === email) {
      this.showMenu = false;
      this.currentEmail = null;
    } else {
      // Sinon, ouvrez le menu pour l'email cliqué.
      this.currentEmail = email;
      this.showMenu = true;
    }
  }
  closeMenu(event: MouseEvent): void {


      this.showMenu = false;

  }
  
  askDeleteEmail(email: Email) {
    this.currentEmail = email;
    this.dialogRef = this.dialogService.open(this.dialog);
  }
  public deleteEmail(email: Email, dialogRef:any): void {
    this.emailStore.deleteEmail({ id: email.id });
    dialogRef.close();
  }
  public copyEmail(email: Email): void {
    this.emailStore.copyEmail({ id: email.id, name:email.name });
  }

  public getLangageCode(dialog: TemplateRef<any>): void {
    this.generatorService.getLangagecode(1).subscribe((data: any) => {
      this.languageList = Object.entries(data).map(([code, text]) => ({ code, text: text as string }));
      this.dialogService.open(dialog, {
        context: 'Choisissez une langue:',
        hasBackdrop: true,
      });
    });
  }
  selectLanguage(email:Email, langCode: string, dialogRef: any): void {
    dialogRef.close();
    // Appel API après sélection de la langue
    console.log("traduction en cours")
    this.emailStore.translateEmail({ id: email.id, langage_code:langCode });
  }

  onDisplayModeChange(): void {
    sessionStorage.setItem('displayMode', this.displayMode);
  }

}
