import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NbAuthComponent } from '@nebular/auth';
import { DeebrLoginComponent } from './deebr/component/auth/deebr-login/deebr-login.component';
import { LogoutComponent } from './deebr/component/auth/logout/logout.component';
import { RequestPasswordComponent } from './deebr/component/auth/request-password/request-password.component';
import { ResetPasswordComponent } from './deebr/component/auth/reset-password/reset-password.component';
import { LoggedInGuard } from './deebr/guard/logged-in.guard';
import { CategoriesResolver } from './deebr/resolver/categories.resolver';
import { UserResolver } from './deebr/resolver/user.resolver';

export const routes: Routes = [
  {
    path: 'pages',
    resolve: {
      categories: CategoriesResolver,
      user: UserResolver
    },
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: 'login',
        component: DeebrLoginComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'request-password',
        component: RequestPasswordComponent,
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
  //enableTracing: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
