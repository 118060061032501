<div *ngIf="show" [class.ouvert]="panelOuvert"
[class.ferme]="!panelOuvert" [style.height]="sidePanelHeight" [style.top]="sidePanelMarginTop" class="side-panel"  >
  
  <button nbButton status="info" class="open-panel-button" [style.left]="panelOuvert ? '-65px' : '-110px'" (click)="togglePanel()">
    {{ panelOuvert ? 'Réduire' : 'Voir la disposition' }}
  </button>

  <!-- Conteneur Swapy : il contiendra tous les slots et items -->
  <div #swapyContainer class="swapy-container">
  <ng-container *ngFor="let item of correctPosition(dashboard); trackBy: trackByItem">
    <div
      class="swapy-slot"
      [attr.data-swapy-slot]="item.ordre_generation"
      

      [style.grid-column-start]="(item.x ?? 0) + 1"
      [style.grid-row-start]="(item.y ?? 0) + 1"
      [style.grid-column-end]="'span ' + (item.cols ?? 1)"
      [style.grid-row-end]="'span ' + (item.rows ?? 1)"
    >



    <div [attr.data-swapy-item]="getSlotName(item)" class="swapy-item">       
      <div class="item-container">
        <div *ngIf="getImageList(item) as imageList" class="image-slider">
          <ng-container *ngIf="preloadImages(imageList)"></ng-container>
             <ng-container *ngIf="!isCarrousel(getCurrentImage(item)); else carrouselTemplate">
              <img *ngIf="imageList.length > 0"
                   [src]="getCurrentImage(item)"
                   class="slider-image"
                   (load)="adjustImageSizeOnLoad($event)" />
            </ng-container>
            <ng-template #carrouselTemplate>
              <img [src]="getFirstImageFromCarrousel(getCurrentImage(item))"
                   class="slider-image"
                   (load)="adjustImageSizeOnLoad($event)" />
              <div class="carrousel-watermark">Carrousel</div>
            </ng-template>
            <button *ngIf="imageList.length > 1"
                    class="slider-arrow left-arrow"
                    (click)="previousImage(item)">
              &#10094;
            </button>
            <button *ngIf="imageList.length > 1"
                    class="slider-arrow right-arrow"
                    (click)="nextImage(item)">
              &#10095;
            </button>
            <div class="slider-dots-container" *ngIf="imageList.length > 0">
              <div class="slider-dots">
                <span *ngFor="let image of imageList; let i = index"
                      class="dot"
                      [class.active]="i === (currentImageIndexes[item.id!] || 0)"
                      (click)="selectImage(item, i)"></span>
                <span [nbSpinner]="loadingImages[item.id!]"
                      nbSpinnerSize="tiny"
                      nbSpinnerStatus="control"
                      class="dot-spinner"></span>
              </div>
            </div>
          </div>

          <i *ngIf="item && item.id && !isGifImage(getCurrentImage(item))"
             class="bi bi-filetype-gif gif-image"
             (click)="createGif($event, item)"
             nbTooltip="Créer un GIF ou un Carrousel avec vos images"
             nbTooltipStatus="warning"></i>
          <nb-icon *ngIf="item && item.id"
                   class="upload-image"
                   icon="camera-outline"
                   (click)="onButtonClick($event, item)"
                   nbTooltip="Remplacer cette image avec une image de votre ordinateur."
                   nbTooltipStatus="info"></nb-icon>
          <nb-icon *ngIf="item && item.id"
                   class="remove-button"
                   icon="trash-2-outline"
                   (click)="removeProduct(item)"
                   nbTooltip="Désélectionner ce produit"
                   nbTooltipStatus="danger"></nb-icon>
          <input id="file-upload-drag-and-drop" type="file" style="display:none;"
                 (click)="setClickedProduct(item)"
                 (change)="onFileSelected($event)">
        </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<app-create-gif-product
  [show]="showCreateGif"
  (closeRequest)="handleCloseRequest()"
  (gifCreated)="handleGifCreated($event)"
  (carrouselCreated)="handleCarrouselCreated($event)">
</app-create-gif-product>

<ng-template #dialogUploadImage let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="text-center">
      {{ errorMessage }}
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
