<h1 id="title" class="title">Mot de passe oublié</h1>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b></b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b></b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label for="input-username">Entrez votre nom d'utilisateur :</label>
    <input
      nbInput
      [(ngModel)]="user.username"
      #username="ngModel"
      id="input-username"
      name="username"
      placeholder="Nom d'utilisateur"
      autofocus
      fullWidth
      fieldSize="large"
      [status]="username.dirty ? (username.invalid ? 'danger' : 'success') : 'basic'"
      required
    />
    <ng-container *ngIf="username.invalid && username.touched">
      <p class="caption status-danger" *ngIf="username.errors && username.errors['required']">
        Un nom d'utilisateur est requis !
      </p>
    </ng-container>
  </div>
  
  <div class="form-control-group">
    <label for="input-email">Entrez votre adresse mail :</label>
    <input
      nbInput
      [(ngModel)]="user.email"
      #email="ngModel"
      id="input-email"
      name="email"
      pattern=".+@.+\..+"
      placeholder="adresse mail"
      autofocus
      fullWidth
      fieldSize="large"
      [status]="email.dirty ? (email.invalid ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.email.required')"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null"
    />
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors && email.errors['required']">
        Une adresse mail est requise !
      </p>
      <p class="caption status-danger" *ngIf="email.errors && email.errors['pattern']">
        L'adresse mail n'est pas valide !
      </p>
    </ng-container>
  </div>

  <button
    nbButton
    fullWidth
    status="danger"
    size="large"
    [disabled]="submitted || !requestPassForm.valid"
    [class.btn-pulse]="submitted"
  >
    Envoyer
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Retour à la page de connexion</a></p>
</section>
