<div class="row">
  <div class="col-2">
    <nb-card>
      <nb-card-body>
        <form [formGroup]="dayNumberAndMetaCateforyForm">
          <select
            placeholder="Catégorie"
            [formControlName]="'metaCategory'"
            class="form-control"
            style="width: 100%; cursor: pointer"
          >
            <option *ngFor="let meta of metaCategories" value="{{ meta.id }}">{{ meta.label }}</option>
          </select>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div class="row">
  <div class="col-4">
    <!-- # of days choosing form-->
    <nb-card [nbSpinner]="dayNumberChanging" nbSpinnerStatus="primary">
      <nb-card-body>
        <form class="row" [formGroup]="dayNumberAndMetaCateforyForm" (ngSubmit)="numberOfDayFormSubmitted()">
          <div class="col-8">
            <label for=""
              >Nombre de jours : {{ numberOfDays }}
              <span *ngIf="showPending" class="pending">{{ pendingDayNumber }}</span></label
            >
            <input
              type="range"
              class="custom-range"
              name="dayNumber"
              min="1"
              max="30"
              step="1"
              [formControlName]="'dayNumber'"
            />
          </div>
          <div class="col-3 pt-2">
            <button type="submit " size="small" class="mt-3 btn btn-primary btn-sm">Changer</button>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
    <nb-card [nbSpinner]="dayNumberChanging" nbSpinnerStatus="primary">
      <nb-card-header>{{ replaceVenteWithDevis ? 'Devis' : 'Ventes' }} des {{ numberOfDays }} prochains jours vs N-1</nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-4 d-flex flex-column justify-content-around">
            <div class="text-center font-weight-bold">
              {{ globalForecast | number: '.0-0' }}
            </div>
          </div>
          <div class="col-8 d-flex flex-column justify-content-around">
            <div class="text-center font-weight-bold">
              <span [ngClass]="{ 'evol-plus': globalEvol > 0, 'evol-moins': globalEvol < 0 }">
                {{ globalEvol | number: '.0-0' }} %
              </span>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
    <nb-card [nbSpinner]="dayNumberChanging" nbSpinnerStatus="primary">
      <nb-card-header>Prévisions des {{ replaceVenteWithDevis ? 'devis' : 'ventes' }}</nb-card-header>
      <nb-card-body>
        <canvas
          baseChart
          [data]="salesChartData"
          [options]="salesChartOptions"
          [type]="'line'"
          [legend]="true"
          id="curve-chart-global"
        >
        </canvas>
      </nb-card-body>
    </nb-card>

      
  </div>
  <div class="col-8">
    <nb-card [nbSpinner]="dayNumberChanging" nbSpinnerStatus="primary">
      <nb-card-header>Evolution des différentes catégories</nb-card-header>
      <nb-card-body>
          <canvas id="bubbleChartId"
              baseChart
              [type]="'bubble'"
              [data]= "sellingBubbleChartData"
              [options] = "bubbleChartOptions"
              (mouseenter)="afficherTooltip()" (mouseleave)="cacherTooltip()"
              >
           </canvas>
           <div id="tooltipGraphique" class="tooltip-custom">Utilisez Ctrl + molette pour le zoom</div>
      </nb-card-body>

  </nb-card>

  </div>
</div>

<div class="row">
  <div class="col-12">
    <nb-card [nbSpinner]="dayNumberChanging" nbSpinnerStatus="primary">
      <nb-card-header>Données de {{ replaceVenteWithDevis ? 'devis' : 'ventes' }}</nb-card-header>
      <nb-card-body>
        <div class="row mt-2">
          <div class="col-3">
            <form class="row mt-2 mb-2 ml-2" [formGroup]="searchForm">
              <div class="col">
                <input
                  type="text"
                  placeholder="Rechercher"
                  class="form-control search-field"
                  name="search"
                  [formControlName]="'search'"
                />
              </div>
            </form>
          </div>
        </div>
        <app-table
          [metaCategory]="metaCategory"
          [dicoEntries]="dicoEntries"
          [stockOrDispo]="stockOrDispo"
          [numberOfDays]="numberOfDays"
          [forecastDates]="forecastDates"
          [searchString]="searchString"
          [canShowProduct]="true"
          [templates]="(templates$ | async) ?? []"
        ></app-table>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <nb-card [nbSpinner]="dayNumberChanging" nbSpinnerStatus="primary">
      <nb-card-header>Heatmap</nb-card-header>
      <nb-card-body>
        <app-treemap  [entries]="dicoEntries"  [update]="dataRefresh" ></app-treemap>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div id="tooltip"></div>