import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScrappedImagesService {
    private productsSource = new BehaviorSubject<Map<number | null, any>>(new Map());
    currentProducts = this.productsSource.asObservable();

    constructor() { }

    updateProducts(product: { id: number | null; urls: any; }) {
        const currentProducts = this.productsSource.value;
        currentProducts.set(product.id, product.urls);
        this.productsSource.next(currentProducts);
    }

    clearProducts() {
        this.productsSource.next(new Map());
    }
}