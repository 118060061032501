<div class="app-table" *ngrxLet="showGenerator$ as showGenerator">
  <table class="table" *ngrxLet="products$ as products">
    <thead class="header-table">
      <tr>
        <th class="col-segment" *ngIf="segments"></th>
        <th scope="col" class="col-category">Catégorie</th>
        <th class="sortable-table-head col-real" scope="col" [class]="{
            'sorting-asc': sorting.key === 'real' && sorting.direction === 'asc',
            'sorting-desc': sorting.key === 'real' && sorting.direction === 'desc'
          }" (click)="changeSorting('real')">
          {{ replaceVenteWithDevis ? 'Devis' : 'Ventes' }} {{ numberOfDays }} derniers jours
        </th>
        <th class="sortable-table-head col-real" [class]="{
            'sorting-asc': sorting.key === 'realNMinusOne' && sorting.direction === 'asc',
            'sorting-desc': sorting.key === 'realNMinusOne' && sorting.direction === 'desc'
          }" (click)="changeSorting('realNMinusOne')">
          {{ replaceVenteWithDevis ? 'Devis' : 'Ventes' }} N-1
        </th>
        <th class="sortable-table-head col-real" [class]="{
            'sorting-asc': sorting.key === 'evolution' && sorting.direction === 'asc',
            'sorting-desc': sorting.key === 'evolution' && sorting.direction === 'desc'
          }" (click)="changeSorting('evolution')">
          Evolution vs. {{ numberOfDays }} derniers jours
        </th>
        <th class="sortable-table-head col-evolution" (click)="changeSorting('evolutionNMinusOne')" [class]="{
            'sorting-asc': sorting.key === 'evolutionNMinusOne' && sorting.direction === 'asc',
            'sorting-desc': sorting.key === 'evolutionNMinusOne' && sorting.direction === 'desc'
          }">
          Evolution vs. N-1
        </th>
        <th class="sortable-table-head col-stock-dispo" scope="col" (click)="changeSorting('stock')"
          *ngIf="stockOrDispo === 'stock'" [class]="{
            'sorting-asc': sorting.key === 'stock' && sorting.direction === 'asc',
            'sorting-desc': sorting.key === 'stock' && sorting.direction === 'desc'
          }">
          Stock
        </th>
        <th class="sortable-table-head col-stock-dispo" scope="col" *ngIf="stockOrDispo === 'dispo'"
          (click)="changeSorting('dispo')" [class]="{
            'sorting-asc': sorting.key === 'dispo' && sorting.direction === 'asc',
            'sorting-desc': sorting.key === 'dispo' && sorting.direction === 'desc'
          }">
          Dispo
        </th>
        <th class="sortable-table-head col-forecast" scope="col" (click)="changeSorting('forecast')" [class]="{
            'sorting-asc': sorting.key === 'forecast' && sorting.direction === 'asc',
            'sorting-desc': sorting.key === 'forecast' && sorting.direction === 'desc'
          }">
          {{ replaceVenteWithDevis ? 'Devis' : 'Ventes' }} des {{ numberOfDays }} prochains jours
        </th>
        <th scope="col" class="col-chart">{{ numberOfDays }} prochains jours</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let entry of lines$ | async">
        <tr (click)="toggleProducts(entry)" class="row-toggle" [class.active]="isActiveEntry(entry)">
          <td class="col-segment" *ngIf="segments">
            <span class="icon-container">
              <nb-icon *ngIf="entry.isSegment" icon="checkmark-circle-outline" status="danger"></nb-icon>
            </span>
          </td>
          <td class="col-category">{{ entry.label }}</td>
          <td class="col-real">{{ entry.real }}</td>
          <td class="col-real-1">{{ entry.realNMinusOne }}</td>
          <td class="col-real-evolution">
            <span [ngClass]="{
                'evol-plus': !!entry.evolution && entry.evolution > 0,
                'evol-moins': !!entry.evolution && entry.evolution < 0
              }">
              {{ entry.evolution | number: '.0-2' }} %
            </span>
          </td>
          <td class="col-evolution-1">
            <span [ngClass]="{
                'evol-plus': !!entry.evolutionNMinusOne && entry.evolutionNMinusOne > 0,
                'evol-moins': !!entry.evolutionNMinusOne && entry.evolutionNMinusOne < 0
              }">
              {{ entry.evolutionNMinusOne | number: '.0-2' }} %
            </span>
          </td>
          <td class="col-stock-dispo" *ngIf="stockOrDispo === 'stock'">
            {{ entry.stock | number: '.0-2' }}
          </td>
          <td class="col-stock-dispo" *ngIf="stockOrDispo === 'dispo'">{{ entry.dispo | number: '.0-0' }} %</td>
          <td class="col-forecast">
            {{ entry.forecast | number: '.0-0' }}
          </td>
          <td class="col-chart">
            <canvas class="in-array-chart" style="height: 50px; width: 100px;" baseChart [data]="entry.data"
              [options]="smallSalesChartOptions" [type]="'line'" [legend]="false" id="curve-small-chart-{{ entry.id }}">
            </canvas>
          </td>
        </tr>
        <tr class="row-products" *ngIf="entry.showProducts">
          <td [attr.colspan]="segments ? 9 : 8" *ngrxLet="isSaving$ as isSaving">
            <header class="row justify-content-between" [formGroup]="forms[entry.id]">
              <div class="actions col">
                <div class="header-product">
                  <div>
                  <nb-form-field>
                    <nb-icon nbPrefix icon="funnel-outline"></nb-icon>
                    <nb-select [(ngModel)]="activeFilter" (ngModelChange)="filterBy(entry.id, $event)"
                      [ngModelOptions]="{ standalone: true }" [placeholder]="'Tous'" [size]="'small'"  multiple>
                      <!-- Taille "small" -->
                      <nb-option [value]="null">Tous</nb-option>
                      <nb-option [value]="'deebr'">deebr</nb-option>
                      <nb-option [value]="'new'">new</nb-option>
                      <nb-option
                        [value]="'visuel'">{{ (showPerfectstayElement$ | async) ? 'NPRLG' : 'visuel' }}</nb-option>
                    </nb-select>
                  </nb-form-field>
                </div>
                <div>
                  <nb-form-field>
                    <nb-icon nbPrefix icon="arrow-downward-outline"></nb-icon>
                    <nb-select [(ngModel)]="sortKey" (ngModelChange)="sortBy(entry.id, $event)"
                      [ngModelOptions]="{ standalone: true }" [placeholder]="'Trier par :'" [size]="'small'">
                      <nb-option [value]="null" disabled>Trier par :</nb-option>
                      <nb-option [value]="'ventes'">{{ replaceVenteWithDevis ? 'Devis' : 'Ventes' }}</nb-option>
                      <nb-option
                        *ngIf="products[metaCategory.id]?.[entry.id]?.entities?.[0]?.score !== undefined && products[metaCategory.id]?.[entry.id]?.entities?.[0]?.score !== null"
                        [value]="'score'">Score</nb-option>
                      <nb-option [value]="'stock'">Stocks</nb-option>
                      <nb-option
                        *ngIf="products[metaCategory.id]?.[entry.id]?.entities?.[0]?.pageviews !== undefined && products[metaCategory.id]?.[entry.id]?.entities?.[0]?.pageviews !== null"
                        [value]="'pageviews'">Pages vues</nb-option>
                    </nb-select>
                  </nb-form-field>
                </div>
                <div class="textarea-container">
                  <textarea [(ngModel)]="searchTerm" class="form-control textarea-resize" [ngModelOptions]="{standalone: true}" 
                  rows="1" (keyup)="onSearch(entry.id, $event)" 
                  (blur)="onBlur()"
                  placeholder="Recherchez vos produits...      séparés par des virgules ou ou des retours à la ligne"></textarea>
                </div>
                <div class="textarea-container-small" *ngIf="showPerfectstayElement$ | async">
                  <textarea [(ngModel)]="exclude_ids" class="form-control textarea-resize-small" [ngModelOptions]="{standalone: true}" 
                  rows="1" (keyup)="onIdsExclusion(entry.id, $event)" 
                  (blur)="onBlur()"
                  placeholder="Ids à exclure      séparés par des virgules ou ou des retours à la ligne"></textarea>
                </div>
                <div>
                  <button nbButton size="tiny" style="font-weight: normal;margin-left: 5px;" (click)="resetFilter(entry.id)" [status]="hasfilter(entry.id) ? 'info' : 'basic'">
                    Réinitialiser filtres
                  </button>

                </div>
              </div>
                <div class="header-product" *ngIf="showPerfectstayElement$ | async">
                  <nb-select [size]="'small'" [(ngModel)]="startatOperator"
                    (selectedChange)="handleStartComparatorChange($event)" [placeholder]="'Start Date <='" [ngModelOptions]="{standalone: true}">
                    <nb-option value="<=">Start Date <=</nb-option>
                    <nb-option value="<">Start Date <</nb-option>
                    <nb-option value="=">Start Date =</nb-option>
                    <nb-option value=">">Start Date ></nb-option>
                    <nb-option value=">=">Start Date >=</nb-option>
                  </nb-select>
                  <input nbInput [(ngModel)]="startDate" placeholder="Start Date" [nbDatepicker]="startatTimePicker"
                    (input)="handleStartDateInputChange()" class="custom-input-width" [ngModelOptions]="{standalone: true}">
                  <nb-datepicker #startatTimePicker (dateChange)="handleStartDateChange($event)"></nb-datepicker>

                  <nb-select [size]="'small'" [(ngModel)]="endatOperator"
                    (selectedChange)="handleEndComparatorChange($event)" [placeholder]="'End Date >='" [ngModelOptions]="{standalone: true}">
                    <nb-option value=">=">End Date >=</nb-option>
                    <nb-option value=">">End Date ></nb-option>
                    <nb-option value="=">End Date =</nb-option>
                    <nb-option value="<">End Date <</nb-option>
                    <nb-option value="<=">End Date <=</nb-option>
                  </nb-select>
                  <input nbInput [(ngModel)]="endDate" placeholder="End Date" [nbDatepicker]="endatTimePicker"
                    (input)="handleEndDateInputChange()" class="custom-input-width" [ngModelOptions]="{standalone: true}">
                  <nb-datepicker #endatTimePicker (dateChange)="handleEndDateChange($event)"></nb-datepicker>
                </div>
                <div class="header-product">
                  <button nbButton size="tiny" style="font-weight: normal;" (click)="clearSelection(entry.id)" [status]="hasSelectedProducts(entry.id) ? 'info' : 'basic'" *ngIf!="showPerfectstayElement$ | async">
                    Réinitialiser sélection
                  </button>
                  <button nbButton size="tiny" style="font-weight: normal;text-align:right" (click)="exportProducts(entry.id)">
                    Exporter les produits
                  </button>
                </div>

                  <div class="header-product" *ngIf="showGenerator" style="margin-left: auto;">
                    <input type="text" class="form-control" placeholder="Thème" formControlName="theme" />
                    <app-template-modal [templates]="templates" (templateSelected)="onTemplateSelect($event)"
                      #templateModal></app-template-modal>
                  <div>
                    <div class="template-select" *ngIf="selectedTemplateName !== null">Template sélectionné :
                      <strong>{{
                        selectedTemplateName }} </strong>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                      <button nbButton appearance="filled" [status]="selectedTemplateId !== null ? 'basic' : 'danger'"
                        (click)="openModal()" style="margin-right: 10px;">
                        {{ selectedTemplateId !== null ? 'Changer de template' : 'Choisir un template' }}
                      </button>

                      <button nbButton appearance="filled" status="danger" (click)="validateSelection(entry.id)" [disabled]="!selectedTemplateId ||
                                                                                            selectedProductsCount !== nbProducts[selectedTemplateId] ||
                                                                                            isSaving" [nbSpinner]="isSaving"
                        nbSpinnerStatus="danger" style="text-transform: none;" [nbTooltip]="getTooltipText(entry)"
                        [nbTooltipDisabled]="tooltipDisabled(entry)">
                        Générer e-mail
                        <ng-container *ngIf="selectedTemplateId !== null">
                          ({{ selectedProductsCount }}/{{ nbProducts[selectedTemplateId] }})
                        </ng-container>
                      </button>
                    </div>
                  </div>
                  </div>
                  <app-drag-drop-products
                    [categoryId]="entry.id"
                    [show]="selectedProductsCount > 0 && showFromMatrice"
                    [templateId]="selectedTemplateId ?? 0" 
                    [productsDisposition]="productsDisposition[entry.id]"
                    [selectedProducts]="selectionDragAndDrop"
                    [resetIndex]="resetProductIndex" 
                    (orderChanged)="handleOrderChanges($event)">
                  </app-drag-drop-products>
                  <app-image-choice [show]="showChoice" (imageSelected)="onImageSelected($event, entry.id)"
                    (closeRequest)="handleCloseRequest()"></app-image-choice>

              </div>
            </header>
            <div class="products-container"
              [nbSpinner]="products[metaCategory.id]?.[entry.id]?.loading === TaskState.InProgress"
              nbSpinnerStatus="primary">
              <ul *ngIf="products[metaCategory.id]?.[entry.id]?.entities?.length || 0 > 0">
                <li *ngFor="let product of products[metaCategory.id][entry.id].entities">

                  <div class="image-container" (mouseenter)="hoveringProduct = product"
                    (mouseleave)="hoveringProduct = null" (click)="toggleProductSelection(entry.id, product)" [class.selected]="isProductSelected(product.id)">
                    <img [src]="getImageSrc(product)" />
                    <div class="flags">
                      <div class="flag deebr" *ngIf="product.deebr === 1">DEEBR</div>
                      <div class="flag new" *ngIf="product.new === 1">NEW</div>
                      <div class="flag visuel" *ngIf="product.visuel === 1">{{ (showPerfectstayElement$ | async) ? 'NPRLG' : 'VISUEL' }}</div>
                    </div>
                    <nb-checkbox class="checkbox" status="danger" [checked]="isProductSelected(product.id)"
                      (click)="toggleProductSelection(entry.id, product)"
                      (checkedChange)="toggleProductSelection(entry.id, product)"
                      *ngIf="selectedProductsCount < maxItemCount || isProductSelected(product.id)">
                    </nb-checkbox>
                    <span *ngIf="isProductSelected(product.id)" class="selection-number">
                        {{ getSelectionNumber(entry.id, product.id) }}
                    </span>
                  </div>

                  <p class="sales">{{ replaceVenteWithDevis ? 'Devis' : 'Ventes' }} : {{ product.quantite_vendue }}</p>
                  <p class="pageviews" *ngIf="product.pageviews !== undefined">Pages vues : {{
                    formatNumber(product.pageviews) }}</p>
                  <p class="score" *ngIf="product.score !== undefined">Score : {{
                    formatNumberToTwoDecimals(product.score) }}</p>
                  <p class="stock">Stock : {{ product.stock }}</p>
                  <a [href]="product.url" target="_blank">
                    <p class="name" [title]="product.name">{{ product.name }}</p>
                  </a>
                </li>
              </ul>
            </div>
            <footer class="row">
              <div class="col">
                <button *ngIf="products[metaCategory.id]?.[entry.id]?.lastPage === false" nbButton appearance="filled"
                  status="danger" (click)="loadMore(entry.id)"
                  [disabled]="products[metaCategory.id]?.[entry.id]?.isLoading || isSaving">
                  Voir plus
                </button>
              </div>
            </footer>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #dialogSelectTemplate let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>Veuillez sélectionner un template avant de choisir des produits.</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="success" (click)="closeDialog()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogNoPlace let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>Aucun emplacement disponible pour ajouter ce produit.</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="success" (click)="closeDialog()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogErrorScrap let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="text-center">Une erreur s'est produite lors de la recherche d'image(s) d'un de vos produits. <br>Nous vous invitons à vous rapprocher de votre contact Deebr si l'erreur se reproduit</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>