<div class="container-fluid">
  <div class="container-fluid">
    <div class="header-container">
      <div class="left-section">
        <h2><strong>Emails</strong></h2>
        <button nbButton outline [nbSpinner]="newEmailspinner" status="danger" style="font-weight: normal;text-transform: none;margin-left: 10px;height: 38px;" (click)="createEmptyEmail()">
          Nouvel email
        </button>
      </div>
      <div class="right-section">
        <input type="text" [(ngModel)]="searchTerm" (ngModelChange)="onSearchTermChange($event)" placeholder="Rechercher par nom" class="search-input">
        <select [(ngModel)]="displayMode" (change)="onDisplayModeChange()" class="form-control select-color">
          <option value="grid">Grille</option>
          <option value="smallGrid">Petite grille</option>
          <option value="list">Liste</option>
        </select>        
        <button nbButton status="outline" style="font-weight: normal;text-transform: none;margin-left: 10px;height: 38px;" (click)="onSort()">
          Date de création
          <i [class]="sorting$.value.direction === 'asc' ? 'bi bi-arrow-up' : 'bi bi-arrow-down'"></i>
        </button>  

      </div>
    </div>
    <hr class="header-divider" />
    <div class="campaign-grid" [ngClass]="{'small-grid': displayMode === 'smallGrid', 'list-view': displayMode === 'list'}">
      <div class="campaign-menu">
        <div class="campaign-item-details">
          <div class="campaign-title"><strong>Campagne</strong></div>
          <div class="campaign-title"><strong>Date</strong></div>
          <div class="campaign-title"><strong>Objet</strong></div>
        </div>
      </div>
      <div *ngFor="let email of emails$ | async" class="campaign-item" [routerLink]="['/pages/generator/', email.id]">
        <img [src]="email.preview_img_url" alt="{{email.name}}" class="campaign-image">
        <div class="campaign-item-details">
          <div class="campaign-title" title="{{ email.name }}">{{ email.name }}</div>
          <div class="campaign-date">{{ email.heure | date: 'medium' }}</div>
          <div class="campaign-objet">{{ email.object}}</div>
        </div>
        <div class="campaign-item-info">
          <button nbButton class="menu-button" (click)="toggleOptionsMenu($event, email)">
            <i class="bi bi-three-dots"></i>
          </button>
          <div *ngIf="showMenu && currentEmail === email" class="menu-options" (click)="$event.stopPropagation()">
            <a class="dropdown-item" [routerLink]="['/pages/generator/', email.id]"><i class="bi-pencil"></i> Editer</a>
            <button class="dropdown-item" (click)="copyEmail(email)"><i class="bi bi-copy"></i> Dupliquer</button>
            <button class="dropdown-item" (click)="getLangageCode(languageDialog)"><i class="bi bi-translate"></i> Traduire</button>
            <button class="dropdown-item" (click)="askDeleteEmail(email)"><i class="bi-trash"></i> Supprimer</button>
          </div>
        </div>
      </div>
  </div>
  <ng-template #dialog let-data let-ref="dialogRef">
    <nb-card>
      <nb-card-body>Êtes-vous sûr de vouloir supprimer cet email ?</nb-card-body>
      <nb-card-footer>
        <div class="row">
          <div class="col text-center">
            <button nbButton status="success" (click)="deleteEmail(currentEmail, dialogRef)">Oui</button>
          </div>
          <div class="col text-center">
            <button nbButton status="danger" (click)="ref.close()">Non</button>
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  </ng-template>
  <ng-template #languageDialog let-ref="dialogRef">
    <nb-card>
      <nb-card-header>Choisissez la langue de traduction:</nb-card-header>
      <nb-card-body>
        <ul class="no-bullets three-per-row">
          <li *ngFor="let lang of languageList">
            <button nbButton outline status="info" size="small" (click)="selectLanguage(currentEmail, lang.code, ref)">{{ lang.text }}</button>
          </li>
        </ul>
      </nb-card-body>        
    </nb-card>
  </ng-template>
</div>