<nb-card>
    <nb-card-header>Prévisualisation de l'image</nb-card-header>
    <nb-card-body>
      <div class="full-container">
        <img #image [src]="imageUrl" alt="Image preview" class="image-fit" (load)="calculateFrameSize()">
        <div class="frame" [ngStyle]="frameStyles"></div>
        <div class="overlay"></div>
      </div>
      <div *ngIf="!progressStarted" class="text">
        <p><br>Votre image sera automatiquement recadrée. <br>Nous prenons en charge uniquement les formats 5:3 pour l'animation</p>
      </div>
      <div *ngIf="progressStarted && progress < 100 && !errorMessage" class="progress-container">
        <br>
        <p>Animation en cours : {{progress.toFixed(1)}}%</p>
        <nb-progress-bar [value]="progress" status="info"></nb-progress-bar>
      </div>      
      <div *ngIf="progress >= 100">
        <p>Animation terminée !</p>
        <a *ngIf="gifUrl" [href]="gifUrl" target="_blank">Voir la vidéo</a>
      </div>
      <div *ngIf="errorMessage" class="error-message">
        <p><br>{{ errorMessage }}</p><br>
        <p>Nous vous invitons à vous rapprocher de votre contact Deebr si l'erreur se reproduit</p>
        <button nbButton (click)="onCancel()">Fermer</button>
      </div>
    </nb-card-body>
    <nb-card-footer class="footer-buttons">
        <button nbButton (click)="onCancel()" *ngIf="!progressStarted">Annuler</button>
        <button nbButton appearance="filled" status="danger" (click)="onValidate()" *ngIf="!progressStarted">Animer</button>
      </nb-card-footer>      
  </nb-card>
  <ng-template #dialogErrorAnimate let-data let-ref="dialogRef">
    <nb-card>
      <nb-card-body class="text-center">Une erreur s'est produite lors de l'animation de votre image. <br>Nous vous invitons à vous rapprocher de votre contact Deebr si l'erreur se reproduit</nb-card-body>
      <nb-card-footer>
        <div class="row">
          <div class="col text-center">
            <button nbButton status="danger" (click)="closeDialogError()">OK</button>
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  </ng-template>
  