<div>
  <strong>Recommandation produit</strong><br><br>

  <div class="input-container">
    <textarea nbInput id="productIds" [(ngModel)]="productIds" placeholder="Insérer vos ids produits (un par ligne ou séparé par des virgules)" rows="25"></textarea>

    <!-- Right side container for campaign name and button -->
    <div class="right-container">
      <input nbInput id="campaignName" [(ngModel)]="campaignName" placeholder="Nom de la campagne" type="text"/>

      <button nbButton 
              [nbSpinner]="isCalling" 
              appearance="filled" 
              status="danger" 
              (click)="generateSegment()" 
              [disabled]="isInputEmpty() || isCalling">Générer les recommandations
      </button>
    </div>
    <p *ngIf="isCalling">Le fichier est en cours de génération, il sera téléchargé automatiquement d'ici quelques secondes...<br>Veuillez ne pas fermer cette page.</p>
  </div>


  <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
</div>
