import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  public downloadFile(data: Blob | string | null, type: string | null, filename?: string) {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([data ?? ''], { type: type ?? '' }));
    if (filename) {
      a.download = filename;
    }
    a.click();
  }

  public saveAsCsv(data: string, filename?: string | undefined): void {
    return saveAs(new Blob(['\uFEFF' + data], { type: 'text/csv;charset=utf-8' }), filename);
  }
  
  public saveAs(data: string | Blob, filename: string): void {
  if (data instanceof Blob) {
    saveAs(data, filename);
  } else {
    saveAs(new Blob([data]), filename);
  }
}
}
