import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { TokenService } from '../service/token.service';
import { Router } from '@angular/router';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.tokenService.currentToken?.getPayload()?.access_token;

    if (token && !this.isRefreshOrLoginRequest(req)) {
      return this.tokenService.verify().pipe(
        mergeMap((isValid: boolean) => {
          if (isValid) {
            const cloned = req.clone({
              headers: req.headers.set('Authorization', 'Bearer ' + token),
            });
            return next.handle(cloned);
          } else {
            console.log("coucou 1")
            this.router.navigate(['/auth/login']);
            return next.handle(req); // Continue with the original request without token
          }
        }),
        catchError((error) => {
          console.log("coucou 2")
          //this.router.navigate(['/auth/login']);
          return throwError(error);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private isRefreshOrLoginRequest(req: HttpRequest<any>): boolean {
    return req.url.endsWith('/api/token/refresh/') || req.url.endsWith('/api/token/') || req.url.endsWith('/api/token/verify/');
  }
}