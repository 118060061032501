import { Component, OnInit, OnDestroy } from '@angular/core';
import { Toast, ToastService } from './deebr/service/toast.service';
import { EmailState, EmailStore, Generate } from './deebr/component/pages/generator/email.store';
import { interval, map, withLatestFrom } from 'rxjs';
import { Router } from '@angular/router';
import { Event } from './deebr/service/event.service';
import { Subscription } from 'rxjs';
import { NbTokenService } from '@nebular/auth';
import { TokenService } from 'src/app/deebr/service/token.service';
import { CategoryService } from 'src/app/deebr/service/category.service';
import { ReportStore } from './deebr/component/pages/report/report.store';

@Component({
  selector: 'deebr-app',
  template: `
    <router-outlet></router-outlet>

    <div class="toast-container">
      <ngb-toast *ngFor="let toast of toasts" [class]="toast.status" [autohide]="toast.autohide" [delay]="5000">
        <div class="row align-items-center">
          <div class="icon col-is-auto">
            <i class="bi-hourglass-split" *ngIf="toast.status === 'pending'"></i>
            <i class="bi-check-circle-fill" *ngIf="toast.status === 'success'"></i>
            <i class="bi-x-circle-fill" *ngIf="toast.status === 'error'"></i>
          </div>
          <div class="col">
            <div>
              <strong>{{ toast.title }}</strong>
            </div>
            <div [innerHTML]="toast.message"></div>
            <ng-container *ngIf="toast.id">
              <div *ngIf="toast.status === 'pending' && (toast.progress || 0) < 100">
                <ngb-progressbar type="secondary" [value]="toast.progress || 0"></ngb-progressbar>
              </div>
              <div *ngIf="toast.status === 'success' && toast.progress === 100">
                <a (click)="gotoEmails(toast)">Voir les emails</a>
              </div>
            </ng-container>
          </div>
          <div class="close col-is-auto">
            <i class="bi-x-lg" (click)="removeToast(toast)"></i>
          </div>
        </div>
      </ngb-toast>
    </div>
    <app-image-modal [show]="showModal" [imageUrl]="imageUrl" [message]="imageMessage"></app-image-modal>
  `,
  styles: [
    `
      .toast-container {
        z-index: 1200;
        position: fixed;
        top: 0;
        right: 0;
        padding: 15px;
      }

      ::ng-deep .toast {
        overflow: hidden;

        .toast-body {
          border-width: 5px 0 0;
          border-style: solid;

          a {
            color: var(--link-text-color) !important;
            text-decoration: var(--link-text-decoration) !important;
            cursor: pointer !important;
          }

          .icon,
          .close {
            font-size: 20px;
          }

          .close {
            cursor: pointer;
          }
        }

        &.pending {
          .toast-body {
            border-color: #35a2eb;

            .icon {
              color: #35a2eb;
            }
          }
        }

        &.success {
          .toast-body {
            border-color: #00c455;

            .icon {
              color: #00c455;
            }
          }
        }

        &.error {
          .toast-body {
            border-color: #f13426;

            .icon {
              color: #f13426;
            }
          }
        }
      }
    `,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  showModal: boolean = false;
  imageUrl: string = '';
  imageMessage: string = '';
  taskId: string = '';
  imageToastSubscription: Subscription;

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private toastService: ToastService,
    private emailStore: EmailStore,
    private Event: Event,
    private nbTokenService: NbTokenService,
    private categoryService: CategoryService,
    private reportStore: ReportStore
  ) {
    this.imageToastSubscription = this.Event.imagemodalEvent$.subscribe((toast: { imageUrl: string, message: string, taskId: string }) => {
      if (toast) {
        this.imageUrl = toast.imageUrl;
        this.imageMessage = toast.message;
        this.showModal = true;
        this.taskId = toast.taskId;
        this.imageToastSubscription.unsubscribe();
      }
    });
  }

  get toasts(): Toast[] {
    return this.toastService.toasts;
  }

  ngOnInit(): void {
    interval(1000)
      .pipe(
        withLatestFrom(this.emailStore.selectProgress()),
        map(([_value, progress]) => progress)
      )
      .subscribe((progress: EmailState['progress']) => {
        Object.keys(progress).forEach((taskId: Generate['task_id']) => {
          if (0 <= progress[taskId].progress && progress[taskId].progress < 100 && !progress[taskId].images && !progress[taskId].imagesChoice) {
            this.emailStore.progress({ taskId });
          }
        });
      });

      window.addEventListener('storage', this.handleStorageEvent.bind(this));
      window.addEventListener('customEvent', this.handleCustomEvent.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.handleStorageEvent.bind(this));
  }

  removeToast(toast: Toast): void {
    this.toastService.remove(toast);
  }

  gotoEmails(toast: Toast): void {
    this.toastService.remove(toast);
    this.router.navigate(['pages', 'generator']);
  }

  private handleStorageEvent(event: StorageEvent): void {
    console.log('Storage event detected:', event);
    if (event.key === 'logoutEvent') {
      this.handleLogout();
    }
    if (event.key === 'loginEvent') {
      console.log('Login event detected');
      this.handleLogin();
    }
  }

  private handleCustomEvent(): void {
    console.log('Custom event detected');
    this.handleLogout();
  }

  private handleLogout(): void {
    this.nbTokenService.clear().subscribe(() => {
      this.tokenService.clearToken();
      this.categoryService.clear();
      this.router.navigate(['/auth/login']);
      this.reportStore.resetState();
      this.emailStore.resetState();
      localStorage.removeItem('logoutEvent'); // Clear the logout event
    });
  }

  private handleLogin(): void {
    this.tokenService.verify().subscribe((isAuthenticated) => {
      console.log('Verifying token');
      if (isAuthenticated) {
        console.log('Token is valid, navigating to dashboard');
        this.router.navigate(['/pages/dashboard']); // Remplacez par votre chemin de redirection
      } else {
        console.log('Token is not valid');
      }
    });
  }
}