import { Component, Input, Output, EventEmitter, ChangeDetectorRef  } from '@angular/core';
import { Template } from '../pages/generator/email.store';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss']
})
export class TemplateModalComponent {
  @Input() templates: Template[] = [];
  @Output() templateSelected = new EventEmitter<Template>();
  constructor(private cdRef: ChangeDetectorRef) {}
  isOpen = false;
  
  selectTemplate(template: Template) {
    this.templateSelected.emit(template);
    this.isOpen = false;
  }




  open() {
    this.isOpen = true;
    this.cdRef.detectChanges();
  }

  close() {
    this.isOpen = false;
  }
}
