import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CampaignSegment } from '../../model/campaign-segment.model';

@Component({
  selector: '[campaignSegmentEditor]',
  templateUrl: './campaign-segment-editor.component.html',
  styleUrls: ['./campaign-segment-editor.component.scss'],
})
export class CampaignSegmentEditorComponent {
  @Input() segment!: CampaignSegment;
  @Input() positionInList!: number;
  @Input() disabledCheck!: boolean;
  @Output() onCheck: EventEmitter<boolean> = new EventEmitter();
  @Output() onClick: EventEmitter<boolean> = new EventEmitter();
  @Output() onDelete: EventEmitter<void> = new EventEmitter();

  get label(): string {
    return this.segment.categories.map(category => category.category.label).join(', ');
  }

  constructor() {}

  checkSegment(checked: boolean): void {
    this.onCheck.emit(checked);
  }

  click() {
    this.onClick.emit();
  }

  delete() {
    this.onDelete.emit();
  }
}
