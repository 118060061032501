<h1 id="title" class="title">Changement du mot de passe</h1>
<p class="sub-title">Renseignez un nouveau mot de passe</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b></b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b></b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="resetPass()" [formGroup]="form" aria-labelledby="title">
  <div class="form-control-group">
    <label for="input-password">Nouveau mot de passe :</label>
    <input
      nbInput
      type="password"
      id="password"
      name="password"
      class="first"
      placeholder="Nouveau mot de passe"
      formControlName="password"
      autofocus
      fullWidth
      fieldSize="large"
      [status]="form.get('password')?.dirty ? (form.get('password')?.invalid ? 'danger' : 'success') : 'basic'"
      required
      minlength="8"
      maxlength="20"
      [attr.aria-invalid]="form.get('password.invalid') && form.get('password')?.touched ? true : null"
    />
    <ng-container *ngIf="form.get('password')?.invalid && form.get('password')?.touched">
      <p class="caption status-danger" *ngIf="rePassHasErrors">Un mot de passe est requis !</p>
      <p class="caption status-danger" *ngIf="passwordHasErrors">
        Le mot de passe doit contenir entre
        {{ getConfigValue('forms.validation.password.minLength') }}
        et {{ getConfigValue('forms.validation.password.maxLength') }}
        caractères
      </p>
    </ng-container>
  </div>
  <div class="form-group">
    <label for="input-re-password">Confirmez le nouveau mot de passe :</label>
    <input
      nbInput
      id="input-re-password"
      name="rePass"
      type="password"
      class="last"
      formControlName="rePass"
      placeholder="Confirmez le mot de passe"
      fullWidth
      fieldSize="large"
      [status]="
        form.get('rePass')?.touched
          ? form.get('rePass')?.invalid || password != rePass
            ? 'danger'
            : 'success'
          : 'basic'
      "
      required
      [attr.aria-invalid]="form.get('rePass')?.invalid && form.get('rePass')?.touched ? true : null"
    />
    <ng-container *ngIf="form.get('rePass')?.touched">
      <p class="caption status-danger" *ngIf="form.get('rePass')?.invalid && form.get('rePass')?.errors && !rePass">
        La confirmation du mot de passe est nécessaire !
      </p>
      <p class="caption status-danger" *ngIf="password !== rePass">Les mots de passe ne sont pas identiques</p>
    </ng-container>
  </div>

  <button
    nbButton
    status="danger"
    fullWidth
    size="large"
    [disabled]="submitted || !form.valid"
    [class.btn-pulse]="submitted"
  >
    Envoyer
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../../login">Retour à la page de connexion</a></p>
</section>
