import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, mergeMap, of, BehaviorSubject, switchMap, filter, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../model/category.model';
import { MetaCategory } from '../model/meta-category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  //list of available meta categories
  public metaCategories: MetaCategory[] = [];
  //current meta category (set to first value by default when loaded)
  public currentMeta: MetaCategory | undefined = undefined;
  // List of available categories, by meta category ID
  public categories: { [metaId: number]: Category[] } = {};
  private categoriesAreLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private categoriesAreLoaded: boolean = false;
  private categoriesAreLoading = false;

  constructor(private http: HttpClient) {}

  clear() {
    this.metaCategories = [];
    this.categories = {};
    this.categoriesAreLoaded = false;
    this.categoriesAreLoaded$.next(false);
  }

  // Loading categories, idealy should be done once
  loadCategories(): Observable<boolean> {
    return this.http
      .get<{ meta_category: { [key: string]: string }; [key: string]: { [key: string]: string } }>(
        `${environment.backendUrl}/api/category/`,
      )
      .pipe(
        map((result) => {
          const metaCategoriesData = result.meta_category;
          this.metaCategories = [];

          // Loading meta categories
          for (const [key, value] of Object.entries(metaCategoriesData)) {
            this.metaCategories.push(new MetaCategory(parseInt(key), value));
          }

          // Setting default current meta
          if (!this.currentMeta) {
            this.currentMeta = this.metaCategories[0];
          }

          // Building categories sets
          this.metaCategories.forEach((meta) => {
            const metaId = meta.id;
            if (result.hasOwnProperty(metaId + '') && typeof result[metaId] === 'object') {
              const categories: Category[] = [];
              for (const [key, value] of Object.entries(result[metaId])) {
                categories.push(new Category(parseInt(key), value, metaId)); // Include meta_category here
              }
              this.categories[metaId] = categories;
            }
          });

          return true;
        }),
      );
  }

  /**
   * Sets current meta category by its ID
   * @param metaId
   */
  setCurrentMeta(metaId: number | string) {
    this.currentMeta = this.metaCategories.find((mc) => mc.id === Number(metaId));
  }

  // Return available categories for current meta, loads categories & meta if needed.
  getCurrentMeta(): Observable<MetaCategory> {
    if (this.categoriesAreLoaded && this.currentMeta) {
      return of(this.currentMeta);
    } else if (!this.categoriesAreLoading) {
      this.categoriesAreLoading = true;
      this.loadCategories().subscribe((success) => {
        if (success && this.currentMeta) {
          this.categoriesAreLoading = false;
          this.categoriesAreLoaded = true;
          this.categoriesAreLoaded$.next(true);
        } else {
          this.categoriesAreLoading = false;
          throw new Error('No categories');
        }
      });
    }

    return this.categoriesAreLoaded$.pipe(
      filter((loaded) => loaded === true),
      take(1),
      map(() => {
        if (this.currentMeta) {
          return this.currentMeta;
        } else {
          throw new Error('No current meta category');
        }
      }),
    );
  }

  getCategoryById(id: number, metaId: number): Category | undefined {
    const categories = this.categories[metaId];
    if (categories) {
      return categories.find((cat) => cat.id === id);
    }
    return undefined;
  }

  // Retourne les catégories disponibles pour la méta donnée, charge les catégories et la méta si nécessaire.
  getCategories(metaIds?: number[]): Observable<Category[]> {
    if (this.categoriesAreLoaded && metaIds) {
      // Collect categories from the specified meta categories
      const categories = metaIds.reduce((acc, metaId) => {
        const cats = this.categories[metaId] || [];
        return acc.concat(cats);
      }, [] as Category[]);
      return of(categories);
    } else if (this.categoriesAreLoaded && this.currentMeta?.id) {
      return of(this.categories[this.currentMeta.id]);
    } else if (!this.categoriesAreLoading) {
      this.categoriesAreLoading = true;
      return this.loadCategories().pipe(
        map((success) => {
          this.categoriesAreLoading = false;
          if (success) {
            this.categoriesAreLoaded = true;
            this.categoriesAreLoaded$.next(true);
            if (metaIds) {
              const categories = metaIds.reduce((acc, metaId) => {
                const cats = this.categories[metaId] || [];
                return acc.concat(cats);
              }, [] as Category[]);
              return categories;
            } else if (this.currentMeta?.id) {
              return this.categories[this.currentMeta.id];
            } else {
              return [];
            }
          } else {
            throw new Error('Failed to load categories');
          }
        }),
      );
    } else {
      return this.categoriesAreLoaded$.pipe(
        filter((loaded) => loaded === true),
        take(1),
        map(() => {
          if (metaIds) {
            const categories = metaIds.reduce((acc, metaId) => {
              const cats = this.categories[metaId] || [];
              return acc.concat(cats);
            }, [] as Category[]);
            return categories;
          } else if (this.currentMeta) {
            return this.categories[this.currentMeta.id];
          } else {
            return [];
          }
        }),
      );
    }
  }
}