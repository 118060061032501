import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface Entreprise {
  id: number;
  name: string;
  siret?: number;
  is_active?: boolean;
  json_file?: string;
  project_id_big_query?: string;
  bucket?: string;
  db?: string;
  mailing_key_public?: string;
  mailing_key_private?: string;
  mailing_tool: number;
  download_segment: string,
  enterprise_logo?: string;  // Ajouter ce champ
  tracking: string;
  push_email: boolean;
}

interface EntrpriseResponse {
  count: number;
  results: Entreprise[];
}

@Injectable({
  providedIn: 'root',
})
export class EntrepriseService {
  constructor(private http: HttpClient) {}

  getEntreprise(): Observable<EntrpriseResponse> {
    return this.http.get<EntrpriseResponse>(`${environment.backendUrl}/api/enterprise/`);
  }
}
