import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter, ViewChild, TemplateRef} from '@angular/core';
import { GridsterConfig, GridsterItem, GridsterItemComponentInterface, GridsterPush } from 'angular-gridster2';
import { Category } from '../../model/category.model';
import { Subject, Subscription } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Product } from '../pages/dashboard/product.store';
import { GeneratorService } from 'src/app/deebr/service/generator.service';
import { SharedDashboardProductService } from 'src/app/deebr/service/shared-dashboard-product.service';
import { SharedProductService } from 'src/app/deebr/service/shared-product.service';
import { ScrappedImagesService } from 'src/app/deebr/service/scrapped-images.service';
import { NbDialogService } from '@nebular/theme';


export interface ProductDisposition {
  col: number;
  row: number;
  colspan: number;
  rowspan: number;
  ordre_generation: number;
  id: number | null;
  image_url_1: string;
  image_produit_scenario: any | undefined;
  width: number;
  height: number;
  url: string;
}

export interface DashboardProduct extends GridsterItem {
  id: number | null;
  image_url_1: string;
  image_produit_scenario: any | undefined;
  ordre_generation: number;
  width: number;
  height: number;
  url: string;
}

@Component({
  selector: 'app-drag-drop-products',
  templateUrl: './drag-drop-products.component.html',
  styleUrls: ['./drag-drop-products.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('ouvert', style({
        right: '0'
      })),
      state('ferme', style({
        right: '-800px'
      })),
      transition('ouvert <=> ferme', animate('800ms ease-in-out'))
    ]),
  ]

})
export class DragDropProductsComponent implements OnInit {
  @Input() categoryId!: Category['id'];
  @Input() show: boolean = false;
  @Input() templateId: number | null = null;
  @Input() productsDisposition: any[] = [];
  @Input() selectedProducts: { id: number | null; image_url_1: string; image_produit_scenario: string | undefined; ordre_generation: number; width: number; height: number; url: string }[] = [];
  @Output() orderChanged = new EventEmitter<{ categoryId: Category['id']; updatedProducts: ProductDisposition[] }>();
  @Output() imageSelected = new EventEmitter<{ imageUrl: string, taskId: string }>();
  @Input() resetIndex: { categoryId: Category['id'], productId: number | null } | null = null;

  @ViewChild('dialogUploadImage') dialogUploadImage!: TemplateRef<any>;

  selectedFile: File | null = null;
  errorMessage: string | null = null;
  panelOuvert: boolean = false;
  clickedProduct: DashboardProduct | null = null;
  private _showCreateGif: boolean = false;
  currentImageIndexes: { [key: number]: number } = {};
  images: { [key: number]: (string | string[])[] } = {};
  private scrappedImagesSubscription!: Subscription;
  sharedProducts: Product[] = [];
  loadingImages: { [key: number]: boolean } = {};

  

  togglePanel() {
    this.panelOuvert = !this.panelOuvert;
  }

  options!: GridsterConfig;
  dashboard: DashboardProduct[] = [];

  constructor(
    private generatorService: GeneratorService,
    private sharedDashboardProductService: SharedDashboardProductService,
    private scrappedImagesService: ScrappedImagesService,
    private sharedProductService: SharedProductService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit(): void {
    // console.log(this.productsDisposition)
    this.options = {
      itemChangeCallback: this.itemChange.bind(this),
      draggable: {
        enabled: true,
      },
      resizable: { enabled: false },
      displayGrid: 'none',
    };

    this.selectedProducts.forEach((product) => {
      this.currentImageIndexes[product.id!] = 0;
      this.images[product.id!] = [];
      this.loadingImages[product.id!] = true;
    });

    // Subscribe to the scrapped images service
    this.scrappedImagesSubscription = this.scrappedImagesService.currentProducts.subscribe((productMap) => {
      productMap.forEach((urls, productId) => {
        if (!this.images[productId!]) {
          this.images[productId!] = [];
        }
        // Add only unique URLs to prevent duplication
        let hasValidImages = false;
        urls.forEach((url: string) => {
          if (!this.images[productId!].includes(url) && url !== "no image found") {
            this.images[productId!].push(url);
            hasValidImages = true;

          }
        });
        this.loadingImages[productId!] = false;

      });
    });

    this.sharedProductService.currentProducts.subscribe(products => {
      this.sharedProducts = products;

      this.sharedProducts.forEach(product => {
        if (!this.images[product.id]) {
          this.loadingImages[product.id!] = true;
          this.images[product.id] = [];
        }
        if (!this.images[product.id].includes(product.image_url_1)) {
          this.images[product.id].unshift(product.image_url_1);
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['productsDisposition'] || changes['selectedProducts']) {
      const maxCols = Math.max(...this.productsDisposition.map(p => p.col + p.colspan)) + 1;
      const maxRows = Math.max(...this.productsDisposition.map(p => p.row + p.rowspan)) + 1;
      this.options = {
        ...this.options,
        maxCols: maxCols,
        maxRows: maxRows,
      };
      this.updateDashboard();
    }

    if (this.resetIndex && this.resetIndex.productId !== null) {

      this.currentImageIndexes[this.resetIndex.productId] = 0;
      this.resetIndex = null 
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.scrappedImagesSubscription) {
      this.scrappedImagesSubscription.unsubscribe();
    }
  }

  getImageList(product: DashboardProduct): (string | string[])[] {
    const productImages: (string | string[])[] = [...(this.images[product.id!] || [])];
  
    if (
      product.image_produit_scenario &&
      product.image_produit_scenario !== '' &&
      product.image_produit_scenario !== -1 &&
      !productImages.includes(product.image_produit_scenario)
    ) {
      productImages.unshift(product.image_produit_scenario);
    }
    return productImages;
  }

  getCurrentImage(product: DashboardProduct): string[] | string {
    const productImages = this.getImageList(product);
    const currentIndex = this.currentImageIndexes[product.id!];
    const currentImage = this.extractCurrentImage(productImages, currentIndex);
    return currentImage;
  }
  
  private extractCurrentImage(images: (string | string[])[], index: number): string[] | string {
    const imageElement = images[index] || images[0];
    if (Array.isArray(imageElement)) {
      return imageElement;
    } else {
      return imageElement || '';
    }
  }
  
  trackByItem(index: number, item: DashboardProduct): string {
    return `${item.id}-${item.x}-${item.y}-${item.cols}-${item.rows}`;  // Combinaison d'ID, de position et de taille
  }

  private updateProductDisposition(product: DashboardProduct, updatedImage: string[] | string): void {
    const productIndex = this.productsDisposition.findIndex(p => p.id === product.id);
    if (productIndex !== -1) {
      this.productsDisposition[productIndex].image_url_1 = updatedImage;  // Met à jour directement sans recréer
    }
  
    // Emit the updated productsDisposition
    this.orderChanged.emit({
      categoryId: this.categoryId,
      updatedProducts: this.productsDisposition
    });
  }

  previousImage(product: DashboardProduct): void {
    const productImages = this.getImageList(product);
    const totalImages = productImages.length;
    const index = this.currentImageIndexes[product.id!] || 0;
    this.currentImageIndexes[product.id!] = index > 0 ? index - 1 : totalImages - 1;
  
    const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[product.id!]);
    console.log("updatedImage", updatedImage);
    console.log("productImages", productImages);

    this.updateProductDisposition(product, updatedImage);
  }
  

  nextImage(product: DashboardProduct): void {
    const productImages = this.getImageList(product);
    const index = this.currentImageIndexes[product.id!] || 0;
    this.currentImageIndexes[product.id!] = index < productImages.length - 1 ? index + 1 : 0;
  
    // Met à jour uniquement le produit spécifique dans le tableau
    const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[product.id!]);
    console.log("updatedImage", updatedImage);
    console.log("productImages", productImages);
  
    this.updateProductDisposition(product, updatedImage);
  }

  selectImage(product: DashboardProduct, index: number): void {
    this.currentImageIndexes[product.id!] = index;
    const productImages = this.getImageList(product);
    const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[product.id!]);

    this.updateProductDisposition(product, updatedImage);
  }

  adjustImageSizeOnLoad(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    const containerElement = imgElement.parentElement as HTMLElement;
  
    if (!imgElement || !containerElement) {
      return; // Si l'un des éléments est null, quittez la fonction
    }
  
    this.adjustImageSize(imgElement, containerElement);
  }
  
  adjustImageSize(imgElement: HTMLImageElement, containerElement: HTMLElement): void {
    // Obtenez les dimensions de l'image
    const imgWidth = imgElement.naturalWidth;
    const imgHeight = imgElement.naturalHeight;
  
    // Obtenez les dimensions du conteneur
    const containerWidth = containerElement.clientWidth;
    const containerHeight = containerElement.clientHeight;
  
    // Calculez les ratios
    const imgRatio = imgWidth / imgHeight;
    const containerRatio = containerWidth / containerHeight;
  
    // Ajustez la taille de l'image en fonction du ratio
    if (imgRatio > containerRatio) {
      // Image est plus large par rapport au conteneur
      imgElement.style.width = '100%';
      imgElement.style.height = 'auto';
    } else {
      // Image est plus haute par rapport au conteneur
      imgElement.style.width = 'auto';
      imgElement.style.height = '100%';
    }
  }
  
  updateDashboard(): void {
    this.dashboard = [];
    
    // Parcourt chaque produit sélectionné et trouve sa position de disposition correspondante
    this.selectedProducts.forEach((product, index) => {
      // Trouve la disposition correspondant à cet ordre de sélection
      const slot = this.productsDisposition.find(sd => sd.ordre_generation === index + 1);
      
      // Si un slot correspondant est trouvé, prépare les données pour Gridster
      if (slot) {
        this.dashboard.push({
          cols: slot.colspan,
          rows: slot.rowspan,
          y: slot.row,
          x: slot.col,
          ordre_generation: slot.ordre_generation,
          image_url_1: product.image_url_1,
          image_produit_scenario: product.image_produit_scenario,
          id:product.id,
          width: product.width,
          height: product.height,
          url: product.url,
        });
      }
    });
    // console.log("productsDispo from drag", this.productsDisposition);
  }

  itemChange(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
    const indexInDashboard = this.dashboard.findIndex(d => d.ordre_generation === item['ordre_generation']);

  // Check if the index of the moved product in the dashboard array is not -1 (i.e., it exists in the array)
  if (indexInDashboard !== -1) {
    // Get the moved product from the dashboard array
    const movedProduct = this.dashboard[indexInDashboard];

    // Create a new position object with the x, y, cols, and rows properties of the moved item
    const newPosition = { x: item.x, y: item.y, cols: item.cols, rows: item.rows };

    // Find the index of the product at the new position in the dashboard array
    const productAtNewPositionIndex = this.dashboard.findIndex(p => 
      p.x === newPosition.x && p.y === newPosition.y && p.cols === newPosition.cols && p.rows === newPosition.rows && p.ordre_generation !== movedProduct.ordre_generation);

    // If a product is found at the new position
    if (productAtNewPositionIndex !== -1) {
      // Swap the 'ordre_generation' values between the moved product and the product at the new position
      [this.dashboard[indexInDashboard].ordre_generation, this.dashboard[productAtNewPositionIndex].ordre_generation] =
      [this.dashboard[productAtNewPositionIndex].ordre_generation, this.dashboard[indexInDashboard].ordre_generation];
    }
  }

    const updatedProducts = this.dashboard.map(product => ({
      id: product.id,
      image_url_1: product.image_url_1,
      image_produit_scenario: product.image_produit_scenario,
      ordre_generation: product.ordre_generation,
      col: product.x,
      row: product.y,
      colspan: product.cols,
      rowspan: product.rows,
      width: product.width,
      height: product.height,
      url: product.url,
    }));
    

    this.orderChanged.emit({
      categoryId: this.categoryId,
      updatedProducts: updatedProducts
    });

    this.productsDisposition = updatedProducts;
  
    // console.log('Dashboard updated after item change:', this.dashboard);
    // console.log('Updated products after item change:', updatedProducts);
  }

  removeProduct(product: DashboardProduct): void {
    // Find the index of the product in the dashboard array
    const index = this.dashboard.findIndex(p => p.id === product.id);
    // If the product is found, remove it and replace its id with null and image URL with an empty string
    if (index !== -1) {

      if (this.dashboard[index].id !== null) {
        this.currentImageIndexes[this.dashboard[index].id!] = 0;
      }
      this.dashboard[index].id = null;
      this.dashboard[index].image_url_1 = "";
      this.dashboard[index].image_produit_scenario = undefined;
      this.dashboard[index].url = "";
      // Prepare the updatedProducts array to emit
      const updatedProducts = this.dashboard.map((p, i) => ({
        id: p.id,
        image_url_1: p.image_url_1,
        image_produit_scenario: p.image_produit_scenario,
        ordre_generation: p.ordre_generation,
        col: p.x,
        row: p.y,
        colspan: p.cols,
        rowspan: p.rows,
        width: p.width,
        height: p.height,
        url: p.url,
      }));

      this.orderChanged.emit({
        categoryId: this.categoryId,
        updatedProducts: updatedProducts
      });
      this.productsDisposition = updatedProducts;
      
      // console.log('Product removed:', product);
      // console.log('Dashboard updated after product removal:', this.dashboard);
      // console.log('Updated products after product removal:', updatedProducts);
    }
  }

  onButtonClick(event: MouseEvent, product: DashboardProduct): void {
    event.stopPropagation(); // Prevent click from bubbling up to the label
    document.getElementById('file-upload-drag-and-drop')?.click(); // Manually trigger click on the input
    this.clickedProduct = product;
    // console.log("onButtonClick Clicked product:", this.clickedProduct);
  }

  setClickedProduct(product: DashboardProduct): void {
    this.clickedProduct = product;  // Enregistre le produit cliqué
  }
  
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];  // Enregistre le fichier sélectionné
      if (this.clickedProduct) {  // Utilise le produit cliqué pour le téléchargement
        this.uploadSelectedFile(this.clickedProduct);
      }
    }
  }

  uploadSelectedFile(product: DashboardProduct) {
    if (this.selectedFile) {
      const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
      const allowedExtensions = ['jpeg', 'jpg', 'png', 'webp'];
      const fileSizeInMB = this.selectedFile.size / 1000000; // Convertir la taille en Mo
  
      if (!allowedExtensions.includes(fileExtension!)) {
        this.errorMessage = "Ce format de fichier n’est pas pris en charge. Vous pouvez importer un fichier .jpeg, .png ou .webp.";
        this.dialogService.open(this.dialogUploadImage);
        return;
      }
  
      if (fileSizeInMB > 5) {
        this.errorMessage = "La taille du fichier ne doit pas dépasser 5 Mo.";
        this.dialogService.open(this.dialogUploadImage);
        return;
      }
  
      this.generatorService.uploadFile(this.selectedFile).subscribe((uploadedImageUrl: string) => {
        const productImages = this.getImageList(product);
        productImages.unshift(uploadedImageUrl);
        this.images[product.id!] = productImages;
        this.currentImageIndexes[product.id!] = 0;
        const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[product.id!]);
        this.updateProductDisposition(product, updatedImage);
      });
    } else {
      console.error("Aucun fichier sélectionné");
    }
  }
  

  handleCloseRequest() {
    this.showCreateGif = false;
  }

  get showCreateGif(): boolean {
    return this._showCreateGif;
  }

  set showCreateGif(value: boolean) {
    this._showCreateGif = value;
  }

  getFirstImageFromCarrousel(image: string[] | string): string {
    if (Array.isArray(image)) {
      return image[0] || '';
    } else {
      return image || '';
    }
  }

  createGif(event: MouseEvent, product: DashboardProduct) {
    this.showCreateGif = true;
    event.stopPropagation(); // Prevent click from bubbling up to the label
    this.clickedProduct = product;

    // console.log("createGif Clicked product:", this.clickedProduct);
    this.sharedDashboardProductService.addProduct(this.clickedProduct);
  }

  handleGifCreated(gifUrl: string) {
    if (this.clickedProduct) {

      const productImages = this.getImageList(this.clickedProduct);
      productImages.unshift(gifUrl);
      this.images[this.clickedProduct.id!] = productImages;
      this.currentImageIndexes[this.clickedProduct.id!] = 0;
      const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[this.clickedProduct.id!]);
      this.updateProductDisposition(this.clickedProduct, updatedImage);
    }
  }

  isGifImage(url: string | string[]): boolean {
    if (Array.isArray(url)) {
      // Check if the first image in the array is a GIF
      const firstUrl = url[0];
      return firstUrl.toLowerCase().endsWith('.gif');
    } else if (typeof url === 'string') {
      return url.toLowerCase().endsWith('.gif');
    }
    return false;
  }

  isCarrousel(image: string | string[]): boolean {
    return Array.isArray(image);
  }

  handleCarrouselCreated(carrouselImages: string[]) {
    console.log("Carrousel created");
    console.log(carrouselImages);
    if (this.clickedProduct) {
      const productImages = this.getImageList(this.clickedProduct);
      productImages.unshift(carrouselImages);
      console.log("productImages", productImages);
      this.images[this.clickedProduct.id!] = productImages;
      this.currentImageIndexes[this.clickedProduct.id!] = 0;
      const updatedImage = this.extractCurrentImage(productImages, this.currentImageIndexes[this.clickedProduct.id!]);
      this.updateProductDisposition(this.clickedProduct, updatedImage);
    }
  }

}
