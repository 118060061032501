import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Enterprise, User } from '../store/user.store';

interface GetUserResponse {
  count: number;
  next: string;
  previous: string;
  results: User[];
}

interface GetEnterpriseResponse {
  count: number;
  next: string;
  previous: string;
  results: Enterprise[];
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  resetPassword(password: string, token: string): Observable<any> {
    return this.http.post(`${environment.backendUrl}/api/password_reset/confirm/`, {
      password,
      token,
    });
  }

  getUsers(): Observable<User[]> {
    return this.http
      .get<GetUserResponse>(`${environment.backendUrl}/api/user/`)
      .pipe(map((res: GetUserResponse) => res.results));
  }

  getEntreprises(): Observable<Enterprise[]> {
    return this.http
      .get<GetEnterpriseResponse>(`${environment.backendUrl}/api/enterprise`)
      .pipe(map((res: GetEnterpriseResponse) => res.results));
  }

  getEntreprise(id: Enterprise['id']): Observable<Enterprise> {
    return this.http.get<Enterprise>(`${environment.backendUrl}/api/enterprise/${id}`);
  }
}
