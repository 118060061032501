import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  private logUrl = `${environment.backendUrl}/api/logs`; // Assurez-vous que ce endpoint est configuré dans votre backend

  constructor(private http: HttpClient) { }

  log(message: string, level: 'info' | 'warn' | 'error' = 'info'): void {
    const logEntry = { message, level, timestamp: new Date().toISOString() };
    this.http.post(this.logUrl, logEntry).subscribe();
  }
}