import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Report } from '../component/pages/report/report.store';


@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(private httpClient: HttpClient) {}

  public getReports(): Observable<Report[]> {
    return this.httpClient.get<Report[]>(`${environment.backendUrl}/api/report`);
  }

  public getPie(startDate?: string, endDate?: string): Observable<Report[]> {
    let params = new HttpParams();
    if (startDate) {
        params = params.append('startdate', startDate);
    }
    if (endDate) {
        params = params.append('endate', endDate);
    }
    return this.httpClient.get<Report[]>(`${environment.backendUrl}/api/pie`, { params: params });
}
}
