<img class="logo" src="/assets/logo_Deebr.png" />

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b></b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label for="input-email">Nom d'utilisateur</label>
    <input
      nbInput
      fullWidth
      [(ngModel)]="user.username"
      #username="ngModel"
      name="email"
      id="input-email"
      placeholder="utilisateur"
      fieldSize="large"
      autofocus
      [status]="username.dirty ? (username.invalid ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.username.required')"
      [attr.aria-invalid]="username.invalid && username.touched ? true : null"
    />
    <ng-container *ngIf="username.invalid && username.touched">
      <p class="caption status-danger" *ngIf="username.errors && username.errors['required']">
        Nom d'utilisateur requis
      </p>
      <p class="caption status-danger" *ngIf="username.errors && username.errors?.['pattern']">
        Email should be the real one!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label for="input-password">Mot de passe</label>
    <input
      nbInput
      fullWidth
      [(ngModel)]="user.password"
      #password="ngModel"
      name="password"
      type="password"
      id="input-password"
      placeholder="Password"
      fieldSize="large"
      [status]="password.dirty ? (password.invalid ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.password.required')"
      [minlength]="getConfigValue('forms.validation.password.minLength')"
      [maxlength]="getConfigValue('forms.validation.password.maxLength')"
      [attr.aria-invalid]="password.invalid && password.touched ? true : null"
    />
    <a class="forgot-password caption-2" routerLink="/auth/request-password">Mot de passe oublié ?</a>
    <ng-container *ngIf="password.invalid && password.touched">
      <p class="caption status-danger" *ngIf="password.errors && password.errors['password']">
        Password is required!
      </p>
      <p class="caption status-danger" *ngIf="password.errors && password.errors['maxlength']">
        Password should contain from {{ getConfigValue('forms.validation.password.minLength') }} to
        {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <nb-checkbox status="danger" name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">
      Se souvenir de moi
    </nb-checkbox>
  </div>

  <button
    nbButton
    fullWidth
    status="danger"
    size="large"
    [disabled]="submitted || !form.valid"
    [class.btn-pulse]="submitted"
  >
    Se connecter
  </button>
</form>

<!-- section class="another-action" aria-label="Register">
  Don't have an account? <a class="text-link" routerLink="../register">Register</a>
</section -->
