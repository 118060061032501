<div class="toolbox row justify-content-between">
  <div class="actions col-is-auto">
    <a id="backButton" nbButton appearance="outline" [routerLink]="['/pages/generator']"><i class="bi-arrow-left"></i></a>
    <input 
    type="text" 
    nbInput
    fullWidth
    [(ngModel)]="emailName" 
    (ngModelChange)="onEmailNameChange($event)" 
    nbInput 
    placeholder="Nom de l'email"
    style="width: 300px;" />
  </div>
  <div class="actions col-is-auto">
    <button id="undoButton" nbButton appearance="outline"><i class="bi-arrow-counterclockwise"></i></button>
    <button id="changeHistoryLink" nbButton appearance="outline"><i class="clock-outline"></i></button>
    <button id="redoButton" nbButton appearance="outline"><i class="bi-arrow-clockwise"></i></button>
  </div>
  <div class="actions col-is-auto">

    <div ngbDropdown>
      <button nbButton appearance="outline" ngbDropdownToggle>
        <i class="bi-gear"></i>
      </button>
      <div ngbDropdownMenu>
        <form class="px-4 py-3" [formGroup]="form">
          <div class="mb-3">
            <label for="title">Objet</label>
            <input type="text" class="form-control" id="title" formControlName="title" placeholder="Ici je saisis mon objet" />
          </div>
          <div>
            <label for="hiddenPreHeader">Sous objet masqué</label>
            <input type="text" class="form-control" id="hiddenPreHeader" formControlName="hiddenPreHeader" placeholder="Ici je saisis mon objet" />
          </div>
        </form>
      </div>
    </div>

    <div ngbDropdown class="d-inline-block">
      <button nbButton appearance="outline" ngbDropdownToggle>
        <i class="bi bi-share"></i>
      </button>
      <div ngbDropdownMenu>
        <form class="px-4 py-3">
          <div class="mb-3">
            <label for="previewLink">Lien de Prévisualisation</label>
            <div class="input-group">
              <input type="text" id="previewLink" class="form-control" readonly [value]="previewHtmlUrl">
              <button class="btn btn-outline-secondary" type="button" (click)="previewHtmlUrl && copyToClipboard(previewHtmlUrl)">
                Copier
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    
    
    <button (click)="openExternalPreview()" nbButton appearance="outline"><i class="bi-tablet"></i></button>
    
    <button (click)="exportSocialMedia()" nbButton appearance="outline" [nbSpinner]="loading" [disabled]="loading" [nbTooltip]="'Export Batch Facebook Instagram'">
      <nb-icon icon="message-square-outline" pack="eva"></nb-icon>
    </button>
    
    <button id="codeEditor" nbButton appearance="outline" status="danger"><i class="bi-code-slash"></i></button>
    <button
      nbButton
      appearance="outline"
      status="danger"
      (click)="exportProcess()"
      [disabled]="isExporting"
      [nbSpinner]="isExporting"
      nbSpinnerStatus="danger"
    >
      Exporter
    </button>
    <button nbButton [disabled]="isManualSave" [nbSpinner]="isManualSave" appearance="filled" status="danger" (click)="save(true)">Enregistrer</button>
  </div>
</div>

<div class="notification-zone"></div>

<div class="container">
  <div class="loading-spinner" [class.hidden]="!isLoading">
      <div class="spinner"></div>
  </div>
  <div class="editor" [class.hidden]="isLoading">
    <div id="stripoPreviewContainer"></div>
    <div id="stripoSettingsContainer" class="full-height"></div>
  </div>
</div>


<script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yEx1q6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>

<app-email-preview-popup *ngIf="showPopup"></app-email-preview-popup>


<ng-template #dialogErrorExport let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="text-center">Une erreur s'est produite lors de l'export batch. <br>Nous vous invitons à vous rapprocher de votre contact Deebr si l'erreur se reproduit</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="closeDialog()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogErrorAnimate let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>{{errorMessage}}</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="closeDialog()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogExportTracking let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>
        <p class="centered-text">Voulez-vous ajouter le tracking à votre email ?</p>
        <p class="text-12">Voici votre tracking par défaut :</p>
        <div class="input-button-container">
          <input type="text" [(ngModel)]="enterpriseTracking" [disabled]="!canEditTracking" [readonly]="!canEditTracking" class="tracking-input" [ngClass]="{'disabled-input': !canEditTracking}"/>
          <button nbButton size="tiny" (click)="toggleEditTracking()">{{ canEditTracking ? 'Sauvegarder' : 'Modifier' }}</button>
        </div>
        <br>
        <div class="row">
          <div class="col text-center">
            <button nbButton status="danger" size="tiny" class="espacement-bouton-gauche" (click)="confirmExport(false)">Non</button>
            <button
              nbButton
              status="success"
              size="tiny"
              class="espacement-bouton-droite"
              (click)="confirmExport(true)"
              [disabled]="!enterpriseTracking"
              *ngIf="enterpriseTracking; else tooltipTemplate">
              Oui
            </button>
            <ng-template #tooltipTemplate>
              <button
                nbButton
                status="success"
                size="tiny"
                class="espacement-bouton-droite"
                [disabled]="true"
                nbTooltip="Veuillez remplir le tracking avec des paramètres."
                nbTooltipStatus="info">
                Oui
              </button>
            </ng-template>
          </div>
        </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #dialogErrorTracking let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="text-center">Une erreur s'est produite lors de l'ajout de tracking dans votre email. <br>Nous vous invitons à vous rapprocher de votre contact Deebr si l'erreur se reproduit</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogPushEmail let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="text-center">{{ data.message }}</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="success" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogErrorPushEmail let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="text-center">Une erreur s'est produite lors de l'envoi de votre email<br>Nous vous invitons à vous rapprocher de votre contact Deebr si l'erreur se reproduit</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>