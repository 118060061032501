<form [formGroup]="segmentCreationForm" class="form inline">
  <div class="row">
    <div class="col-2">
      <nb-card>
        <nb-card-body>
          <select
            placeholder="Catégorie"
            [formControlName]="'metaCategory'"
            class="form-control"
            style="width: 100%; cursor: pointer"
          >
            <option *ngFor="let meta of metaCategories" value="{{ meta.id }}">{{ meta.label }}</option>
          </select>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="col-5"></div>

  </div>

  <div class="row">
    <div class="col-12">
      <nb-card [nbSpinner]="metaCategoryChanges" nbSpinnerStatus="primary">
        <nb-card-header>
          <input
            *ngIf="view === 'table'"
            type="text"
            placeholder="Rechercher"
            class="form-control search-field"
            name="search"
            [formControlName]="'search'"
          />
        </nb-card-header>
        <nb-card-body>

          <app-table
            *ngIf="view === 'table'"
            [metaCategory]="metaCategory"
            [currentCategory]="currentCategory"
            [dicoEntries]="filteredDicoEntries"
            [segments]="segments"
            [stockOrDispo]="stockOrDispo"
            [numberOfDays]="numberOfDays"
            [forecastDates]="forecastDates"
            [searchString]="searchString"
            (onRowClicked)="setCategory($event)"
          ></app-table>
        </nb-card-body>
      </nb-card>
    </div>
  </div>

  <div *ngIf="currentCategory" class="row">
    <div class="col-5">
      
      <nb-card style="min-height: 350px" [nbSpinner]="!displayForms" nbSpinnerStatus="primary">
        <nb-card-header> Courbe de lift pour la catégorie {{ currentCategory.label }} </nb-card-header>
        <nb-card-body>
          <canvas
            *ngIf="displayForms"
            baseChart
            [data]="liftCurveData"
            [options]="liftCurveChartOptions"
            type="line"
            [legend]="false"
            id="lift-curve-chart"
          >
          </canvas>
        </nb-card-body>
      </nb-card>
      <nb-card>
        <nb-card-body>
          <label for="volume">Volume</label>
          <input
            type="range"
            [disabled]="!displayForms"
            class="custom-range"
            name="volume"
            min="1"
            max="100"
            [formControlName]="'volume'"
          />
        </nb-card-body>
      </nb-card>
          <nb-card [nbSpinner]="vennDiagrammGenerating" nbSpinnerStatus="primary" style="min-height: 200px">
            <nb-card-header>Diagramme de Venn</nb-card-header>
            <nb-card-body>
              <div class="row" *ngIf="!vennDiagrammGenerating">
                <div class="col">
                  <venn-diagramm [data]="vennData" *ngIf="!vennDiagrammGenerating"></venn-diagramm>
                </div>
              </div>
            </nb-card-body>
          </nb-card>
    </div>
    <div class="col-2">
      <nb-card [nbSpinner]="!displayForms" nbSpinnerStatus="primary" style="min-height: 250px">
        <nb-card-body>
          <ng-container *ngIf="displayForms">
            <nb-alert class="status-success text-center mb-1">
              <strong>{{ emailsRatio | number: '.0-0' }}</strong>
              <span><small>Nombre d'emails que vous pouvez cibler</small></span>
            </nb-alert>
            <nb-alert class="status-success text-center mb-1">
              <strong>{{ volume | number: '.0-0' }} %</strong>
              <span><small>du total de la base d'email sera ciblé</small></span>
            </nb-alert>
            <nb-alert class="status-success text-center mb-1">
              <strong>{{ potential | number: '.0-0' }} %</strong>
              <span><small>du potentiel de ventes atteint</small></span>
            </nb-alert>
            <nb-alert class="status-success text-center mb-0">
              <strong>x {{ lift | number: '.1-1' }}</strong>
              <span><small>Lift</small></span>
            </nb-alert>
          </ng-container>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="col-5">
      <!-- suppression du graph de prévision
      <nb-card style="min-height: 350px" [nbSpinner]="!displayForms" nbSpinnerStatus="primary">
        <nb-card-header>Prévisions de vente pour la catégorie {{ currentCategory.label }}</nb-card-header>
        <nb-card-body>
          <canvas
            *ngIf="displayForms"
            baseChart
            [data]="evolData"
            [options]="evolCurveChartOptions"
            type="line"
            [legend]="true"
            id="evol-curve-chart"
          >
          </canvas>
        </nb-card-body>
      </nb-card>-->
      
        <nb-card>
          <nb-card-body>
            <div class="row">
              <div class="col-4">
                <select [formControlName]="'capping'" (change)="onValueChange($event)" class="form-control">
                  <option *ngFor="let cap of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="cap">{{ cap }}</option>
              </select>
            </div>
            <div class="col-8">
              <label>
                  {{ selectedCap | i18nPlural: {'=1': 'message', 'other': 'messages'} }} 
                  maximum par utilisateur
              </label>
          </div>
            
              
            </div>
          </nb-card-body>
        </nb-card>
      
      <nb-card>
        <nb-card-body>
          <div class="row">
            <div class="col-5">
              <p class="mt-2"><b>Catégorie</b> {{ currentCategory.label }}</p>
            </div>
            <div class="col-5">
              <button
                [disabled]="
                  !displayForms || !canUpsertSegment || buildingCampaign || mergingCampaign || segmentsGenerating
                "
                nbButton
                appearance="filled"
                status="danger"
                (click)="upsertSegment()"
                [nbSpinner]="buildingCampaign"
                nbSpinnerStatus="danger"
                class="btn btn-primary"
              >
                <ng-container *ngIf="!selectedSegment">Ajouter le segment</ng-container>
                <ng-container *ngIf="selectedSegment">Mettre à jour le segment</ng-container>
              </button>
            </div>
            
          </div>
        </nb-card-body>
      </nb-card>
        <nb-card [nbSpinner]="buildingCampaign || mergingCampaign" nbSpinnerStatus="primary" style="min-height: 200px">
          <nb-card-header>Campagne</nb-card-header>
          <nb-card-body>
            <table class="table table-segments" *ngIf="segments.length > 0">
              <thead>
                <th>
                  <nb-checkbox
                    status="danger"
                    [disabled]="segments.length < 2"
                    [checked]="allSegmentChecked"
                    (checkedChange)="checkAllSegment($event)"
                  ></nb-checkbox>
                </th>
                <th>Catégories</th>
                <th>Volume</th>
                <th>Lift</th>
                <th>Potentiel</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let segment of segments; let index = index"
                  [class.active]="segment === selectedSegment"
                  [class.selectable]="segment.categories.length === 1"
                  campaignSegmentEditor
                  [segment]="segment"
                  [disabledCheck]="segments.length < 2"
                  (onClick)="selectSegment(segment)"
                  (onCheck)="checkSegment(segment, $event)"
                  (onDelete)="deleteSegment(segment)"
                ></tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>TOTAL</th>
                  <td></td>
                  <td class="number">{{ total | number: '.0-0' }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </nb-card-body>
        </nb-card>
        <nb-card class="segments-actions">
          <nb-card-body>
            <div class="row actions">
              <button
                [disabled]="!canMergeCampaigns || buildingCampaign || mergingCampaign || segmentsGenerating"
                nbButton
                appearance="outline"
                status="danger"
                (click)="onClickMergeCampaigns()"
                [nbSpinner]="mergingCampaign"
                nbSpinnerStatus="danger"
              >
                Fusionner les campagnes
              </button>
              <button
                [disabled]="segments.length === 0 || buildingCampaign || mergingCampaign || segmentsGenerating"
                (click)="askGenerateSegments()"
                nbButton
                appearance="filled"
                status="danger"
                [nbSpinner]="segmentsGenerating"
                nbSpinnerStatus="danger"
              >
                Générer les segments
              </button>
            </div>
            <div class="row">
              <div class="col-12">
                <span *ngIf="segmentsGenerating" style="color: #35a2eb"
                  >Segments en cours de création, cela peut prendre plusieurs minutes</span
                >
              </div>
            </div>
          </nb-card-body>
        </nb-card>

    </div>
    
  </div>
</form>



<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>Êtes-vous sûr de vouloir générer les segments ?</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="success" (click)="generateSegments()">Oui</button>
        </div>
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">Non</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogError let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>Erreur de génération des segments</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">Ok</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogOk let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>Les segments ont été générés</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="success" (click)="ref.close()">Ok</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogCurveError let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>{{ errorMessage }}</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">Ok</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>