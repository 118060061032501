import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'venn-diagramm',
  templateUrl: './venn-diagramm.component.html',
  styleUrls: ['./venn-diagramm.component.scss'],
})
export class VennDiagrammComponent implements OnInit, AfterViewInit {
  @ViewChild('chartContainer') vennChartContainer!: any;
  vennChart!: anychart.charts.Venn;
  @Input() data: any;

  constructor() {}

  ngOnInit(): void {
    anychart.licenseKey(environment.anyChartLicenseKey);
    this.vennChart = anychart.venn(this.data);
  }

  ngAfterViewInit(): void {
    this.vennChart.container(this.vennChartContainer.nativeElement);
    this.vennChart.intersections().labels(false);
    this.vennChart.tooltip().format('nb mails: {%value}');

    this.vennChart.credits().enabled(false);

    this.vennChart.legend().enabled(false);
    this.vennChart.draw();
  }
}
