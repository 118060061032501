import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NbLoginComponent, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { NbTokenService, NbAuthToken } from '@nebular/auth';
import { TokenService } from 'src/app/deebr/service/token.service';

@Component({
  selector: 'app-deebr-login',
  templateUrl: './deebr-login.component.html',
  styleUrls: ['../auth.scss'],
})
export class DeebrLoginComponent extends NbLoginComponent implements OnInit {

  constructor(
    service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) options: {},
    cd: ChangeDetectorRef,
    router: Router,
    private tokenService: TokenService,
    private nbTokenService: NbTokenService
  ) {
    super(service, options, cd, router);
  }

  ngOnInit(): void {
    this.tokenService.verify().subscribe((isValid: boolean) => {
      if (!isValid) {
        this.router.navigate(['/auth/login']);
      }
    });

    // Ajoutez un log pour vérifier le token stocké dans localStorage
    const storedToken = localStorage.getItem('auth_app_token');
  }

  override login(): void {
    super.login();
    this.nbTokenService.tokenChange().subscribe((token: NbAuthToken) => {
      if (token.isValid()) {
        const tokenCreationTime = Date.now();
        localStorage.setItem('auth_app_token', token.toString()); // Stockez le jeton dans le localStorage
        localStorage.setItem('tokenCreationTime', tokenCreationTime.toString()); // Enregistrez l'heure de création du jeton

        // Ajoutez un log pour vérifier le token stocké
        const storedToken = localStorage.getItem('auth_app_token');
      }
    });
  }
}
