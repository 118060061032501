import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, map, mergeMap, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../model/category.model';
import { DfUnit } from '../model/df-unit.model';
import { CategoryService } from './category.service';

@Injectable({
  providedIn: 'root',
})
export class DfService {
  constructor(private http: HttpClient, private categoryService: CategoryService) { }

  getReal(numDays: number, meta_cat: number[], category?: Category): Observable<{ data_n_1: DfUnit; data_n: DfUnit }[]> {
    return combineLatest([this.categoryService.getCategories(meta_cat), this.categoryService.getCurrentMeta()]).pipe(
      mergeMap((result) => {
        let [categories, meta] = result;

        let params: { [key: string]: string } = {};
        if (numDays) {
          params['day'] = '' + numDays;
        }

        params['meta_category'] = meta.id + '';

        if (category && category.id) {
          params['category'] = category.id + '';
          //@todo not working,
          return this.http
            .get<{ data_n_1: DfUnit[]; data_n: DfUnit[] }>(`${environment.backendUrl}/api/real/`, { params })
            .pipe(
              map((result) => {
                let units: { data_n_1: DfUnit; data_n: DfUnit }[] = [];
                result.data_n.forEach((dfu, key) => {
                  units.push({ data_n: dfu, data_n_1: result.data_n_1[key] });
                });
                return units;
              }),
            );
        }
        //no category asked : returning overall data
        else {
          return this.http
            .get<{ data_n_1: DfUnit[]; data_n: DfUnit[] }>(`${environment.backendUrl}/api/allreal/`, { params })
            .pipe(
              map((result) => {
                let units: { data_n_1: DfUnit; data_n: DfUnit }[] = [];
                result.data_n.forEach((dfu, key) => {
                  units.push({ data_n: dfu, data_n_1: result.data_n_1[key] });
                });
                return units;
              }),
            );
        }
      }),
    );
  }

  getDfTo(
    numDays: number,
    meta_cat: number[],
    category?: Category,
  ): Observable<{ category: number; meta_category: number; pourcent_dispo?: number; stock?: number }[]> {

    return this.categoryService.getCategories(meta_cat).pipe(
      mergeMap((categories) => {

        let httpParams = new HttpParams();
        if (numDays) {
          httpParams = httpParams.set('day', '' + numDays);
        }
        if (category) {
          httpParams = httpParams.set('category', '' + category.id);
        }

        // Set meta_category as a comma-separated string
        httpParams = httpParams.set('meta_category', meta_cat.join(','));

        return this.http
          .get<{ [metaCategoryKey: string]: { [categoryId: string]: number | { pourcent_dispo: number } } }>(
            `${environment.backendUrl}/api/to/`,
            {
              params: httpParams,
            },
          )
          .pipe(
            map((data) => {
              let arr: { category: number; meta_category: number; pourcent_dispo?: number; stock?: number }[] = [];
              for (const [metaCategoryKey, categoriesData] of Object.entries(data)) {
                // Extract meta_category ID from the key, e.g., 'meta_category_1' => 1
                const metaCategoryId = parseInt(metaCategoryKey.replace('meta_category_', ''));

                for (const [categoryIdStr, value] of Object.entries(categoriesData)) {
                  let categoryId = parseInt(categoryIdStr);
                  if (typeof value === 'number') {
                    // Handle stock values
                    arr.push({ category: categoryId, meta_category: metaCategoryId, stock: value });
                  } else if (typeof value === 'object' && 'pourcent_dispo' in value) {
                    // Handle pourcent_dispo values
                    arr.push({ category: categoryId, meta_category: metaCategoryId, pourcent_dispo: value.pourcent_dispo });
                  }
                }
              }
              return arr;
            }),
          );
      }),
    );
  }
}
