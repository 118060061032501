import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { map, Observable, switchMap, tap, catchError,EMPTY } from 'rxjs';
import { Category } from 'src/app/deebr/model/category.model';
import { MetaCategory } from 'src/app/deebr/model/meta-category.model';
import { TaskState } from 'src/app/deebr/model/task.state';
import { ProductsService } from 'src/app/deebr/service/product.service';

export interface Product {
  id: number;
  category1_id: number;
  name: string;
  quantite_vendue: number;
  pageviews?: number;
  stock: number;
  url: string;
  image_url_1: string;
  deebr: 0 | 1;
  new: 0 | 1;
  visuel?: number;
  image_produit_scenario : string | number;
  score?: number;
  promo?: number;
  remise?:number;
}
interface ProductAction {
  metaCategoryId: number;
  categoryId: number;
  page: number;
  filter: 'deebr' | 'new' | 'visuel' | 'promo' | null;
  sort: { key: 'stock' | 'ventes' | 'pageviews' | 'score'; direction: 'ASC' | 'DESC' };
  searchTerm: string| null;
  exclude_ids: string| null;
  startat: string| null;
  startatComparator: string| null;
  endat: string| null;
  endatComparator: string| null;
  stockMin?: number;
  stockMax?: number;
}
export interface ProductsState {
  products: Record<
    MetaCategory['id'],
    Record<
      Category['id'],
      {
        entities?: Product[];
        filter: 'deebr' | 'new' | 'visuel' | 'promo' | null;
        sort: { key: 'stock' | 'ventes' | 'pageviews' | 'score'; direction: 'ASC' | 'DESC' };
        page: number;
        lastPage: boolean;
        loading: TaskState;
        isLoading: boolean;
        searchTerm: string | null;
        exclude_ids: string| null
        startat: string | null;
        startatComparator: string | null;
        endat: string | null;
        endatComparator: string | null;
        stockMin?: number;
        stockMax?: number;
      }
    >
  >;
}

const initialState = {
  products: {},
};

@Injectable()
export class ProductsStore extends ComponentStore<ProductsState> {
  constructor(private readonly productsService: ProductsService) {
    super(initialState);
  }

  readonly getProduct = this.effect((action$: Observable<{
    metaCategoryId: number;
    categoryId: number;
    page: number;
    filter: 'deebr' | 'new' | 'visuel' | 'promo' | null;
    sort: { key: 'stock' | 'ventes' | 'pageviews' | 'score'; direction: 'ASC' | 'DESC' };
    searchTerm: string| null;
    exclude_ids: string| null;
    list_images_url?: string;
    startat: string| null;
    startatComparator: string| null;
    endat: string| null;
    endatComparator: string| null;
    stockMin?: number;
    stockMax?: number;
  }>) => {
    return action$.pipe(
      tap(action => this.setLoadingState(action)),
      switchMap(action => {
        const currentState = this.get().products[action.metaCategoryId]?.[action.categoryId];
        // Preserve stock range from current state if not provided in action
        const stockMin = action.stockMin ?? currentState?.stockMin;
        const stockMax = action.stockMax ?? currentState?.stockMax;
        
        return this.productsService.getProduct(
          action.categoryId, 
          action.metaCategoryId, 
          action.page, 
          action.filter, 
          action.sort, 
          action.searchTerm, 
          action.exclude_ids, 
          action.list_images_url,
          action.startat, 
          action.startatComparator, 
          action.endat, 
          action.endatComparator,
          stockMin,
          stockMax
        ).pipe(
          map((res: Product[] | string) => (typeof res === 'string' ? [] : res)),
          tap(products => this.updateProductsState({...action, stockMin, stockMax}, products)),
          catchError(error => {
            console.error('Error fetching products:', error);
            this.setErrorState(action);
            return EMPTY;
          })
        )
      }),
    );
  });
  
  private setLoadingState(action: ProductAction): void {
    this.patchState(state => ({
      ...state,
      products: {
        ...state.products,
        [action.metaCategoryId]: {
          ...state.products[action.metaCategoryId],
          [action.categoryId]: {
            ...state.products[action.metaCategoryId]?.[action.categoryId],
            loading: TaskState.InProgress,
            isLoading: true,
          },
        },
      },
    }));
  }
  
  private updateProductsState(action: ProductAction, newProducts: Product[]): void {
    this.patchState(state => {
      const existingProducts = state.products[action.metaCategoryId]?.[action.categoryId]?.entities || [];
      const updatedProducts = existingProducts.concat(newProducts);
      const currentState = state.products[action.metaCategoryId]?.[action.categoryId];
  
      return {
        ...state,
        products: {
          ...state.products,
          [action.metaCategoryId]: {
            ...state.products[action.metaCategoryId],
            [action.categoryId]: {
              ...currentState,
              entities: updatedProducts,
              lastPage: newProducts.length < 12,
              loading: TaskState.Success,
              isLoading: false,
              stockMin: action.stockMin ?? currentState?.stockMin,
              stockMax: action.stockMax ?? currentState?.stockMax
            },
          },
        },
      };
    });
  }
  
  
  private setErrorState(action: ProductAction): void {
    this.patchState(state => ({
      ...state,
      products: {
        ...state.products,
        [action.metaCategoryId]: {
          ...state.products[action.metaCategoryId],
          [action.categoryId]: {
            ...state.products[action.metaCategoryId]?.[action.categoryId],
            loading: TaskState.Failed,
            isLoading: false,
          },
        },
      },
    }));
  }
  

  selectProducts() {
    return this.select((state) => state.products);
  }
}
