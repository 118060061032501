<div class="modal-fullscreen" *ngIf="isOpen">
    <div class="modal-header">
        <button type="button" class="btn btn-outline-secondary" (click)="close()">Retour</button>
    </div>
    
    <div class="modal-content">
        <!-- Titre 'Template' -->
        <div style="text-align: left; padding: 15px 0px 20px 30px;">
            <strong>Template</strong>
        </div>

        <!-- Search bar -->
        <div class="search-container" style="padding: 0 30px 20px 30px;">
            <input type="text" 
                   [(ngModel)]="searchQuery" 
                   placeholder="Rechercher un template..." 
                   class="search-input">
        </div>

        <!-- Grille des templates -->
        <div class="template-grid">
            <div *ngFor="let template of filteredTemplates" class="template-item" (click)="selectTemplate(template)">
                <img [src]="template.preview" alt="{{template.name}}">
                <p class="template-title"><strong>{{ template.name }}</strong></p>
            </div>
            <!-- Add this inside the template-grid div, before the *ngFor -->
        </div>
        <div *ngIf="filteredTemplates.length === 0" class="no-results-message">
            Aucun template ne correspond à votre recherche.
        </div>
    </div>
</div>
